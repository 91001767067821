
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {SideBarEntry} from "../../../../common/components/workbench/sidebar/SideBar";
import {NotificationsLogWindow} from "./components/NotificationsLogWindow";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";

export const SideBarLayout: {[category: string]: Array<SideBarEntry>} = {
    'Notifications': [
        {class: DiscoveredEdge, icon: 'geosearch'},
        {label: "Logs", component: NotificationsLogWindow, icon: 'document'},
    ],
}
