import {
    Button,
    ButtonGroup,
    Intent,
    Position,
    Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Reducer, useReducer } from "react";

interface Props {
    initialPage?: number;
    total: number;
    size?: number;
    onPageChange: (page: number) => void;
    className?: string;
    showEllipsis?: boolean
    buttonClassName?: string;
    pageNumberDivClassName?: string;
}

interface InitialState {
    currentPage: number;
    size: number;
    total: number;
    showEllipsis: boolean;
}

interface State extends InitialState {
    pages: number[];
    showEndEllipsis: boolean;
    showStartEllipsis: boolean;
    totalPages: number;
}

interface Actions {
    // tslint:disable-next-line:no-reserved-keywords
    type: "PAGE_CHANGE";
    page: number;
    showEllipsis: boolean;
}

const getState = ({ currentPage, size, total, showEllipsis }: InitialState): State => {
    const totalPages = Math.ceil(total / size);

    const PAGES_TO_SHOW = 5;
    const PAGES_ON_EITHER_SIDE = 2;

    let showStartEllipsis = false;
    let showEndEllipsis = false;

    // create an array of pages to repeat in the pager control
    let startPage = 0;
    let endPage = 0;
    if (totalPages <= PAGES_TO_SHOW) {
        // less than PAGES_TO_SHOW total pages, so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= PAGES_TO_SHOW - PAGES_ON_EITHER_SIDE) {
            // more than PAGINATION_THRESHOLD total pages so calculate start and end pages
            startPage = 1;
            endPage = PAGES_TO_SHOW;
            showEndEllipsis = true && showEllipsis;
        } else if (currentPage + PAGES_ON_EITHER_SIDE >= totalPages) {
            // current page approaching the total pages
            startPage = totalPages - (PAGES_TO_SHOW - 1);
            endPage = totalPages;
            showStartEllipsis = true && showEllipsis;
        } else {
            // current page is somewhere in the middle
            startPage = currentPage - PAGES_ON_EITHER_SIDE;
            endPage = currentPage + PAGES_ON_EITHER_SIDE;
            showStartEllipsis = true && showEllipsis;
            showEndEllipsis = true && showEllipsis;
        }
    }

    const pages = Array.from(
        { length: endPage + 1 - startPage },
        (_, i) => startPage + i
    );

    // Too large or small currentPage
    let correctCurrentPage = currentPage;
    if (currentPage > totalPages) {
        correctCurrentPage = totalPages;
    }
    if (currentPage <= 0) {
        correctCurrentPage = 1;
    }

    return {
        currentPage: correctCurrentPage,
        pages,
        showEndEllipsis,
        showStartEllipsis,
        size,
        total,
        totalPages,
        showEllipsis,
    };
};

const reducer: Reducer<State, Actions> = (state, action) => {
    switch (action.type) {
        case "PAGE_CHANGE":
            return getState({
                ...state,
                currentPage: action.page,
                showEllipsis: action.showEllipsis,
            });

        default:
            throw new Error();
    }
};

export const Pagination = React.memo<Props>(
    ({
         initialPage = 1,
         total,
         size = 100,
         onPageChange,
         className,
         showEllipsis,
         pageNumberDivClassName,
         buttonClassName,
    }) => {
        const [state, dispatch] = useReducer(
            reducer,
            { currentPage: initialPage, total, size, totalPages: 0, showEllipsis: showEllipsis || false },
            getState,
        );

        const changePage = (page: number) => {
            dispatch({ type: "PAGE_CHANGE", page, showEllipsis: showEllipsis || false });
            onPageChange(page);
        };

        if (state.totalPages === 1) {
            return null;
        }

        return (
            <div className={className}>
                <ButtonGroup>
                    <Tooltip
                        content="First"
                        disabled={state.currentPage === 1}
                        position={Position.TOP}
                    >
                        <Button
                            className={buttonClassName}
                            disabled={state.currentPage === 1}
                            icon={IconNames.DOUBLE_CHEVRON_LEFT}
                            onClick={() => changePage(1)}
                        />
                    </Tooltip>
                    <Tooltip
                        content="Previous"
                        disabled={state.currentPage === 1}
                        position={Position.TOP}
                    >
                        <Button
                            className={buttonClassName}
                            icon={IconNames.CHEVRON_LEFT}
                            disabled={state.currentPage === 1}
                            onClick={() =>
                                changePage(Math.max(1, state.currentPage - 5))
                            }
                        />
                    </Tooltip>
                    {state.showStartEllipsis && (
                        <Button disabled={true}>&#8230;</Button>
                    )}
                    {state.pages.map(page => (
                        <Button
                            className={buttonClassName}
                            key={page}
                            intent={
                                state.currentPage === page
                                    ? Intent.PRIMARY
                                    : Intent.NONE
                            }
                            onClick={() => changePage(page)}
                        >
                            <div className={pageNumberDivClassName}>{page}</div>
                        </Button>
                    ))}
                    {state.showEndEllipsis && (
                        <Button disabled={true}>&#8230;</Button>
                    )}
                    <Tooltip
                        content="Next"
                        disabled={state.currentPage === state.totalPages}
                        position={Position.TOP}
                    >
                        <Button
                            className={buttonClassName}
                            icon={IconNames.CHEVRON_RIGHT}
                            disabled={state.currentPage === state.totalPages}
                            onClick={() =>
                                changePage(
                                    Math.min(
                                        state.currentPage + 5,
                                        state.totalPages
                                    )
                                )
                            }
                        />
                    </Tooltip>
                    <Tooltip
                        content="Last"
                        disabled={state.currentPage === state.totalPages}
                        position={Position.TOP}
                    >
                        <Button
                            className={buttonClassName}
                            disabled={state.currentPage === state.totalPages}
                            icon={IconNames.DOUBLE_CHEVRON_RIGHT}
                            onClick={() => changePage(state.totalPages)}
                        />
                    </Tooltip>
                </ButtonGroup>
            </div>
        );
    }
);
