import * as React from "react";
import {EdgeJob} from "raasify-models-specification-ts/core/EdgeJob";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const EdgeJobList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: EdgeJob._created,
                    columnWidth: 220
                }),
                new InstanceListAttribute({
                    name: EdgeJob._command,
                    columnWidth: 100,
                    //rowFilter: (value) => [EdgeJob.command.RunCommand, EdgeJob.command.RunScript].includes(value),
                }),
                new InstanceListAttribute({
                    name: EdgeJob._associatedEdgeName,
                    columnWidth: 150,
                }),
            ]}
        />
    );
};

