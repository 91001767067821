import * as React from "react";

import {FC} from "react";
import {InstanceHomeProps} from "../../../InstanceHome";


const DestinationHome: FC<InstanceHomeProps> = (props) => {
    return (
        <div className={'instance-home-outer'}>
            <div className={'instance-home-title'}>Destinations</div>
            <div className={'instance-home-main-line'}>
                Destinations are instances of North bound data connectors.
            </div>
            <div className={'instance-home-sub-line'}>
                Select one on the left or add a new one by clicking the (+)
            </div>
        </div>
    );
}

export default DestinationHome;

