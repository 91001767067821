import React, {FC, ReactElement, useEffect, useState} from "react";
import {Alignment, Button, Classes, H4, Intent, Menu, MenuItem, Overlay} from "@blueprintjs/core";
import {ItemListPredicate, ItemListRenderer, ItemRenderer, Select2} from "@blueprintjs/select";
import classNames from "classnames";
import {getById} from "../../../../utils/instanceUtil";
import {Services} from "../../../../services/Services";
import {TabAttributeRelatedAttribute} from "../../../../model/TabAttributeRelatedAttribute";
import {Selected} from "../../../../model/Selected";
import {useAppSelector} from "../../../../redux/hooks";
import {AppToaster} from "../../../../../AppToaster";
import {InstanceEdit} from "../../../../../custom/benches/InstanceEdit";

type JSONObject = {[index: string]: any};

export interface RelatedInstanceSelectProps {
    instance: any;
    attributeName: string;
    onChange(name: string, value: string): void;
    disabled?: boolean;
    attribute: {[index: string]: any};
    services: Services;
    tabAttribute?: ReactElement;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: any, err: any) => void) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedPage: string;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    onSelectedRelatedInstance?: (instance: JSONObject) => void;
    selected: Selected;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    sort?: (items: Array<any>) => void;
    inputs?: any;
    hidden?: boolean;
}

const _RelatedInstanceSelect = Select2.ofType<{[index: string]: any}>();

export const RelatedInstanceSelect: FC<RelatedInstanceSelectProps> = (props) => {
    const {
        instance,
        attribute,
        attributeName,
        onChange,
        disabled,
        services,
        tabAttribute,
        onInstanceSave,
        relatedSelectCount,
        setRelatedSelectCount,
        selectedPage,
        editMode,
        setEditMode,
        onSelectedRelatedInstance,
        selected,
        getInstances,
        getInstance,
        sort,
        inputs,
        hidden,
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [newInstance, setNewInstance] = useState<{}>({});
    const [nestingLevel, setNestingLevel] = useState<number>(0);

    const relatedSpecificationKey = attribute.associatedEntitySpecification;
    const relatedSpecification = services.specification.get(relatedSpecificationKey);

    const instances = useAppSelector((state) => state.instances)

    // Use default relatedAttributes if not defined: name, description
    const _relatedAttributes = tabAttribute?.props.relatedAttributes ? tabAttribute.props.relatedAttributes : [
        {name: 'name', colWidth: 5},
        {name: 'description', colWidth: 7}
    ]

    const renderOption: ItemRenderer<any> = (option, {handleClick, modifiers}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                disabled={disabled}
                className={'related-select-menu-item'}
                active={modifiers.active}
                key={option.id}
                onClick={handleClick}
                text={(
                    <div className={'grid'}>
                        {_relatedAttributes.map((attribute: TabAttributeRelatedAttribute) => {
                            return <div key={attribute.name} className={'col-' + attribute.colWidth}>{option[attribute.name]}</div>
                        })}
                    </div>
                )}
            />
        );
    };

    const renderMenu: ItemListRenderer<any> = ({ items, itemsParentRef, query, renderItem }) => {
        const renderedItems = items.map(renderItem).filter(item => item != null);
        return (
            <Menu
                className={'related-select'}
                ulRef={itemsParentRef}>
                <MenuItem
                    className={'related-select-menu-item'}
                    disabled={true}
                    text={(
                        <div className={'grid'}>
                            {_relatedAttributes.map((attribute: TabAttributeRelatedAttribute) => {
                                const label = relatedSpecification.attributes[attribute.name]
                                    ? relatedSpecification.attributes[attribute.name].label
                                    : relatedSpecification.attributes['id']
                                return <div key={attribute.name} className={'col-' + attribute.colWidth}>{label}</div>
                            })}
                        </div>
                    )}
                />
                {!tabAttribute?.props.disableRelatedAddNew && <MenuItem
                    className={'related-select-menu-item'}
                    disabled={false}
                    onClick={handleAddNewOnClick}
                    text={(
                        <div className={'related-select-add-new'}>Add New {relatedSpecification.label} ...</div>
                    )}
                />}
                {renderedItems}
            </Menu>
        );
    };

    const filterList: ItemListPredicate<{[index: string]: any}> = (query, items) => {
        const _filteredInstances: Array<{}> = Object.assign([], items);

        const filterData = (item: {[index: string]: {}}) => {
            let result = false;
            if(item.name === 'None' && item.id === null) {
                // don't filter out None (deselect)
                return true;
            }

            if(tabAttribute?.props.relatedAttributes) {
                if(tabAttribute?.props.filter && !tabAttribute?.props.filter(item, inputs)) {
                    return false;
                }
                for (const attribute of _relatedAttributes) {
                    if(item[attribute.name] && item[attribute.name].toString().toLowerCase().includes(query.toLowerCase())) {
                        result = true;
                    }
                }
            }
            return result;
        };

        return _filteredInstances.filter(item => filterData(item));
    }

    const handleInstanceEditAttributeChange = (name: string, value: string) => {
        setNewInstance({...newInstance, [name]: value});
    }

    const handleInstanceEditOnSave = (specification: {[index: string]: any}, instance: {[index: string]: any}, callback: (res: any, err: any) => void) => {
        //console.log("handleInstanceEditOnSave", specification, instance);
        services.data.post(specification.url, instance)
            .then((data) => {
                //console.log("post data", data);
                if (specification.key === relatedSpecification.key) {
                    onChange(attribute.name, data.id)
                    setNewInstance({});
                    setIsOpen(false);
                    setRelatedSelectCount(relatedSelectCount - 1);
                }
                callback(data, undefined);
            })
            .catch((err) => {
                console.log("post error", err);
                AppToaster.show({
                    icon: "error",
                    intent: Intent.DANGER,
                    message: err.message,
                });
                console.error(err);
                console.error(JSON.stringify(err, null, 4));
                callback(undefined, err);

            })
    }

    const handleAddNewOnClick = () => {
        const count = relatedSelectCount+1;
        setIsOpen(true);
        setRelatedSelectCount(count);
        if(nestingLevel === 0) {
            setNestingLevel(count);
        }
    }

    const handleAddNewOnCancel = () => {
        setIsOpen(false);
        setNewInstance({});
        setRelatedSelectCount(relatedSelectCount-1);
    }

    const classes = classNames(
        Classes.CARD,
        Classes.PORTAL,
        'instance-overlay',
        'related-instance-overlay-' + nestingLevel,
    );

    const onItemSelect = (item: {[index: string]: any}) => {
        onChange(attribute.name, item.id);
        onSelectedRelatedInstance && onSelectedRelatedInstance(item);
    }

    useEffect(() => {
        if(instances[relatedSpecificationKey]) {
            const selectedInstance = getById(instances[relatedSpecificationKey].instances, instance[attributeName]);
            onSelectedRelatedInstance && onSelectedRelatedInstance(selectedInstance as JSONObject)
        }
    }, [selected])

    useEffect(() => {
        if(instances[relatedSpecificationKey]) {
            const selectedInstance = getById(instances[relatedSpecificationKey].instances, instance[attributeName]);
            onSelectedRelatedInstance && onSelectedRelatedInstance(selectedInstance as JSONObject)
        }
    }, [instances]);

    useEffect(() => {
        getInstances(relatedSpecification);
    }, []);

    // bp4-text-large
    const className = disabled ? 'bp4-disabled' : '';

    if(!instances[relatedSpecificationKey] || !instance) {
        return <></>
    }

    const selectedInstance = getById(instances[relatedSpecificationKey].instances, instance[attributeName]);
    const _items = [{name: 'None', id: null}, ...instances[relatedSpecificationKey].instances]
    sort && sort(_items);

    return (
        <div className={'related-instance-select'}>
            <_RelatedInstanceSelect
                popoverProps={{matchTargetWidth: true, usePortal: false}}
                popoverContentProps={{className: 'object-list-suggest-popover-content'}}
                fill
                filterable={true}
                disabled={disabled}
                noResults={<MenuItem disabled={true} text="No results."/>}
                query={""}
                inputProps={{id: attributeName, className: 'related-select'}}
                activeItem={selectedInstance}
                key={attributeName}
                itemRenderer={renderOption}
                itemListRenderer={renderMenu}
                itemListPredicate={filterList}
                items={_items}
                onItemSelect={onItemSelect}
            >
                <div>
                    {!hidden && <Button
                        alignText={Alignment.LEFT}
                        className={className + ' select-button'}
                        text={selectedInstance ? selectedInstance.name : !disabled ? "Select " + relatedSpecification.label : ' '}
                        rightIcon="double-caret-vertical"
                    />}
                    {hidden && <div key={String(100 * Math.random() + Math.random())}></div>}
                </div>
            </_RelatedInstanceSelect>
            <Overlay
                isOpen={isOpen}
                //onClose={handleAddNewOnCancel}
                autoFocus
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus
                hasBackdrop
                usePortal
            >
                <div className={classes} style={{}}>
                    <H4 className={'new-instance-overlay-header'}>Add a new {relatedSpecification.label}</H4>
                    <InstanceEdit
                        key={'RelatedInstanceSelect_' + relatedSpecification.name}
                        onAttributeChange={handleInstanceEditAttributeChange}
                        onInstanceSave={handleInstanceEditOnSave}
                        onCancel={handleAddNewOnCancel}
                        instance={newInstance}
                        setInstance={setNewInstance}
                        services={services}
                        specification={relatedSpecification}
                        relatedSelectCount={relatedSelectCount}
                        setRelatedSelectCount={setRelatedSelectCount}
                        getInstances={getInstances}
                        getInstance={getInstance}
                        selectedPage={selectedPage}
                        editMode={editMode}
                        setEditMode={() => {}}
                        selected={selected}
                    />
                </div>
            </Overlay>
        </div>
    )
};
