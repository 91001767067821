import {SingleResultView} from "./OutputDataTable";

type JSONObject = {[index: string]: any};

export const flatten = function(data: JSONObject, lastAttributePathOnly?: boolean) {
    let l: any;
    let result: any = {};
    const recurse = (cur: any, prop: string) => {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        }
        else if (Array.isArray(cur)) {
            for(let i=0, l=cur.length; i<l; i++) {
                recurse(cur[i], prop + "." + i);
            }
            if (l === 0) {
                result[prop] = [];
            }
        }
        else {
            let isEmpty = true;
            for (const p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop+"."+p : p);
            }
            if (isEmpty && prop) {
                result[prop] = {};
            }
        }
    }
    recurse(data, "");

    // if lastAttributePathOnly set, just use the last part of the path as the key
    if(lastAttributePathOnly) {
        const _result: JSONObject = {};
        for(const [key, value] of Object.entries(result)) {
            const attributeName = getAttributeFromPath(key);
            _result[attributeName] = value;
        }
        result = _result;
    }

    return result;
}

export const getRowKeyRegex = (rowKeySpec: string) => {
    const rowKeyRegex = String(rowKeySpec)
        .replace(/\./g, "\\.")
        .replace(/\*/g, "\\d+")
    return new RegExp(rowKeyRegex);
}

export const getRowKeyFromPath = (rowKeySpec: string, path: string) => {
    let thisRowKey = "";
    const keyParts = rowKeySpec.split(".");
    const pathParts = path.split(".");

    for(let i = 0; i < keyParts.length; i++) {
        if(keyParts[i] === pathParts[i]) {
            // path match
            thisRowKey += "." + pathParts[i]
        }
        else if(keyParts[i] === '*' && pathParts[i].match(/\d+/)) {
            // array index match
            thisRowKey += "." + pathParts[i]
        }
        else {
            break;
        }
    }
    return thisRowKey.substring(1);
}

export const getRowKeys = (rowKeySpec: string, flattenedData: JSONObject) => {
    const rowKeys: any = {};
    for(const path of Object.keys(flattenedData)) {
        const thisRowKey = getRowKeyFromPath(rowKeySpec, path)
        const rowKeyRegex = getRowKeyRegex(rowKeySpec);
        if(thisRowKey.match(rowKeyRegex) && !rowKeys[thisRowKey]) {
            rowKeys[thisRowKey] = {};
        }
    }
    return rowKeys;
}

export const convertFlattenedToRows = (rowKeySpec: string, flattenedData: JSONObject) => {
    const rows = getRowKeys(rowKeySpec, flattenedData);
    for (const [path, value] of Object.entries(flattenedData)) {
        for (const rowKey of Object.keys(rows)) {
            const thisRowKey = getRowKeyFromPath(rowKeySpec, path);
            if (thisRowKey && rowKey.includes(thisRowKey)) {
                rows[rowKey][path] = value;
            }
        }
    }
    return rows;
}

export const getAttributeFromPath = (path: string) => {
    const attributeParts = path.split(".")
    const attributeName = attributeParts[attributeParts.length-1];
    return attributeName;
}

export const convertRowMapToArray = (rowMap: JSONObject) => {
    const rows = [];
    for (const [rowKey, rowVal] of Object.entries(rowMap)) {
        const row: JSONObject = {};
        // For each row, shorten the attributes
        for (const [attribute, value] of Object.entries(rowVal)) {
            const attributeName = getAttributeFromPath(attribute);
            row[attributeName] = value;
        }
        rows.push(row);
    }
    return rows;
}

export const convertOutputDataToHorizontalData
    = (data: JSONObject, singleResultView: SingleResultView) =>
{
    const flattenedData = flatten(data);
    const rowMap = convertFlattenedToRows(singleResultView.rowKeySpec as string, flattenedData)
    const rowArray = convertRowMapToArray(rowMap);
    //console.log({data, flattenedData, rowMap, rowArray});
    return rowArray;
}

