
export class TabAttributeRelatedAttribute {
    private readonly _name: string;
    private readonly _colWidth: number;

    constructor(name: string, colWidth: number) {
        this._name = name;
        this._colWidth = colWidth;
    }

    get name(): string {
        return this._name;
    }

    get colWidth(): number {
        return this._colWidth;
    }
}
