import React, {FC, ReactNode} from 'react';
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {Metric} from "raasify-models-specification-ts/core/Metric";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import EdgeHome from "./workbench/components/home/EdgeHome";
import EndpointHome from './workbench/components/home/EndpointHome';
import AutomationPolicyHome from "./workbench/components/home/AutomationPolicyHome";
import AutomationHome from "./workbench/components/home/AutomationHome";
import ActivationRequestHome from './notifications/home/ActivationRequestHome';
import DiscoveredEdgeHome from './notifications/home/DiscoveredEdgeHome';
import MetricHome from './workbench/components/home/MetricHome';
import {Selected} from "../../common/model/Selected";
import {Destination} from "raasify-models-specification-ts/core/Destination";
import {DestinationPolicy} from "raasify-models-specification-ts/core/DestinationPolicy";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";
import EndpointPolicyHome from "./workbench/components/home/EndpointPolicyHome";
import DestinationHome from './workbench/components/home/DestinationHome';
import DestinationPolicyHome from "./workbench/components/home/DestinationPolicyHome";


export interface InstanceHomeProps {
    selected: Selected;
}

export const InstanceHome: FC<InstanceHomeProps> = (props) => {
    const name = props.selected.specification?.name;
    return (
        <>
            {name === ActivationRequest.specification.name && <ActivationRequestHome {...props} />}
            {name === DiscoveredEdge.specification.name && <DiscoveredEdgeHome {...props} />}
            {name === Automation.specification.name && <AutomationHome {...props} />}
            {name === AutomationPolicy.specification.name && <AutomationPolicyHome {...props} />}
            {name === Destination.specification.name && <DestinationHome {...props} />}
            {name === DestinationPolicy.specification.name && <DestinationPolicyHome {...props} />}

            {name === Metric.specification.name && <MetricHome {...props} />}
            {name === Edge.specification.name && <EdgeHome {...props}/>}
            {name === Endpoint.specification.name && <EndpointHome {...props} />}
            {name === EndpointPolicy.specification.name && <EndpointPolicyHome {...props} />}
        </>
    );
};
