import React, {FC, ReactElement, useEffect, useRef, useState} from "react";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {core} from "raasify-models-specification-json/index.json";
import {Icon, Intent} from "@blueprintjs/core";
import {v4 as uuid} from 'uuid';
import _ from "lodash";
import {CustomComponentProps} from "../../../../../common/components/main/CustomComponentProps";
import {useAppDispatch, useAppSelector} from "../../../../../common/redux/hooks";
import {Selected} from "../../../../../common/model/Selected";
import {AppToaster} from "../../../../../AppToaster";
import {DefaultPanel} from "../../../../../common/components/workbench/common/panel/DefaultPanel";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {ObjectListSuggest} from "../../../../../common/components/workbench/common/forms/ObjectListSuggest";
import {useNavigate} from "react-router-dom";
import {Tooltip2} from "@blueprintjs/popover2";
import {builderSlice} from "../../../../../common/redux/slices/builder";
import {PluginAPI, Terminal} from "../../../../../common/components/terminal/Terminal";
import {DateTime} from 'luxon';
import ScrollToBottom from "react-scroll-to-bottom";
import {atomic} from 'atomic';
import {ConnectorRequest} from "raasify-models-specification-ts/core/ConnectorRequest";

type JSONObject = {[index: string]: any};

// @ts-ignore
const CONNECTOR_REQUEST_KEY = core.enums.Specification.ConnectorRequest;
const CONNECTOR_REQUEST_SPEC = core.specifications["core.ConnectorRequest"];

const CONNECTOR_TEMPLATE_ID_ANSIBLE = '3f0149a1-4d6d-4421-a1cd-55b712b4cda1';

const SERVICE_NAME = 'connector-ansible';

const AUTOMATION_TEMPLATE_ID = "f8bdd83e-3448-4f74-8808-5d3aceba42fc";

const CLI_PLAYBOOK = (endpoint: JSONObject, commands: Array<string>) => {
    let playbook;

    if(endpoint.remoteCLIType === 'Shell' || endpoint.associatedEndpointPolicyName === 'Edge') {
        playbook = `- name: Linux Device Commands
  hosts: all
  gather_facts: false
  tasks:
    - shell: |\n`;
        for(const command of commands) {
            playbook += `        ${command}\n`;
        }
    }
    else if(endpoint.remoteCLIType === 'Raw') {
        playbook = `- name: Linux Raw Device Commands
  hosts: all
  gather_facts: false
  tasks:
    - raw: |\n`;
        for(const command of commands) {
            playbook += `        ${command}\n`;
        }
    }
    else { //if(endpoint.remoteCLIType === 'CLICommand') {
        playbook = `- name: ${endpoint.associatedEndpointPolicyName} Commands
  gather_facts: false
  hosts: all
  vars:
    ansible_connection: ansible.netcommon.network_cli
    ansible_network_os: ${endpoint.ansibleNetworkOS}
  tasks:
    - cli_command:
        command: '{{item}}'
      with_items:\n`;
        for(const command of commands) {
            playbook += `        - ${command}\n`;
        }
    }
    //console.log("playbook", playbook);
    return playbook;
};

const MAX_CSS_CLASSES = 6;

const TIMEOUT = 30;

export const timeutil = {
    sleep: async (ms: number) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
};

const ENDPOINT_NOT_SELECTED_MESSAGE = 'Select Endpoint and then enter commands to execute at the prompt.';

const MSG = "Executing command, retrieving result:";


export const RemoteCLI: FC<CustomComponentProps> = (props) => {
    const {
        getInstances,
        getInstance,
        services,
        relatedSelectCount,
        setRelatedSelectCount,
        selectedPage,
        selectedDeployment,
    } = props;

    const instances = useAppSelector((state) => state.instances)
    const [instance, setInstance] = useState<JSONObject>({
        serviceName: "connector-ansible",
        request: {},
    });
    const [endpoint, setEndpoint] = useState<JSONObject>();
    const [response, setResponse] = useState<JSONObject>({});
    const [selected, setSelected] = useState<Selected>(new Selected());
    const [selectedOut, setSelectedOut] = useState<Selected>(new Selected());
    const [running, setRunning] = useState<boolean>(false);
    const [textArea, setTextArea] = useState<Array<ReactElement>>([]);
    const [commandIndex, setCommandIndex] = useState<number>(0);
    const [multiLineMode, setMultiLineMode] = useState<boolean>(false);
    const [currentCommand, setCurrentCommand] = useState<Array<string>>([]);

    const [API, setAPI] = useState<PluginAPI>();

    const instancesRef:any = useRef();
    instancesRef.current = instances;
    const endpointRef:any = useRef();
    endpointRef.current = endpoint;
    const instanceRef:any = useRef();
    instanceRef.current = instance;
    const commandIndexRef:any = useRef();
    commandIndexRef.current = commandIndex;
    const currentCommandRef:any = useRef();
    currentCommandRef.current = currentCommand;
    const multiLineModeRef:any = useRef();
    multiLineModeRef.current = multiLineMode;
    const textAreaRef:any = useRef();
    textAreaRef.current = textArea;
    const selectedDeploymentRef:any = useRef();
    selectedDeploymentRef.current = selectedDeployment;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleAttributeChange = (name: string, value: any) => {
        setInstance(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleEndpointChange = (endpoint: any) => {
        if(!endpoint.associatedEdgeId) {
            alert("Endpoint must be associated to an Edge.");
        }
        setInstance(prevState => ({
            ...prevState,
            associatedEdgeId: endpoint.associatedEdgeId,
            associatedEndpointId: endpoint.id,
        }))
        setEndpoint(endpoint);
        setPrompt(`${endpoint.name}${multiLineModeRef.current ? '<multi>' : ''}#`);
        updateTextAreaWithCurrentCommand(endpoint.name);
    }

    useEffect(() => {
        getInstances(Endpoint.specification, undefined);
    }, [])

    useEffect(() => {
        //console.log("Selected endpoint", currentCommand);
    }, [endpoint])

    useEffect(() => {
        //console.log("Current command", currentCommand);
    }, [currentCommand])

    useEffect(() => {
        //console.log("Multiline Mode", multiLineMode);
    }, [multiLineMode])

    useEffect(() => {
        //console.log("Text Area", textArea);
    }, [textArea])

    const handleTextAreaButtonClick = (playbook: string, endpoint: JSONObject) => {
        //console.log("handleTextAreaButtonClick", playbook);
        dispatch(builderSlice.actions.set({data: playbook, endpoint: endpoint}));
        console.log("Navigating to", `/deployments/${selectedDeploymentRef.current.id}/workbench/AutomationBuilder`);
        navigate(`/deployments/${selectedDeploymentRef.current.id}/workbench/AutomationBuilder`);
    }

    const textAreaContent = (additional: any, playbook: string, endpoint: any) => {
        const tooltipText = "Open playbook in Automation Builder";
        return (
            <div className={'remote-cli-textarea-container'}>
                <div className={'remote-cli-text-area-button'}>
                    <Tooltip2
                        className={'remote-cli-tooltip-container'}
                        content={<div className={'tooltip-content'}>{tooltipText}</div>}
                        placement="right"
                    >
                        <Icon icon={"play"} onClick={() => handleTextAreaButtonClick(playbook, endpoint)}/>
                    </Tooltip2>
                </div>
                <pre className={'remote-cli-textarea'}>{additional}</pre>
            </div>
        )
    }

    const appendTextArea = (additional: any, playbook: string, endpoint: any) => {
        setTextArea([...textAreaRef.current, textAreaContent(additional, playbook, endpoint)]);
    }

    const updateLatestTextArea = (additional: any, playbook: string, endpoint: any) => {
        setTextArea(() => {
            const newState = [...textAreaRef.current];
            if(newState.length > 0) {
                newState.pop();
            }
            newState.push(textAreaContent(additional, playbook, endpoint));
            return newState;
        });
    }

    const deleteLatestTextArea = () => {
        setTextArea(() => {
            const newState = [...textAreaRef.current];
            if(newState.length > 0) {
                newState.pop();
            }
            return newState;
        });
    }

    const RESPONSE_CHECK_INTERVAL = 333;
    const DOT_INTERVAL = 250;
    const MAX_DOTS = 6;

    const timeoutLoop = async (state: executionState, lineNumber: any, lines: any) => {
        await timeutil.sleep(DOT_INTERVAL*2);
        let firstRun = true;
        const initial = lines[lineNumber] + " ";
        let i=0;
        let x=-2;
        let forward = true;
        while(state.isRunning) {
            let dots = '';
            if(forward) {
                if(x===-2 && !firstRun) {
                    await timeutil.sleep(DOT_INTERVAL*3);
                }
                for (let z = 0; z <=MAX_DOTS; z++) {
                    dots += x===z || x+1===z || x+2===z ? ' —' : '  ';
                }
            }
            else {
                for (let z = MAX_DOTS; z >= 0; z--) {
                    dots += x===z || x-1===z || x-2===z ? ' —' : '  ';
                }
            }
            let line = initial + dots;

            const update = atomic(signal => async () => {
                if((API?.getLine(lineNumber) as string).includes(MSG)) {
                    // Only update if
                    await API?.updateLine(lineNumber, line);
                }
                if (signal.aborted) return
            }, 500)
            await update();

            x++;
            i++;
            if(x === MAX_DOTS + 3) {
                x = -3;

                forward = !forward;
                if(forward) {
                    await timeutil.sleep(DOT_INTERVAL);
                }
            }
            else {
                await timeutil.sleep(DOT_INTERVAL);
            }
            firstRun = false;
        }
        // This will ensure that the response will only update the line once the dots are done
        state.timeoutLoopDone = true;
    }

    const responseLoop = async (state: executionState) => {
        while(state.isRunning) {
            let connectorRequests = instancesRef.current[ConnectorRequest.getKey()]?.instances ? instancesRef.current[ConnectorRequest.getKey()].instances : [];
            for(const connectorRequest of connectorRequests) {
                if(connectorRequest.id === state.id && connectorRequest.reply) {
                    state.reply = connectorRequest.reply;
                    return;
                }
            }
            await timeutil.sleep(RESPONSE_CHECK_INTERVAL);
        }
    }

    const updateTextAreaWithCurrentCommand = (endpointName: string) => {
        if(endpointRef.current && currentCommandRef.current && currentCommandRef.current.length > 0) {
            const playbook = CLI_PLAYBOOK(endpointRef.current, currentCommandRef.current);
            updateLatestTextArea(
                <span className={getClassName()}>Command to execute on {endpointName}<br/>
                    Playbook:<br/>{playbook as string}
                </span>,
                playbook,
                endpointRef.current
            );
        }
    }

    const handleSetMultiLineMode = () => {
        setMultiLineMode(true);
        setPrompt(`${endpointRef.current?.name ? endpointRef.current?.name : ''}<multi>#`);
    }

    const handleSetSingleLineMode = () => {
        setMultiLineMode(false);
        setCurrentCommand([]);
        setPrompt(`${endpointRef.current?.name ? endpointRef.current?.name : ''}#`);
    }

    const handleSubmitCommand = () => {
        executeCLICommand(currentCommandRef.current);
        setCurrentCommand([]);
    }

    const handleReset = () => {
        setCurrentCommand([]);
        setMultiLineMode(false);
        setTextArea([]);
        setEndpoint(undefined);
        setPrompt(`#`);
    }

    type executionState = {reply: any|undefined, id: string|undefined, isRunning: boolean, timeoutLoopDone: boolean};

    const executeCLICommand = async (commands: Array<string>) => {
        setRunning(true);
        let state: executionState = {reply: undefined, id: undefined, isRunning: true, timeoutLoopDone: false};

        setResponse({});
        // add service name to instance
        instanceRef.current.serviceName = SERVICE_NAME;

        const playbook = CLI_PLAYBOOK(endpointRef.current, commands);
        const className = getClassName();
        setCommandIndex(commandIndexRef.current + 1);
        const commandTimestamp = DateTime.local().toFormat('yyyy-LL-dd HH:mm:ss');
        const textAreaFunction = appendTextArea;
        textAreaFunction(
            <span className={className}>
                Executed at: {commandTimestamp} on {endpointRef.current.name}<br/>
                Playbook:<br/>{playbook as string}
            </span>,
            playbook,
            endpointRef.current,
        );

        let msg = MSG;

        if(API) {
            await API.printLine(msg);
            const lines = API.getLines();
            const lineNumber = lines.length - 1;

            services.data.post(CONNECTOR_REQUEST_SPEC.url, {
                ...instanceRef.current,
                request: {
                    ...instanceRef.current.request,
                    playbook: playbook,
                    timeout: TIMEOUT,
                },
                associatedConnectorTemplateId: CONNECTOR_TEMPLATE_ID_ANSIBLE,
                associatedAutomationTemplateId: AUTOMATION_TEMPLATE_ID,
            }).then((res) => {
                state.id = res.id;
                Promise.race([
                    timeoutLoop(state, lineNumber, lines),
                    responseLoop(state)
                ])
                    .then(async (data: any) => {
                        // get data from event
                        const reply = state.reply;
                        state.isRunning = false;
                        while (!state.timeoutLoopDone) {
                            await timeutil.sleep(50);
                        }
                        console.log("got reply", reply);
                        if (reply && reply.error) {
                            API?.updateLine(lineNumber, (
                                <span className={className}>
                                        {_.isObject(reply.error) ? JSON.stringify(reply.error) : reply.error}
                                    </span>
                            ));
                        }
                        else if (reply && reply.tasks && reply.tasks.length > 0) {
                            if (reply.tasks[0].stdout && (reply.tasks[0].stdout !== "" || !reply.tasks[0].msg)) {
                                // Add timestamp
                                const output = `Executed at: ${commandTimestamp}\n\n${reply.tasks[0].stdout}`
                                API?.updateLine(lineNumber, (<span className={className}>{output}</span>));
                            }
                            else if (reply.tasks[0].stderr) {
                                API?.updateLine(lineNumber, (<span className={className}>{reply.tasks[0].stderr}</span>));
                            }
                            else if (reply.tasks[0].results && reply.tasks[0].results.length > 0 && reply.tasks[0].results[0].msg) {
                                API?.updateLine(lineNumber, (<span className={className}>{reply.tasks[0].results[0].msg}</span>));
                            }
                            else if (reply.tasks[0].msg) {
                                API?.updateLine(lineNumber, (<span className={className}>{reply.tasks[0].msg}</span>));
                            }
                            else {
                                API?.updateLine(lineNumber, (<span className={className}>Request failed</span>));
                                console.error("Request failed (1):", data);
                            }
                        }
                        else {
                            API?.updateLine(lineNumber, (<span className={className}>Request failed</span>));
                            console.error("Request failed (2):", data);
                        }
                        setResponse(data);
                        setSelectedOut(new Selected().setInstanceId(uuid()));

                    })
                    .catch((err: Error) => {
                        AppToaster.show({
                            icon: "error",
                            intent: Intent.DANGER,
                            message: err.message,
                        });
                        console.error(err);
                    })
                    .finally(() => {
                        setRunning(false);
                    })
            })
            .catch((err: Error) => {
                AppToaster.show({
                    icon: "error",
                    intent: Intent.DANGER,
                    message: err.message,
                });
                console.error(err);
            })
        }
    }

    const getClassName = () => {
        const cssIndex = commandIndexRef.current % MAX_CSS_CLASSES;
        return `cli-command-${cssIndex}`;
    }

    const setPrompt = (prompt: string) => {
        //console.log("Setting prompt to", prompt);
        API?.setPrompt(prompt);
    }

    const bindPluginAPI = (API: PluginAPI) => {
        setAPI(API);
    }

    const handleCommand = (command: string) => {
        if (!endpointRef.current) {
            API?.printLine(ENDPOINT_NOT_SELECTED_MESSAGE);
        }
        else if(command === "trump") {
            API?.printLine(<img src={"https://media1.giphy.com/media/l0EQhAiNw3JDCz6kHq/giphy.gif?cid=790b7611d8e352ad1fc4e3a16719bcddc458fc8827d4850b&rid=giphy.gif&ct=g"} />);
        }
        else if(multiLineModeRef.current) {
            const commands = [...command.split('\n')];
            setCurrentCommand(commands);
            executeCLICommand(commands);
        }
        else {
            executeCLICommand(command.split('\n'));
        }
    }

    // Get just CLI capable Endpoints
    let endpoints = instances[Endpoint.getKey()]?.instances ? instances[Endpoint.getKey()].instances : [];
    const _endpoints = [];
    for(const endpoint of endpoints) {
        if(endpoint.remoteCLI) {
            _endpoints.push(endpoint);
        }
    }
    endpoints = [..._endpoints];

    return (
        <div className={"remote-cli-panel"}>
            <DefaultPanel
                selected={selected}
                onAttributeChange={handleAttributeChange}
                services={services}
                instance={instance}
                specification={CONNECTOR_REQUEST_SPEC}
                instanceEditTab={
                    <InstanceEditTab noHeader>
                        <TabAttributeRow>
                            <div className={'playbook-tester-width'}>
                                <div>
                                    <div className={'bp4-text-small attribute-label'}>Endpoint</div>
                                    <div className={'remote-cli-endpoint'}>
                                        <ObjectListSuggest
                                            attributeName={'name'}
                                            instance={endpoint}
                                            onChange={handleEndpointChange}
                                            items={endpoints}
                                            menuItemLayout={[
                                                {label: "Name", attributeName: "name", style: {width: "60%"}},
                                                {label: "Policy", attributeName: "associatedEndpointPolicyName", style: {width: "40%"}}
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </TabAttributeRow>
                        <div className={"remote-cli-container"}>
                            <div className={"remote-cli-terminal"}>
                                <Terminal
                                    bindPluginAPI={bindPluginAPI}
                                    handleCommand={handleCommand}
                                    onSingleLineMode={handleSetSingleLineMode}
                                    onMultiLineMode={handleSetMultiLineMode}
                                    onReset={handleReset}
                                />
                            </div>
                            <ScrollToBottom className={'remote-cli-textarea-outer'}>
                                {textArea}
                            </ScrollToBottom>
                        </div>
                    </InstanceEditTab>
                }
                onInstanceSave={(specification, instance, response) => {
                    // not used here
                    //console.log("onInstanceSave", specification, instance, response);
                }}
                relatedSelectCount={relatedSelectCount}
                setRelatedSelectCount={setRelatedSelectCount}
                getInstances={getInstances}
                getInstance={getInstance}
                selectedPage={selectedPage}
                editMode={true}
                setEditMode={() => {}}
            />
        </div>
    );
}
