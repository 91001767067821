import React, {FC, useEffect, useState} from "react";
import {Services} from "../../../../../common/services/Services";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {AutomationTemplate} from "raasify-models-specification-ts/core/AutomationTemplate";
import {MetricTrigger} from "raasify-models-specification-ts/core/MetricTrigger";
import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";
import {getFromInputs} from "../../../../../common/utils/instanceUtil";
import {DefaultPanel} from "../../../../../common/components/workbench/common/panel/DefaultPanel";
import {Selected} from "../../../../../common/model/Selected";
import {Button, Icon} from "@blueprintjs/core";
import {InstanceListFilter} from "../../../../../common/model/InstanceListFilter";
import {Metric} from "raasify-models-specification-ts/core/Metric";

type JSONObject = {[index: string]: any};

export interface MetricTriggerEditorProps {
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    services: Services;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedPage: string;
    selected: Selected;
    editMode: boolean;
    instance: JSONObject;
    instances: Array<JSONObject>;
    editInstances: Array<JSONObject>;
    setEditInstances: (instances: Array<JSONObject>) => void;
    isAddNew?: boolean;
}

const SPECIFICATION = MetricTrigger.specification;

export const MetricTriggerEditor: FC<MetricTriggerEditorProps> = (props) => {
    const {
        getInstances,
        getInstance,
        services,
        relatedSelectCount,
        setRelatedSelectCount,
        selectedPage,
        selected,
        editMode,
        instance,
        setEditInstances,
        editInstances,
        instances,
        isAddNew,
    } = props;

    const handleDelete = (deletingInstance: any) => {
        if(deletingInstance.id) {
            services.data.delete(SPECIFICATION.url as string, deletingInstance.id)
                .then((data) => {
                    const _instances = []
                    for (const instance of editInstances) {
                        if (instance !== deletingInstance) {
                            _instances.push(instance);
                        }
                    }
                    setEditInstances(_instances);
                });
        }
        else {
            const _editInstances = []
            for(const instance of editInstances) {
                if(instance !== deletingInstance) {
                    _editInstances.push(Object.assign({}, instance));
                }
            }
            setEditInstances(_editInstances);
        }
    }

    const handleAdd = () => {
        if(editMode) {
            setEditInstances([...editInstances, {}]);
        }
    }

    const handleAttributeChange = (_instance: any, name: string, value: string) => {
        const _editInstances = []
        for(const instance of editInstances) {
            if(instance === _instance) {
                const __instance = Object.assign({}, _instance);
                __instance[name] = value;
                _editInstances.push(__instance);
            }
            else {
                _editInstances.push(instance);
            }
        }
        setEditInstances(_editInstances);
    }

    const getPanel = (_instance: any, ix: number) => {
        return (
            <DefaultPanel
                className={`compliance-metric-trigger-panel compliance-metric-trigger-panel-${ix}`}
                getInstances={getInstances}
                getInstance={getInstance}
                instance={_instance}
                services={services}
                specification={SPECIFICATION}
                onInstanceSave={() => {}}
                relatedSelectCount={relatedSelectCount}
                setRelatedSelectCount={setRelatedSelectCount}
                selectedPage={selectedPage}
                onAttributeChange={(name, value) => handleAttributeChange(_instance, name, value)}
                instanceEditTab={
                    <InstanceEditTab noHeader>
                        <TabAttributeRow>
                            <TabAttribute style={{width: "25%"}} name={MetricTrigger._onResult} />
                            <TabAttribute style={{width: "25%"}} name={MetricTrigger._minimumOccurrences} />
                            <TabAttribute style={{width: "25%"}} name={MetricTrigger._retentionMode} />
                            <TabAttribute style={{width: "25%"}} name={MetricTrigger._retentionValue} default={(inputs: any) => {
                                if(!inputs.instance.name) {
                                    // Only set on first load
                                    return "1";
                                }
                            }}/>
                            {editMode && <Icon className={'compliance-metric-trigger-delete-button'} icon={"cross"} onClick={()=>handleDelete(_instance)} />}
                        </TabAttributeRow>
                        <TabAttributeRow>
                            <TabAttribute
                                style={{width: "50%"}}
                                name={MetricTrigger._associatedAutomationPolicyId}
                                relatedAttributes={[
                                    new TabAttributeRelatedAttribute(AutomationPolicy._name, 12),
                                ]}
                                filter={(item: any) => item.type === AutomationPolicy.type.User && item.scope === AutomationPolicy.scope.Application && item.id !== instance.associatedAutomationPolicyId}
                            />
                            <TabAttribute style={{width: "50%"}} name={AutomationTemplate._name} default={(inputs: any) => {
                                const automationPolicy = getFromInputs(MetricTrigger._associatedAutomationPolicyId, inputs);
                                if(automationPolicy && inputs.instance && inputs.prevInstance && inputs.instance.associatedAutomationPolicyId != inputs.prevInstance.associatedAutomationPolicyId) {
                                    return `${automationPolicy.name}`;
                                }
                                return inputs.instance && inputs.instance.associatedAutomationPolicyId ? undefined : null;
                            }}/>
                            <TabAttribute style={{width: "33%"}} name={MetricTrigger._frequency} />
                        </TabAttributeRow>
                        <TabAttributeRow>

                        </TabAttributeRow>
                    </InstanceEditTab>
                }
                editMode={editMode}
                setEditMode={() => {}}
                selected={selected}
            />

        );
    }

    const iconClass = editMode ? "compliance-metric-trigger-add-button-enabled" : "compliance-metric-trigger-add-button-disabled";

    if(!instances || isAddNew) {
        return <></>
    }

    return (
        <div>
            <hr />
            <div className={"compliance-metric-trigger-title"}>
                Metric Triggers
                <Icon
                    className={"compliance-metric-trigger-add-button " + iconClass}
                    icon="add"
                    onClick={handleAdd}
                />
            </div>
            {editInstances && editInstances.map((instance: any, ix: number) => {
                return getPanel(instance, ix);
            })}
            {editInstances && editInstances.length === 0 && <span className={'compliance-metric-trigger-no-entries'}>No entries found.</span>}
        </div>
    )

}
