import * as React from "react";
import {AutomationExecution} from "raasify-models-specification-ts/core/AutomationExecution";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";
import {logo} from "../../../workbench/components/list/InstanceListUtil";

export const AutomationExecutionList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}

            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const automationExecution = instance as AutomationExecution;
                    return (
                        <>
                            <div className={'normal-row-div instance-list-margin'}>
                                <div className={"instance-list-card-name"}>{automationExecution.associatedAutomationPolicyName}</div>
                                <div className={"instance-list-card-attribute"}>{automationExecution.created}</div>
                            </div>
                        </>
                    )
                }}

            instanceListAttributes={[
                new InstanceListAttribute({
                    name: AutomationExecution._associatedAutomationPolicyName,
                    columnWidth: 200,
                }),
                new InstanceListAttribute({
                    name: AutomationExecution._startDateTime,
                    columnWidth: 100,
                }),
                new InstanceListAttribute({
                    name: AutomationExecution._type,
                    columnWidth: 150,
                }),
                new InstanceListAttribute({
                    name: AutomationExecution._result,
                    columnWidth: 80,
                }),
            ]}
        />
    );
};
