import * as React from "react";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";
import {Destination} from "raasify-models-specification-ts/core/Destination";

export const DestinationList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Destination._name,
                    columnWidth: 150,
                }),
                new InstanceListAttribute({
                    name: Destination._description,
                    columnWidth: 250,
                }),
            ]}
        />
    );
};
