import React, {FC} from "react";
import {Specification as SpecificationType} from "common-models-ts";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {Selected} from "../../common/model/Selected";
import {Services} from "../../common/services/Services";
import {InstanceListAttribute} from "../../common/model/InstanceListAttribute";

import {AutomationList} from "./workbench/components/list/AutomationList";
import {AutomationPolicyList} from "./workbench/components/list/AutomationPolicyList";
import {MetricList} from "./workbench/components/list/MetricList";
import {EdgeList} from "./workbench/components/list/EdgeList";
import {EndpointList} from "./workbench/components/list/EndpointList";
import {EndpointPolicyList} from "./workbench/components/list/EndpointPolicyList";
import {DestinationList} from "./workbench/components/list/DestinationList";
import {DestinationPolicyList} from "./workbench/components/list/DestinationPolicyList";

import {ActivationRequestList} from "./notifications/components/list/ActivationRequestList";
import {DiscoveredEdgeList} from "./notifications/components/list/DiscoveredEdgeList";

import {ActivationRequestList as PowerActivationRequestList} from "./powerbench/components/list/ActivationRequestList";
import {ActivationResponseList as PowerActivationResponseList} from "./powerbench/components/list/ActivationResponseList";

import {ApplicationList as PowerApplicationList} from "./powerbench/components/list/ApplicationList";
import {ApplicationPolicyList as PowerApplicationPolicyList} from "./powerbench/components/list/ApplicationPolicyList";
import {AutomationExecutionList as PowerAutomationExecutionList} from "./powerbench/components/list/AutomationExecutionList";
import {AutomationList as PowerAutomationList} from "./powerbench/components/list/AutomationList";
import {AutomationPolicyList as PowerAutomationPolicyList} from "./powerbench/components/list/AutomationPolicyList";
import {AutomationTemplateList as PowerAutomationTemplateList} from "./powerbench/components/list/AutomationTemplateList";
import {CertificateList as PowerCertificateList} from "./powerbench/components/list/CertificateList";
import {MetricList as PowerMetricList} from "./powerbench/components/list/MetricList";
import {MetricExecutionList as PowerMetricExecutionList} from "./powerbench/components/list/MetricExecutionList";
import {MetricTriggerExecutionList as PowerMetricTriggerExecutionList} from "./powerbench/components/list/MetricTriggerExecutionList";
import {MetricTriggerList as PowerMetricTriggerList} from "./powerbench/components/list/MetricTriggerList";
import {ConnectorList as PowerConnectorList} from "./powerbench/components/list/ConnectorList";
import {ConnectorPolicyList as PowerConnectorPolicyList} from "./powerbench/components/list/ConnectorPolicyList";
import {ConnectorTemplateList as PowerConnectorTemplateList} from "./powerbench/components/list/ConnectorTemplateList";
import {ConnectorRequestList as PowerConnectorRequestList} from "./powerbench/components/list/ConnectorRequestList";
import {CredentialList as PowerCredentialList} from "./powerbench/components/list/CredentialList";
import {CronScheduleList as PowerCronScheduleList} from "./powerbench/components/list/CronScheduleList";
import {DeploymentPolicyList as PowerDeploymentPolicyList} from "./powerbench/components/list/DeploymentPolicyList";
import {DestinationList as PowerDestinationList} from "./powerbench/components/list/DestinationList";
import {DestinationPolicyList as PowerDestinationPolicyList} from "./powerbench/components/list/DestinationPolicyList";
import {DiscoveredEdgeJobList as PowerDiscoveredEdgeJobList} from "./powerbench/components/list/DiscoveredEdgeJobList";
import {DiscoveredEdgeList as PowerDiscoveredEdgeList} from "./powerbench/components/list/DiscoveredEdgeList";
import {EdgeJobList as PowerEdgeJobList} from "./powerbench/components/list/EdgeJobList";
import {EdgeList as PowerEdgeList} from "./powerbench/components/list/EdgeList";
import {EdgePolicyList as PowerEdgePolicyList} from "./powerbench/components/list/EdgePolicyList";
import {EndpointHostList as PowerEndpointHostList} from "./powerbench/components/list/EndpointHostList";
import {EndpointList as PowerEndpointList} from "./powerbench/components/list/EndpointList";
import {EndpointPolicyList as PowerEndpointPolicyList} from "./powerbench/components/list/EndpointPolicyList";
import {EndpointTemplateList as PowerEndpointTemplateList} from "./powerbench/components/list/EndpointTemplateList";
import {NodeList as PowerNodeList} from "./powerbench/components/list/NodeList";
import {NodePolicyList as PowerNodePolicyList} from "./powerbench/components/list/NodePolicyList";
import {SettingsList as PowerSettingsList} from "./powerbench/components/list/SettingsList";
import {StateGroupList as PowerStateGroupList} from "./powerbench/components/list/StateGroupList";
import {StateList as PowerStateList} from "./powerbench/components/list/StateList";
import {TransformerList as PowerTransformerList} from "./powerbench/components/list/TransformerList";
import {VMPolicyList as PowerVMPolicyList} from "./powerbench/components/list/VMPolicyList";

import {Edge} from "raasify-models-specification-ts/core/Edge";
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {AutomationExecution} from "raasify-models-specification-ts/core/AutomationExecution";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {Metric} from "raasify-models-specification-ts/core/Metric";
import {Application} from "raasify-models-specification-ts/core/Application";
import {ApplicationPolicy} from "raasify-models-specification-ts/core/ApplicationPolicy";
import {AutomationTemplate} from "raasify-models-specification-ts/core/AutomationTemplate";
import {Certificate} from "raasify-models-specification-ts/core/Certificate";
import {MetricExecution} from "raasify-models-specification-ts/core/MetricExecution";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {Connector} from "raasify-models-specification-ts/core/Connector";
import {ConnectorPolicy} from "raasify-models-specification-ts/core/ConnectorPolicy";
import {ConnectorTemplate} from "raasify-models-specification-ts/core/ConnectorTemplate";
import {ConnectorRequest} from "raasify-models-specification-ts/core/ConnectorRequest";
import {Credential} from "raasify-models-specification-ts/core/Credential";
import {CronSchedule} from "raasify-models-specification-ts/core/CronSchedule";
import {DeploymentPolicy} from "raasify-models-specification-ts/core/DeploymentPolicy";
import {DiscoveredEdgeJob} from "raasify-models-specification-ts/core/DiscoveredEdgeJob";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import {EdgeJob} from "raasify-models-specification-ts/core/EdgeJob";
import {EdgePolicy} from "raasify-models-specification-ts/core/EdgePolicy";
import {EndpointHost} from "raasify-models-specification-ts/core/EndpointHost";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";
import {Node} from "raasify-models-specification-ts/core/Node";
import {NodePolicy} from "raasify-models-specification-ts/core/NodePolicy";
import {Settings} from "raasify-models-specification-ts/core/Settings";
import {StateGroup} from "raasify-models-specification-ts/core/StateGroup";
import {State} from "raasify-models-specification-ts/core/State";
import {Transformer} from "raasify-models-specification-ts/core/Transformer";
import {VMPolicy} from "raasify-models-specification-ts/core/VMPolicy";
import {MetricTrigger} from "raasify-models-specification-ts/core/MetricTrigger";
import {ActivationResponse} from "raasify-models-specification-ts/core/ActivationResponse";
import {Destination} from "raasify-models-specification-ts/core/Destination";
import {DestinationPolicy} from "raasify-models-specification-ts/core/DestinationPolicy";
import {EndpointTemplate} from "raasify-models-specification-ts/core/EndpointTemplate";
import {MetricTriggerExecution} from "raasify-models-specification-ts/core/MetricTriggerExecution";

import {InstanceListFilter} from "../../common/model/InstanceListFilter";

export interface InstanceListProps {
    relatedView?: boolean;
    className: string;
    specification: { [index: string]: any };
    selected: Selected;
    rootSelected?: Selected;
    services: Services;
    filteredInstances?: Array<{ [index: string]: any }>;
    onSortClick?: (direction: number, specification: SpecificationType, attributeName: string) => void;
    onMount: (instanceAttributeList: Array<InstanceListAttribute>) => void;
    selectedPage: string;
    selectedDeployment: Deployment;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean)  => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    onInstanceDelete(specification: { [index: string]: {} } | null, instanceId: string, callback: (res: any, err: any) => void): void;
}

export const InstanceList: FC<InstanceListProps> = (props) => {
    const name = props.specification.name;
    if (props.selectedPage === 'workbench') {
        return (
            <>
                {name === Automation.specification.name && <AutomationList {...props} />}
                {name === AutomationPolicy.specification.name && <AutomationPolicyList {...props} />}
                {name === Metric.specification.name && <MetricList {...props} />}
                {name === Edge.specification.name && <EdgeList {...props} />}
                {name === Endpoint.specification.name && <EndpointList {...props} />}
                {name === EndpointPolicy.specification.name && <EndpointPolicyList {...props} />}
                {name === Destination.specification.name && <DestinationList {...props} />}
                {name === DestinationPolicy.specification.name && <DestinationPolicyList {...props} />}
            </>
        );
    }
    if (props.selectedPage === 'notifications') {
        return (
            <>
                {name === ActivationRequest.specification.name && <ActivationRequestList {...props} />}
                {name === DiscoveredEdge.specification.name && <DiscoveredEdgeList {...props} />}
            </>
        );
    }
    if (props.selectedPage === 'powerbench') {
        return (
            <>
                {name === ActivationRequest.specification.name && <PowerActivationRequestList {...props} />}
                {name === ActivationResponse.specification.name && <PowerActivationResponseList {...props} />}
                {name === Application.specification.name && <PowerApplicationList {...props} />}
                {name === ApplicationPolicy.specification.name && <PowerApplicationPolicyList {...props} />}
                {name === AutomationExecution.specification.name && <PowerAutomationExecutionList {...props} />}
                {name === Automation.specification.name && <PowerAutomationList {...props} />}
                {name === AutomationPolicy.specification.name && <PowerAutomationPolicyList {...props} />}
                {name === AutomationTemplate.specification.name && <PowerAutomationTemplateList {...props} />}
                {name === Certificate.specification.name && <PowerCertificateList {...props} />}
                {name === Connector.specification.name && <PowerConnectorList {...props} />}
                {name === ConnectorPolicy.specification.name && <PowerConnectorPolicyList {...props} />}
                {name === ConnectorTemplate.specification.name && <PowerConnectorTemplateList {...props} />}
                {name === ConnectorRequest.specification.name && <PowerConnectorRequestList {...props} />}
                {name === Credential.specification.name && <PowerCredentialList {...props} />}
                {name === CronSchedule.specification.name && <PowerCronScheduleList {...props} />}
                {name === DeploymentPolicy.specification.name && <PowerDeploymentPolicyList {...props} />}
                {name === Destination.specification.name && <PowerDestinationList {...props} />}
                {name === DestinationPolicy.specification.name && <PowerDestinationPolicyList {...props} />}
                {name === DiscoveredEdgeJob.specification.name && <PowerDiscoveredEdgeJobList {...props} />}
                {name === DiscoveredEdge.specification.name && <PowerDiscoveredEdgeList {...props} />}
                {name === Edge.specification.name && <PowerEdgeList {...props} />}
                {name === EdgeJob.specification.name && <PowerEdgeJobList {...props} />}
                {name === EdgePolicy.specification.name && <PowerEdgePolicyList {...props} />}
                {name === Endpoint.specification.name && <PowerEndpointList {...props} />}
                {name === EndpointHost.specification.name && <PowerEndpointHostList {...props} />}
                {name === EndpointPolicy.specification.name && <PowerEndpointPolicyList {...props} />}
                {name === EndpointTemplate.specification.name && <PowerEndpointTemplateList {...props} />}
                {name === Metric.specification.name && <PowerMetricList {...props} />}
                {name === MetricExecution.specification.name && <PowerMetricExecutionList {...props} />}
                {name === MetricTrigger.specification.name && <PowerMetricTriggerList {...props} />}
                {name === MetricTriggerExecution.specification.name && <PowerMetricTriggerExecutionList {...props} />}
                {name === Node.specification.name && <PowerNodeList {...props} />}
                {name === NodePolicy.specification.name && <PowerNodePolicyList {...props} />}
                {name === Settings.specification.name && <PowerSettingsList {...props} />}
                {name === StateGroup.specification.name && <PowerStateGroupList {...props} />}
                {name === State.specification.name && <PowerStateList {...props} />}
                {name === Transformer.specification.name && <PowerTransformerList {...props} />}
                {name === VMPolicy.specification.name && <PowerVMPolicyList {...props} />}
            </>
        );
    } else {
        return <></>
    }
};
