import * as React from "react";

import { TabAttributeRelatedAttribute } from "../../../../../common/model/TabAttributeRelatedAttribute";
import { Edge } from "raasify-models-specification-ts/core/Edge";
import { Endpoint } from "raasify-models-specification-ts/core/Endpoint";
import { Credential } from "raasify-models-specification-ts/core/Credential";
import { EndpointPolicy } from "raasify-models-specification-ts/core/EndpointPolicy";
import { VMPolicy } from "raasify-models-specification-ts/core/VMPolicy";
import { getSpecification } from "../../../../util/specificationUtil";
import { FC } from "react";
import { InstanceEditProps } from "../../../InstanceEdit";
import { BaseInstanceEdit } from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import { InstanceEditTabs } from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import { InstanceEditTab } from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import { TabAttributeRow } from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import { TabAttribute } from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import { getFromInputs } from "../../../../../common/utils/instanceUtil";
import { InputSchema } from "../../../../../common/components/workbench/common/schema/InputSchema";
import { Automation } from "raasify-models-specification-ts/core/Automation";
import { AutomationExecution } from "raasify-models-specification-ts/core/AutomationExecution";
import { MetricExecution } from "raasify-models-specification-ts/core/MetricExecution";
import { EndpointTemplate } from "raasify-models-specification-ts/core/EndpointTemplate";
import { Transformer } from "raasify-models-specification-ts/core/Transformer";
import OutputSchema from "../../../../../common/components/workbench/common/schema/OutputSchema";
import { ListAttribute } from "../../../../../common/model/ListAttribute";


const EndpointEdit: FC<InstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(Endpoint.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{ width: "33%" }} name={Endpoint._name} />
                        <TabAttribute
                            style={{ width: "33%" }}
                            name={Endpoint._associatedEndpointPolicyId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(EndpointPolicy._name, 5),
                                new TabAttributeRelatedAttribute(EndpointPolicy._description, 7),
                            ]}
                        />
                        <TabAttribute
                            style={{ width: "34%" }}
                            name={Endpoint._associatedEdgeId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(Edge._name, 5),
                                new TabAttributeRelatedAttribute(Edge._description, 7),
                            ]}
                        />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{ width: "80%" }} name={Endpoint._description} />
                        <TabAttribute style={{ width: "20%" }} name={Endpoint._inProduction} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{ width: "50%" }} name={Endpoint._nodeConfiguredState} disabled />
                        <TabAttribute style={{ width: "50%" }} name={Endpoint._nodeDiscoveredState} disabled />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{ width: "50%" }} name={Endpoint._applicationConfiguredState} disabled />
                        <TabAttribute style={{ width: "50%" }} name={Endpoint._applicationDiscoveredState} disabled />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{ width: "50%" }}
                            name={Endpoint._nodeSSHHost}
                            hidden={(inputs: any) => {
                                const endpointPolicy: EndpointPolicy = getFromInputs(Endpoint._associatedEndpointPolicyId, inputs);
                                if (!endpointPolicy) return true;
                                return endpointPolicy && !endpointPolicy.nodeSSHHost;
                            }}
                        />
                        <TabAttribute
                            style={{ width: "50%" }}
                            name={Endpoint._endpointHostSSHHost}
                            hidden={(inputs: any) => {
                                return true;
                                //const endpointPolicy: EndpointPolicy = getFromInputs(Endpoint._associatedEndpointPolicyId, inputs);
                                //return endpointPolicy && !endpointPolicy.endpointHost;
                            }}
                        />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{ width: "50%" }}
                            name={Endpoint._applicationBaseURI}
                            hidden={(inputs: any) => {
                                const endpointPolicy: EndpointPolicy = getFromInputs(Endpoint._associatedEndpointPolicyId, inputs);
                                if (!endpointPolicy) return true;
                                return endpointPolicy && !endpointPolicy.applicationBaseURI;
                            }}
                        />
                        <TabAttribute
                            style={{ width: "50%" }}
                            name={Endpoint._applicationHealthURI}
                            hidden={(inputs: any) => {
                                const endpointPolicy: EndpointPolicy = getFromInputs(Endpoint._associatedEndpointPolicyId, inputs);
                                if (!endpointPolicy) return true;
                                return endpointPolicy && !endpointPolicy.applicationHealthURI;
                            }}
                        />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{ width: "100%" }}
                            name={Endpoint._associatedVMPolicyId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(VMPolicy._name, 6),
                                new TabAttributeRelatedAttribute(VMPolicy._memoryGB, 3),
                                new TabAttributeRelatedAttribute(VMPolicy._vCPUs, 3),
                            ]}
                            hidden={(inputs: any) => {
                                return true;
                                //const endpointPolicy: EndpointPolicy = getFromInputs(Endpoint._associatedEndpointPolicyId, inputs);
                                //return endpointPolicy && !endpointPolicy.VMPolicy;
                            }}
                        />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{ width: "100%" }}
                            name={Endpoint._associatedCredentialId}
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(Credential._name, 4),
                                new TabAttributeRelatedAttribute(Credential._description, 6),
                                new TabAttributeRelatedAttribute(Credential._method, 2),
                            ]}
                        />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{ width: "100%" }} name={Endpoint._discoveryError} disabled hidden={(inputs: any) => (!inputs.instance.discoveryError)} />
                    </TabAttributeRow>
                    <InputSchema
                        {...props}
                        sourceUrl={EndpointTemplate.getUrl()}
                        sourceIdAttribute={EndpointPolicy._associatedEndpointTemplateId}
                        schemaAttribute={EndpointTemplate._inputSchema}
                        policyUrl={EndpointPolicy.getUrl()}
                        policyIdAttribute={Endpoint._associatedEndpointPolicyId}
                        policyInputAttribute={EndpointPolicy._inputData}
                    />
                    <OutputSchema
                        {...props}
                        sourceUrl={EndpointTemplate.getUrl()}
                        sourceIdAttribute={Endpoint._associatedEndpointTemplateId}
                        schemaAttribute={EndpointTemplate._outputSchema}
                        useSelf
                        resultAttribute={Endpoint._latestExecutionResponse}
                        listAttributes={[
                            new ListAttribute(AutomationExecution._startDateTime, "Started", 100),
                            new ListAttribute(AutomationExecution._result, "Result", 60),
                        ]}
                    />
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default EndpointEdit;

