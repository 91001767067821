import React, {FC} from "react";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {Selected} from "../../common/model/Selected";
import {Services} from "../../common/services/Services";
import {InstanceListAttribute} from "../../common/model/InstanceListAttribute";
import {InstanceJob} from "../../common/model/InstanceJob";
import {BaseRelatedContainer} from "../../common/components/workbench/instance/related/BaseRelatedContainer";

export interface RelatedContainerProps {
    specification: {[index: string]: any};
    selected: Selected;
    rootSelected?: Selected;
    services: Services;
    selectedPage: string;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, callback: (res: any, err: any) => void) => void;
    onInstanceDelete: (specification: {[index: string]: {}}, instanceId: string, callback: (res: any, err: any) => void) => void;
    selectedDeployment: Deployment;
    onInstanceListOnMount?: (instanceAttributeList: Array<InstanceListAttribute>) => void;
    onInstanceListOnSelect?: (instanceId: string) => void;
    onInstanceMenuOnJob?: (job: InstanceJob, parameters: any) => void;
}


export const RelatedContainer: FC<RelatedContainerProps> = (props) => {
    return (
        <BaseRelatedContainer
            {...props}
        />
    );
};
