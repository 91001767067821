import * as React from "react";
import {FC} from "react";
import {TabAttributeRelatedAttribute} from "../../../../model/TabAttributeRelatedAttribute";
import {StringMap} from "common-models-ts";

export interface TabAttributeProps {
    style?: StringMap;
    name: string;
    relatedAttributes? : Array<TabAttributeRelatedAttribute>;
    filter? : ((instance: any, inputs?: any) => boolean);
    sort?: (items: Array<any>) => void;
    suggestList?: Array<string>;
    disabled?: boolean;
    disableRelatedAddNew?: boolean;
    default?: (inputs: any) => string|null|undefined;
    hidden?: (inputs: any) => boolean;
    nullLabel?: string;
    password?: boolean;
    children?: React.ReactNode;
}

export const TabAttribute: FC<TabAttributeProps> = (props) => {
    return (
        <>
            {props.children}
        </>
    );
}
