import * as React from "react";

import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {AutomationTemplate} from "raasify-models-specification-ts/core/AutomationTemplate";
import {CronSchedule} from "raasify-models-specification-ts/core/CronSchedule";
import {InstanceEditProps} from "../../../InstanceEdit";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {BaseInstanceEdit} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {InputSchema} from "../../../../../common/components/workbench/common/schema/InputSchema";
import {getFromInputs} from "../../../../../common/utils/instanceUtil";
import {getRandomInt} from "../../../../../common/utils/numberUtil";

const AutomationPolicyEdit: FC<InstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(AutomationPolicy.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{width: "70%"}}
                            name={AutomationPolicy._associatedAutomationTemplateId}
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(AutomationTemplate._name, 9),
                                new TabAttributeRelatedAttribute(AutomationTemplate._packageLabel, 3),
                            ]}
                            filter={(item: any) => (item.type === AutomationTemplate.type.Configuration || item.type === AutomationTemplate.type.Service) && item.scope === AutomationTemplate.scope.Application}
                            sort={(items: any) => {
                                items.sort(function (a: any, b: any) {
                                    return (a.packageLabel && a.packageLabel.localeCompare(b.packageLabel))
                                        || (a.packageName && a.packageName.includes("/") && a.packageName.split("/")[1]
                                                && b.packageName && b.packageName.includes("/") && b.packageName.split("/")[1]
                                                && a.packageName.split("/")[1].localeCompare(b.packageName.split("/")[1]))
                                        || (a.name && a.name.localeCompare(b.name));
                                });
                            }}
                        />
                        <TabAttribute style={{width: "30%"}} name={AutomationPolicy._schedule} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{width: "40%"}}
                            name={AutomationPolicy._associatedCronScheduleId}
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(CronSchedule._name, 8),
                                new TabAttributeRelatedAttribute(CronSchedule._cronEntry, 4),
                            ]}
                            hidden={(inputs: any) => inputs.instance.schedule !== AutomationPolicy.schedule.RunPeriodically}
                        />
                        <TabAttribute
                            style={{width: "30%"}}
                            name={AutomationPolicy._startAtDateTime}
                            nullLabel={"Immediately"}
                            hidden={(inputs: any) => !inputs.instance.schedule || inputs.instance.schedule === AutomationPolicy.schedule.RunNow}
                        />
                        <TabAttribute
                            style={{width: "30%"}}
                            name={AutomationPolicy._endAtDateTime}
                            hidden={(inputs: any) => inputs.instance.schedule !== AutomationPolicy.schedule.RunPeriodically}
                            nullLabel={"Until removed"}
                        />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={AutomationTemplate._name} default={(inputs: any) => {
                            const automationTemplate = getFromInputs(AutomationPolicy._associatedAutomationTemplateId, inputs);
                            if(automationTemplate && inputs.instance && inputs.prevInstance && inputs.instance.associatedAutomationTemplateId != inputs.prevInstance.associatedAutomationTemplateId) {
                                // Only set on Automation Template change
                                return `${automationTemplate.name} - ${getRandomInt(9999)}`;
                            }
                            return inputs.instance && inputs.instance.associatedAutomationTemplateId ? undefined : null;
                        }}/>
                        <TabAttribute style={{width: "50%"}} name={AutomationTemplate._description} default={(inputs: any) => {
                            const automationTemplate = getFromInputs(AutomationPolicy._associatedAutomationTemplateId, inputs);
                            if(automationTemplate && inputs.instance && inputs.prevInstance && inputs.instance.associatedAutomationTemplateId != inputs.prevInstance.associatedAutomationTemplateId) {
                                // Only set on Automation Template change
                                return `${automationTemplate.name} Automation Policy`;
                            }
                            return inputs.instance && inputs.instance.associatedAutomationTemplateId ? undefined : null;
                        }}/>
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={AutomationPolicy._retentionMode} />
                        <TabAttribute style={{width: "50%"}} name={AutomationPolicy._retentionValue} default={(inputs: any) => {
                            const automationTemplate = getFromInputs(AutomationPolicy._associatedAutomationTemplateId, inputs);
                            if(automationTemplate && inputs.instance && inputs.prevInstance && inputs.instance.associatedAutomationTemplateId != inputs.prevInstance.associatedAutomationTemplateId) {
                                // Only set on Automation Template change
                                return "1";
                            }
                        }}/>
                    </TabAttributeRow>
                    <InputSchema
                        {...props}
                        sourceUrl={AutomationTemplate.getUrl()}
                        sourceIdAttribute={AutomationPolicy._associatedAutomationTemplateId}
                        schemaAttribute={AutomationTemplate._inputSchema}
                    />
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default AutomationPolicyEdit;

