import React, {FC, useEffect, useRef, useState} from "react";
import {Column, Cell, SelectionModes, ColumnHeaderCell, IRegion as Region, Table, IMenuContext} from "@blueprintjs/table";
import moment from "moment";
import {HorizontalDataColumns} from "../../workbench/common/schema/OutputDataTable";
import copyToClipboard from 'copy-to-clipboard';
import {AppToaster} from "../../../../AppToaster";
import {Intent} from "@blueprintjs/core";

type JSONObject = {[index: string]: any};

interface DashboardListProps {
    title?: string;
    instances: Array<JSONObject>|undefined;
    specColumns: HorizontalDataColumns;
    className?: string;
    tableClassName?: string;
    titleClassName?: string;
}


export const DashboardList: FC<DashboardListProps> = (props) => {
    const {instances, specColumns, className, title, tableClassName, titleClassName} = props;
    const [columnWidths, setColumnWidths] = useState<Array<number>>();

    const initialColumnState = [];
    for(const dataColumn of Object.values(specColumns)) {
        initialColumnState.push(dataColumn.width);
    }

    const table = useRef(null);

    const getInitialColumnWidths = () => {
        const _columnWidths = [];
        for(const col of getColumns()) {
            _columnWidths.push(col.columnWidth);
        }
        return _columnWidths;
    }

    const cellRenderer = (rowIndex: number, col: any) => {
        let attributeName = col.attribute;
        let path = col.path;
        let data = col.data;
        let value;
        if(data) {
            value = instances && instances[rowIndex][data];
        }
        else {
            value = instances && instances[rowIndex][attributeName];
        }
        if(path) {
            // JSON path from value
            const parts = path.split(".");
            for(const part of parts) {
                value = value[part];
            }
        }
        // Look for conditional css class based on value
        const conditionalClassEntry = specColumns[attributeName].conditionalClass;
        const conditionalClass = conditionalClassEntry && conditionalClassEntry[value] ? conditionalClassEntry[value] : "";

        // Handle Boolean
        if (typeof value == "boolean") {
            value = value ? 'Yes' : 'No';
        }

        // Convert Datetime
        if (String(value).match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+Z/)) {
            value = moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss.SSS');
        }

        const cellClass = instances && instances[rowIndex].new ? 'log-cell-new' : 'log-cell';

        return (
            <Cell wrapText>
                <div className={cellClass + " " + conditionalClass}>
                    {value && value.toString()}
                </div>
            </Cell>
        );
    };

    let region:Region[] = [];

    const getColumns = () => {
        const columns:Array<{label:string, attribute:string, columnWidth: number}> = [];
        Object.keys(specColumns).map((columnKey) => {
            columns.push({
                ...specColumns[columnKey],
                attribute: columnKey,
                label: specColumns[columnKey].label as string,
                columnWidth: specColumns[columnKey].width,
            });
        });

        return columns;
    }

    const getRowHeights = (columns: Array<{ label: string | undefined; attribute: string; filter: string | undefined, columnWidth: number }>) => {
        const rowHeights: Array<number> = [];
        const ROW_HEIGHT = 20;
        const COL_LENGTH = 100;
        const CHAR_WIDTH = 6.7;

        if(instances) {
            for (const instance of instances) {
                let height = ROW_HEIGHT;
                let i = 0;
                for (const col of columns) {
                    const colLength = columnWidths ? columnWidths[i] : COL_LENGTH;
                    let valueLength = instance[col.attribute]?.length;
                    if (valueLength) {
                        valueLength += 3 // for padding
                        const _height = ROW_HEIGHT * Math.max(1, Math.ceil(valueLength / (colLength / CHAR_WIDTH)));
                        height = Math.max(height, _height);
                    }
                    i++;
                }
                rowHeights.push(height);
            }
        }
        return rowHeights;
    }

    const handleColumnWidthChanged = (index: number, size: number) => {
        if(columnWidths) {
            const _columnWidths = [];
            for(const columnWidth of columnWidths) {
                _columnWidths.push(columnWidth);
            }
            _columnWidths[index] = size;
            setColumnWidths(_columnWidths);
        }
    }

    useEffect(() => {
        setColumnWidths(getInitialColumnWidths());
    }, [instances])

    if(!instances || !columnWidths) {
        return <></>
    }

    const columns = getColumns();
    const rowHeights = getRowHeights(columns as any);

    if(columnWidths.length !== columns.length) {
        return <></>;
    }

    const handleOnSelection = (iMenuContext: IMenuContext) => {
        const cols = iMenuContext.getSelectedRegions()[0].cols;
        const rows = iMenuContext.getSelectedRegions()[0].rows;
        if(cols && rows) {
            const col = cols[0];
            const row = rows[0];
            const _col = columns[col];
            const value = instances && instances[row][_col.attribute];
            copyToClipboard(value.toString());
            AppToaster.show({
                icon: "info-sign",
                intent: Intent.SUCCESS,
                message: `"${value}" copied to clipboard`,
            });
        }
        return <></>
    }

    return (
        <div className={className}>
            <div className={titleClassName}>{title}</div>
            <Table
                className={tableClassName}
                onColumnWidthChanged={handleColumnWidthChanged}
                enableRowHeader={false}
                enableRowResizing={false}
                enableColumnResizing={true}
                numRows={instances.length}
                selectionModes={SelectionModes.NONE}
                enableMultipleSelection={false}
                selectedRegions={region}
                rowHeights={rowHeights}
                columnWidths={columnWidths}
                bodyContextMenuRenderer={handleOnSelection}
            >
                { columns.map((col, ix: number) => {
                    const columnHeaderCellRenderer = () => {
                        return (
                            <ColumnHeaderCell
                                className={'instance-list-header'}
                                name={col.label}
                            />
                        )
                    }
                    return (
                        <Column
                            key={col.attribute}
                            name={col.label}
                            cellRenderer={(rowIndex) => cellRenderer(rowIndex, col)}
                            columnHeaderCellRenderer={columnHeaderCellRenderer}
                        />
                    )
                })}
            </Table>
        </div>
    );
};
