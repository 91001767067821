import * as React from "react";

import {Destination} from "raasify-models-specification-ts/core/Destination";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC, useEffect, useState} from "react";
import {BaseInstanceEdit} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";
import {InstanceEditProps} from "../../../InstanceEdit";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {DestinationPolicy} from "raasify-models-specification-ts/core/DestinationPolicy";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";


const DestinationPolicyEdit: FC<InstanceEditProps> = (props) => {
    const [parentInstance, setParentInstance] = useState<Destination>();
    useEffect(() => {
        props.getInstance(
            Destination.specification,
            props.rootSelected?.instanceId as string,
            (data) => setParentInstance(data));
    }, [])

    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(DestinationPolicy.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{width: "100%"}}
                            name={DestinationPolicy._associatedAutomationPolicyId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(AutomationPolicy._name, 9),
                                new TabAttributeRelatedAttribute(AutomationPolicy._packageLabel, 3),
                            ]}
                            hidden={(inputs: any) => {
                                return !parentInstance || parentInstance.executionType !== Destination.executionType.Automation;
                            }}
                        />
                        <TabAttribute
                            style={{width: "100%"}}
                            name={DestinationPolicy._associatedEndpointPolicyId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(EndpointPolicy._name, 9),
                                new TabAttributeRelatedAttribute(EndpointPolicy._packageLabel, 3),
                            ]}
                            hidden={(inputs: any) => {
                                return !parentInstance || parentInstance.executionType !== Destination.executionType.Endpoint;
                            }}
                        />
                    </TabAttributeRow>
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default DestinationPolicyEdit;

