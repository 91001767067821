import React, {FC} from "react";

import {InstanceMenuItem} from "../../../../../common/model/InstanceMenuItem";
import {InstanceMode} from "../../../../../common/enums/InstanceMode";
import {BaseInstanceMenu} from "../../../../../common/components/workbench/instance/menu/BaseInstanceMenu";
import {InstanceMenuProps} from "../../../InstanceMenu";
import {DestinationPolicy} from "raasify-models-specification-ts/core/DestinationPolicy";

export const DestinationMenu: FC<InstanceMenuProps> = (props) => {
    return (
        <BaseInstanceMenu
            {...props}
            instanceMenuItems={[
                new InstanceMenuItem({
                    mode: InstanceMode.Data,
                }),
                new InstanceMenuItem({
                    mode: InstanceMode.Detail,
                }),
                new InstanceMenuItem({
                    mode: InstanceMode.Logs,
                }),
                new InstanceMenuItem({
                    mode: InstanceMode.Related,
                    relatedSpecKey: DestinationPolicy.getKey(),
                    label: 'Policies',
                    icon: 'dashboard',
                }),
                new InstanceMenuItem({
                    mode: InstanceMode.Delete,
                }),
            ]}
        />
    );
};
