import React, {FC, ReactElement} from "react";
import {Services} from "../../../../services/Services";
import {InstanceEditTab} from "../edit/InstanceEditTab";
import {TabAttributeRow} from "../edit/TabAttributeRow";
import {TabAttribute} from "../edit/TabAttribute";
import {Selected} from "../../../../model/Selected";
import {DefaultTabs} from "../../common/tabs/DefaultTabs";
import {InstanceEditTabs} from "./InstanceEditTabs";


export interface InstanceEditAllProps {
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    specification: {[index: string]: any};
    instance:  { [index: string]: any; };
    setInstance: (editedInstance: { [index: string]: any; }) => void;
    services: Services;
    onCancel: () => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => void;
    onAttributeChange: (name: string, value: any) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedPage: string;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    selected: Selected;
}

export const InstanceEditAll: FC<InstanceEditAllProps> = (props) => {
    const {
        instance,
        services,
        onInstanceSave,
        onAttributeChange,
        relatedSelectCount,
        setRelatedSelectCount,
        getInstances,
        specification,
        selectedPage,
        editMode,
        setEditMode,
        selected,
        onCancel,
        getInstance,
    } = props;
    const attributeNames = services.specification.getAttributeNamesBySpecification(specification);

    const instanceEditTab = (
        <InstanceEditTabs
            showBackButton={false}
            showNextButton={false}
            showSaveButton={true}
            showCancelButton={true}
            isReadOnly={false}
        >
            <InstanceEditTab noHeader>
                {attributeNames && attributeNames.map((attributeName) => {
                    return (
                        <TabAttributeRow key={attributeName}>
                            <TabAttribute name={attributeName} />
                        </TabAttributeRow>
                    )
                })}
            </InstanceEditTab>
        </InstanceEditTabs>
    )

    //console.log("Instance Detail", specification, attributeNames);
    return (
        <DefaultTabs
            onAttributeChange={onAttributeChange}
            specification={specification}
            onInstanceSave={onInstanceSave}
            instance={instance}
            services={services}
            onCancel={onCancel}
            id={specification.key}
            instanceEditTabs={instanceEditTab}
            relatedSelectCount={relatedSelectCount}
            setRelatedSelectCount={setRelatedSelectCount}
            getInstances={getInstances}
            getInstance={getInstance}
            selectedPage={selectedPage}
            editMode={editMode}
            setEditMode={setEditMode}
            selected={selected}
            isReadOnly={false}
            useAttributeNamesAsLabels
        />
    );

};
