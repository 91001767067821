import * as React from "react";
import {DeploymentPolicy} from "raasify-models-specification-ts/core/DeploymentPolicy";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const DeploymentPolicyList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: DeploymentPolicy._name,
                    columnWidth: 150,
                }),
                new InstanceListAttribute({
                    name: DeploymentPolicy._associatedStateGroupName,
                    columnWidth: 250,
                }),
                new InstanceListAttribute({
                    name: DeploymentPolicy._scope,
                    columnWidth: 100,
                }),
            ]}
        />
    );
};
