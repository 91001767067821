import * as React from "react";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";
import {logo} from "../../../workbench/components/list/InstanceListUtil";

export const AutomationList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const automation = instance as Automation;
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(automation)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 5,
                                }}
                            >
                                {automation.status}
                            </div>

                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{automation.name}</div>
                                <div className={"instance-list-card-attribute"}>{automation.associatedEdgeName || automation.associatedEndpointName}</div>
                            </div>
                        </>
                    )
                }
            }
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Automation._name,
                }),
                new InstanceListAttribute({
                    name: Automation._status,
                }),
                new InstanceListAttribute({
                    name: Automation._associatedEndpointName,
                }),
                new InstanceListAttribute({
                    name: Automation._associatedEdgeName,
                }),
                new InstanceListAttribute({
                    name: Automation._associatedAutomationPolicyName,
                }),
            ]}
        />
    );
};
