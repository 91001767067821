import * as React from "react";
import {ApplicationPolicy} from "raasify-models-specification-ts/core/ApplicationPolicy";
import {NodePolicy} from "raasify-models-specification-ts/core/NodePolicy";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const ApplicationPolicyList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: ApplicationPolicy._name,
                    columnWidth: 150,
                }),
                new InstanceListAttribute({
                    name: ApplicationPolicy._associatedDeploymentPolicyName,
                    columnWidth: 230,
                }),
                new InstanceListAttribute({
                    name: NodePolicy._runAutomationOn,
                    columnWidth: 120,
                }),
            ]}
        />
    );
};
