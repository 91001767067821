import * as React from "react";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {Destination} from "raasify-models-specification-ts/core/Destination";
import {InstanceListProps} from "../../../InstanceList";
import {logo} from "./InstanceListUtil";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";

export const DestinationList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const Destination = instance as Destination;
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(Destination)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}

                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{Destination.name}</div>
                                <div className={"instance-list-card-attribute"}>{Destination.description}</div>
                            </div>

                        </>
                    )
                }
            }
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Destination._name,
                }),
                new InstanceListAttribute({
                    name: Destination._description,
                }),
            ]}
        />
    );
};
