import React, {FC, useEffect} from "react";
import {InstanceContainer} from "../workbench/instance/InstanceContainer";
import {SideBar} from "../workbench/sidebar/SideBar";
import {Services} from "../../services/Services";
import {Selected} from "../../model/Selected";
import {InstanceListAttribute} from "../../model/InstanceListAttribute";
import {InstanceJob} from "../../model/InstanceJob";
import {Specification as SpecificationType} from "common-models-ts";
import {useAppSelector} from "../../redux/hooks";
import {CustomComponentProps} from "./CustomComponentProps";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {User} from "raasify-models-specification-ts/user/User";
import {InstanceList} from "../../../custom/benches/InstanceList";
import {InstanceMenu} from "../../../custom/benches/InstanceMenu";
import {InstanceListMenu} from "../../../custom/benches/InstanceListMenu";


export interface MainProps {
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    filteredInstances: Array<{[index: string]: any}>;
    filter: String;
    onInstanceListOnFilter: (filter: string) => void;
    selected: Selected;
    services: Services;
    SideBarLayout: {};
    selectedPage: string;
    onInstanceListOnSortClick: (direction: number, specification: SpecificationType, attributeName: string) => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, callback: (res: any, err: any) => void) => void;
    onInstanceDelete: (specification: {[index: string]: {}}, instanceId: string, callback: (res: any, err: any) => void) => void;
    onInstanceListOnMount: (instanceAttributeList: Array<InstanceListAttribute>) => void;
    onInstanceMenuOnClick: (instanceMode: string) => void;
    onInstanceMenuOnJob: (job: InstanceJob, parameters: any) => void;
    onRelatedInstanceMenuOnJob: (job: InstanceJob, parameters: any) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedDeployment: Deployment;
    me: User;
}

export const Main: FC<MainProps> = (props) => {
    const {
        getInstances,
        getInstance,
        filteredInstances,
        filter,
        selected,
        services,
        SideBarLayout,
        selectedPage,
        onInstanceListOnFilter,
        onInstanceListOnSortClick,
        onInstanceSave,
        onInstanceDelete,
        onInstanceListOnMount,
        onInstanceMenuOnJob,
        onRelatedInstanceMenuOnJob,
        onInstanceMenuOnClick,
        relatedSelectCount,
        setRelatedSelectCount,
        selectedDeployment,
        me,
    } = props;

    const instances = useAppSelector((state) => state.instances)

    useEffect(() => {
        //console.log("remount");
    }, [])

    const CustomComponent:React.FC<CustomComponentProps>|null = selected.component;

    return (
        <>
            <div>
                <SideBar
                    selectedDeployment={selectedDeployment}
                    layout={SideBarLayout}
                    selected={selected}
                    services={services}
                    selectedPage={selectedPage}
                />
            </div>

            {CustomComponent && <CustomComponent
                me={me}
                selected={selected}
                getInstances={getInstances}
                getInstance={getInstance}
                services={services}
                relatedSelectCount={relatedSelectCount}
                setRelatedSelectCount={setRelatedSelectCount}
                selectedPage={selectedPage}
                selectedDeployment={selectedDeployment}
            />}
            {selected?.specification &&
                <>
                    <div>
                        <InstanceListMenu
                            className={'instance-list-outer-width'}
                            onInstanceSave={onInstanceSave}
                            onInstanceDelete={onInstanceDelete}
                            onFilter={onInstanceListOnFilter}
                            filter={filter}
                            services={services}
                            selected={selected}
                            relatedSelectCount={relatedSelectCount}
                            setRelatedSelectCount={setRelatedSelectCount}
                            getInstances={getInstances}
                            getInstance={getInstance}
                            selectedPage={selectedPage}
                            selectedDeployment={selectedDeployment}
                            specification={selected.specification}
                        />
                        {selected.specification &&
                            <InstanceList
                                className={'instance-list-table'}
                                onMount={onInstanceListOnMount}
                                onSortClick={onInstanceListOnSortClick}
                                filteredInstances={filteredInstances}
                                services={services}
                                selected={selected}
                                specification={selected.specification}
                                selectedPage={selectedPage}
                                selectedDeployment={selectedDeployment}
                                onInstanceDelete={onInstanceDelete}
                                getInstances={getInstances}
                                getInstance={getInstance}
                            />
                        }
                    </div>

                    <div className={'instance-view-pane'}>
                        <InstanceMenu
                            services={services}
                            getInstances={getInstances}
                            getInstance={getInstance}
                            selected={selected}
                            relatedSelectCount={relatedSelectCount}
                            setRelatedSelectCount={setRelatedSelectCount}
                            onClick={onInstanceMenuOnClick}
                            onJob={onInstanceMenuOnJob}
                            onInstanceDelete={onInstanceDelete}
                            selectedPage={selectedPage}
                            specification={selected.specification}
                            selectedDeployment={selectedDeployment as Deployment}
                        />
                        <InstanceContainer
                            onInstanceSave={onInstanceSave}
                            services={services}
                            selected={selected}
                            relatedSelectCount={relatedSelectCount}
                            setRelatedSelectCount={setRelatedSelectCount}
                            getInstances={getInstances}
                            getInstance={getInstance}
                            onInstanceDelete={onInstanceDelete}
                            selectedPage={selectedPage}
                            selectedDeployment={selectedDeployment}
                            onJob={onRelatedInstanceMenuOnJob}
                        />
                    </div>
                </>
            }
        </>
    );
}
