
import React, {FC} from "react";
import {CustomComponentProps} from "../../../../../common/components/main/CustomComponentProps";
import {LogWindow} from "../../../../../common/components/workbench/instance/logwindow/LogWindow";

export const NotificationsLogWindow: FC<CustomComponentProps> = (props) => {
    return (
        <LogWindow
            selected={props.selected}
            services={props.services}
            className={'notifications-log-window-table'}
            defaultColumnWidths={[200,300,100,100,150,450]}
            defaultRowHeight={20}
        />
    )
}
