import {ItemRenderer, Select2} from "@blueprintjs/select";
import {FC} from "react";
import {Alignment, Button, MenuItem} from "@blueprintjs/core";
import * as React from "react";


export interface EnumSelectProps {
    value: string;
    onChange(value: string): void;
    disabled?: boolean;
    items: Array<string>;
    className?: string;
    inputClassName?: string;
    buttonClassName?: string;
}

const _CustomSelect = Select2.ofType<string>();

export const CustomSelect: FC<EnumSelectProps> = (props) => {
    const {value, items, onChange, disabled, inputClassName, buttonClassName} = props;

    const renderOption: ItemRenderer<any> = (option, {handleClick, modifiers}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }

        return (
            <MenuItem
                disabled={disabled}
                className={'bp4-text-large'}
                active={modifiers.active}
                key={option}
                text={option}
                onClick={handleClick}
            />
        );
    };

    const onItemSelect = (item: string) => {
        onChange(item)
    }

    // bp4-text-large
    const className = disabled ? 'bp4-disabled' : '';

    return (
        <_CustomSelect
            popoverProps={{matchTargetWidth: true, usePortal: false}}
            popoverContentProps={{className: 'list-popover-content-normal'}}
            fill
            inputProps={{className: inputClassName}}
            disabled={disabled}
            noResults={<MenuItem disabled={true} text="No results."/>}
            query={value}
            filterable={false}
            activeItem={value}
            itemRenderer={renderOption}
            items={items}
            onItemSelect={onItemSelect}
            className={className}
        >
            <Button
                alignText={Alignment.LEFT}
                className={buttonClassName}
                text={value}
                rightIcon="double-caret-vertical"
            />
        </_CustomSelect>
    )
}
