import React, {FC, ReactNode} from 'react';
import {Services} from "../../common/services/Services";
import {Selected} from "../../common/model/Selected";
import {BaseInstanceEditProps} from "../../common/components/workbench/instance/edit/BaseInstanceEdit";
import AutomationEdit from "./workbench/components/edit/AutomationEdit";
import AutomationExecutionEdit from "./workbench/components/edit/AutomationExecutionEdit";
import AutomationPolicyEdit from "./workbench/components/edit/AutomationPolicyEdit";
import MetricEdit from "./workbench/components/edit/MetricEdit";
import CredentialEdit from "./workbench/components/edit/CredentialEdit";
import CronScheduleEdit from "./workbench/components/edit/CronScheduleEdit";
import EdgeEdit from "./workbench/components/edit/EdgeEdit";
import EndpointEdit from "./workbench/components/edit/EndpointEdit";
import EndpointPolicyEdit from "./workbench/components/edit/EndpointPolicyEdit";
import EndpointHostEdit from "./workbench/components/edit/EndpointHostEdit";
import ActivationRequestEdit from "./notifications/components/edit/ActivationRequestEdit";
import DiscoveredEdgeEdit from "./notifications/components/edit/DiscoveredEdgeEdit";
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationExecution} from "raasify-models-specification-ts/core/AutomationExecution";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {Metric} from "raasify-models-specification-ts/core/Metric";
import {Credential} from "raasify-models-specification-ts/core/Credential";
import {CronSchedule} from "raasify-models-specification-ts/core/CronSchedule";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";

import {ActivationResponse} from 'raasify-models-specification-ts/core/ActivationResponse';
import {Destination} from 'raasify-models-specification-ts/core/Destination';
import {DestinationPolicy} from 'raasify-models-specification-ts/core/DestinationPolicy';
import {EndpointPolicy} from 'raasify-models-specification-ts/core/EndpointPolicy';

import {EndpointHost} from "raasify-models-specification-ts/core/EndpointHost";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import ActivationResponseEdit from "./powerbench/components/edit/ActivationResponseEdit";
import {InstanceEditAll} from "../../common/components/workbench/instance/edit/InstanceEditAll";
import DestinationEdit from "./workbench/components/edit/DestinationEdit";
import DestinationPolicyEdit from "./workbench/components/edit/DestinationPolicyEdit";


export interface InstanceEditProps {
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    specification: {[index: string]: any};
    instance:  { [index: string]: any; };
    setInstance: (editedInstance: { [index: string]: any; }) => void;
    services: Services;
    onCancel: () => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => void;
    onAttributeChange: (name: string, value: any) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedPage: string;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    selected: Selected;
    rootSelected?: Selected;
    children?: ReactNode;
    setLoading?: (state: boolean) => void;
    isAddNew?: boolean;
}

export const InstanceEdit: FC<BaseInstanceEditProps> = (props) => {
    const name = props.specification.name;

    const {
        onAttributeChange,
        onInstanceSave,
        specification,
        services,
        instance,
        setInstance,
        relatedSelectCount,
        setRelatedSelectCount,
        getInstances,
        getInstance,
        selectedPage,
        editMode,
        setEditMode,
        onCancel,
        selected,
    } = props;

    if (props.selectedPage === 'workbench' || props.selectedPage === 'notifications') {
        return (
            <>
                {name === ActivationRequest.specification.name && <ActivationRequestEdit {...props} />}
                {name === ActivationResponse.specification.name && <ActivationResponseEdit {...props} />}
                {name === DiscoveredEdge.specification.name && <DiscoveredEdgeEdit {...props} />}
                {name === Automation.specification.name && <AutomationEdit {...props} />}
                {name === AutomationExecution.specification.name && <AutomationExecutionEdit {...props} />}
                {name === AutomationPolicy.specification.name && <AutomationPolicyEdit {...props} />}
                {name === Destination.specification.name && <DestinationEdit {...props} />}
                {name === DestinationPolicy.specification.name && <DestinationPolicyEdit {...props} />}

                {name === Metric.specification.name && <MetricEdit {...props} />}
                {name === Credential.specification.name && <CredentialEdit {...props} />}
                {name === CronSchedule.specification.name && <CronScheduleEdit {...props} />}
                {name === Edge.specification.name && <EdgeEdit {...props} />}
                {name === Endpoint.specification.name && <EndpointEdit {...props} />}
                {name === EndpointPolicy.specification.name && <EndpointPolicyEdit {...props} />}
                {name === EndpointHost.specification.name && <EndpointHostEdit {...props} />}
            </>
        );
    }
    else if (props.selectedPage === 'powerbench' &&
        [
            AutomationExecution.specification.name,
            Automation.specification.name,
        ].includes(name)
    ) {
        return (
            <>
                {name === AutomationExecution.specification.name && <AutomationExecutionEdit {...props} />}
                {name === Automation.specification.name && <AutomationEdit {...props} />}
            </>
        );
    }
    else {
        return (
            <InstanceEditAll
                key={'InstanceContainer_' + name}
                onAttributeChange={onAttributeChange}
                onInstanceSave={onInstanceSave}
                specification={specification}
                services={services}
                instance={instance}
                setInstance={setInstance}
                relatedSelectCount={relatedSelectCount}
                setRelatedSelectCount={setRelatedSelectCount}
                getInstances={getInstances}
                getInstance={getInstance}
                selectedPage={selectedPage}
                editMode={editMode}
                setEditMode={setEditMode}
                onCancel={onCancel}
                selected={selected}
            />
        );
    }
};
