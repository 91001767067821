import * as React from "react";
import {FC, ReactElement, useEffect, useRef, useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
    Alignment,
    Button,
    Classes,
    FormGroup,
    Icon,
    InputGroup, Intent,
    Radio,
    RadioGroup,
    Switch,
    TextArea
} from "@blueprintjs/core";
import { DatePicker, TimePrecision } from "@blueprintjs/datetime";
import {EnumSelect} from "./EnumSelect";
import {RelatedInstanceSelect} from "./RelatedInstanceSelect";
import {JSONEditor} from "./JSONEditor";
import {Services} from "../../../../services/Services";
import {ListSuggest} from "./ListSuggest";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import moment from 'moment';
import {Selected} from "../../../../model/Selected";
import {CodeEditor} from "./CodeEditor";
import {AppToaster} from "../../../../../AppToaster";

type JSONObject = {[index: string]: any};

export interface AttributeProps {
    instance: any;
    services: Services;
    specification: {[index: string]: any}|null;
    attributeName: string;
    onChange(name: string, value: any): void;
    disabled?: boolean;
    useAttributeNamesAsLabels?: boolean;
    tabAttribute?: ReactElement;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    isDetail?: boolean;
    selectedPage: string;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    placeholder?: string;
    onSelectedRelatedInstance?: (instance: JSONObject) => void;
    focus?: boolean;
    nullLabel?: string;
    selected: Selected;
    inheritanceEnabled?: boolean;
    sort?: (items: Array<any>) => void;
    inputs?: any;
}

export const Attribute: FC<AttributeProps> = (props) => {
    const {
        instance,
        services,
        specification,
        attributeName,
        onChange,
        disabled,
        useAttributeNamesAsLabels,
        tabAttribute,
        onInstanceSave,
        relatedSelectCount,
        setRelatedSelectCount,
        onSelectedRelatedInstance,
        getInstances,
        isDetail,
        selectedPage,
        editMode,
        setEditMode,
        placeholder,
        focus,
        nullLabel,
        selected,
        inheritanceEnabled,
        sort,
        inputs,
        getInstance,
    } = props;

    const [showPopover, setShowPopover] = useState<boolean>(false);

    const wrapperRef = useRef(null);

    let attributeComponent;
    const attribute = specification?.attributes[attributeName];
    if(!attribute) {
        console.log("props", props);
        console.log("specification", specification);
        console.log("attributeName", attributeName);
    }
    const specType = attribute.type;
    const isEnum = attribute.enum;
    const mask = attribute.mask;

    useEffect(() => {
        if(['datetime'].includes(specType)) {
            const handleClickOutside = (event: any) => {
                if (wrapperRef.current && !(wrapperRef.current as any).contains(event.target) && event.target.id !== 'dateTimeButton') {
                    setShowPopover(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [wrapperRef]);

    const handleOnChange = (event: any) => {
        const target = event && event.target;
        const value = target && (target.type === "checkbox" ? target.checked : target.value);
        const id = target && target.id;
        //console.log("handleOnChange", id, value);
        onChange(id, value);
    }

    const handleArrayOnChange = (event: any) => {
        const target = event && event.target;
        const value = target.value;
        onChange(attributeName, value ? value.split("\n") : []);
    }

    const handleJSONEditorOnChange = (value: object | undefined) => {
        onChange(attributeName, value);
    }

    const handleTextAreaOnChange = (value: object | undefined) => {
        //console.log("handleTextAreaOnChange", value);
        onChange(attributeName, value);
    }

    const handleCodeEditorOnChange = (value: string | undefined) => {
        onChange(attributeName, value);
    }

    const getAttributeValue = (instance: any, attributeName: string) => {
        let updatedValue;
        let originalValue = instance[attributeName] ? instance[attributeName] : "";
        if(mask) {
            updatedValue = originalValue.replace(/./g, '*');
        }
        return updatedValue ? updatedValue : originalValue;
    }

    const getPlaceHolderValue = (placeholder: string) => {
        let updatedValue;
        let originalValue = placeholder;
        if(mask) {
            updatedValue = placeholder.replace(/./g, '*');
        }
        return updatedValue ? updatedValue : originalValue;
    }

    if(!specification || !attribute || !instance) {
        attributeComponent = (<></>)
    }
    else if(isEnum && !isDetail && !disabled) {
        attributeComponent = (
            <EnumSelect
                 attribute={attribute}
                 attributeName={attributeName}
                 instance={instance}
                 disabled={disabled}
                 onChange={onChange}
                 placeholder={placeholder}
            />
        )
    }
    else if(['string'].includes(specType) && tabAttribute?.props.suggestList) {
        attributeComponent = (
            <ListSuggest
                attribute={attribute}
                attributeName={attributeName}
                instance={instance}
                disabled={disabled}
                onChange={onChange}
                items={tabAttribute?.props.suggestList as Array<string>}
            />
        )
    }
    else if(['string', 'integer', 'number'].includes(specType)
        || (['boolean'].includes(specType) && attribute.readOnly)
        || (isDetail && ['association', 'composition', 'associatedEntityAttribute', 'boolean'].includes(specType)) ) {
        const type = specType === 'integer' || specType === 'number' ? 'number' : 'text';
        attributeComponent = (
            <InputGroup
                autoFocus={focus}
                id={attributeName}
                disabled={disabled}
                type={'text'}
                onChange={handleOnChange}
                value={['boolean'].includes(specType) ? instance[attributeName] ? 'Yes' : 'No' : getAttributeValue(instance, attributeName)}
                placeholder={getPlaceHolderValue(placeholder as string)}
            />
        )
    }
    else if(['associatedEntityAttribute'].includes(specType)) {
        const type = specType === 'integer' ? 'number' : 'text';
        attributeComponent = (
            <InputGroup
                autoFocus={focus}
                readOnly={true}
                id={attributeName}
                disabled={disabled}
                type={'text'}
                value={instance[attributeName] ? instance[attributeName] : ""}
            />
        )
    }
    else if(['datetime'].includes(specType)) {
        const pad = (value: number) => {
            return String(value).padStart(2, '0')
        }

        const handleDateChange = (selectedDate: Date, isUserChange: boolean) => {
            if(selectedDate) {
                const dateString = `${selectedDate.getFullYear()}-${pad(selectedDate.getMonth()+1)}-${pad(selectedDate.getDate())}`
                const timeString = `${pad(selectedDate.getHours())}:${pad(selectedDate.getMinutes())}`
                const convertToUtc = moment(dateString + ' ' + timeString, 'YYYY-MM-DD HH:mm').toISOString();
                onChange(attributeName, convertToUtc)
            }
            else {
                onChange(attributeName, null)
            }
        }

        const convertToLocal = instance[attributeName]
            ? moment.utc(instance[attributeName], 'YYYY-MM-DDTHH:mm:ss.SSSZ').local()
            : null;
        const convertToLocalString = convertToLocal ? convertToLocal?.format('YYYY-MM-DD HH:mm:ss.SSS') : null;

        const now = new Date();
        const tomorrow = new Date(new Date().getTime()+(1 * 24*60*60*1000));
        const nextWeek = new Date(new Date().getTime()+(7 * 24*60*60*1000));
        const nextMonth = new Date(new Date().getTime()+(31 * 24*60*60*1000));

        // if the date is in the past, reset to tomorrow
        const defaultValue
            = (convertToLocal && now.getTime() < convertToLocal?.toDate().getTime() ? convertToLocal?.toDate() : tomorrow) as Date;

        const handleOnClickInputField = () => {
            setShowPopover(!showPopover);
        }

        attributeComponent = (
            <div>
                <Popover2
                    usePortal
                    isOpen={showPopover}
                    content={
                    <div ref={wrapperRef}>
                        <DatePicker
                            className={Classes.ELEVATION_1}
                            onChange={handleDateChange}
                            timePrecision={TimePrecision.MINUTE}
                            timePickerProps={{useAmPm: true, showArrowButtons: true}}
                            highlightCurrentDay
                            reverseMonthAndYearMenus
                            minDate={now}
                            defaultValue={defaultValue}
                            shortcuts={[
                                {date: new Date(), includeTime: true, label: "Now"},
                                {date: tomorrow, label: "Tomorrow"},
                                {date: nextWeek, includeTime: true, label: "Next Week"},
                                {date: nextMonth, includeTime: true, label: "Next Month"},
                            ]}
                            showActionsBar
                        />
                        <button
                            className="bp4-button bp4-minimal datepicker-button"
                            onClick={() => setShowPopover(false)}
                        ><span className="bp4-button-text">OK</span></button>
                    </div>

                    }
                    enforceFocus
                    canEscapeKeyClose
                    placement="bottom"
                    disabled={disabled}
                >
                    <Button
                        onClick={handleOnClickInputField}
                        disabled={disabled}
                        alignText={Alignment.LEFT}
                        className={'attribute-date-picker-button'}
                        text={<div id={'dateTimeButton'}>{convertToLocalString || nullLabel}</div>}
                        rightIcon={<Icon className={"attribute-date-picker-button-right-icon"} icon={"caret-down"}/>}
                    />
                </Popover2>
            </div>
        )
    }
    else if(['association', 'composition'].includes(specType)) {
        attributeComponent = (
                <RelatedInstanceSelect
                    instance={instance}
                    attributeName={attributeName}
                    onChange={onChange}
                    attribute={attribute}
                    services={services}
                    disabled={disabled}
                    tabAttribute={tabAttribute}
                    onInstanceSave={onInstanceSave}
                    relatedSelectCount={relatedSelectCount}
                    setRelatedSelectCount={setRelatedSelectCount}
                    selectedPage={selectedPage}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    onSelectedRelatedInstance={onSelectedRelatedInstance && onSelectedRelatedInstance}
                    selected={selected}
                    getInstances={getInstances}
                    getInstance={getInstance}
                    sort={sort}
                    inputs={inputs}
                />
        )
    }
    else if(['text'].includes(specType) || (isDetail && ['json'].includes(specType) && !instance[attributeName])) {
        let value = specType === 'text' ? instance[attributeName] :
            instance[attributeName] ? JSON.stringify(instance[attributeName], null, 4) : "";

        attributeComponent = (
            <TextArea
                autoFocus={focus}
                className={'attribute-textarea'}
                id={attributeName}
                disabled={disabled}
                growVertically={true}
                fill
                onChange={handleOnChange}
                value={value !== null ? value : ""}
                placeholder={placeholder}
            />
        )
    }
    else if(['yaml', 'python', 'javascript'].includes(specType)) {
        let value = instance[attributeName];
        attributeComponent = (
            <CodeEditor
                className={'attribute-textarea'}
                disabled={disabled}
                onChange={handleCodeEditorOnChange}
                value={value !== null ? value : ""}
                placeholder={placeholder}
                language={specType}
                height={"300px"}
                width={"100%"}
            />
        )
    }
    else if(['array'].includes(specType)) {
        let value = Array.isArray(instance[attributeName]) ? instance[attributeName].join("\n") : "";
        let placeholderText = Array.isArray(placeholder) ? placeholder.join("\n") : "";
        let rows = value ? value.split("\n").length
            : placeholderText.split("\n").length;
        attributeComponent = (
            <TextArea
                autoFocus={focus}
                className={'attribute-textarea'}
                id={attributeName}
                disabled={disabled}
                fill
                onChange={handleArrayOnChange}
                value={value !== null ? value : ""}
                placeholder={placeholderText}
                rows={rows}
            />
        )
    }
    else if(['json'].includes(specType)) {
        attributeComponent = (
            <div className={disabled ? 'json-disabled' : 'json-enabled'}>
                <JSONEditor
                    selected={selected}
                    id={attributeName}
                    disabled={disabled}
                    value={instance[attributeName] ? instance[attributeName] : undefined}
                    onChange={handleJSONEditorOnChange}
                    placeholder={placeholder}
                />
            </div>
        )
    }
    else if(['boolean'].includes(specType) && inheritanceEnabled) {
        // Use inherit, enabled, disabled radio button group (with placeholder)

        const handleOnChangeRadioButton = (event: any) => {
            let result = undefined;
            if(event.target.value === "enabled") result = true;
            else if(event.target.value === "disabled") result = false;
            onChange(attributeName, result);
        }

        const selectedValue = instance[attributeName] === undefined ? "inherit" : instance[attributeName] ? "enabled" : "disabled";
        const placeholderValue = placeholder === undefined ? "inherit" : placeholder ? "enabled" : "disabled";

        attributeComponent = (
            <div className={"attribute-inheritance-boolean-inherit-container"}>
                <RadioGroup
                    disabled={disabled}
                    onChange={handleOnChangeRadioButton}
                    selectedValue={selectedValue}
                >
                    <Radio label="Inherit" value={"inherit"} />
                    <Radio label="Enabled" value={"enabled"} />
                    <Radio label="Disabled" value={"disabled"} />
                </RadioGroup>
                <div className={"attribute-inheritance-boolean-inherit-placeholder"}>({placeholderValue})</div>
            </div>
        )
    }
    else if(['boolean'].includes(specType)) {
        attributeComponent = (
            <Switch
                id={attributeName}
                disabled={disabled}
                onChange={handleOnChange}
                checked={instance[attributeName]}
            />
        )
    }


    if(!specification) {
        console.error("Error retrieving attribute. No specification specified");
        return <></>
    }
    if(!attribute) {
        console.error("Error retrieving attribute ", attributeName, "from specification", specification?.key);
        return <></>
    }
    if(!instance) {
        return <></>
    }

    return (
        <FormGroup
            key={attribute.name}
            className={"bp4-text-small attribute-container"}
            label={<div className={'attribute-label-container'}>
                <div className={'attribute-label'}>
                    {useAttributeNamesAsLabels ? attribute.name : attribute.label}
                    {attribute.unitsShort && <span className={'attribute-label-units'}>({attribute.unitsShort})</span>}
                </div>
                <div className={'attribute-help-button-div'}>
                    <Tooltip2
                        className={'tooltip-container'}
                        content={<div className={'tooltip-content'}>{attribute.description}</div>}
                        placement="right-start"
                    >
                        <Icon
                            color={"rgba(15,67,146,0.77)"}
                            className={"tooltip-icon"}
                            icon={"help"}
                            size={11}
                        />
                    </Tooltip2>
                </div>
                <div className={'attribute-clipboard-button-div'}>
                    <Tooltip2
                        className={'tooltip-container2'}
                        content={<div className={'tooltip-content'}>Copy {attribute.label} to clipboard</div>}
                        openOnTargetFocus={false}
                        placement="right-start"
                        usePortal={true}
                    >
                        <CopyToClipboard
                            onCopy={
                                // @ts-ignore
                                value => {
                                    AppToaster.show({
                                        icon: "info-sign",
                                        intent: Intent.SUCCESS,
                                        message: `${attribute.label} copied to clipboard`,
                                    });
                                }
                            }
                            text={typeof instance[attributeName] === 'object'
                                ? JSON.stringify(instance[attributeName], null, 4)
                                : instance[attributeName]}>
                            <Icon
                                size={11}
                                className={"attribute-clipboard-button"}
                                icon="duplicate"
                            />
                        </CopyToClipboard>
                    </Tooltip2>
                </div>
            </div>}
            labelFor={attributeName}
        >
            <div
                className={'attribute'}
            >
                {attributeComponent}
            </div>
        </FormGroup>
    );
};
