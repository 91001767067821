import * as React from "react";
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";
import {convertDateTime, logo} from "../../../workbench/components/list/InstanceListUtil";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";

export const ActivationRequestList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}

            instanceListCard={
                (instance: any, isRelatedInstance: boolean|undefined) => {
                    const activationRequest = instance as ActivationRequest;
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(activationRequest)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 5,
                                }}
                            >
                                {activationRequest.status}
                            </div>

                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{convertDateTime(activationRequest.created as string)}</div>
                                <div className={"instance-list-card-attribute"}>{(activationRequest.edgeInfo as any)?.publicIPAddress?.IPAddress}</div>
                                <div className={"instance-list-card-attribute"}>{(activationRequest.edgeInfo as any)?.publicIPAddress?.city}</div>
                            </div>
                        </>
                    )
                }
            }
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: ActivationRequest._created,
                }),
                new InstanceListAttribute({
                    name: ActivationRequest._status,
                }),
                new InstanceListAttribute({
                    name: DiscoveredEdge._edgeInfo,
                    filter: 'publicIPAddress.IPAddress',
                }),
                new InstanceListAttribute({
                    name: DiscoveredEdge._edgeInfo,
                    filter: 'publicIPAddress.city',
                }),
            ]}
        />
    );
};
