import * as React from "react";
import {AutomationJob} from "raasify-models-specification-ts/core/AutomationJob";
import {InstanceJob} from "../../../../../common/model/InstanceJob";
import {FC} from "react";
import {BaseInstanceJobs} from "../../../../../common/components/workbench/instance/jobs/BaseInstanceJobs";
import {InstanceJobsProps} from "../../../InstanceJobs";

export const AutomationJobs: FC<InstanceJobsProps> = (props) => {
    return (
        <BaseInstanceJobs
            {...props}
            jobs={[
                new InstanceJob({
                    command: AutomationJob.command.RunNow,
                    icon: 'refresh',
                }),
                new InstanceJob({
                    command: AutomationJob.command.Cancel,
                    icon: 'cross',
                }),
            ]}
        />
    );
}

