import * as React from "react";

import {Alert, Button, Classes, Intent, TabId, Tabs} from "@blueprintjs/core";
import {FC, ReactElement, useEffect, useState} from "react";
import {Services} from "../../../../services/Services";
import {DefaultPanel} from "../panel/DefaultPanel";
import {InstanceEditorPanel} from "../../instance/edit/InstanceEditorPanel";
import {getById} from "../../../../utils/instanceUtil";
import {Selected} from "../../../../model/Selected";
import {useAppSelector} from "../../../../redux/hooks";


export interface DefaultTabsProps {
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    specification: {[index: string]: {}};
    id: string;
    instance:  { [index: string]: any; };
    services: Services;
    onCancel: () => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => void;
    onAttributeChange: (name: string, value: any) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedPage: string;
    instanceEditTabs: ReactElement;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    useAttributeNamesAsLabels?: boolean;
    selected: Selected;
    isReadOnly?: boolean;
    children?: React.ReactNode;
}

export const DefaultTabs: FC<DefaultTabsProps> = (props) => {
    const {
        getInstances,
        specification,
        instance,
        services,
        id,
        onCancel,
        onInstanceSave,
        onAttributeChange,
        relatedSelectCount,
        setRelatedSelectCount,
        selectedPage,
        instanceEditTabs,
        editMode,
        setEditMode,
        selected,
        isReadOnly,
        useAttributeNamesAsLabels,
        getInstance,
    } = props;
    const instances = useAppSelector((state) => state.instances)
    const instanceEditTabList = Array.isArray(instanceEditTabs.props.children) ? instanceEditTabs.props.children : [instanceEditTabs.props.children];

    const [selectedTabId, setSelectedTabId] = useState<TabId>(instanceEditTabList[0].props.title);
    const [isCancelAlertOpen, setCancelAlertOpen] = useState<boolean>(false);

    const handleTabChange = (newTab: TabId, previousTab: TabId|undefined, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setSelectedTabId(newTab);
    }

    const getTabIndex = (tabId: TabId) => {
        for(let i = 0; i < instanceEditTabList.length; i++) {
            if(instanceEditTabList[i].title === tabId) {
                return i;
            }
        }
        return 0;
    }

    const handleBackClick = () => {
        let tabIndex = getTabIndex(selectedTabId);
        tabIndex--;
        if(tabIndex >= 0) {
            setSelectedTabId(instanceEditTabList[tabIndex].title);
        }
    }

    const handleNextClick = () => {
        let tabIndex = getTabIndex(selectedTabId);
        tabIndex++;
        if(tabIndex <= instanceEditTabList.length-1) {
            setSelectedTabId(instanceEditTabList[tabIndex].title);
        }
    }

    const handleSaveClick = () => {
        onInstanceSave(specification, instance, (res, err) => {

        });
    }

    const handleCancelClick = () => {
        // Check for changes
        const local = JSON.stringify(instance);
        let remote;
        if(instances[specification.key as string]) {
            remote = JSON.stringify(getById(instances[specification.key as string].instances, instance.id));
        }
        if(local !== "{}" && local !== remote) {
            setCancelAlertOpen(true);
        }
        else {
            handleCancelAlertConfirm();
        }
    }

    const handleCancelAlertCancel = () => {
        setCancelAlertOpen(false);
    }

    const handleCancelAlertConfirm = () => {
        setEditMode(false);
        setCancelAlertOpen(false);
        onCancel();
    }

    const handleEditClick = () => {
        setEditMode(true);
    }

    return (
        <div className={"instance-view-tabs-container"}>
            {!editMode && !isReadOnly && <Button
                className={Classes.MINIMAL + " instance-view-tabs-edit-button"}
                icon="edit"
                onClick={handleEditClick} />}
            <Tabs
                className={"instance-view-tabs"}
                animate={true}
                id={id}
                key={id}
                renderActiveTabPanelOnly={true}
                vertical={false}
                selectedTabId={selectedTabId}
                onChange={handleTabChange}
            >
                {instanceEditTabList.map((instanceEditTab: React.ReactElement, ix: number) => (
                    <div className={"default-tabs-outer"} key={'InstanceEditorPanel' + ix}>
                        <InstanceEditorPanel
                            services={services}
                            showBackButton={instanceEditTabs.props.showBackButton}
                            showNextButton={instanceEditTabs.props.showNextButton}
                            showSaveButton={instanceEditTabs.props.showSaveButton}
                            showCancelButton={instanceEditTabs.props.showCancelButton}
                            onBackClick={handleBackClick}
                            onNextClick={handleNextClick}
                            onSaveClick={handleSaveClick}
                            onCancelClick={handleCancelClick}
                            editMode={editMode}
                            setEditMode={setEditMode}
                        >
                            <DefaultPanel
                                onAttributeChange={onAttributeChange}
                                services={services}
                                instance={instance}
                                specification={specification}
                                disabled={instanceEditTabs.props.isReadOnly}
                                editMode={editMode}
                                setEditMode={setEditMode}
                                onInstanceSave={onInstanceSave}
                                relatedSelectCount={relatedSelectCount}
                                setRelatedSelectCount={setRelatedSelectCount}
                                getInstances={getInstances}
                                getInstance={getInstance}
                                selectedPage={selectedPage}
                                instanceEditTab={instanceEditTab}
                                selected={selected}
                                useAttributeNamesAsLabels={useAttributeNamesAsLabels}
                            />
                        </InstanceEditorPanel>
                    </div>
                ))}
            </Tabs>
            <Alert
                className={'new-instance-abort'}
                cancelButtonText={"Continue editing"}
                confirmButtonText={"Abort changes"}
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isCancelAlertOpen}
                onCancel={handleCancelAlertCancel}
                onConfirm={handleCancelAlertConfirm}
            >
                <p>
                    Are you sure you want to abort changes?
                </p>
            </Alert>
        </div>
    );
}
