import React, {FC, useEffect, useState} from 'react';

import * as monaco from "monaco-editor";
import Editor, {loader, Monaco,} from "@monaco-editor/react";
import {setDiagnosticsOptions} from 'monaco-yaml';
import {editor} from "monaco-editor";

import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import cssWorker from 'monaco-editor/esm/vs/language/css/css.worker?worker';
import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';
import yamlWorker from '../../../../../yaml.worker.js?worker';

// Remove submenu items
// @ts-ignore
import * as actions from "monaco-editor/esm/vs/platform/actions/common/actions";
let menus = actions.MenuRegistry._menuItems
let contextMenuEntry = [...menus].find(entry => entry[0]._debugName == "EditorContext")
let contextMenuLinks = contextMenuEntry[1]
let removableIds = [
//    "editor.action.clipboardCutAction",
//    "editor.action.clipboardCopyAction",
//    "editor.action.clipboardPasteAction",
    "editor.action.refactor",
    "editor.action.sourceAction",
    "editor.action.revealDefinition",
    "editor.action.revealDeclaration",
    "editor.action.goToTypeDefinition",
    "editor.action.goToImplementation",
    "editor.action.goToReferences",
    "editor.action.formatDocument",
    "editor.action.formatSelection",
    "editor.action.changeAll",
    "editor.action.rename",
    "editor.action.quickOutline",
    "editor.action.quickCommand",
    "Peek",
]
let removeById = (list: any, ids: any) => {
    let node = list._first
    do {
        let shouldRemove = ids.includes(node.element?.command?.id) || ids.includes(node.element?.title)
        if (shouldRemove) { list._remove(node) }
    } while ((node = node.next))
}
removeById(contextMenuLinks, removableIds)



loader.config({ monaco });

// @ts-ignore
self.MonacoEnvironment = {
    getWorker(_: any, label: string) {
        if (label === 'json') {
            return new jsonWorker()
        }
        if (label === 'css' || label === 'scss' || label === 'less') {
            return new cssWorker()
        }
        if (label === 'html' || label === 'handlebars' || label === 'razor') {
            return new htmlWorker()
        }
        if (label === 'typescript' || label === 'javascript') {
            return new tsWorker()
        }
        if (label === 'yaml') {
            return new yamlWorker()
        }
        return new editorWorker()
    }
}

interface CodeEditorProps {
    language: string;
    value: any;
    disabled?: boolean;
    onChange(value: string|undefined): void;
    className?: string;
    placeholder?: any;
    width?: string;
    height?: string;
    fontSize?: number;
    actions?: Array<editor.IActionDescriptor>;
}


export const CodeEditor: FC<CodeEditorProps> = (props) => {
    const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor>();
    const {language, value, disabled, onChange, className, width, height, fontSize, actions} = props;

    const handleOnChange = (value: string|undefined) => {
        onChange(value);
    }

    const handleEditorValidation = (markers: any) => {
        // model markers
        //markers.forEach((marker: any) => console.log("onValidate:", marker.message));
    }

    const handleOnMount = (
        editor: monaco.editor.IStandaloneCodeEditor,
        monaco: Monaco,
    ) => {
        if (editor && actions) {
            for (const action of actions) {
                editor.addAction(action);
            }
        }
        setEditor(editor);
    }

    useEffect(() => {
        setDiagnosticsOptions({
            // Have to set an empty Diagnostics options to get syntax checking
            enableSchemaRequest: true,
            hover: true,
            completion: true,
            validate: true,
            format: true,
        });

        return () => {
            editor && editor.dispose();
        };
    }, [])

    return (
        <div style={{border: "1px solid #ccc"}} className={className}>
            <Editor
                options={{
                    readOnly: disabled,
                    lineDecorationsWidth: 5,
                    lineNumbersMinChars: 0,
                    glyphMargin: true,
                    folding: true,
                    lineNumbers: 'on',
                    minimap: {
                        enabled: false,
                    },
                    fontSize: fontSize || 11,
                    quickSuggestions: {
                        other: false,
                        comments: false,
                        strings: false,
                    },
                    parameterHints: {
                        enabled: false,
                    },
                    suggestOnTriggerCharacters: true,
                    acceptSuggestionOnEnter: "on",
                    tabCompletion: "on",
                    wordBasedSuggestions: false,
                    scrollbar: {
                        alwaysConsumeMouseWheel: false,
                    },
                    contextmenu: true,
                }}
                width={width}
                height={height}
                language={language}
                value={value || ""}
                onValidate={handleEditorValidation}
                onChange={handleOnChange}
                onMount={handleOnMount}
            />
        </div>
    );
}
