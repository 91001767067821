import * as React from "react";
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {ActivationRequestJob} from "raasify-models-specification-ts/core/ActivationRequestJob";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {InstanceJob} from "../../../../../common/model/InstanceJob";
import {FC} from "react";
import {BaseInstanceJobs} from "../../../../../common/components/workbench/instance/jobs/BaseInstanceJobs";
import {InstanceJobsProps} from "../../../InstanceJobs";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";

export const ActivationRequestJobs: FC<InstanceJobsProps> = (props) => {
    return (
        <BaseInstanceJobs
            {...props}
            jobs={[
                new InstanceJob({
                    command: ActivationRequestJob.command.Approve,
                    label: "Assign to Edge",
                    icon: "tick",
                    parameters: (
                        <InstanceEditTab noHeader>
                            <TabAttributeRow>
                                <TabAttribute
                                    style={{width: "100%"}}
                                    name={ActivationRequestJob._associatedEdgeId}
                                    relatedAttributes={[
                                        new TabAttributeRelatedAttribute(Edge._name, 4),
                                        new TabAttributeRelatedAttribute(Edge._description, 6),
                                        new TabAttributeRelatedAttribute(Edge._activated, 2),
                                    ]}
                                    filter={(item: any) => !item.activated}
                                />
                            </TabAttributeRow>
                        </InstanceEditTab>
                    ),
                    overlay: {
                        label: 'Select Edge to match Activation Request',
                        button: 'Approve',
                    },
                    isDisabled: (item: any) => item.status === ActivationRequest.status.Approved,
                }),
                new InstanceJob({
                    command: ActivationRequestJob.command.Reject,
                    icon: "cross",
                    isDisabled: (item: any) => item.status === ActivationRequest.status.Approved,
                }),
                new InstanceJob({
                    command: ActivationRequestJob.command.Upgrade,
                    icon: 'changes',
                    isDisabled: (item: any) => item.status === ActivationRequest.status.Approved,
                }),
                new InstanceJob({
                    command: ActivationRequestJob.command.Restart,
                    icon: 'refresh',
                    isDisabled: (item: any) => item.status === ActivationRequest.status.Approved,
                }),
                new InstanceJob({
                    command: ActivationRequestJob.command.Reboot,
                    icon: 'reset',
                    isDisabled: (item: any) => item.status === ActivationRequest.status.Approved,
                }),
            ]}
        />
    );
}

