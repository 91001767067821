import * as React from "react";

import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";
import {Edge} from "raasify-models-specification-ts/core/Edge";

import {EdgePolicy} from "raasify-models-specification-ts/core/EdgePolicy";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {BaseInstanceEdit, BaseInstanceEditProps} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import edgeInfo from "../../../../constants/edgeInfo"


const EdgeEdit: FC<BaseInstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(Edge.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "25%"}} name={Edge._name} />
                        <TabAttribute
                            style={{width: "25%"}}
                            name={Edge._associatedEdgePolicyId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(EdgePolicy._name, 5),
                                new TabAttributeRelatedAttribute(EdgePolicy._description, 7),
                            ]}
                        />
                        <TabAttribute style={{width: "25%"}} name={Edge._edgeDeploymentType} />
                        <TabAttribute style={{width: "25%"}} name={Edge._inProduction} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Edge._description} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "60%"}} name={Edge._location} />
                        <TabAttribute style={{width: "20%"}} name={Edge._activated} disabled />
                        <TabAttribute style={{width: "20%"}} name={Edge._connected} disabled />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "25%"}} name={Edge._subdomain} />
                        <TabAttribute style={{width: "75%"}} name={Edge._fqdn} disabled />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "20%"}} name={Edge._autoActivate} />
                        <TabAttribute style={{width: "30%"}} name={Edge._autoActivatePath} suggestList={edgeInfo} />
                        <TabAttribute style={{width: "30%"}} name={Edge._autoActivateValue} />
                        <TabAttribute style={{width: "20%"}} name={Edge._SSHPort} disabled hidden={inputs => !inputs.instance.edgeInfo} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Edge._edgeInfo} disabled hidden={inputs => !inputs.instance.edgeInfo} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Edge._SSHPublicKey} disabled hidden={inputs => !inputs.instance.edgeInfo} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                    </TabAttributeRow>
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default EdgeEdit;

