import {getCreateNewItem, IItemRendererProps, ItemRenderer, MultiSelect2} from "@blueprintjs/select";
import {FC, SyntheticEvent, useState} from "react";
import {MenuItem} from "@blueprintjs/core";
import * as React from "react";


export interface EnumSelectProps {
    onItemSelect(value: string): void;
    onRemove(value: string, index: number): void;
    createNewItemFromQuery?(query: string): string;
    disabled?: boolean;
    items: Array<string>;
    selectedItems: Array<string>;
    className?: string;
    noMenu?: boolean;
}

const _CustomMultiSelect = MultiSelect2.ofType<string>();

export const CustomMultiSelect: FC<EnumSelectProps> = (props) => {
    const {items, onItemSelect, onRemove, disabled, selectedItems, noMenu, createNewItemFromQuery} = props;

    const [query, setQuery] = useState<string>("");

    const itemRenderer = function (item: string, itemProps: IItemRendererProps) {
        if(noMenu) {
            return null;
        }
        return (
            <MenuItem
                disabled={disabled}
                className={'bp4-text-large'}
                key={item}
                text={item}
            />
        );
    }

    const tagRenderer = function (item: string) {
        return item;
    }

    // bp4-text-large
    const className = disabled ? 'bp4-disabled' : '';

    const handleItemSelect = (item: string, event: SyntheticEvent|undefined) => {
        //console.log("handleItemSelect", item);
        onItemSelect(item);
        setQuery("");
    }

    const handleCreateNewItemFromQuery = (query: string) => {
        //console.log("handleCreateNewItemFromQuery", query);
        return query;
    }

    const handleQueryChange = (
        query: string,
        event?: React.ChangeEvent<HTMLInputElement>
    ) => {
        //console.log("query", query);
        setQuery(query);
    };

    return (
        <_CustomMultiSelect
            popoverProps={{matchTargetWidth: true, usePortal: false}}
            popoverContentProps={{className: 'object-list-suggest-popover-content'}}
            items={items}
            selectedItems={selectedItems}
            fill
            disabled={disabled}
            className={className}
            tagRenderer={tagRenderer}
            itemRenderer={itemRenderer}
            onItemSelect={handleItemSelect}
            onRemove={onRemove}
            createNewItemFromQuery={handleCreateNewItemFromQuery}
            createNewItemPosition={"last"}
            activeItem={getCreateNewItem()}
            query={query}
            onQueryChange={handleQueryChange}
        />
    )
}
