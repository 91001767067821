import React, {FC, useEffect} from "react";
import traverse from 'json-schema-traverse';
//import {HorizontalDataTable} from "./HorizonalDataTable";
import {VerticalDataTable} from "./VerticalDataTable";
import {HorizontalDataTable} from "./HorizontalDataTable";
import {Selected} from "../../../../model/Selected";

type JSONObject = {[index: string]: any};

export interface OutputDataTableProps {
    instance: JSONObject|undefined;
    schema: JSONObject|undefined;
    resultAttribute: string;
    className?: string;
    selected: Selected;
}

export interface SingleResultViewSection {
    data: string;
    label?: string;
    rows?: Array<string>;
}

export class SingleResultViewType {
    static Vertical = 'vertical';
    static Horizontal = 'horizontal';
}

export interface SingleResultView {
    type: SingleResultViewType;
    sections?: Array<SingleResultViewSection>; // for vertical table
    columns?: HorizontalDataColumns; // for horizontal table
    rowKeySpec?: string;
}

export interface HorizontalDataColumns {
    [name: string]: {width: number, label?: string, conditionalClass?: {[value: string]: string}}
}

export const getAttributeSchema = (schema: JSONObject, schemaPath: string): JSONObject|undefined => {
    let res: JSONObject|undefined = undefined;
    traverse(schema, {cb: (def: any, path: any) => {
        if(schemaPath === path) {
            res = def;
        }
    }});
    return res;
}

export const getAttributeSchemaFromAttribute = (schema: JSONObject, attributeName: string): JSONObject|undefined => {
    let res: JSONObject|undefined = undefined;
    traverse(schema, {cb: (def: any, path: any) => {
            if(path.endsWith('/properties/' + attributeName)) {
                res = def;
            }
        }});
    return res;
}

export const OutputDataTable: FC<OutputDataTableProps> = (props) => {
    const {instance, schema, resultAttribute, className, selected} = props;
    const result = instance && instance[resultAttribute];

    const getDataTable = (singleResultViewType: string, schemaViewIndex?: number) => {
        return (
            <div className={className}>
                {singleResultViewType === SingleResultViewType.Vertical && result &&
                    <VerticalDataTable
                        className={"vertical-data-table"}
                        result={result}
                        schema={schema as JSONObject}
                        schemaViewIndex={schemaViewIndex}
                        selected={selected}
                    />
                }
                {singleResultViewType === SingleResultViewType.Horizontal && result &&
                    <HorizontalDataTable
                        {...props}
                        className={"all-list-table"}
                        result={result}
                        schema={schema as JSONObject}
                        schemaViewIndex={schemaViewIndex}
                        singleResultView
                    />
                }
            </div>
        );
    }

    if(Array.isArray(schema?.views?.singleResult)) {
        return (
            <div className={"flex-row-div"}>
                {schema?.views?.singleResult.map((view: any, ix: number) => {
                    const singleResultViewType = view.type;
                    //console.log("result", result);
                    //console.log("singleResultViewType", singleResultViewType);
                    return (
                        <div key={"data-table-" + ix} className={"flex-row-div-col"}>
                            {getDataTable(singleResultViewType, ix)}
                        </div>
                    )
                })}
            </div>
        )
    }
    else {
        const singleResultViewType = schema?.views?.singleResult?.type;
        //console.log("result", result);
        //console.log("singleResultViewType", singleResultViewType);
        return getDataTable(singleResultViewType);
    }

};
