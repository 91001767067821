import React, {FC, useEffect, useRef, useState} from "react";
import {Classes, Icon, Menu, MenuItem, Overlay} from "@blueprintjs/core";
import {Cell, Column, ColumnHeaderCell, Regions, SelectionModes, Table2} from "@blueprintjs/table";
import * as _ from "lodash";
import {InstanceLog} from "../../../../model/InstanceLog";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {Services} from "../../../../services/Services";
import {logsSlice} from "../../../../redux/slices/logs";
import {Selected} from "../../../../model/Selected";
import {getById} from "../../../../utils/instanceUtil";
import {Tooltip2} from "@blueprintjs/popover2";
import {getAttributeSchemaFromAttribute} from "../../common/schema/OutputDataTable";
import classNames from "classnames";


export interface LogWindowProps {
    className?: string;
    title?: string;
    tableClassName?: string;
    defaultColumnWidths?: Array<number>;
    titleClassName?: string;
    defaultRowHeight?: number;
    services: Services;
    selected: Selected;
}

const PRESET_WIDTHS = [100, 100, 55, 110, 150, 400];

const overlayClasses = classNames(
    Classes.CARD,
    Classes.PORTAL,
    "horizontal-data-info-overlay",
);

export const LogWindow: FC<LogWindowProps> = (props) => {
    const {
        className,
        title,
        tableClassName,
        defaultColumnWidths,
        titleClassName,
        defaultRowHeight,
        services,
        selected,
    } = props;

    const logs = useAppSelector((state) => state.logs).logs;
    const instances = useAppSelector((state) => state.instances);
    const [mouseEnter, setMouseEnter] = useState(false);
    const [columnWidths, setColumnWidths] = useState<Array<number>>(defaultColumnWidths ? defaultColumnWidths: PRESET_WIDTHS);
    const [showInfo, setShowInfo] = useState<number>(-1);

    const dispatch = useAppDispatch();

    const getLogs = (filterId?: string) => {
        const endpoint = filterId ? `logs/${filterId}` : `logs`;
        services.data.get(endpoint)
            .then((res) => {
                dispatch(logsSlice.actions.replaceAll({data: res.data, filterId}));
            })
    }

    useEffect(() => {
        dispatch(logsSlice.actions.clearAll());
        let instanceId = selected.instanceId;
        if(selected.specification?.name === 'Edge') {
            // special case for edge, needs to use discoveredEdgeId
            const edge: any = getById(instances['core.Edge'].instances, instanceId);
            instanceId = edge.associatedDiscoveredEdgeId;
        }
        getLogs(instanceId || undefined);
    }, [selected])

    useEffect(() => {
    }, [])

    if(!logs) {
        return <></>
    }

    const columns = [
        {
            label: InstanceLog._timestamp,
            getter: InstanceLog.getTimestamp,
        },
        {
            label: InstanceLog._from,
            getter: InstanceLog.getFrom
        },
        {
            label: InstanceLog._level,
            getter: InstanceLog.getLevel
        },
        {
            label: InstanceLog._category,
            getter: InstanceLog.getCategory,
        },
        {
            label: InstanceLog._related,
            getter: (log: InstanceLog) => {
                let res = '';
                if(log.related) {
                    for (const related of log.related) {
                        if (related.specification !== 'Edge') {
                            if (res !== '') {
                                res += "\n";
                            }
                            res += related.name;
                            break; // Just show the first one for now
                        }
                    }
                }
                return res;
            },
        },
        {
            label: InstanceLog._message,
            getter: InstanceLog.getMessage,
        },
    ]

    const menuRenderer = () => (
            <Menu>
                <MenuItem
                    disabled
                    className={'bp4-text-small'}
                    text={''}
                />
                <MenuItem
                    //onClick={(e) => onSortClick(1, selected.specification, col.attribute)}
                    className={'bp4-text-small'}
                    icon="sort-asc"
                    text="Sort Ascending"
                />
                <MenuItem
                    //onClick={(e) => onSortClick(-1, selected.specification, col.attribute)}
                    className={'bp4-text-small'}
                    icon="sort-desc"
                    text="Sort Descending"
                />
            </Menu>
        )


    const columnHeaderCellRenderer = (label: string) => {
        return (
            <ColumnHeaderCell
                className={'bp4-text-small log-window-column-header-cell'}
                name={_.capitalize(label)}
                //menuRenderer={menuRenderer}  // Disable sort for log window
            />
        )
    }

    const getInfoOverlay = () => {
        return (
            <Overlay
                isOpen={showInfo >= 0}
                onClose={() => setShowInfo(-1)}
                autoFocus
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus
                hasBackdrop
                usePortal
            >
                <div className={overlayClasses}>
                    <Icon size={16} className={"horizontal-data-info-overlay-close"} onClick={() => setShowInfo(-1)} icon="cross"/>
                    <div className={"horizontal-data-info-container"}>
                        {showInfo >= 0 && columns.map((column, x) => {
                            const value = column.getter(logs[showInfo] as InstanceLog);
                            if(value && value !== 'undefined') {
                                return (
                                    <div key={`horizontal-data-info-attribute-container-${x}`}
                                         className={'horizontal-data-info-attribute-container'}>
                                        <div className={'horizontal-data-info-attribute-name'}>
                                            {column.label}
                                        </div>
                                        <pre className={'horizontal-data-info-attribute-value'}>
                                            {value}
                                        </pre>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </Overlay>
        )
    }

    const cellRenderer = (rowIndex: number, getter: (log: InstanceLog) => any, label: string, filter?: string | undefined) => {
        let value = getter(logs[rowIndex] as InstanceLog);
        if(value && filter) {
            const path = filter.split(/\./);
            for(const p of path) {
                value = value[p];
            }
        }

        return (
            <Cell wrapText>
                <div
                    className={logs[rowIndex].new ? 'log-cell-new' : 'log-cell'}
                    onClick={() => {setShowInfo(rowIndex)}}
                >
                     {value}
                </div>
            </Cell>
        );
    };

    const handleColumnWidthChanged = (index: number, size: number) => {
        if(columnWidths) {
            const _columnWidths = [];
            for(const columnWidth of columnWidths) {
                _columnWidths.push(columnWidth);
            }
            _columnWidths[index] = size;
            setColumnWidths(_columnWidths);
        }
    }

    const table = useRef<Table2>(null)



    return (
        <div
            onMouseEnter={() => {
                setMouseEnter(true);
            }}
            onMouseLeave={() => {
                setMouseEnter(false);
            }}
            className={className}
        >
            {title && <h4 className={titleClassName}>{title}</h4>}
            <Table2
                ref={table}
                onColumnWidthChanged={handleColumnWidthChanged}
                className={tableClassName}
                enableRowHeader={false}
                defaultRowHeight={defaultRowHeight || 40}
                enableRowResizing={false}
                enableColumnResizing={true}
                numRows={logs.length}
                selectionModes={SelectionModes.NONE}
                enableMultipleSelection={false}
                columnWidths={columnWidths}
                onVisibleCellsChange={() => {
                    if(!mouseEnter) {
                        // only reset scrollbar is the mouse is not over element
                        table.current?.scrollToRegion(Regions.row(0))
                    }
                }}
            >
                {columns.map(column => {
                    return (
                        <Column
                            className={'log-window-column'}
                            key={column.label}
                            name={column.label}
                            cellRenderer={(rowIndex) => cellRenderer(rowIndex, column.getter, column.label)}
                            columnHeaderCellRenderer={() => columnHeaderCellRenderer(column.label)}
                        />
                    )
                })}
            </Table2>
            {getInfoOverlay()}
        </div>

    )
};
