import * as React from "react";
import {FC} from "react";
import {InstanceListProps} from "../../../InstanceList";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {DestinationPolicy} from "raasify-models-specification-ts/core/DestinationPolicy";
import {logo} from "./InstanceListUtil";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";


export const DestinationPolicyList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}

            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const destinationPolicy = instance as DestinationPolicy;
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(destinationPolicy)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{destinationPolicy.associatedAutomationPolicyName}</div>
                                <div className={"instance-list-card-name"}>{destinationPolicy.associatedEndpointPolicyName}</div>
                            </div>
                        </>
                    )
                }
            }
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: DestinationPolicy._associatedEndpointPolicyName,
                }),
                new InstanceListAttribute({
                    name: DestinationPolicy._associatedAutomationPolicyId,
                }),
            ]}
        />
    );
};
