
/**
 * BaseError
 */
class BaseError extends Error {
    private msg: any;
    private status: any;
    /**
     * BaseError constructor
     * @param {string} message - The error message
     */
    constructor(detail: any) {
        super(detail.message);
        this.name = detail.name;
        this.msg = detail.message;
        this.status = detail.status;
        this.stack = new Error(detail.message).stack;
    }
}

/**
 * ValidationError
 */
export class ValidationError extends BaseError {

}

/**
 * URLValidationError
 */
export class URLValidationError extends BaseError {}

/**
 * RestClientError
 */
export class RestClientError extends BaseError {}

/**
 * NotFoundError
 */
export class NotFoundError extends BaseError {}

/**
 * InternalServerError
 */
export class InternalServerError extends BaseError {}

/**
 * ConflictError
 */
export class ConflictError extends BaseError {}

/**
 * BadRequestError
 */
export class BadRequestError extends BaseError {}

/**
 * ServiceUnavailableError
 */
export class ServiceUnavailableError extends BaseError {}

/**
 * DBTypeError
 */
export class DBTypeError extends BaseError {}
