import * as React from "react";
import {FC, useEffect} from "react";
import {InstanceListTable} from "./InstanceListTable";
import {Services} from "../../../../services/Services";
import {Selected} from "../../../../model/Selected";
import {InstanceListAttribute} from "../../../../model/InstanceListAttribute";
import {Instance, Specification as SpecificationType} from "common-models-ts";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {InstanceListFilter} from "../../../../model/InstanceListFilter";

export interface BaseInstanceListProps {
    relatedView? : boolean;
    className: string;
    specification: {[index: string]: any};
    selected: Selected;
    rootSelected?: Selected;
    services: Services;
    filteredInstances?: Array<{[index: string]: any}>;
    onSortClick?: (direction: number, specification: SpecificationType, attributeName: string) => void;
    instanceListAttributes: Array<InstanceListAttribute>;
    instanceListCard?: (instance: Instance, isRelatedInstance?: boolean) => React.ReactElement;
    onMount: (instanceAttributeList: Array<InstanceListAttribute>, instanceListFilter?: string) => void;
    onInstanceDelete(specification: {[index: string]: {}} | null, instanceId: string, callback: (res: any, err: any) => void): void;
    selectedPage: string;
    selectedDeployment: Deployment;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean)  => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
}

export const BaseInstanceList: FC<BaseInstanceListProps> = (props) => {
    useEffect(() => {
        //console.log("remount");
    }, [])

    return (
        <InstanceListTable
            {...props}
            instanceListCard={props.instanceListCard}
        />
    );
}
