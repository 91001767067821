import * as React from "react";

import {FC} from "react";
import {InstanceHomeProps} from "../../../InstanceHome";


const EdgeHome: FC<InstanceHomeProps> = (props) => {
    return (
        <div className={'instance-home-outer'}>
            <div className={'instance-home-title'}>Edges</div>
            <div className={'instance-home-main-line'}>
                Edges run the raasify edge software at the Remote site and enable secure remote automation.
            </div>
            <div className={'instance-home-sub-line'}>
                Select one on the left or add a new one by clicking the (+)
            </div>
        </div>
    );
}

export default EdgeHome;

