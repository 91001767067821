import * as React from "react";
import {FC, ReactNode} from "react";

export interface InstanceEditTabsProps {
    showBackButton?: boolean;
    showNextButton?: boolean;
    showSaveButton?: boolean;
    showCancelButton?: boolean;
    isReadOnly?: boolean;
    children?: ReactNode;
}

export const InstanceEditTabs: FC<InstanceEditTabsProps> = (props) => {
    return (
        <>
            {props.children}
        </>
    );
}
