import React, {FC, useEffect, useRef, useState} from "react";
import {Column, Cell, SelectionModes, ColumnHeaderCell, IRegion as Region, Table} from "@blueprintjs/table";
import moment from "moment";
import {getAttributeSchema, getAttributeSchemaFromAttribute, HorizontalDataColumns, SingleResultView} from "./OutputDataTable";
import {convertOutputDataToHorizontalData, getAttributeFromPath} from "./HorizontalDataUtil";
import {Button, Classes, Menu, Icon, MenuItem, MenuDivider, Overlay, Spinner, Intent, PopoverInteractionKind} from "@blueprintjs/core";
import classNames from "classnames";
import {Popover2} from "@blueprintjs/popover2";
import {Services} from "../../../../services/Services";
import {AppToaster} from "../../../../../AppToaster";

type JSONObject = {[index: string]: any};

interface HorizontalDataTableProps {
    services?: Services;
    className: string;
    result?: JSONObject;
    results?: Array<JSONObject>;
    schema: JSONObject,
    singleResultView?: boolean;
    allResultsView?: boolean;
    schemaViewIndex?: number;
}

const ACTION_WIDTH = 80;

const overlayClasses = classNames(
    Classes.CARD,
    Classes.PORTAL,
    "horizontal-data-info-overlay",
);

export const HorizontalDataTable: FC<HorizontalDataTableProps> = (props) => {
    const [instances, setInstances] = useState<any>();
    const {className, result, schema, singleResultView, services, results, allResultsView, schemaViewIndex} = props;

    const _singleResultView = singleResultView && schemaViewIndex !== undefined
        ? schema?.views?.singleResult[schemaViewIndex] : schema?.views?.singleResult;
    const _allResultsView = allResultsView && schema.views.allResults;
    const view = _singleResultView ? _singleResultView : _allResultsView;

    const label = _singleResultView?.label || _allResultsView?.label || undefined;

    const noActions = view?.noActions || false;

    useEffect(() => {   
        setInstances(singleResultView && result
            ? convertOutputDataToHorizontalData(result, _singleResultView)
            : results);
    }, [result])

    const specColumns: HorizontalDataColumns = allResultsView
        ? _allResultsView?.columns
        : _singleResultView?.columns;

    const [columnWidths, setColumnWidths] = useState<Array<number>>();
    const [showInfo, setShowInfo] = useState<number>(-1);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const table = useRef(null);

    const getTableWidth = () => {
        let width = 30;
        for(const col of Object.values(specColumns)) {
            width += col.width ? col.width : 100;
        }
        if(!noActions && !allResultsView) {
            width += ACTION_WIDTH;
        }
        return `${width}px`;

    }

    const getUnits = (attributeSchema: JSONObject) => {
        if(attributeSchema.unitsShort) {
            return `(${attributeSchema.unitsShort})`;
        }
        else if(attributeSchema.units) {
            return `(${attributeSchema.units})`;
        }
        return "";
    }

    const getLabel = (attributeSchema: JSONObject) => {
        if(attributeSchema.shortTitle) {
            return `${attributeSchema.shortTitle}`;
        }
        else if(attributeSchema.title) {
            return `${attributeSchema.title}`;
        }
        return "";
    }

    const getColumns = () => {
        const columns:Array<{label:string|undefined, attribute:string, columnWidth: number}> = [];
        Object.keys(specColumns).map((columnKey) => {
            const attributeName = getAttributeFromPath(columnKey);
            const attributeSchema = getAttributeSchemaFromAttribute(schema, attributeName);
            columns.push({
                attribute: attributeName,
                label: specColumns[columnKey].label ? specColumns[columnKey].label
                    : attributeSchema ? `${getLabel(attributeSchema)} ${getUnits(attributeSchema)}` : attributeName,
                columnWidth: specColumns[columnKey].width,
            });
        });
        if(singleResultView && !noActions) {
            // actions
            columns.push({
                attribute: "actions",
                label: "Actions",
                columnWidth: ACTION_WIDTH,
            });
        }
        return columns;
    }

    const getRowHeights = (columns: Array<{ label: string | undefined; attribute: string; filter: string | undefined, columnWidth: number }>) => {
        const rowHeights: Array<number> = [];
        const ROW_HEIGHT = 20;
        const COL_LENGTH = 100;
        const CHAR_WIDTH = 6.7;

        if(instances) {
            for (const instance of instances) {
                let height = ROW_HEIGHT;
                let i = 0;
                for (const col of columns) {
                    const colLength = columnWidths ? columnWidths[i] : COL_LENGTH;
                    let valueLength = instance[col.attribute]?.length;
                    if (valueLength) {
                        valueLength += 3 // for padding
                        const _height = ROW_HEIGHT * Math.max(1, Math.ceil(valueLength / (colLength / CHAR_WIDTH)));
                        height = Math.max(height, _height);
                    }
                    i++;
                }
                rowHeights.push(height);
            }
        }
        return rowHeights;
    }

    const columns = getColumns();
    const rowHeights = getRowHeights(columns as any);

    const getInitialColumnWidths = () => {
        const _columnWidths = [];
        for(const col of getColumns()) {
            _columnWidths.push(col.columnWidth);
        }
        return _columnWidths;
    }

    const cellRenderer = (rowIndex: number, attributeName: string) => {
        if(!instances || instances?.length === 0) {
            if(columns[0].attribute === attributeName) {
                return (
                    <Cell wrapText><div className={"log-cell"}>No data found</div></Cell>
                )
            }
        }

        let value = instances && instances[rowIndex][attributeName];

        // Handle Boolean
        if (typeof value == "boolean") {
            value = value ? 'Yes' : 'No';
        }

        // Convert Datetime
        if (String(value).match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+Z/)) {
            value = moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss.SSS');
        }

        const cellClass = instances && instances[rowIndex].new ? 'log-cell-new' : 'log-cell';

        return (
            <Cell wrapText>
                <div className={cellClass}>
                    {value}
                </div>
            </Cell>
        );
    };

    const actionButtonRenderer = (rowIndex: number) => {
        return (
            <Cell>
                <div className={"horizontal-data-button-container"}>
                    <Icon
                        size={14}
                        className={"horizontal-data-button horizontal-data-button-info"}
                        onClick={() => setShowInfo(rowIndex)}
                        icon={"info-sign"}/>
                    {schema.actionMenu && <Popover2
                        key={'popover' + rowIndex}
                        enforceFocus={true}
                        content={getActionMenuOverlay(rowIndex)}
                        canEscapeKeyClose
                        onClose={() => setSelectedRowIndex(-1)}
                        usePortal
                        placement="left-start"
                        interactionKind={"click"}
                    >
                        <Icon
                            size={14}
                            className={"horizontal-data-button horizontal-data-button-info"}
                            onClick={() => setSelectedRowIndex(rowIndex)}
                            icon={"more"}/>
                    </Popover2>}
                </div>
            </Cell>
        );
    };

    const actionButtonHeaderCellRenderer = () => {
        return (
            <ColumnHeaderCell
                className={'instance-list-header'}
                name={""}
            />
        )
    }

    let region:Region[] = [];


    const handleColumnWidthChanged = (index: number, size: number) => {
        if(columnWidths) {
            const _columnWidths = [];
            for(const columnWidth of columnWidths) {
                _columnWidths.push(columnWidth);
            }
            _columnWidths[index] = size;
            setColumnWidths(_columnWidths);
        }
    }

    const handlePOSTAction = (rowIndex: number, action: string, actionSpec: JSONObject) => {
        if(instances) {
            const instance = instances[rowIndex];
            const input = actionSpec.input;
            // Build the request
            const request: JSONObject = {};
            for (const [attributeName, attributeValue] of Object.entries(input)) {
                if (Array.isArray(attributeValue)) {
                    for (const value of attributeValue) {
                        // find the first attribute in the list with a value
                        if (instance[value]) {
                            request[attributeName] = instance[value];
                            break;
                        }
                    }
                } else {
                    request[attributeName] = attributeValue as string;
                }
            }
            // POST the request
            //console.log("POSTing:", request);
            services?.data.post(actionSpec.url, request)
                .then((data) => {
                    //console.log("POST Response:", data);
                    AppToaster.show({
                        icon: "tick",
                        intent: Intent.SUCCESS,
                        message: `${action} ${data.name} was created`,
                    });
                })
                .catch((error) => {
                    console.error("POST Response:", error);
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: error.message,
                    });
                })
        }
    }

    useEffect(() => {
        setColumnWidths(getInitialColumnWidths());
    }, [instances])

    useEffect(() => {
        setColumnWidths(getInitialColumnWidths());
    }, [])

    useEffect(() => {
    }, [results])

    useEffect(() => {
    }, [selectedRowIndex])

    if((!_singleResultView?.columns && !_allResultsView?.columns)
        || !instances ||!columnWidths || columnWidths.length !== columns.length) {
        return <></>
    }

    const getInfoOverlay = () => {
        return (
            <Overlay
                isOpen={showInfo >= 0}
                onClose={() => setShowInfo(-1)}
                autoFocus
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus
                hasBackdrop
                usePortal
            >
                <div className={overlayClasses}>
                    <Icon size={16} className={"horizontal-data-info-overlay-close"} onClick={() => setShowInfo(-1)} icon="cross"/>
                    <div className={"horizontal-data-info-container"}>
                        {showInfo >= 0 && Object.keys(instances[showInfo]).map((attributeName: string, x: number) => {
                            const attributeSchema = getAttributeSchemaFromAttribute(schema, attributeName);
                            const attributeLabel = attributeSchema?.title;
                            const attributeValue = instances[showInfo][attributeName];
                            return (
                                <div key={`horizontal-data-info-attribute-container-${x}`} className={'horizontal-data-info-attribute-container'}>
                                    <div className={'horizontal-data-info-attribute-name'}>
                                        {attributeLabel ? attributeLabel : attributeName}
                                    </div>
                                    <div className={'horizontal-data-info-attribute-value'}>
                                        <pre>{attributeValue}</pre>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Overlay>
        )
    }

    const getActionMenuOverlay = (rowIndex: number) => {
            let title = 'Actions';
            const selectedInstance = rowIndex >= 0 ? instances[rowIndex] : undefined;
            if(selectedInstance && Object.keys(selectedInstance) && Object.keys(selectedInstance).length > 0) {
                const firstAttributeName = Object.keys(selectedInstance)[0];
                const attributeSchema = getAttributeSchemaFromAttribute(schema, firstAttributeName);
                const attributeLabel = attributeSchema?.title;
                title = `${attributeLabel}: ${selectedInstance[firstAttributeName]}`;
            }


            const traverseActions = (actions: JSONObject) => {
                const menuItems = [];
                for (const [action, actionSpec] of Object.entries(actions)) {
                    if (actionSpec.type === "menu") {
                        // this level is a menu only, so there will be sub menus
                        menuItems.push(
                            <MenuItem
                                popoverProps={{
                                    //interactionKind: PopoverInteractionKind.CLICK,
                                    popoverClassName: "data-table-action-menu"
                                }}
                                className={"menu-item"}
                                icon={actionSpec.icon}
                                text={action}
                            >
                                {traverseActions(actionSpec)}
                            </MenuItem>
                        )
                    }
                    else if(actionSpec.type === "POST") {
                        menuItems.push(
                            <MenuItem
                                onClick={() => handlePOSTAction(rowIndex, action, actionSpec)}
                                className={"menu-item"}
                                icon={actionSpec.icon}
                                text={action}
                            />
                        )
                    }
                }
                return menuItems;
            }

            const actionMenu = traverseActions(schema.actionMenu);

            return (
                <Menu className={Classes.ELEVATION_1}>
                    <MenuDivider title={title}/>
                    {actionMenu}
                </Menu>
            )

    }

    return (
        <div className={className}>
            {label && <div className={"output-data-table-section-label"}>{label}</div>}
            <Table
                className={"horizontal-data-table"}
                onColumnWidthChanged={handleColumnWidthChanged}
                enableRowHeader={true}
                enableRowResizing={false}
                enableColumnResizing={true}
                numRows={instances.length}
                selectionModes={SelectionModes.ROWS_AND_CELLS}
                enableMultipleSelection={false}
                selectedRegions={region}
                rowHeights={rowHeights}
                columnWidths={columnWidths}
            >
                { columns.map((col, ix: number) => {
                    const columnHeaderCellRenderer = () => {
                        return (
                            <ColumnHeaderCell
                                className={'instance-list-header'}
                                name={col.label}
                            />
                        )
                    }
                    let _cellRenderer = cellRenderer;
                    let _columnHeaderCellRenderer = columnHeaderCellRenderer;
                    if(!noActions && singleResultView && ix === columns.length - 1) {
                        _cellRenderer = actionButtonRenderer;
                        _columnHeaderCellRenderer = actionButtonHeaderCellRenderer;
                    }
                    return (
                        <Column
                            key={col.attribute}
                            className={'horizontal-data-table'}
                            name={col.label}
                            cellRenderer={(rowIndex) => _cellRenderer(rowIndex, col.attribute)}
                            columnHeaderCellRenderer={_columnHeaderCellRenderer}
                        />
                    )
                })}
            </Table>
            {getInfoOverlay()}
        </div>
    );
};
