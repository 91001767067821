import * as React from "react";

import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";
import {Credential} from "raasify-models-specification-ts/core/Credential";
import {EndpointHost} from "raasify-models-specification-ts/core/EndpointHost";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {InstanceEditProps} from "../../../InstanceEdit";
import {BaseInstanceEdit} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";


const EndpointHostEdit: FC<InstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(EndpointHost.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "80%"}} name={EndpointHost._name} />
                        <TabAttribute style={{width: "20%"}} name={EndpointHost._inProduction} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={EndpointHost._description} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "33%"}} name={EndpointHost._sourceImageDirectory} />
                        <TabAttribute style={{width: "34%"}} name={EndpointHost._guestImageDirectory} />
                        <TabAttribute style={{width: "3%"}} name={EndpointHost._sourceBridge} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={EndpointHost._SSHHost} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{width: "100%"}}
                            name={EndpointHost._associatedCredentialId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(Credential._name, 4),
                                new TabAttributeRelatedAttribute(Credential._description, 4),
                                new TabAttributeRelatedAttribute(Credential._method, 3),
                            ]}
                        />
                    </TabAttributeRow>
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default EndpointHostEdit;

