import * as React from "react";

import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {BaseInstanceEdit, BaseInstanceEditProps} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";


const ActivationRequestEdit: FC<BaseInstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(ActivationRequest.getKey())}
            isReadOnly={true}
        >
            <InstanceEditTabs>
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={ActivationRequest._created} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={ActivationRequest._edgeInfo} />
                    </TabAttributeRow>
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default ActivationRequestEdit;

