import React, {FC, useEffect, useState} from "react";
import {FocusStyleManager, Intent, Spinner} from "@blueprintjs/core";
FocusStyleManager.onlyShowFocusOnTabs();

// common imports
import {CORE_LOGIC, EDGE_LOGIC, MARKETPLACE_LOGIC, Services, USER_LOGIC} from "./common/services/Services";
import {InstanceMode} from "./common/enums/InstanceMode";
import {Main} from "./common/components/main/Main";
import {usePrevious} from "./common/utils/stateUtil";
import {InstanceListAttribute} from "./common/model/InstanceListAttribute";
import {InstanceLog} from "./common/model/InstanceLog";
import {Selected} from "./common/model/Selected";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {useNavigate} from "react-router-dom";
import {v4 as uuid} from 'uuid';
// Note: There should not be any references to custom imports in the common folder
// Eventually common will become a library for use with other projects
// Main.tsx should be the only place where raasify imports are tied to common imports

// custom imports
import {Specification as SpecificationType, UnknownMap} from "common-models-ts";
import {core, user} from 'raasify-models-specification-json/index.json';
import {SideBarLayout as NotificationBenchLayout} from "./custom/benches/notifications/components/SideBarLayout";
import {SideBarLayout as PowerbenchLayout} from "./custom/benches/powerbench/components/SideBarLayout";
import {SideBarLayout as WorkbenchLayout} from "./custom/benches/workbench/components/SideBarLayout";
import useIsMounted from "ismounted";
import {InstanceJob} from "./common/model/InstanceJob";
import RenderOnAuthenticated from "./common/components/RenderOnAuthenticated";
import RenderOnAnonymous from "./common/components/RenderOnAnonymous";
import NotAllowed from "./common/components/NotAllowed";
import {User} from "raasify-models-specification-ts/user/User";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {Deployments} from "./common/components/deployments/Deployments";
import {TopMenu} from "./common/components/topmenu/TopMenu";
import {EventService} from "./common/services/EventService";
import {Marketplace} from "./common/components/marketplace/Marketplace";
import {AppToaster} from "./AppToaster";
import {Dashboard} from "./custom/dashboard/Dashboard";
import {useAppDispatch, useAppSelector} from "./common/redux/hooks";
import {instancesSlice} from "./common/redux/slices/instances";
import {logsSlice} from "./common/redux/slices/logs";
import {SideBarEntry} from "./common/components/workbench/sidebar/SideBar";
import {getById} from "./common/utils/instanceUtil";
import {RemoteCLI} from "./custom/benches/workbench/components/components/RemoteCLI";
import {AutomationBuilder} from "./custom/benches/workbench/components/components/AutomationBuilder";
import _ from "lodash";
import {NotificationsLogWindow} from "./custom/benches/notifications/components/components/NotificationsLogWindow";
import {builderSlice} from "./common/redux/slices/builder";
import {Package} from "raasify-models-specification-ts/marketplace/Package";
import {Edge} from "raasify-models-specification-ts/core/Edge";

import {instanceListFilter as workbenchInstanceListFilter} from "./custom/benches/workbench/modifiers/instanceListFilter";
import {instanceListFilter as powerbenchInstanceListFilter} from "./custom/benches/powerbench/modifiers/instanceListFilter";
import {instanceListFilter as notificationsInstanceListFilter} from "./custom/benches/notifications/modifiers/instanceListFilter";
import {instanceListFilter as marketplaceInstanceListFilter} from "./custom/marketplace/modifiers/instanceListFilter";
import { InstanceListFilter } from "./common/model/InstanceListFilter";

const INSTANCE_LIST_FILTER: any = {
    workbench: workbenchInstanceListFilter,
    powerbench: powerbenchInstanceListFilter,
    notifications: notificationsInstanceListFilter,
    marketplace: marketplaceInstanceListFilter,
}

const {Specification} = core.enums;

// @ts-ignore
const SPECIFICATION: {[index: string]: any} = core.specifications[Specification.Edge];

// @ts-ignore
const DEPLOYMENT_SPECIFICATION = user.specifications[user.enums.Specification.Deployment];
// @ts-ignore
const DEPLOYMENT_KEY = user.enums.Specification.Deployment;

// @ts-ignore
const EDITOR_SPECIFICATION: {[index: string]: any} = core.specifications[Specification.ConnectorRequest];

// @ts-ignore
const NOTIFICATION_SPECIFICATION: {[index: string]: any} = core.specifications[Specification.DiscoveredEdge];


const services = new Services(core.specifications);

const getJobSpecification = (specificationKey: string) => {
    // @ts-ignore
    return core.specifications[specificationKey + 'Job'];
}

const PAGE_DEPLOYMENTS = "deployments";
const PAGE_DASHBOARD = "dashboard";
const PAGE_MARKETPLACE = "marketplace";
const PAGE_WORKBENCH = "workbench";
const PAGE_POWERBENCH = "powerbench";
const PAGE_NOTIFICATIONS = "notifications";

type JSONObject = {[index: string]: any};

let SELECTED_DEPLOYMENT: Deployment | undefined;

const queryStringToJSON = (params: string) => {
    const pairs: string[] = params.split('&');
    const result: JSONObject = {};
    pairs.forEach((pair) => {
        const pairArray = pair.split('=');
        result[pairArray[0]] = pairArray[1];
    });
    return result;
}

const queryStringToPagination = (params: string) => {
    const result: JSONObject = {};
    if(params) {
        const pairs: string[] = params.split('&');
        pairs.forEach((pair) => {
            if(pair) {
                const pairArray = pair.split('=');
                result[pairArray[0]] = parseInt(pairArray[1]);
            }
        });
    }
    return result;
}

interface Pagination {
    size: number,
    page: number,
    total: number,
}

const modifierFilter = (item: {[index: string]: {}}, instanceListFilter?: InstanceListFilter) => {
    if(instanceListFilter) {
        for(const _instanceListFilter of [instanceListFilter.transient, instanceListFilter.db]) {
            if (_instanceListFilter) {
                const _filter = queryStringToJSON(_instanceListFilter);
                for (const [key, value] of Object.entries(_filter)) {
                    if (item[key] && item[key].toString() !== value) {
                        return false;
                    }
                }
            }
        }
    }
    return true;
}

export const AppContainer: FC = (props) => {
    const instances = useAppSelector((state) => state.instances)
    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();
    const [instanceFilters, setInstanceFilters] = useState<{[index: string]: string}>({});

    const [initializing, setInitializing] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredInstances, setFilteredInstances] = useState<Array<{}>>([]);
    const [filter, setFilter] = useState<String>("");
    const [abortEdit, setAbortEdit] = useState<boolean>(false);
    const [shouldConfirmAbort, setShouldConfirmAbort] = useState<boolean>(false);
    const [relatedSelectCount, setRelatedSelectCount] = useState<number>(0);
    const [instanceListAttributes, setInstanceListAttributes] = useState<Array<InstanceListAttribute>>();
    const [selectedDeployment, setSelectedDeployment] = useState<Deployment|undefined>();
    const [selectedPage, setSelectedPage] = useState<string|undefined>();
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [selected, setSelected] = useState<Selected>();
    const [params, setParams] = useState<URLSearchParams>();
    const [me, setMe] = useState<User>(User.new({}));

    const previousSelected : Selected|undefined = usePrevious(selected);
    const location = useLocation();
    const navigate = useNavigate();
    const getLocationInfo = () => {
        const parts = location.pathname.split('/');
        parts.shift();
        let deploymentId,
            topSelected,
            mainSelected,
            mainInstanceId,
            mainRelatedSelected,
            mainRelatedInstanceId;
        if(parts.length > 0 && parts[0] === PAGE_DEPLOYMENTS) {
            deploymentId = parts.length > 1 ? parts[1] : undefined;
            topSelected = parts.length > 2 ? parts[2] : undefined;
            mainSelected = parts.length > 3 ? parts[3] : undefined;
            mainInstanceId = parts.length > 4 ? parts[4] : undefined;
            mainRelatedSelected = parts.length > 5 ? parts[5] : undefined;
            mainRelatedInstanceId = parts.length > 6 ? parts[6] : undefined;
        }
        return {
            deploymentId,
            topSelected,
            mainSelected,
            mainInstanceId,
            mainRelatedSelected,
            mainRelatedInstanceId,
        }
    }

    const CUSTOM_COMPONENTS = [
        RemoteCLI, AutomationBuilder, NotificationsLogWindow
    ]

    const getCustomComponent = (name: string) => {
        for(const component of CUSTOM_COMPONENTS) {
            if(name === component.name) {
                return component;
            }
        }
        return null;
    }

    const getLocationSelected = () => {
        const {deploymentId, topSelected, mainSelected, mainInstanceId, mainRelatedSelected, mainRelatedInstanceId} = getLocationInfo();
        let initialSelected = new Selected();
        if(topSelected && [PAGE_DEPLOYMENTS, PAGE_MARKETPLACE, PAGE_DASHBOARD].includes(topSelected)) {
            // unset
        }
        else if(topSelected === PAGE_WORKBENCH && mainSelected && getCustomComponent(mainSelected)) {
            initialSelected.setComponent(getCustomComponent(mainSelected))
                .setSpecification(EDITOR_SPECIFICATION)
                .setJobSpecification(getJobSpecification(EDITOR_SPECIFICATION.key))
                .setInstanceMode(InstanceMode.Data);
        }
        else if(topSelected === PAGE_NOTIFICATIONS && mainSelected && getCustomComponent(mainSelected)) {
            initialSelected.setComponent(getCustomComponent(mainSelected))
                .setSpecification(NOTIFICATION_SPECIFICATION)
                .setJobSpecification(getJobSpecification(NOTIFICATION_SPECIFICATION.key))
                .setInstanceMode(InstanceMode.Data);
        }
        else if (topSelected === PAGE_NOTIFICATIONS) {
            initialSelected
                .setSpecification(NOTIFICATION_SPECIFICATION)
                .setJobSpecification(getJobSpecification(NOTIFICATION_SPECIFICATION.key))
                .setInstanceMode(InstanceMode.Data);
        }
        else {
            initialSelected = new Selected()
                .setSpecification(SPECIFICATION)
                .setJobSpecification(getJobSpecification(SPECIFICATION.key))
                .setInstanceMode(InstanceMode.Data);
        }
        if(mainSelected) {
            initialSelected.setSpecification(services.specification.getByURL(mainSelected));
            if(services.specification.getByURL(mainSelected)) {
                initialSelected.setJobSpecification(getJobSpecification(services.specification.getByURL(mainSelected).key));
            }
        }
        if(mainInstanceId) {
            initialSelected.setInstanceId(mainInstanceId);
        }
        if(mainRelatedSelected) {
            initialSelected.setRelatedSpecification(services.specification.getByURL(mainRelatedSelected));
            initialSelected.setRelatedJobSpecification(getJobSpecification(services.specification.getByURL(mainRelatedSelected).key));
            initialSelected.setInstanceMode(InstanceMode.Related);
        }
        if(mainRelatedInstanceId) {
            initialSelected.setRelatedInstanceId(mainRelatedInstanceId);
        }
        return initialSelected;
    }

    const handleInstanceListOnSortClick = (direction: number, specification: SpecificationType, attributeName: string) => {
        dispatch(instancesSlice.actions.sort({key: specification.key as string, direction, attributeName}));
    }

    const handleInstanceListOnFilter = (filter: string) => {
        if(selected?.specification) {
            const instanceListFilter = getInstanceListFilter(selected.specification as UnknownMap);
            const _filteredInstances: Array<{}> = Object.assign([], instances[selected.getSpecificationKey()]?.instances);

            const filterData = (item: { [index: string]: {} }) => {
                let result = false;
                if (instanceListAttributes) {
                    for (const attribute of instanceListAttributes) {
                        const _attribute = attribute instanceof InstanceListAttribute ? (attribute as InstanceListAttribute).name : attribute;

                        if (attribute.rowFilter && item[_attribute] && !attribute.rowFilter(item[_attribute].toString())) {
                            // If there's a row filter, and it doesn't pass. Bail out
                            return false;
                        }

                        if (item[_attribute] && item[_attribute].toString().toLowerCase().includes(filter.toLowerCase())) {
                            result = true;
                        }
                    }
                }
                else {
                    result = true;
                }
                return result;
            };

            // TODO: only supports '='. Need to add support for '!' or '*' as first char of value


            let filteredInstances = _filteredInstances.filter(item => filterData(item));
            filteredInstances = filteredInstances.filter(item => modifierFilter(item, instanceListFilter));
            setFilteredInstances(filteredInstances);
        }
    }

    const handleMarketplaceOnFilter = (filter: string) => {
        const _filteredInstances: Array<{}> = Object.assign([], instances[Package.getKey()]?.instances);
        const _attribute = "systemWords";
        const filterData = (item: {[index: string]: {}}) => {
            let result = false;
            if(item[_attribute] && item[_attribute].toString().toLowerCase().includes(filter.toLowerCase())) {
                result = true;
            }
            return result;
        };
        const filteredInstances = _filteredInstances.filter(item => filterData(item));
        setFilteredInstances(filteredInstances);
    }

    const getDataService = (specificationPackage: string) => {
        if(specificationPackage === 'user') {
            return services.userData;
        }
        else if(specificationPackage === 'marketplace') {
            return services.marketplaceData.deployment(selectedDeployment?.id);
        }
        return services.data.deployment(selectedDeployment?.id);
    }

    const handleInstanceSave = (specification: {[index: string]: any}, instance: {[index: string]: any}, callback: (res: any, err: any) => void) => {
        const dataService = getDataService(specification.package);
        if(instance.id) {
            setSelected(selected?.clone()
                .setInstanceMode(InstanceMode.Data));
            // UPDATE
            dataService.put(specification.url, instance)
                .then(data => {
                    callback(data, null);
                })
                .catch(err => {
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: err.message,
                    });
                    console.error(err);
                    console.error(JSON.stringify(err, null, 4));
                    callback(null, err);
                })
        }
        else {
            // CREATE
            //console.log("Creating new instance", instance);
            dataService.post(specification.url, instance)
                .then(data => {
                    callback(data, null);
                })
                .catch(err => {
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: err.message,
                    });
                    console.error(err);
                    console.error(JSON.stringify(err, null, 4));
                    callback(null, err);
                })
        }
    }


    const handleInstanceDelete = (specification: {[index: string]: any}, instanceId: string, callback: (res: any, err: any) => void) => {
        if(selected) {
            const dataService = getDataService(specification.package);
            dataService.delete(specification.url, instanceId)
                .then(data => {
                    callback(data, null);
                    if (specification.package !== 'user') {
                        if (selected.instanceId === instanceId) {
                            setSelected(selected.clone().setInstanceId(null).setRelatedInstanceId(null).setRelatedSpecification(null).setInstanceMode(InstanceMode.Data));
                        } else if (selected.relatedInstanceId === instanceId) {
                            setSelected(selected.clone().setRelatedInstanceId(null));
                        }
                    }
                })
                .catch(err => {
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: err.message,
                    });
                    console.error(err);
                    console.error(JSON.stringify(err, null, 4));
                    callback(null, err);
                })
        }
    }

    const confirmAbort = () => {
        if(shouldConfirmAbort && !confirm("Are you sure you want to abort changes?")) {
            setAbortEdit(false);
            return false;
        }
        setAbortEdit(true);
        return true;
    }

    const handleInstanceListOnMount = (instanceListAttributes: Array<InstanceListAttribute>) => {
        setInstanceListAttributes(instanceListAttributes);
    }

    const handleInstanceMenuOnClick = (instanceMode: string) => {
        if(confirmAbort() && selected) {
            const newSelected = selected.clone().setInstanceMode(instanceMode);
            setSelected(newSelected);
        }
    }

    const handleRelatedInstanceMenuOnJob = (job: InstanceJob, parameters: any) => {
        if(selected) {
            const toastKey = uuid();
            AppToaster.show({
                icon: "info-sign",
                intent: Intent.PRIMARY,
                message: "Job '" + job.command + "' submitted.",
            }, toastKey);
            services.data.post(`${selected.relatedSpecification?.url}/${selected.relatedInstanceId}/jobs`, {
                command: job.command,
                ...parameters,
            })
                .then(data => {
                    console.log("job response", data);
                    AppToaster.show({
                        icon: "info-sign",
                        intent: Intent.SUCCESS,
                        message: "Job '" + job.command + "' accepted.",
                    }, toastKey);
                })
                .catch(err => {
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: err.message,
                    }, toastKey);
                    console.error(err);
                    console.error(JSON.stringify(err, null, 4));
                })
        }
    }

    const handleInstanceMenuOnJob = (job: InstanceJob, parameters: any) => {
        if(selected) {
            const toastKey = uuid();
            AppToaster.show({
                icon: "info-sign",
                intent: Intent.PRIMARY,
                message: "Job '" + job.command + "' submitted.",
            }, toastKey);
            services.data.post(`${selected.specification?.url}/${selected.instanceId}/jobs`, {
                command: job.command,
                ...parameters,
            })
                .then(data => {
                    console.log("job response", data);
                    AppToaster.show({
                        icon: "info-sign",
                        intent: Intent.SUCCESS,
                        message: "Job '" + job.command + "' accepted.",
                    }, toastKey);
                })
                .catch(err => {
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: err.message,
                    }, toastKey);
                    console.error(err);
                    console.error(JSON.stringify(err, null, 4));
                })
        }
    }

    const previousSelectedDeployment = usePrevious(selectedDeployment);
    const handleDeploymentSelect = (deployment: {[index: string]: any}|undefined) => {
        //console.log("handleDeploymentSelect", deployment?.name);
        if(!deployment) {
            setSelectedDeployment(undefined);
            //console.log("Attempting clear logs/instances");
            dispatch(instancesSlice.actions.clearAll());
            dispatch(builderSlice.actions.clear());
            setSelected( new Selected()
                .setSpecification(SPECIFICATION)
                .setJobSpecification(getJobSpecification(SPECIFICATION.key))
                .setInstanceMode(InstanceMode.Data)
            );
        }
        else if(previousSelectedDeployment?.id !== deployment.id) {
            //console.log("Attempting clear logs/instances");
            dispatch(instancesSlice.actions.clearAll());
            dispatch(builderSlice.actions.clear());
            setLoading(true);
            setSelectedDeployment(Deployment.new(deployment));
        }
    }

    const handleFilters = () => {
        if (selectedPage !== PAGE_MARKETPLACE) {
            handleInstanceListOnFilter(filter.toString());
        } else {
            handleMarketplaceOnFilter(filter.toString());
        }
    }

    const loadInitialData = () => {
        if (selected && selectedDeployment && selected.specification) {
            getInstances(selected.specification, () => {
                setLoading(false);
            });
        }
        else {
            getInstances(DEPLOYMENT_SPECIFICATION, (data) => {
                setLoading(false);
            });
        }
    }

    const initialize = () => {
        setSelected(getLocationSelected());
        //console.log("gotoDefaultDeployment");
        getInstances(DEPLOYMENT_SPECIFICATION, (deployments: Array<JSONObject>, err: any) => {
            if(err) {
                console.error("gotoDefaultDeployment file", err);
            }
            else {
                if (deployments) {
                    for (const deployment of deployments) {
                        if (deployment.defaultDeployment && location.pathname === `/`) {
                            setSelectedDeployment(Deployment.new(deployment));
                            //console.log("Navigating to", `/deployments/${deployment?.id}/dashboard`);
                            navigate(`/deployments/${deployment?.id}/dashboard`);
                        }
                    }
                    setInitializing(false);

                }
                setLoggedIn(true);
            }
        });
    }

    const handlePageChange = () => {
        setFilter("")
        loadInitialData();
    }

    const getInstanceListFilter = (specification: {[index: string]: any}) => {
        try {
            const instanceListFilter = selectedPage ? INSTANCE_LIST_FILTER[selectedPage.toLowerCase()] : undefined;
            if(selectedPage === PAGE_MARKETPLACE) {
                const filter = instanceListFilter[specification.key];
                return filter
            }
            else if(selectedPage === PAGE_POWERBENCH || selectedPage === PAGE_WORKBENCH || selectedPage === PAGE_NOTIFICATIONS) {
                const key = selected?.getSpecificationKey();
                if (specification.key === key) {
                    // Ensure we only filter for the selected specification and not any other calls from that page
                    return instanceListFilter[key]
                }
            }
        }
        catch(e) {
            console.error("getInstanceListFilter", selectedPage, specification, e);
        }
        return undefined;
    }

    const getInstances = (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean) => {
        const dataService = getDataService(specification.package).deployment(getLocationInfo().deploymentId);
        setInstanceFilters(prevState => ({
            ...prevState,
            [specification.key]: instanceListFilter ? instanceListFilter : getInstanceListFilter(specification)
        }));

        dataService.get(specification.url, undefined, instanceListFilter ? instanceListFilter : getInstanceListFilter(specification), pagination, useFilterAnd)
            .then(res => {
                const data = res.data;
                if(callback) {
                    callback(data, null);
                }
                dispatch(instancesSlice.actions.replace({key: specification.key, data, pagination: queryStringToPagination(res.headers['x-pagination'] as string) as Pagination}));
            })
            .catch(err => {
                console.error(err);
                console.error(JSON.stringify(err, null, 4));
                if(callback) {
                    callback(null, err);
                }
            })
    }

    const getInstance = (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => {
        const dataService = getDataService(specification.package).deployment(getLocationInfo().deploymentId);

        dataService.get(specification.url, id)
            .then(res => {
                const data = res.data;
                if(callback) {
                    callback(data, null);
                }
                dispatch(instancesSlice.actions.update({key: specification.key, data}));
            })
            .catch(err => {
                console.error(err);
                console.error(JSON.stringify(err, null, 4));
                if(callback) {
                    callback(null, err);
                }
            })
    }

    const handleSetSelectedPage = (_selectedPage: string | undefined) => {
        const newSelected = getLocationSelected();
        //console.log("handleSetSelectedPage newSelected", newSelected);
        setSelected(newSelected);
        setSelectedPage(_selectedPage);
    }

    const updateSelectionFromLocation = () => {
        //console.log("updateSelectionFromLocation", location.pathname);
        const parts = location.pathname.split('/');
        parts.shift();
        if(parts.length > 0 && parts[0] === PAGE_DEPLOYMENTS) {
            const {deploymentId, topSelected} = getLocationInfo();
            if(topSelected) {
                handleSetSelectedPage(topSelected);
            }
            else {
                handleSetSelectedPage(PAGE_DEPLOYMENTS);
            }
            if(!instances[DEPLOYMENT_SPECIFICATION.key]) {
                getInstances(DEPLOYMENT_SPECIFICATION, (deployments: Array<JSONObject>) => {
                    handleDeploymentSelect(deploymentId ? getById(deployments, deploymentId) : undefined);
                    setLoading(false);
                });
            }
            else {
                handleDeploymentSelect(deploymentId ? getById(instances[DEPLOYMENT_SPECIFICATION.key].instances, deploymentId) : undefined);
                setLoading(false);
            }
        }
    }

    const verifyAPIAccess = () => {
        services.userData.get('me')
            .then((res) => {
                setMe(User.new(res.data));
                initialize();
            })
            .catch((err) => {
                console.error(err);
            })
    }

    useEffect(() => {
        setParams(new URLSearchParams(location.search));
        //console.log("useEffect []");
        verifyAPIAccess();
    }, []);

    useEffect(() => {
        if(loggedIn) {
            //console.log("useEffect loggedIn", loggedIn);
            updateSelectionFromLocation();
        }
    }, [loggedIn]);

    useEffect(() => {
        if(loggedIn) {
            //console.log("useEffect location", location);
            updateSelectionFromLocation();
        }
    }, [location]);

    useEffect(() => {
        if(selectedPage) {
            //console.log("useEffect selectedPage", selectedPage);
            handlePageChange();
        }
    }, [selectedPage]);

    useEffect(() => {
        if(instanceListAttributes) {
            //console.log("useEffect instanceListAttributes", instanceListAttributes);
            handleInstanceListOnFilter(filter.toString());
        }
    }, [instanceListAttributes]);

    useEffect(() => {
        //console.log("useEffect filter", filter);
        handleFilters();
    }, [filter]);

    useEffect(() => {
        SELECTED_DEPLOYMENT = selectedDeployment;
        if(selectedDeployment && selected) {
            //console.log("useEffect selectedDeployment", selectedDeployment);
            dispatch(instancesSlice.actions.clearAllExcept({key: DEPLOYMENT_KEY}));
            // get the default instances
            if (selected.specification) {
                getInstances(selected.specification, () => {
                    setLoading(false);
                });
            }
        }
    }, [selectedDeployment]);

    useEffect(() => {
        if(selected) {
            //console.log("useEffect selected", selected);
            if (previousSelected?.getSpecificationKey() !== selected.getSpecificationKey()) {
                loadInitialData();
            }
        }
    }, [selected]);

    useEffect(() => {
        if(instances) {
            //console.log("useEffect instances", instances);
            handleFilters();
        }
    }, [instances]);

    if(initializing || !loggedIn || !selected) {
        return <Spinner className={"dashboard-spinner"} intent={Intent.PRIMARY} size={60} />
    }

    return (
        <>
            <RenderOnAuthenticated>
                <div className={'main-outer'}>
                    <div className={'main-header'}>
                        <TopMenu
                            me={me}
                            params={params}
                            getInstances={getInstances}
                            selectedPage={selectedPage}
                            selectedDeployment={selectedDeployment}
                        />
                    </div>
                    <div className={'main-content'}>
                        <Routes>
                            <Route path="/" element={<Navigate to="/deployments" replace />} />
                            <Route path="/deployments" element={
                                <Deployments
                                    me={me}
                                    selected={selected}
                                    loading={loading}
                                    setLoading={setLoading}
                                    getInstances={getInstances}
                                    getInstance={getInstance}
                                    services={services}
                                    relatedSelectCount={relatedSelectCount}
                                    setRelatedSelectCount={setRelatedSelectCount}
                                    onInstanceSave={handleInstanceSave}
                                    onInstanceDelete={handleInstanceDelete}
                                    onDeploymentSelect={handleDeploymentSelect}
                                    selectedPage={selectedPage}
                                />}
                            />
                            <Route path="/deployments/:deploymentId" element={<Navigate to={`/deployments/${selectedDeployment?.id}/dashboard`}/>}/>
                            <Route path="/deployments/:deploymentId/dashboard" element={
                                <Dashboard
                                    selected={selected}
                                    services={services}
                                    setLoading={setLoading}
                                    selectedDeployment={selectedDeployment}
                                    getInstances={getInstances}
                                />
                            }/>
                            <Route path="/deployments/:deploymentId/marketplace" element={
                                <Marketplace
                                    me={me}
                                    loading={loading}
                                    setLoading={setLoading}
                                    getInstances={getInstances}
                                    getInstance={getInstance}
                                    filteredInstances={filteredInstances}
                                    onFilter={setFilter}
                                    filter={filter}
                                    services={services}
                                    onInstanceSave={handleInstanceSave}
                                    selectedPage={selectedPage}
                                    params={params}
                                />}
                            />
                            <Route
                                path="/deployments/:deploymentId/workbench/*"
                                element={
                                    <Main
                                        me={me}
                                        selectedDeployment={selectedDeployment as Deployment}
                                        getInstances={getInstances}
                                        getInstance={getInstance}
                                        filteredInstances={filteredInstances}
                                        filter={filter}
                                        services={services}
                                        selected={selected}
                                        selectedPage={selectedPage as string}
                                        SideBarLayout={WorkbenchLayout}
                                        onInstanceListOnFilter={setFilter}
                                        onInstanceListOnSortClick={handleInstanceListOnSortClick}
                                        onInstanceSave={handleInstanceSave}
                                        onInstanceDelete={handleInstanceDelete}
                                        onInstanceListOnMount={handleInstanceListOnMount}
                                        onInstanceMenuOnClick={handleInstanceMenuOnClick}
                                        onInstanceMenuOnJob={handleInstanceMenuOnJob}
                                        onRelatedInstanceMenuOnJob={handleRelatedInstanceMenuOnJob}
                                        relatedSelectCount={relatedSelectCount}
                                        setRelatedSelectCount={setRelatedSelectCount}
                                    />
                                }
                            />
                            <Route
                                path="/deployments/:deploymentId/powerbench/*"
                                element={
                                    <Main
                                        me={me}
                                        selectedDeployment={selectedDeployment as Deployment}
                                        getInstances={getInstances}
                                        getInstance={getInstance}
                                        filteredInstances={filteredInstances}
                                        filter={filter}
                                        services={services}
                                        selected={selected}
                                        selectedPage={selectedPage as string}
                                        SideBarLayout={PowerbenchLayout}
                                        onInstanceListOnFilter={setFilter}
                                        onInstanceListOnSortClick={handleInstanceListOnSortClick}
                                        onInstanceSave={handleInstanceSave}
                                        onInstanceDelete={handleInstanceDelete}
                                        onInstanceListOnMount={handleInstanceListOnMount}
                                        onInstanceMenuOnClick={handleInstanceMenuOnClick}
                                        onInstanceMenuOnJob={handleInstanceMenuOnJob}
                                        onRelatedInstanceMenuOnJob={handleRelatedInstanceMenuOnJob}
                                        relatedSelectCount={relatedSelectCount}
                                        setRelatedSelectCount={setRelatedSelectCount}
                                    />
                                }
                            />
                            <Route
                                path="/deployments/:deploymentId/notifications/*"
                                element={
                                    <Main
                                        me={me}
                                        selectedDeployment={selectedDeployment as Deployment}
                                        getInstances={getInstances}
                                        getInstance={getInstance}
                                        filteredInstances={filteredInstances}
                                        filter={filter}
                                        services={services}
                                        selected={selected}
                                        selectedPage={selectedPage as string}
                                        SideBarLayout={NotificationBenchLayout}
                                        onInstanceListOnFilter={setFilter}
                                        onInstanceListOnSortClick={handleInstanceListOnSortClick}
                                        onInstanceSave={handleInstanceSave}
                                        onInstanceDelete={handleInstanceDelete}
                                        onInstanceListOnMount={handleInstanceListOnMount}
                                        onInstanceMenuOnClick={handleInstanceMenuOnClick}
                                        onInstanceMenuOnJob={handleInstanceMenuOnJob}
                                        onRelatedInstanceMenuOnJob={handleRelatedInstanceMenuOnJob}
                                        relatedSelectCount={relatedSelectCount}
                                        setRelatedSelectCount={setRelatedSelectCount}
                                    />
                                }
                            />
                        </Routes>
                    </div>
                </div>
                <EventService
                    instanceFilters={instanceFilters}
                    service={CORE_LOGIC + ',' + EDGE_LOGIC}
                    dataService={services.data}
                    deployment={selectedDeployment}
                    deploymentMode={true}
                    defaultInstancesKey={Edge.getKey()}
                    defaultInstancesIdAttribute={Edge._associatedDiscoveredEdgeId}
                />
                <EventService
                    instanceFilters={instanceFilters}
                    service={USER_LOGIC}
                    dataService={services.userData}
                />
                <EventService
                    instanceFilters={instanceFilters}
                    service={MARKETPLACE_LOGIC}
                    dataService={services.marketplaceData}
                    deployment={selectedDeployment}
                    deploymentMode={true}
                />
                {loading && <Spinner className={"dashboard-spinner"} intent={Intent.PRIMARY} size={60} />}
            </RenderOnAuthenticated>
            <RenderOnAnonymous>
                <NotAllowed/>
            </RenderOnAnonymous>
        </>
    );
}
