import React, {FC, useEffect, useRef, useState} from "react";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {AutomationTemplate} from "raasify-models-specification-ts/core/AutomationTemplate";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {core, marketplace} from "raasify-models-specification-json/index.json";
import {Button, Classes, H4, Icon, Intent, Overlay, Spinner, Tab, Tabs} from "@blueprintjs/core";
import {v4 as uuid} from 'uuid';
import nunjucks from 'nunjucks';
import _ from "lodash";
import {CustomComponentProps} from "../../../../../common/components/main/CustomComponentProps";
import {Selected} from "../../../../../common/model/Selected";
import {useAppDispatch, useAppSelector} from "../../../../../common/redux/hooks";
import { AppToaster } from "../../../../../AppToaster";
import {DefaultPanel} from "../../../../../common/components/workbench/common/panel/DefaultPanel";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {ObjectListSuggest} from "../../../../../common/components/workbench/common/forms/ObjectListSuggest";
import {CodeEditor} from "../../../../../common/components/workbench/common/forms/CodeEditor";
import {JSONEditor} from "../../../../../common/components/workbench/common/forms/JSONEditor";
import {OutputDataTable} from "../../../../../common/components/workbench/common/schema/OutputDataTable";
import {Tooltip2} from "@blueprintjs/popover2";
import {IconName} from "@blueprintjs/icons";
import {MaybeElement} from "@blueprintjs/core/lib/esm/common";
import classNames from "classnames";
import {InputSchema} from "../../../../../common/components/workbench/common/schema/InputSchema";
import {builderSlice} from "../../../../../common/redux/slices/builder";
import * as monaco from "monaco-editor";
import {editor} from "monaco-editor";
import ICodeEditor = editor.ICodeEditor;
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {getByAttributeValue, getById} from "../../../../../common/utils/instanceUtil";
import {AttributeRaw} from "../../../../../common/components/workbench/common/forms/AttributeRaw";
import UserService from "../../../../../common/services/UserService";
import {Package} from "raasify-models-specification-ts/marketplace/Package";
import {ConnectorRequest} from "raasify-models-specification-ts/core/ConnectorRequest";
import {EndpointTemplate} from "raasify-models-specification-ts/core/EndpointTemplate";
import {timeutil} from "./RemoteCLI";
import {atomic} from "atomic";

type JSONObject = {[index: string]: any};


// set nunjucks auto escaping to false
nunjucks.configure({ autoescape: false });

// @ts-ignore
const CONNECTOR_REQUEST_KEY = core.enums.Specification.ConnectorRequest;
const CONNECTOR_REQUEST_SPEC = core.specifications["core.ConnectorRequest"];
const TRANSFORMER_SPEC = core.specifications["core.Transformer"];
const PACKAGE_KEY = marketplace.enums.Specification.Package;
const PACKAGE_SPEC = marketplace.specifications["marketplace.Package"];

const AUTOMATION_TEMPLATES_ENDPOINT = 'automationtemplates';
const INSTALL_NEXT_VERSION = 'installnextversion';

const DEFAULT_FONT_SIZE = 11;
const MIN_FONT_SIZE = 6;
const MAX_FONT_SIZE = 30;

const CONNECTOR_TEMPLATE_ID_ANSIBLE = '3f0149a1-4d6d-4421-a1cd-55b712b4cda1';
const CONNECTOR_TEMPLATE_ID_NODE_JS = '9543a3a0-3626-4962-8068-546efd6c799d';
const CONNECTOR_TEMPLATE_ID_PYTHON = '1e509f94-36ca-45a2-87c9-38335a778b49';


enum SaveDetailType {
    New = 'new',
    Existing = 'existing',
}

interface SaveDetails {
    type: SaveDetailType,
    package: any,
    endpointTemplate: any,
    automationName: string,
    automationDescription: string,
}

const EMPTY_PLAYBOOK = {
//    id: 'e457b7b0-ca76-46db-9641-770238ea2bed',
    name: "Empty Playbook",
    template: {
        playbook: "- name: Please enter\n" +
            "  gather_facts: false\n" +
            "  hosts: all\n" +
            "  tasks:\n" +
            "    - name: Please enter\n" +
            "      command: >-\n" +
            "        Enter commands (one command per line)"
    },
    inputSchema: {
        title: "Automation Inputs",
        type: "object",
        properties: {}
    },
    associatedConnectorTemplateName: "Ansible",
    associatedEndpointTemplateId: "",
}

const DEFAULT_TRANSFORMER = `const transformer = ({rawResponse}) => {
    return  {
        output: rawResponse.tasks[0].stdout
    }
}
`;

const DEFAULT_OUTPUT_SCHEMA = {
    type: "object",
    properties: {
        output: {
            title: "Output",
            description: "CLI command output",
            type: "text"
        },
    },
    views: {
        singleResult: {
            type: "vertical",
            noLabels: true,
            usePreTags: true,
            sections: [
                {
                    data: "output",
                },
            ]
        },
    }
};

const DEFAULT_TRANSFORMER_INSTANCE = {
    name: "Default Transformer",
    description: "Default Transformer",
    transformerMode: 'Advanced',
    transformerAdvancedJS: DEFAULT_TRANSFORMER,
    outputSchema: DEFAULT_OUTPUT_SCHEMA,
}



const toCamelCase = (input: string) => {
    input = input || '';
    return input.replace(/\w\S*/g, (txt: string) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const AutomationBuilder: FC<CustomComponentProps> = (props) => {
    const {
        getInstances,
        getInstance,
        services,
        relatedSelectCount,
        setRelatedSelectCount,
        selectedPage,
        me,
    } = props;

    const instances = useAppSelector((state) => state.instances);
    const playbook = useAppSelector((state) => state.builder).data;
    const endpoint = useAppSelector((state) => state.builder).endpoint;
    const [instance, setInstance] = useState<JSONObject>({
        request: {},
    });
    const [loading, setLoading] = useState<boolean>();
    const [automationTemplate, setAutomationTemplate] = useState<JSONObject>();
    const [response, setResponse] = useState<JSONObject>({});
    const [selected, setSelected] = useState<Selected>(new Selected());
    const [selectedOut, setSelectedOut] = useState<Selected>(new Selected());
    const [script, setScript] = useState<string>("");
    const [selectedInputTab, setSelectedInputTab] = useState<string>("template");
    const [selectedOutputTab, setSelectedOutputTab] = useState<string>("result");
    const [transformer, setTransformer] = useState<JSONObject>();
    const [transformerAdvancedJS, setTransformerAdvancedJS] = useState<string>();
    const [inputSchema, setInputSchema] = useState<JSONObject>();
    const [outputSchema, setOutputSchema] = useState<JSONObject>();
    const [templateValue, setTemplateValue] = useState()
    const [inputFontSize, setInputFontSize] = useState<number>(DEFAULT_FONT_SIZE)
    const [templateSaveOverlayOpen, setTemplateSaveOverlayOpen] = useState<boolean>(false);
    const [maximize, setMaximize] = useState<boolean>(true);
    const [inputSchemaInstance, setInputSchemaInstance] = useState<JSONObject>();
    const schema = outputSchema ? outputSchema : transformer && transformer['outputSchema'];
    const [selectedString, setSelectedString] = useState<string>();
    const [showNewVariableOverlay, setShowNewVariableOverlay] = useState<boolean>();
    const [newVariable, setNewVariable] = useState<JSONObject>();
    const [inputSchemaCodeMode, setInputSchemaCodeMode] = useState<boolean>(false);
    const [saveDetails, setSaveDetails] = useState<SaveDetails>();

    const dispatch = useAppDispatch();
    const t:any = UserService.getTokenParsed();
    const emailUser = t.email.split("@")[0];
    const emailDomain = t.email.split("@")[1];
    const userDirectory = `${emailUser}-${emailDomain}`;
    const orgName = me.associatedOrganizationName;

    const instancesRef:any = useRef();
    instancesRef.current = instances;
    const playbookRef:any = useRef();
    playbookRef.current = playbook;
    const inputSchemaRef:any = useRef();
    inputSchemaRef.current = inputSchema;
    const automationTemplateRef:any = useRef();
    automationTemplateRef.current = automationTemplate;
    const inputSchemaInstanceRef:any = useRef();
    inputSchemaInstanceRef.current = inputSchemaInstance;
    const endpointRef:any = useRef();
    endpointRef.current = endpoint;

    const handleAttributeChange = (name: string, value: any) => {
        setInstance(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const onCodeChange = (value: any) => {
        const _automationTemplate = automationTemplateRef.current;
        if(_automationTemplate) {
            let newAutomationTemplate;
            if (_automationTemplate.associatedConnectorTemplateName === 'Ansible') {
                newAutomationTemplate = {..._automationTemplate, template: {playbook: value}};
                handlePlaybookChange(newAutomationTemplate);
            } else if (_automationTemplate.associatedConnectorTemplateName === 'NodeJS' || _automationTemplate.associatedConnectorTemplateName === 'Python') {
                newAutomationTemplate = {..._automationTemplate, template: {script: value}};
                handleScriptChange(newAutomationTemplate);
            }
            //console.log("onCodeChange setAutomationTemplate", (newAutomationTemplate as any)?.name);
            setAutomationTemplate(newAutomationTemplate);
        }
    }

    const onAutomationTemplateChange = (automationTemplate: AutomationTemplate) => {
        if(automationTemplate.associatedConnectorTemplateName === 'Ansible') {
            handlePlaybookChange(automationTemplate);
        }
        else if(automationTemplate.associatedConnectorTemplateName === 'NodeJS' || automationTemplate.associatedConnectorTemplateName === 'Python') {
            handleScriptChange(automationTemplate);
        }
    }

    const handlePlaybookChange = (automationTemplate: any) => {
        //("handlePlaybookChange", automationTemplate);
        const value = automationTemplate.template.playbook;
        setInstance(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                ...automationTemplate.template,
            },
        }))
        dispatch(builderSlice.actions.set({data: value}));
        setTemplateValue(value);
    }

    const handleScriptChange = (automationTemplate: any) => {
        const value = automationTemplate.template.script;
        setInstance(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
                ...automationTemplate.template,
            },
        }))
        setScript(value);
        setTemplateValue(value);
    }

    const handleTransformerAdvancedJSChange = (value: any) => {
        setInstance(prevState => ({
            ...prevState,
            request: {
                ...prevState.request,
            },
            transformerAdvancedJS: value,
        }))
        setTransformerAdvancedJS(value);
        setTransformer(prevState => ({
            ...prevState,
            transformerAdvancedJS: value,
        }))
    }

    const handleOutputSchemaChange = (value: any) => {
        try {
            const parsedValue = JSON.parse(value);
            setInstance(prevState => ({
                ...prevState,
                request: {
                    ...prevState.request,
                    outputSchema: parsedValue,
                }
            }))
            setOutputSchema(parsedValue);
            setTransformer(prevState => ({
                ...prevState,
                outputSchema: parsedValue,
            }))
        }
        catch(e) {
        }
    }

    const handleInputSchemaChange = (value: any) => {
        try {
            const parsedValue = JSON.parse(value);
            setInputSchema(parsedValue);
            //console.log("handleInputSchemaChange setAutomationTemplate", (automationTemplateRef.current as any)?.name);
            setAutomationTemplate({
                ...automationTemplateRef.current,
                inputSchema: parsedValue,
            })
        }
        catch(e) {
        }
    }

    const handleUpdateInputSchemaInstance = (automationTemplate: any) => {
        setInputSchemaInstance({
            inputData: automationTemplate.inputData,
            associatedAutomationTemplateId: automationTemplate.id,
        })
    }

    const renderNunjucksObject = (obj: any, input: any) => {
        for(const i in obj) {
            if(obj.hasOwnProperty(i)){
                if(obj[i] && !_.isObject(obj[i]) && !_.isArray(obj[i])) {
                    obj[i] = nunjucks.renderString(obj[i], input);
                }
                else if(obj[i]) {
                    renderNunjucksObject(obj[i], input);
                }
            }
        }
    };

    const getServiceName = (automationTemplate: AutomationTemplate) => {
        return 'connector-' + automationTemplate?.associatedConnectorTemplateName?.toLowerCase();
    }

    const getLanguage = (automationTemplate: AutomationTemplate) => {
        if(automationTemplate) {
            if (automationTemplate.associatedConnectorTemplateName === 'Ansible') {
                return 'yaml';
            }
            if (automationTemplate.associatedConnectorTemplateName === 'NodeJS') {
                return 'javascript';
            }
            if (automationTemplate.associatedConnectorTemplateName === 'Python') {
                return 'python';
            }
        }
        return '';
    }

    const handleAutomationTemplateChange = (_automationTemplate: any, preventPlaybookUpdate?: boolean) => {
        if(_automationTemplate) {
            const automationTemplate = _automationTemplate as AutomationTemplate;
            getInstances(Endpoint.specification, undefined,
                {db: "associatedEndpointTemplateId=" + automationTemplate.associatedEndpointTemplateId});
        }

        //console.log("handleAutomationTemplateChange", _automationTemplate.name);
        if(endpoint && endpoint.associatedEndpointTemplateId !== _automationTemplate.associatedEndpointTemplateId) {
            dispatch(builderSlice.actions.set({endpoint: null}));
        }

        if(!preventPlaybookUpdate && _automationTemplate.associatedConnectorTemplateName === 'Ansible') {
            dispatch(builderSlice.actions.set({data: _automationTemplate.template.playbook}));
        }
        else if(['NodeJS', 'Python'].includes(_automationTemplate.associatedConnectorTemplateName)) {
            setScript(_automationTemplate.template.script);
        }

        setResponse({});
        //console.log("handleAutomationTemplateChange setAutomationTemplate", (_automationTemplate as any)?.name);

        setAutomationTemplate(_automationTemplate);
        onAutomationTemplateChange(_automationTemplate);
        handleUpdateInputSchemaInstance(_automationTemplate);
        setInputSchema(_automationTemplate.inputSchema);
        setSelected(new Selected().setInstanceId(_automationTemplate.id))
        // Get the transformer is possible
        if(_automationTemplate.associatedTransformerId) {
            services.data.get(TRANSFORMER_SPEC.url, _automationTemplate.associatedTransformerId)
                .then((res) => {
                    const data = res.data;
                    setTransformer(data);
                    setTransformerAdvancedJS(data.transformerAdvancedJS);
                    setOutputSchema(data.outputSchema);
                    setInstance(prevState => ({
                        ...prevState,
                        transformerAdvancedJS: data.transformerAdvancedJS,
                    }))
                })
                .catch(err => {
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: err.message,
                    });
                    console.error(err);
                })
        }
        else {
            // populate the defaults
            setTransformer(DEFAULT_TRANSFORMER_INSTANCE);
            setTransformerAdvancedJS(DEFAULT_TRANSFORMER);
            setOutputSchema(DEFAULT_OUTPUT_SCHEMA);
            setInstance(prevState => ({
                ...prevState,
                transformerAdvancedJS: DEFAULT_TRANSFORMER,
            }))
        }
    }

    const handleEndpointChange = (endpoint: any) => {
        setResponse({});
        if(!endpoint.associatedEdgeId) {
            alert("Endpoint must be associated to an Edge.");
        }
        setInstance(prevState => ({
            ...prevState,
            associatedEdgeId: endpoint.associatedEdgeId,
            associatedEndpointId: endpoint.id,
        }))
        dispatch(builderSlice.actions.set({endpoint: endpoint}));
    }

    type executionState = {connectorRequest: any|undefined, id: string|undefined, isRunning: boolean};
    const RESPONSE_CHECK_INTERVAL = 333;
    const TIMEOUT_MS = 60000;

    const responseLoop = async (state: executionState) => {
        const REST_CHECK_INTERVAL = 5000;
        let restCheckInterval = REST_CHECK_INTERVAL;
        while(state.isRunning) {
            let connectorRequests = instancesRef.current[ConnectorRequest.getKey()]?.instances ? instancesRef.current[ConnectorRequest.getKey()].instances : [];
            for(const connectorRequest of connectorRequests) {
                if(connectorRequest.id === state.id && connectorRequest.reply) {
                    state.connectorRequest = connectorRequest;
                    return;
                }
            }
            await timeutil.sleep(RESPONSE_CHECK_INTERVAL);
            restCheckInterval -= RESPONSE_CHECK_INTERVAL;
            if(restCheckInterval < 0) {
                restCheckInterval = REST_CHECK_INTERVAL;
                getInstances(ConnectorRequest.specification, (data) => {
                }, {db: "id=" + state.id});
            }
        }
    }

    const handleSendRequest = () => {
        if(!endpoint) {
            alert("Endpoint must be selected.");
        }
        else {
            if(automationTemplate) {
                let state: executionState = {connectorRequest: undefined, id: undefined, isRunning: true};

                let template;
                let connectorTemplateId;
                if (automationTemplate.associatedConnectorTemplateName === 'Ansible') {
                    template = {...automationTemplate.template, playbook};
                    connectorTemplateId = CONNECTOR_TEMPLATE_ID_ANSIBLE;
                } else if (automationTemplate.associatedConnectorTemplateName === 'NodeJS') {
                    template = {...automationTemplate.template, script};
                    connectorTemplateId = CONNECTOR_TEMPLATE_ID_NODE_JS;
                } else if (automationTemplate.associatedConnectorTemplateName === 'Python') {
                    template = {...automationTemplate.template, script};
                    connectorTemplateId = CONNECTOR_TEMPLATE_ID_PYTHON;
                }
                setResponse({});
                setLoading(true);
                instance.serviceName = getServiceName(automationTemplate as AutomationTemplate);
                services.data.post(CONNECTOR_REQUEST_SPEC.url, {
                    ...instance,
                    request: {...template, variables: {automation: {mergedInput: inputSchemaInstance?.inputData}}},
                    associatedAutomationTemplateId: automationTemplate?.id,
                    associatedConnectorTemplateId: connectorTemplateId,
                })
                    .then((data) => {
                        state.id = data.id;
                        Promise.race([
                            new Promise((_, reject) => setTimeout(() => reject(new Error('Command Timed Out')), TIMEOUT_MS)),
                            responseLoop(state),
                        ]).then((res) => {
                            state.isRunning = false;
                            // GET the response again to include transient data
                            getInstances(ConnectorRequest.specification, (data) => {
                                console.log("CONNECTOR_REQUEST_SPEC getInstances data response", data);
                                setResponse(data[0]);
                            }, {db: "id=" + state.connectorRequest.id});
                        })
                            .catch(err => {
                                AppToaster.show({
                                    icon: "error",
                                    intent: Intent.DANGER,
                                    message: err.message,
                                });
                                console.error(err);
                            })
                            .finally(() => setLoading(false));
                    })
                    .catch(err => {
                        AppToaster.show({
                            icon: "error",
                            intent: Intent.DANGER,
                            message: err.message,
                        });
                        console.error(err);
                    })
            }
        }
    }

    useEffect(() => {
        //console.log("useEffect automationTemplate", automationTemplate?.name);
    }, [automationTemplate])


    useEffect(() => {
        if(!automationTemplate && playbook) {
            handleAutomationTemplateChange({
                ...EMPTY_PLAYBOOK,
                template: {
                    playbook: playbook
                },
            }, true);
        }
    }, [playbook])

    useEffect(() => {
        if(!automationTemplate && endpoint) {
            handleEndpointChange(endpoint);
        }
    }, [endpoint])

    useEffect(() => {
        ////console.log("remount", instance);
        getInstances(EndpointTemplate.specification, undefined);
        getInstances(AutomationTemplate.specification, undefined, {db: "type=Configuration&scope=Application"}, undefined, true);
        getInstances(Package.specification, undefined, {db: "directory=" + userDirectory});
        return () => {
            dispatch(builderSlice.actions.clear());
            //console.log("useEffect setAutomationTemplate", undefined);
            setAutomationTemplate(undefined);
        }
    }, [])

    useEffect(() => {
        setSelectedOut(new Selected().setInstanceId(uuid()));
    }, [response])

    useEffect(() => {
        //console.log("instance", instance);
    }, [instance])

    useEffect(() => {
        //console.log("saveDetails", saveDetails);
    }, [saveDetails])

    const spinnerStyle = !loading ? { display: 'none' } : { display: 'inline'};

    let templates = instances[AutomationTemplate.getKey()]?.instances ? instances[AutomationTemplate.getKey()]?.instances : [];
    templates = [...templates];
    templates.sort(function (a: any, b: any) {
        return (a.packageLabel && a.packageLabel.localeCompare(b.packageLabel))
            || (a.packageName && a.packageName.localeCompare(b.packageName))
            || (a.name && a.name.localeCompare(b.name));
    });
    templates = [
        EMPTY_PLAYBOOK,
        ...templates
    ];

    let endpoints = instances[Endpoint.getKey()]?.instances ? instances[Endpoint.getKey()].instances : [];
    if(automationTemplate && automationTemplate.associatedEndpointTemplateId) {
        const _endpoints = [];
        for(const endpoint of endpoints) {
            if(automationTemplate.associatedEndpointTemplateId === endpoint.associatedEndpointTemplateId) {
                _endpoints.push(endpoint);
            }
        }
        endpoints = [..._endpoints];
    }
    if(!automationTemplate) {
        endpoints = [];
    }

    const handleOnInputTabSelect = (tabId: string) => {
        setSelectedInputTab(tabId);
    }

    const handleOnOutputTabSelect = (tabId: string) => {
        setSelectedOutputTab(tabId);
    }

    const handleInputFontSizeChange = (direction: number) => {
        if((direction === -1 && inputFontSize > MIN_FONT_SIZE) || (direction === 1 && inputFontSize < MAX_FONT_SIZE)) {
            setInputFontSize(inputFontSize + direction);
        }
    }

    const handleJSONEditorFontSizeChange = (direction: number) => {
        let r = document.querySelector(':root');
        const fontSizeString = getComputedStyle(r as any).getPropertyValue('--jse-font-size-mono');
        const fontSize = parseInt(fontSizeString.replace('px', ''));
        if((direction === -1 && fontSize > MIN_FONT_SIZE) || (direction === 1 && fontSize < MAX_FONT_SIZE)) {
            const newSize = String(fontSize + direction).toString() + 'px';
            (r as any).style.setProperty('--jse-font-size-mono', newSize);
        }
    }

    const fontSizeIcon = (tooltipText: string, icon: IconName | MaybeElement, className: string, onClickMethod: (direction: number) => void, direction: number) => {
        return (
            <Tooltip2
                className={'tooltip-container'}
                content={<div className={'tooltip-content'}>{tooltipText}</div>}
                placement="right-start"
            >
                <Icon icon={icon} className={className} onClick={() => onClickMethod(direction)}/>
            </Tooltip2>
        )
    }

    const handleResize = () => {
        setMaximize(!maximize)
    }

    const resizeIcon = (tooltipText: string, maximize: boolean) => {
        return (
            <Tooltip2
                className={'tooltip-container'}
                content={<div className={'tooltip-content'}>{tooltipText}</div>}
                placement="right-start"
            >
                <Icon icon={maximize ? "minimize" : "maximize"} className={"automation-builder-resize-icon"} onClick={handleResize}/>
            </Tooltip2>
        )
    }

    const handleOnCloseTemplateSave = () => {
        setTemplateSaveOverlayOpen(false);
    }

    const disableOverwrite = () => {
        return !automationTemplate?.id
            || automationTemplate?.name === EMPTY_PLAYBOOK.name
            || (!automationTemplate?.packageName.startsWith(`@${userDirectory}/`)
                && !automationTemplate?.packageName.startsWith(`@${userDirectory}..`)
                && !automationTemplate?.packageName.startsWith(`@${orgName}`));
    }

    const getPackageInfo = (packageName: string) => {
        const _packageName = packageName.substring(1);
        // Forked Package Format ${directory}..${forkedFrom}/${packageName}
        const forkedPackageMatch = _packageName.match(/(.+)\.\.(.+)\/(.+)/);
        let forkedFrom, directory, name;
        if(forkedPackageMatch) {
            directory = forkedPackageMatch[1]
            forkedFrom = forkedPackageMatch[2];
            name = forkedPackageMatch[3];
        }
        else {
            const packageMatch = _packageName.match(/(.+)\/(.+)/);
            if(packageMatch) {
                directory = packageMatch[1];
                name = packageMatch[2];
            }
        }
        const res = {
            name,
            directory,
            forkedFrom,
        };
        //console.log("getPackageInfo", res);
        return res
    }

    const handleSave = () => {
        const _package = automationTemplate?.name !== EMPTY_PLAYBOOK.name
        && (automationTemplate?.packageName.startsWith(`@${userDirectory}/`)
            || automationTemplate?.packageName.startsWith(`@${userDirectory}..`)
            || automationTemplate?.packageName.startsWith(`@${orgName}/`))
            ? getPackageInfo(automationTemplate?.packageName) : {directory: userDirectory};
        getInstances(Package.specification, undefined, {db: "directory=" + _package.directory});
        setSaveDetails({
            type: disableOverwrite() ? SaveDetailType.New : SaveDetailType.Existing,
            package: _package,
            endpointTemplate: automationTemplate?.name !== EMPTY_PLAYBOOK.name
                ? getById(instances[EndpointTemplate.getKey()]?.instances, automationTemplate?.associatedEndpointTemplateId)
                : {name: ''},
            automationName: automationTemplate?.name !== EMPTY_PLAYBOOK.name ? automationTemplate?.name : "",
            automationDescription: automationTemplate?.name !== EMPTY_PLAYBOOK.name ? automationTemplate?.description : "",
        })
        setTemplateSaveOverlayOpen(true);
    }

    const handleOnSaveTemplateSave = () => {
        setLoading(true);
        const requestData = {
            id: automationTemplate?.id,
            package: {
                name: saveDetails?.package.name,
                directory: saveDetails?.package.directory,
                forkedFrom: saveDetails?.package.forkedFrom,
                version: saveDetails?.package.version,
            },
            associatedEndpointTemplateId: saveDetails?.endpointTemplate.id,
            associatedEndpointTemplateName: saveDetails?.endpointTemplate.name,
            automationName: saveDetails?.automationName,
            automationDescription: saveDetails?.automationDescription,
            automationTemplate: {
                ...automationTemplate,
                associatedEndpointTemplateId: saveDetails?.endpointTemplate.id,
                associatedEndpointTemplateName: saveDetails?.endpointTemplate.name,
            },
            transformer,
        }

        const method = saveDetails?.type === SaveDetailType.New ? 'POST' : 'PUT';
        const id = saveDetails?.type === SaveDetailType.New ? undefined : requestData.id;
        services.marketplaceData.request(method, AUTOMATION_TEMPLATES_ENDPOINT, id, requestData)
            .then((data) => {
                //(JSON.stringify(data, null, 4));
                setTemplateSaveOverlayOpen(false);
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.SUCCESS,
                    message: `${saveDetails?.automationName} saved to repository.`,
                });
                setLoading(false);
                services.marketplaceData.post(INSTALL_NEXT_VERSION, requestData)
                    .then((data) => {
                        //console.log(JSON.stringify(data, null, 4));
                        AppToaster.show({
                            icon: "tick",
                            intent: Intent.SUCCESS,
                            message: `${saveDetails?.package.name} installed.`,
                        });
                    })
                    .catch((err) => {
                            console.error(err);
                            AppToaster.show({
                                icon: "cross",
                                intent: Intent.DANGER,
                                message: err.message,
                            });
                        })

                setTimeout(() => {
                    AppToaster.show({
                        icon: "refresh",
                        intent: Intent.PRIMARY,
                        message: `Building and Installing ${saveDetails?.package.name} ...`,
                    });
                }, 1000);
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
                AppToaster.show({
                    icon: "cross",
                    intent: Intent.DANGER,
                    message: err.message,
                });
            })
    }

    const handleSaveTemplateFormChange = (name: string, value: any) => {
        if(name === 'directory') {
            getInstances(Package.specification, undefined, {db: "directory=" + value});
            setSaveDetails({
                ...(saveDetails as SaveDetails),
                package: {
                    ...(saveDetails as SaveDetails).package,
                    directory: value,
                }
            })
        }
        else {
            setSaveDetails({
                ...(saveDetails as SaveDetails),
                [name]: value,
            })
        }
        if(name === 'endpointPolicy') {
            setAutomationTemplate((prevState) => {
                //console.log("onCodeChange setAutomationTemplate", (prevState as any)?.name);
                return {
                    ...prevState,
                    associatedEndpointTemplateId: value.id,
                    associatedEndpointTemplateName: value.name,
                }
            })
        }
        if(name === 'automationName' && saveDetails?.type === SaveDetailType.New) {
            setTransformer((prevState) => ({
                ...prevState,
                name: value,
                description: `Transformer for ${value}`,
            }))
        }
    }

    const getTemplateSaveOverlay = () => {
        const packageGroupList = [userDirectory];
        if(orgName !== userDirectory) {
            packageGroupList.push(orgName as string)
        }
        ////console.log("automationTemplate", automationTemplate);
        return (
            <Overlay
                isOpen={templateSaveOverlayOpen}
                onClose={handleOnCloseTemplateSave}
                autoFocus
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus
                hasBackdrop
                usePortal
            >
                <div className={classNames(
                    Classes.CARD,
                    Classes.PORTAL,
                    "automation-builder-template-save-overlay",
                )}>
                    <H4>Save Automation Template</H4>
                    <AttributeRaw
                        style={{width: "100%"}}
                        instance={saveDetails}
                        label={"Save as"}
                        attributeName={"type"}
                        onChange={handleSaveTemplateFormChange}
                        type={"radio"}
                        radioItems={[
                            {label: "New Automation", value: SaveDetailType.New},
                            {label: "Update Existing", value: SaveDetailType.Existing},
                        ]}
                        disabled={disableOverwrite()}
                    />
                    <AttributeRaw
                        style={{width: "100%"}}
                        instance={saveDetails?.package}
                        label={"Package Directory"}
                        attributeName={"directory"}
                        onChange={handleSaveTemplateFormChange}
                        type={"string"}
                        defaultValue={saveDetails?.package?.directory}
                        enumList={packageGroupList}
                        disabled={saveDetails?.type === SaveDetailType.Existing}
                    />
                    <div className={"automation-builder-template-save-overlay-package-container"}>
                        <AttributeRaw
                            style={{width: "100%"}}
                            instance={saveDetails?.package}
                            label={"Package Name"}
                            attributeName={"package"}
                            onChange={handleSaveTemplateFormChange}
                            type={"string"}
                            disabled={saveDetails?.type === SaveDetailType.Existing}
                            suggestList={instances && instances[Package.getKey()] && instances[Package.getKey()].instances}
                            suggestListLabelAttribute={"name"}
                            suggestListHideLabels
                            suggestListAddNew={(name: string): any => {
                                return {
                                    name: name,
                                    directory: saveDetails?.package?.directory,
                                    addNew: true,
                                };
                            }}
                        />
                        {saveDetails?.package.forkedFrom &&
                            <div className={"automation-builder-template-save-overlay-forked-from"}>
                                Forked from: {saveDetails?.package.forkedFrom}
                            </div>
                        }
                    </div>
                    <AttributeRaw
                        style={{width: "100%"}}
                        instance={saveDetails?.endpointTemplate}
                        label={"Endpoint Template"}
                        attributeName={"endpointTemplate"}
                        onChange={handleSaveTemplateFormChange}
                        type={"string"}
                        disabled={saveDetails?.type === SaveDetailType.Existing}
                        suggestList={instances && instances[EndpointTemplate.getKey()] && instances[EndpointTemplate.getKey()].instances}
                        suggestListLabelAttribute={"name"}
                    />
                    <AttributeRaw
                        style={{width: "100%"}}
                        instance={saveDetails}
                        label={"Automation Name"}
                        attributeName={"automationName"}
                        onChange={handleSaveTemplateFormChange}
                        type={"string"}
                        disabled={saveDetails?.type === SaveDetailType.Existing}
                    />
                    <AttributeRaw
                        style={{width: "100%"}}
                        instance={saveDetails}
                        label={"Automation Description"}
                        attributeName={"automationDescription"}
                        onChange={handleSaveTemplateFormChange}
                        type={"string"}
                        disabled={saveDetails?.type === SaveDetailType.Existing}
                    />

                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        {<Button disabled={loading} type="submit" onClick={handleOnSaveTemplateSave} intent={Intent.SUCCESS} icon="confirm" text={"Save and Install"}/>}
                        {<Button disabled={loading} onClick={handleOnCloseTemplateSave} intent={Intent.DANGER} icon="undo" text={"Cancel"}/>}
                    </div>
                </div>
            </Overlay>
        )
    }

    const handleCreateInputVariableFromSelection = (ed: ICodeEditor) => {
        const playbook = playbookRef.current;
        const selected = ed.getSelection();
        if(selected) {
            const lineNumber = selected.selectionStartLineNumber-1;
            const startIndex = selected.startColumn-1;
            const endIndex = selected.endColumn-1;
            if(playbook) {
                const playbookLines = playbook.split("\n");
                const selectedString = playbookLines[lineNumber].substring(startIndex, endIndex);

                if(selectedString.length <= 2) {
                    alert("Ensure you select the whole variable to replace");
                    return;
                }

                setSelectedString(selectedString);
                setShowNewVariableOverlay(true);
                setNewVariable({
                    name: "",
                    title: "",
                    description: "",
                    default: selectedString,
                    type: "string",
		    style: {flexBasis: "25%"},
                })
            }
        }
    }

    const handleOnCloseNewVariableOverlay = () => {
        setShowNewVariableOverlay(false);
    }

    const handleNewVariableAttributeChange = (name: string, value: string) => {
        if(name === 'name') {
            // Set the other vars from name
            setNewVariable((prevState: JSONObject) => ({
                ...prevState,
                [name]: value,
                title: toCamelCase(value),
                description: `The ${value}`
            }));
        }
        else {
            setNewVariable((prevState: JSONObject) => ({
                ...prevState,
                [name]: value,
            }));
        }
    }


    const handleOnSaveNewVariableOverlay = () => {
        //console.log("handleOnSaveNewVariableOverlay", newVariable);
        if(newVariable) {
            updateInputSchemaVariable(newVariable);
        }
    }

    const updateInputSchemaVariable = (variable: any, skipReplace?: boolean) => {
        //console.log("updateInputSchemaVariable", variable);

        const inputSchema = {
            ...inputSchemaRef.current,
            properties: {
                ...inputSchemaRef.current?.properties,
                [variable.name]: {
                    ...inputSchemaRef.current.properties[variable.name],
                    title: variable.title,
                    description: variable.description,
                    type: variable.type,
                    default: variable.default,
                    style: variable.style,
                },
            }
        }
        setInputSchema(inputSchema);

        const inputSchemaInstance = inputSchemaInstanceRef.current;
        setInputSchemaInstance({
            ...inputSchemaInstance,
            inputData: {
                ...inputSchemaInstance.inputData,
                [variable.name]: variable.default,
            }
        });
        setShowNewVariableOverlay(false);

        const automationTemplate = automationTemplateRef.current;

        let playbook = playbookRef.current;
        const newAutomationTemplate = {
            ...automationTemplate,
            template: {
                playbook: playbook,
            },
            inputData: {
                ...automationTemplate.inputData,
                [variable.name]: variable.default,
            },
            inputSchema,
        }

        if(!skipReplace) {
            const varRegex = new RegExp(selectedString as string, 'g');
            playbook = playbook.replace(varRegex, `{{automation.mergedInput.${variable.name}}}`);
            newAutomationTemplate.template.playbook = playbook;
            handlePlaybookChange(newAutomationTemplate);
            //console.log("updateInputSchemaVariable setAutomationTemplate", (newAutomationTemplate as any)?.name);

            setAutomationTemplate(newAutomationTemplate);
        }
        else {
            //console.log("updateInputSchemaVariable setAutomationTemplate", (newAutomationTemplate as any)?.name);
            setAutomationTemplate(newAutomationTemplate);
        }
    }

    const handleInputSchemaFormHeaderChange = (name: string, value: string) => {
        ////console.log("handleInputSchemaFormHeaderChange", name, value);
        const inputSchema = {
            ...inputSchemaRef.current,
            title: value,
        }
        setInputSchema(inputSchema);
        const automationTemplate = automationTemplateRef.current;
        //console.log("handleInputSchemaFormHeaderChange setAutomationTemplate", (automationTemplate as any)?.name);
        setAutomationTemplate({
            ...automationTemplate,
            template: {
                playbook: playbook,
            },
            inputSchema,
        })
    }

    const handleInputSchemaFormChange = (name: string, value: string, attribute: any) => {
        //console.log("handleInputSchemaFormChange", name, value);
        if(name === "name") {
            alert("name change not supported yet");
            return;
        }
        if(name === 'width') {
            updateInputSchemaVariable({
                ...attribute,
                style: {...attribute.style, flexBasis: value},
            }, true)

        }
        else {
            updateInputSchemaVariable({
                ...attribute,
                [name]: value,
            }, true)
        }
    }

    const getInputSchemaForm = () => {
        const getPanel = (name:string, _attribute: any, ix: number) => {
            const attribute = Object.assign({}, _attribute);
            attribute.name = name;
            attribute.width = attribute.style?.flexBasis;
            return (
                <div className={"automation-build-input-schema-form-variable"}>
                    <AttributeRaw style={{width: "40%"}} instance={attribute} label={`${ix+1}) Variable Name`} attributeName={"name"} onChange={(n, v) => handleInputSchemaFormChange(n, v, attribute)} type={"string"} />
                    <AttributeRaw style={{width: "40%"}} instance={attribute} label={"Title"} attributeName={"title"} onChange={(n, v) => handleInputSchemaFormChange(n, v, attribute)} type={"string"} />
                    <AttributeRaw style={{width: "20%"}} instance={attribute} label={"Width"} attributeName={"width"} onChange={(n, v) => handleInputSchemaFormChange(n, v, attribute)} type={"string"} />
                    <AttributeRaw style={{width: "60%"}} instance={attribute} label={"Description"} attributeName={"description"} onChange={(n, v) => handleInputSchemaFormChange(n, v, attribute)} type={"string"} />
                    <AttributeRaw style={{width: "40%"}} instance={attribute} label={"Default"} attributeName={"default"} onChange={(n, v) => handleInputSchemaFormChange(n, v, attribute)} type={"string"} />
                </div>
            );
        }

        return (
            <div style={{height: codeEditorStyle.height}} className={"automation-build-input-schema-form-container"}>
                <div className={"automation-build-input-schema-form-header"}>
                    <AttributeRaw instance={inputSchema} label={"Input Form Label"} attributeName={"title"} onChange={handleInputSchemaFormHeaderChange} type={"string"} />
                </div>
                {inputSchema && Object.entries(inputSchema.properties).map(([name, attribute], ix) => {
                    return getPanel(name, attribute, ix);
                })}
                {inputSchema && Object.values(inputSchema.properties).length === 0
                    && <span className={'automation-build-input-schema-form-variable'}>No variables found.</span>}
            </div>
        )
    }

    const getNewVariableOverlay = () => {
        return (
            <Overlay
                isOpen={showNewVariableOverlay}
                onClose={handleOnCloseNewVariableOverlay}
                autoFocus
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus
                hasBackdrop
                usePortal
            >
                <div className={classNames(
                    Classes.CARD,
                    Classes.PORTAL,
                    "deployments-manage-popup",
                )}>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleOnSaveNewVariableOverlay();
                    }}>
                        <H4>Add new variable ({selectedString})</H4>
                        <DefaultPanel
                            selected={selected}
                            onAttributeChange={handleNewVariableAttributeChange}
                            services={services}
                            instance={newVariable as any}
                            specification={{
                                attributes: {
                                    name: {
                                        name: "name",
                                        label: "Variable Name",
                                        type: "string",
                                        description: "The variable name to be used in the template"
                                    },
                                    title: {
                                        name: "title",
                                        label: "Title",
                                        type: "string",
                                        description: "The label to be used in the input form"
                                    },
                                    description: {
                                        name: "description",
                                        label: "Description",
                                        type: "string",
                                        description: "The tooltip label to be used in the input form"
                                    },
                                    default: {
                                        name: "default",
                                        label: "Default",
                                        type: "string",
                                        description: "The default value to be used in the input form"
                                    }
                                }
                            }}
                            instanceEditTab={
                                <InstanceEditTab noHeader>
                                    <TabAttributeRow>
                                        <TabAttribute style={{width: "100%"}} name={'name'} />
                                    </TabAttributeRow>
                                    <TabAttributeRow>
                                        <TabAttribute style={{width: "100%"}} name={'title'} />
                                    </TabAttributeRow>
                                    <TabAttributeRow>
                                        <TabAttribute style={{width: "100%"}} name={'description'} />
                                    </TabAttributeRow>
                                    <TabAttributeRow>
                                        <TabAttribute style={{width: "100%"}} name={'default'} />
                                    </TabAttributeRow>
                                </InstanceEditTab>
                            }
                            onInstanceSave={(specification, instance, response) => {
                                // not used here
                                //console.log("onInstanceSave", specification, instance, response);
                            }}
                            relatedSelectCount={relatedSelectCount}
                            setRelatedSelectCount={setRelatedSelectCount}
                            getInstances={getInstances}
                            getInstance={getInstance}
                            selectedPage={selectedPage}
                            editMode={true}
                            setEditMode={() => {}}
                        />
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            {<Button type="submit" onClick={handleOnSaveNewVariableOverlay} intent={Intent.SUCCESS} icon="confirm"
                                     text={"Add Variable"}/>}
                            {<Button onClick={handleOnCloseNewVariableOverlay} intent={Intent.DANGER} icon="undo" text={"Cancel"}/>}
                        </div>
                    </form>
                </div>
            </Overlay>
        )
    }

    const offset = maximize ? '35px' : 'calc( (100vh - 190px) * 0.5 )';

    const codeEditorStyle = {
        width: "calc( (100vw - 200px) * 0.45  - 10px)",
        height: `calc( 100vh - 190px - ${offset} )`,
    };

    const jsonEditorStyle = {
        width: "calc( (100vw - 200px) * 0.55 - 10px)",
        height: `calc( 100vh - 185px - ${offset} )`,
    }

    const templateActions = [{
        // An unique identifier of the contributed action.
        id: 'create-input-variable',

        // A label of the action that will be presented to the user.
        label: 'Create Input Variable from Selection',

        // An optional array of keybindings for the action.
        keybindings: [
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.F10,
        ],

        // A precondition for this action.
        precondition: undefined,

        // A rule to evaluate on top of the precondition in order to dispatch the keybindings.
        keybindingContext: undefined,

        contextMenuGroupId: 'navigation',

        contextMenuOrder: 1,

        // Method that will be executed when the action is triggered.
        // @param editor The editor instance is passed in as a convenience
        run: (ed: ICodeEditor) => {
            handleCreateInputVariableFromSelection(ed);
        }
    }]

    return (
        <div className={'automation-builder-panel-outer'}>
            {getTemplateSaveOverlay()}
            {getNewVariableOverlay()}
            <DefaultPanel
                className={"automation-builder-panel"}
                selected={selected}
                onAttributeChange={handleAttributeChange}
                services={services}
                instance={instance}
                specification={CONNECTOR_REQUEST_SPEC}
                instanceEditTab={
                    <InstanceEditTab noHeader>
                        <TabAttributeRow>
                            <div>
                                <div className={'automation-builder-template'}>
                                    <div className={'bp4-text-small attribute-label'}>Automation Template</div>
                                    <ObjectListSuggest
                                        attributeName={'name'}
                                        instance={automationTemplate}
                                        filterAttributes={['packageLabel', 'packageName', 'name']}
                                        onChange={handleAutomationTemplateChange}
                                        items={templates}
                                        menuItemLayout={[
                                            {label: "Name", attributeName: "name", style: {width: "50%"}},
                                            {label: "Provider", attributeName: "packageRepository", style: {width: "25%"},
                                                modifier: (value) => (value ? value.split('/')[0] : value)},
                                            {label: "Package", attributeName: "packageLabel", style: {width: "25%"}},
                                        ]}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={'automation-builder-endpoint'}>
                                    <div className={'bp4-text-small attribute-label'}>Endpoint</div>
                                    <ObjectListSuggest
                                        attributeName={'name'}
                                        instance={endpoint}
                                        onChange={handleEndpointChange}
                                        items={endpoints}
                                        menuItemLayout={[
                                            {label: "Name", attributeName: "name", style: {width: "60%"}},
                                            {label: "Policy", attributeName: "associatedEndpointPolicyName", style: {width: "40%"}}
                                        ]}
                                    />
                                </div>
                            </div>
                            <Button
                                className={"automation-builder-button automation-builder-send"}
                                intent={Intent.SUCCESS}
                                icon="play"
                                text={"Send Request"}
                                disabled={!endpoint}
                                onClick={(e) => handleSendRequest()}
                            />
                            <Button
                                className={"automation-builder-button automation-builder-save"}
                                intent={Intent.PRIMARY}
                                icon="saved"
                                text={"Save ..."}
                                disabled={!automationTemplate}
                                onClick={(e) => handleSave()}
                            />
                        </TabAttributeRow>
                        <div>
                            <div className={"automation-builder-input-editor-container"}>
                                <div className={"automation-builder-json-container"}>
                                    <div className={"automation-builder-request-container"}>
                                        <div className={"automation-builder-request-font-size"}>
                                            {false && fontSizeIcon("Increase font", "plus", "automation-builder-request-font-size-icon", handleInputFontSizeChange, 1)}
                                            {false && fontSizeIcon("Decrease font", "minus", "automation-builder-request-font-size-icon", handleInputFontSizeChange, -1)}
                                        </div>
                                        <Tabs
                                            onChange={handleOnInputTabSelect}
                                            className={"automation-builder-tabs"}
                                            id={"tabs"}
                                            selectedTabId={selectedInputTab}
                                            renderActiveTabPanelOnly
                                        >
                                            <Tab id="template" title="Template" panel={
                                                <div className={"automation-builder-template"}>
                                                    <CodeEditor
                                                        fontSize={inputFontSize}
                                                        language={getLanguage(automationTemplate as AutomationTemplate)}
                                                        width={codeEditorStyle.width}
                                                        height={codeEditorStyle.height}
                                                        className={"automation-builder-yaml"}
                                                        disabled={!automationTemplate}
                                                        onChange={(data) => onCodeChange(data)}
                                                        value={templateValue}
                                                        actions={templateActions}
                                                    />
                                                </div>
                                            }/>
                                            <Tab id="inputSchema" title="Input Schema" panel={
                                                <div className={"automation-builder-template"}>
                                                    <div className={"automation-builder-input-schema-button-container"}>
                                                        <Tooltip2
                                                            className={'tooltip-container'}
                                                            content={<div className={'tooltip-content'}>Switch edit mode</div>}
                                                            placement="right-start"
                                                        >
                                                            <Icon className={"automation-builder-input-schema-button"} icon={"manually-entered-data"} onClick={() => setInputSchemaCodeMode(!inputSchemaCodeMode)} />
                                                        </Tooltip2>
                                                    </div>
                                                    {inputSchemaCodeMode && <CodeEditor
                                                        fontSize={inputFontSize}
                                                        language={"json"}
                                                        width={codeEditorStyle.width}
                                                        height={codeEditorStyle.height}
                                                        className={"automation-builder-yaml"}
                                                        disabled={!automationTemplate}
                                                        onChange={(data) => handleInputSchemaChange(data)}
                                                        value={JSON.stringify(inputSchema, null, 2)}
                                                    />}
                                                    {!inputSchemaCodeMode && getInputSchemaForm()}
                                                </div>
                                            }/>
                                            <Tab id="transformer" title="Transformer" panel={
                                                <div className={"automation-builder-template"}>
                                                    <CodeEditor
                                                        fontSize={inputFontSize}
                                                        language={"javascript"}
                                                        width={codeEditorStyle.width}
                                                        height={codeEditorStyle.height}
                                                        className={"automation-builder-yaml"}
                                                        disabled={!automationTemplate}
                                                        onChange={(data) => handleTransformerAdvancedJSChange(data)}
                                                        value={transformerAdvancedJS}
                                                    />
                                                </div>
                                            }/>
                                            <Tab id="outputSchema" title="Output Schema" panel={
                                                <div className={"automation-builder-template"}>
                                                    <CodeEditor
                                                        fontSize={inputFontSize}
                                                        language={"json"}
                                                        width={codeEditorStyle.width}
                                                        height={codeEditorStyle.height}
                                                        className={"automation-builder-yaml"}
                                                        disabled={!automationTemplate}
                                                        onChange={(data) => handleOutputSchemaChange(data)}
                                                        value={JSON.stringify(outputSchema, null, 2)}
                                                    />
                                                </div>
                                            }/>
                                        </Tabs>
                                    </div>
                                    <div className={"automation-builder-output-container"}>
                                        <div className={"automation-builder-output-font-size"}>
                                            {false && fontSizeIcon("Increase font", "plus", "automation-builder-output-font-size-icon", handleJSONEditorFontSizeChange, 1)}
                                            {false && fontSizeIcon("Decrease font", "minus", "automation-builder-output-font-size-icon", handleJSONEditorFontSizeChange, -1)}
                                        </div>
                                        <Tabs
                                            onChange={handleOnOutputTabSelect}
                                            className={"automation-builder-tabs"}
                                            id={"tabs"}
                                            selectedTabId={selectedOutputTab}
                                            renderActiveTabPanelOnly
                                        >
                                            <Tab id="result" title="Result" panel={
                                                <div className={"automation-builder-results"}>
                                                    <OutputDataTable
                                                        {...props}
                                                        selected={selectedOut}
                                                        className={"output-data-table"}
                                                        instance={response}
                                                        schema={schema}
                                                        resultAttribute={'transformedResponse'}
                                                    />
                                                </div>
                                            }/>
                                            <Tab id="json" title="JSON" panel={
                                                <div className={"automation-builder-results"}>
                                                    <JSONEditor
                                                        selected={selectedOut}
                                                        className={"automation-builder-json"}
                                                        style={jsonEditorStyle}
                                                        id={"response"}
                                                        disabled={true}
                                                        onChange={() => {
                                                        }}
                                                        value={response.transformedResponse ? response.transformedResponse : {}}
                                                    />
                                                </div>
                                            }/>
                                            <Tab id="raw" title="Raw" panel={
                                                <div className={"automation-builder-results"}>
                                                    <JSONEditor
                                                        selected={selectedOut}
                                                        className={"automation-builder-json"}
                                                        style={jsonEditorStyle}
                                                        id={"response"}
                                                        disabled={true}
                                                        onChange={() => {
                                                        }}
                                                        value={response.reply ? response.reply : {}}
                                                    />
                                                </div>
                                            }/>
                                            <Tab id="request" title="Request" panel={
                                                <div className={"automation-builder-results"}>
                                                    <JSONEditor
                                                        selected={selectedOut}
                                                        className={"automation-builder-json"}
                                                        style={jsonEditorStyle}
                                                        id={"response"}
                                                        disabled={true}
                                                        onChange={() => {
                                                        }}
                                                        value={response.request ? response.request : {}}
                                                    />
                                                </div>
                                            }/>
                                        </Tabs>
                                    </div>
                                </div>
                                <div className={"automation-builder-inputs"}>
                                    <div className={"automation-builder-resize"}>
                                        {!maximize && resizeIcon("Minimize", true)}
                                        {maximize && resizeIcon("Maximize", false)}
                                    </div>
                                    <InputSchema
                                        instance={inputSchemaInstance as any}
                                        setInstance={setInputSchemaInstance}
                                        editMode={true}
                                        setEditMode={(editMode: boolean) => {
                                            console.log("setEditMode Function not implemented.");
                                        }}
                                        {...props}
                                        selected={new Selected()}
                                        inputSourceInstance={automationTemplate}
                                        sourceUrl={AutomationTemplate.getUrl()}
                                        sourceIdAttribute={AutomationPolicy._associatedAutomationTemplateId}
                                        schemaAttribute={AutomationTemplate._inputSchema}
                                        schema={inputSchema}
                                    />
                                </div>
                            </div>
                        </div>
                    </InstanceEditTab>
                }
                onInstanceSave={(specification, instance, response) => {
                    // not used here
                    //console.log("onInstanceSave", specification, instance, response);
                }}
                relatedSelectCount={relatedSelectCount}
                setRelatedSelectCount={setRelatedSelectCount}
                getInstances={getInstances}
                getInstance={getInstance}
                selectedPage={selectedPage}
                editMode={true}
                setEditMode={() => {}}
            />
            <div style={spinnerStyle}><Spinner className={"instance-spinner"} intent={Intent.PRIMARY} size={60} /></div>
        </div>
    );
}
