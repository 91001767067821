import * as React from "react";
import {EdgeJob} from "raasify-models-specification-ts/core/EdgeJob";
import {InstanceJob} from "../../../../../common/model/InstanceJob";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {FC} from "react";
import {BaseInstanceJobs} from "../../../../../common/components/workbench/instance/jobs/BaseInstanceJobs";
import {InstanceJobsProps} from "../../../InstanceJobs";

export const EdgeJobs: FC<InstanceJobsProps> = (props) => {
    return (
        <BaseInstanceJobs
            {...props}
            jobs={[
                new InstanceJob({
                    command: EdgeJob.command.Upgrade,
                    icon: 'changes',
                    isDisabled: (item: any) => !item.activated,
                }),
                new InstanceJob({
                    command: EdgeJob.command.Restart,
                    icon: 'refresh',
                    isDisabled: (item: any) => !item.activated,
                }),
                new InstanceJob({
                    command: EdgeJob.command.ClearSSHKnownHosts,
                    icon: 'clean',
                    isDisabled: (item: any) => !item.activated,
                }),
                new InstanceJob({
                    command: EdgeJob.command.Reboot,
                    icon: 'reset',
                    isDisabled: (item: any) => !item.activated,
                }),
                new InstanceJob({
                    command: EdgeJob.command.Deactivate,
                    icon: 'ban-circle',
                    isDisabled: (item: any) => !item.activated,
                }),
                new InstanceJob({
                    command: EdgeJob.command.RunCommand,
                    label: "Run Command",
                    icon: "walk",
                    parameters: (
                        <InstanceEditTab noHeader>
                            <TabAttributeRow>
                                <TabAttribute style={{width: "100%"}} name={EdgeJob._runCommand} />
                            </TabAttributeRow>
                        </InstanceEditTab>
                    ),
                    overlay: {
                        label: 'Command to run on the Edge',
                        button: 'Run',
                    },
                    isDisabled: (item: any) => !item.activated,
                }),
                new InstanceJob({
                    command: EdgeJob.command.RunScript,
                    label: "Run Script",
                    icon: "play",
                    parameters: (
                        <InstanceEditTab noHeader>
                            <TabAttributeRow>
                                <TabAttribute style={{width: "100%"}} name={EdgeJob._runScript} />
                            </TabAttributeRow>
                        </InstanceEditTab>
                    ),
                    overlay: {
                        label: 'Script to run on the Edge',
                        button: 'Run',
                    },
                    isDisabled: (item: any) => !item.activated,
                }),
            ]}
        />
    )
};

