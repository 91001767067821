import * as React from "react";
import {DiscoveredEdgeJob} from "raasify-models-specification-ts/core/DiscoveredEdgeJob";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const DiscoveredEdgeJobList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: DiscoveredEdgeJob._created,
                    columnWidth: 220
                }),
                new InstanceListAttribute({
                    name: DiscoveredEdgeJob._command,
                    columnWidth: 100,
                    //rowFilter: (value) => [DiscoveredEdgeJob.command.RunCommand, DiscoveredEdgeJob.command.RunScript].includes(value),
                }),
                new InstanceListAttribute({
                    name: DiscoveredEdgeJob._associatedDiscoveredEdgeId,
                    columnWidth: 150,
                }),
            ]}
        />
    );
};
