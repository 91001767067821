import * as React from "react";
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const ActivationRequestList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: ActivationRequest._created,
                    columnWidth: 250,
                }),
                new InstanceListAttribute({
                    name: ActivationRequest._edgeInfo,
                    filter: 'hostnameCTL.staticHostname',
                    label: 'Hostname',
                    columnWidth: 150,
                }),
                new InstanceListAttribute({
                    name: ActivationRequest._status,
                    columnWidth: 100,
                }),
            ]}
        />
    );
};
