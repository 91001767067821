import React, {FC, useEffect, useState} from "react";
import {Alert, Alignment, Button, Classes, H4, Intent, Navbar, Overlay,} from "@blueprintjs/core";
import {InstanceMode} from "../../../../enums/InstanceMode";
import {Selected} from "../../../../model/Selected";
import {InstanceMenuItem} from "../../../../model/InstanceMenuItem";
import {Popover2} from "@blueprintjs/popover2";
import {Services} from "../../../../services/Services";
import {InstanceJob} from "../../../../model/InstanceJob";
import {getById} from "../../../../utils/instanceUtil";
import {InstanceModeIcon} from "../../../../enums/InstanceModeIcon";
import {DefaultPanel} from "../../common/panel/DefaultPanel";
import classNames from "classnames";
import {useAppSelector} from "../../../../redux/hooks";
import {InstanceJobs} from "../../../../../custom/benches/InstanceJobs";
import {Link} from "react-router-dom";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";

export interface BaseInstanceMenuProps
{
    services: Services;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    selected: Selected;
    rootSelected?: Selected;
    onClick(instanceMode: string, relatedSpecKey?: string): void;
    onJob?: (job: InstanceJob, parameters: any) => void;
    onInstanceDelete(specification: {[index: string]: {}} | null, instanceId: string, callback: (res: any, err: any) => void): void;
    instanceMenuItems: Array<InstanceMenuItem>;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedPage: string;
    editMode?: boolean;
    setEditMode?: (editMode: boolean) => void;
    children?: React.ReactNode;
    selectedDeployment: Deployment;
}

export const BaseInstanceMenu: FC<BaseInstanceMenuProps> = (props) => {
    const {
        onClick,
        onJob,
        selected,
        rootSelected,
        onInstanceDelete,
        instanceMenuItems,
        services,
        getInstances,
        getInstance,
        relatedSelectCount,
        setRelatedSelectCount,
        selectedPage,
        setEditMode,
        selectedDeployment,
    } = props;
    const instances = useAppSelector((state) => state.instances)
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false);
    const [jobMenuOpen, setJobMenuOpen] = useState<boolean>(false);
    const [isJobsParametersOpen, setIsJobsParametersOpen] = useState<boolean>(false);
    const [job, setJob] = useState<InstanceJob>();
    const [jobParameters, setJobParameters] = useState<{}>({});
    const [approve, setApprove] = useState<boolean>(false);

    const handleInstanceDelete = () => {
        if(selected.instanceId) {
            onInstanceDelete(selected.specification, selected.instanceId, (res, err) => {
                if(err) {
                    console.error(err);
                }
            })
        }
    }

    const handleDeleteClick = () => {
        setDeleteAlertOpen(true);
    }

    const handleDeleteAlertCancel = () => {
        setDeleteAlertOpen(false);
        setJobMenuOpen(false);
    }

    const handleDeleteAlertConfirm = () => {
        setDeleteAlertOpen(false);
        handleInstanceDelete();
        setJobMenuOpen(false);
    }

    const handleJobMenuInteraction = (nextOpenState: boolean, event: React.SyntheticEvent<HTMLElement, Event>|undefined) => {
        setJobMenuOpen(nextOpenState);
    }

    const handleOnClick = (instanceMenuItem: InstanceMenuItem) => {
        const {mode} = instanceMenuItem;

        if(mode === InstanceMode.Delete) {
            handleDeleteClick();
        }
        else if(mode === InstanceMode.Jobs) {
            toggleJobMenuOpen();
        }
        else {
            onClick(mode)
        }
    }

    const toggleJobMenuOpen = () => {
        setJobMenuOpen(!jobMenuOpen);
    }

    const handleOnJob = (job: InstanceJob) => {
        if(job.parameters) {
            // if parameters are defined, we need to open the overlay
            setJob(job);
            setIsJobsParametersOpen(true);
        }
        else {
            toggleJobMenuOpen();
            onJob && onJob(job, {});
        }
    }

    const handleJobParametersAttributeChange = (name: string, value: any) => {
        setJobParameters({
            ...jobParameters,
            [name]: value,
        });
    }

    const handleOnSaveJobParameters = () => {
        setApprove(true);
        setIsJobsParametersOpen(false);
    }

    const handleOnCancelJobParameters = () => {
        setIsJobsParametersOpen(false);
    }

    useEffect(() => {
        if(approve && job && onJob) {
            onJob(job, jobParameters);
            setJob(undefined);
            setJobParameters({});
        }
        if(approve) {
            setApprove(false);
        }
    }, [approve]);

    const isActive = (instanceMenuItem: InstanceMenuItem) => {
        return instanceMenuItem.relatedSpecKey
            ? selected.instanceMode === instanceMenuItem.mode && selected.relatedSpecification?.key === instanceMenuItem.relatedSpecKey
            : selected.instanceMode === instanceMenuItem.mode;
    }

    const getButtonComponent = (instanceMenuItem: InstanceMenuItem) => {
        const {mode, label, relatedSpecKey, icon} = instanceMenuItem;

        if(mode === InstanceMode.Related) {
            const relatedSpec = services.specification.get(relatedSpecKey as string);
            return (
                <Link to={`/deployments/${selectedDeployment.id}/${selectedPage}/${selected.specification?.url}/${selected.instanceId}/${relatedSpec.url}`}>
                    <Button
                        key={'button' + mode + relatedSpecKey}
                        disabled={!selected.instanceId}
                        active={isActive(instanceMenuItem)}
                        className={Classes.MINIMAL + ' instance-list-menu-button'}
                        icon={icon ? icon as any : InstanceModeIcon[mode]}
                        text={label ? label : mode}
                    />
                </Link>
            )
        }
        else {
            return (
                <Button
                    key={'button' + mode + relatedSpecKey}
                    disabled={!selected.instanceId}
                    active={isActive(instanceMenuItem)}
                    onClick={(e) => handleOnClick(instanceMenuItem)}
                    className={Classes.MINIMAL + ' instance-list-menu-button'}
                    icon={icon ? icon as any : InstanceModeIcon[mode]}
                    text={label ? label : mode}
                />
            )
        }
    }

    const getComponent = (instanceMenuItem: InstanceMenuItem) => {
        const mode = instanceMenuItem.mode;
        const buttonComponent = getButtonComponent(instanceMenuItem);
        const instance = selected.instanceId && getById(instances[selected.getSpecificationKey()].instances, selected.instanceId);
        if(selected && instance) {
            return mode === InstanceMode.Jobs ? (
                <Popover2
                    key={'popover' + mode}
                    isOpen={jobMenuOpen}
                    content={(
                        <InstanceJobs
                            selectedPage={selectedPage}
                            specification={selected.specification as { [index: string]: any; }}
                            instance={instance}
                            services={services}
                            getInstances={getInstances}
                            onJob={handleOnJob}
                        />
                    )}
                    placement="bottom"
                    interactionKind={"click"}
                    onInteraction={handleJobMenuInteraction}
                >
                    {buttonComponent}
                </Popover2>
            ) : buttonComponent;
        }
    }

    useEffect(() => {
        //console.log("remount");
    }, [])


    const overlayClasses = classNames(
        Classes.CARD,
        Classes.PORTAL,
        'job-parameters-overlay',
    );

    if(!instances[selected.getSpecificationKey()]) {
        return <></>
    }

    return (
        <div>
            <Navbar className={'instance-menu'}>
                <Navbar.Group align={Alignment.LEFT} className={'instance-menu-group'}>
                    {selected.instanceId && instanceMenuItems.map(instanceMenuitem => {
                        return getComponent(instanceMenuitem);
                    })}
                </Navbar.Group>
            </Navbar>
            {selected.instanceId &&
                <>
                    <Alert
                        className={'new-instance-abort'}
                        cancelButtonText={"Cancel"}
                        confirmButtonText={"Delete"}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={isDeleteAlertOpen}
                        onCancel={handleDeleteAlertCancel}
                        onConfirm={handleDeleteAlertConfirm}
                    >
                        <p>
                            Are you sure you want to delete this instance?
                        </p>
                    </Alert>
                    <Overlay
                        isOpen={isJobsParametersOpen}
                        onClose={handleOnCancelJobParameters}
                        autoFocus
                        canEscapeKeyClose={false}
                        canOutsideClickClose={false}
                        enforceFocus
                        hasBackdrop
                        usePortal
                    >
                        <div className={overlayClasses}>
                            <H4 className={'job-parameters-overlay-header'}>{job?.overlay?.label|| job?.command + ' ' + selected.specification?.label}</H4>
                            <DefaultPanel
                                getInstance={getInstance}
                                selected={selected}
                                disabled={false}
                                onAttributeChange={handleJobParametersAttributeChange}
                                services={services}
                                instance={jobParameters}
                                specification={selected.jobSpecification}
                                onInstanceSave={(specification,instance, response) => {
                                    // not used here
                                    //console.log("onInstanceSave", specification, instance, response);
                                }}
                                relatedSelectCount={relatedSelectCount}
                                setRelatedSelectCount={setRelatedSelectCount}
                                getInstances={getInstances}
                                selectedPage={selectedPage}
                                instanceEditTab={job?.parameters}
                                editMode={true}
                                setEditMode={setEditMode ? setEditMode : () => {}}
                            />
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                {<Button onClick={handleOnSaveJobParameters} intent={Intent.SUCCESS} icon="confirm" text={job?.overlay?.button||"Confirm"}/>}
                                {<Button onClick={handleOnCancelJobParameters} intent={Intent.DANGER} icon="undo" text={"Cancel"}/>}
                            </div>
                        </div>
                    </Overlay>
                </>
            }
        </div>

    );
}
