import * as React from "react";
import {EndpointHost} from "raasify-models-specification-ts/core/EndpointHost";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const EndpointHostList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: EndpointHost._name,
                    columnWidth: 120
                }),
                new InstanceListAttribute({
                    name: EndpointHost._SSHHost,
                    columnWidth: 120
                }),
                new InstanceListAttribute({
                    name: EndpointHost._sourceImageDirectory,
                    columnWidth: 120
                }),
                new InstanceListAttribute({
                    name: EndpointHost._guestImageDirectory,
                    columnWidth: 120
                }),
                new InstanceListAttribute({
                    name: EndpointHost._sourceBridge,
                    columnWidth: 80
                }),
            ]}
        />
    );
};

