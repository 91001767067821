import * as React from "react";

import {FC} from "react";
import {InstanceHomeProps} from "../../InstanceHome";


const DiscoveredEdgeHome: FC<InstanceHomeProps> = (props) => {
    return (
        <div className={'instance-home-outer'}>
            <div className={'instance-home-title'}>Discovered Edges</div>
            <div className={'instance-home-main-line'}>
                Discovered Edges are records of remote Edges coming up for the first time and being discovered by the deployment.
            </div>
            <div className={'instance-home-sub-line'}>
                Select one on the left
            </div>
        </div>
    );
}

export default DiscoveredEdgeHome;

