import * as React from "react";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {InstanceListProps} from "../../../InstanceList";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {logo} from "./InstanceListUtil";
import {Tooltip2} from "@blueprintjs/popover2";
import {Icon} from "@blueprintjs/core";
import {IconName} from "@blueprintjs/icons";

export const EndpointList: FC<InstanceListProps> = (props) => {

    const getStatus = (endpoint: Endpoint) => {
        if(['AppRunning', 'AppReady', 'AppReachable'].includes(endpoint.applicationDiscoveredState as string)
            || (endpoint.applicationDiscoveredState === 'AppConfigured' && endpoint.associatedEndpointPolicyName === 'Lumens LC200') // TODO: we need to do a lookup of the state and check if in final state
        ) {
            return {
                icon: endpoint.inProduction ? 'full-circle' : 'error',
                color: 'green',
                msg: endpoint.applicationDiscoveredState,
            }
        }
        else if(endpoint.associatedEdgeActivated) {
            return {
                icon: 'error',
                color: 'red',
                msg: endpoint.applicationDiscoveredState,
            }
        }
        else {
            return {
                icon: 'error',
                color: 'grey',
                msg: 'Not Activated',
            }
        }
    }

    return (
        <BaseInstanceList
            {...props}

            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const endpoint = instance as Endpoint;
                    const status = getStatus(endpoint);
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(endpoint)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 5,
                                }}
                            >
                                <Tooltip2
                                    content={<div className={'tooltip-content'}>{status.msg}</div>}
                                    openOnTargetFocus={false}
                                    placement="bottom-end"
                                    usePortal={true}
                                >
                                    <Icon icon={status.icon as IconName} size={12} color={status.color} />
                                </Tooltip2>
                            </div>
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 5,
                                }}
                            >
                                {endpoint.inProduction ? 'In production' : 'Not in production'}
                            </div>

                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{endpoint.name}</div>
                                <div className={"instance-list-card-attribute"}>{endpoint.associatedEndpointPolicyName}</div>
                                <div className={"instance-list-card-attribute"}>{endpoint.associatedEdgeName}</div>
                            </div>
                        </>
                    )
                }
            }

            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Endpoint._name,
                }),
                new InstanceListAttribute({
                    name: Endpoint._associatedEndpointPolicyName,
                }),
                new InstanceListAttribute({
                    name: Endpoint._associatedEdgeName,
                }),
            ]}
        />
    );
};
