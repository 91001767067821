
export default [
    "system.manufacturer",
    "system.productName",
    "system.version",
    "system.serialNumber",
    "system.UUID",
    "system.wakeUpType",
    "system.SKUNumber",
    "system.family",
    "system.status",
    "baseboard.manufacturer",
    "baseboard.productName",
    "baseboard.version",
    "baseboard.serialNumber",
    "baseboard.assetTag",
    "baseboard.locationInChassis",
    "baseboard.chassisHandle",
    "baseboard.type",
    "baseboard.containedObjectHandles",
    "processor.numberOfCPUs",
    "memory.location",
    "memory.use",
    "memory.errorCorrectionType",
    "memory.maximumCapacity",
    "memory.errorInformationHandle",
    "memory.numberOfDevices",
    "IPAddress",
    "MACAddress",
    "publicIPAddress.IPAddress",
    "publicIPAddress.version",
    "publicIPAddress.city",
    "publicIPAddress.region",
    "publicIPAddress.regionCode",
    "publicIPAddress.country",
    "publicIPAddress.countryName",
    "publicIPAddress.countryCode",
    "publicIPAddress.countryCodeISO3",
    "publicIPAddress.countryCapital",
    "publicIPAddress.continentCode",
    "publicIPAddress.inEU",
    "publicIPAddress.postal",
    "publicIPAddress.latitude",
    "publicIPAddress.longitude",
    "publicIPAddress.timezone",
    "publicIPAddress.UTCOffset",
    "publicIPAddress.countryCallingCode",
    "publicIPAddress.currency",
    "publicIPAddress.currencyName",
    "publicIPAddress.languages",
    "publicIPAddress.countryArea",
    "publicIPAddress.countryPopulation",
    "publicIPAddress.ASN",
    "publicIPAddress.ORG",
    "hostnameCTL.staticHostname",
    "hostnameCTL.iconName",
    "hostnameCTL.chassis",
    "hostnameCTL.machineID",
    "hostnameCTL.bootID",
    "hostnameCTL.virtualization",
    "hostnameCTL.operatingSystem",
    "hostnameCTL.CPEOSName",
    "hostnameCTL.kernel",
    "hostnameCTL.architecture",
    "DHCP.options.subnetMask",
    "DHCP.options.routers",
    "DHCP.options.dhcpLeaseTime",
    "DHCP.options.dhcpMessageType",
    "DHCP.options.domainNameServers",
    "DHCP.options.dhcpServerIdentifier",
    "DHCP.options.dhcpRenewalTime",
    "DHCP.options.broadcastAddress",
    "DHCP.options.dhcpRebindingTime",
    "DHCP.options.hostName",
    "DHCP.options.domainName",
    "DHCP.interface",
    "DHCP.fixedAddress",
    "DHCP.renew",
    "DHCP.rebind",
    "DHCP.expire",
];
