
/**
 * Static Class for Headers
 */
export class Headers {
    static HTTP_HEADER_X_DEPLOYMENT_ID = 'x-deployment-id';
    static HTTP_HEADER_X_CLIENT_ID = 'x-client-id';
    static HTTP_HEADER_X_SERVICE = 'x-service';
    static HTTP_HEADER_X_REPLY_SERVICE = 'x-reply-service';
    static HTTP_HEADER_X_INTERNAL = 'x-internal';
    static HTTP_HEADER_X_TRANSPORT = 'x-transport';
    static HTTP_HEADER_X_FILTER = 'x-filter';
    static HTTP_HEADER_X_FILTER_USE_OR = 'x-filter-use-or';
    static HTTP_HEADER_X_SORT = 'x-sort';
    static HTTP_HEADER_X_PAGINATION = 'x-pagination';
    static HTTP_HEADER_X_BYPASS_TRANSIENT_ATTRIBUTES = 'x-bypass-transient-attributes';

    static TRANSPORT_INTERNAL = 'internal';
}


export declare enum HTTPMethod {
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    GET = "GET"
}
