import {ItemRenderer, Suggest} from "@blueprintjs/select";
import {FC} from "react";
import {Alignment, Button, MenuItem} from "@blueprintjs/core";
import * as React from "react";


export interface ListSuggestProps {
    instance: any;
    attributeName: string;
    onChange(name: string, value: string|null): void;
    disabled?: boolean;
    attribute: {[index: string]: any};
    items: Array<string>;
    children?: React.ReactNode;
}

const StringSuggest = Suggest.ofType<string>();

export const ListSuggest: FC<ListSuggestProps> = (props) => {
    const {instance, attribute, attributeName, onChange, disabled, items} = props;

    const renderOption: ItemRenderer<any> = (option, {handleClick, modifiers}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                disabled={disabled}
                className={'bp4-text-large list-suggest'}
                active={modifiers.active}
                key={option}
                text={option}
                onClick={handleClick}
            />
        );
    };

    const renderOptionValue = (item: string) => {
        return item;
    }

    const onItemSelect = (item: string) => {
        onChange(attribute.name, item)
    }

    const onQueryChange = (queryString: string) => {
        //console.log("queryString:", queryString);
        onChange(attribute.name, queryString)
    }

    const itemPredicate = (query: string, item: string, index?: undefined | number) => {
        return item.includes(query);
    }

    return (
        <StringSuggest
            popoverProps={{matchTargetWidth: true, usePortal: false}}
            popoverContentProps={{className: 'list-popover-content-normal'}}
            {...props as any}
            inputValueRenderer={renderOptionValue}
            inputProps={{id: attributeName}}
            disabled={disabled}
            noResults={<MenuItem className={'bp4-text-large'} disabled={true} text="No results."/>}
            query={instance[attributeName] ? instance[attributeName] : ""}
            fill
            activeItem={instance[attributeName] ? instance[attributeName] : ""}
            key={attributeName}
            itemRenderer={renderOption}
            items={items}
            itemPredicate={itemPredicate}
            onItemSelect={onItemSelect}
            onQueryChange={onQueryChange}
        />
    )
}
