import * as React from "react";
import {FC, ReactNode} from "react";

export interface InstanceEditTabProps {
    noHeader?: boolean;
    header?: string;
    children?: ReactNode;
}

export const InstanceEditTab: FC<InstanceEditTabProps> = (props) => {
    return (
        <>
            {props.children}
        </>
    );
}
