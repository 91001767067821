import * as React from "react";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const EndpointPolicyList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: EndpointPolicy._name,
                    columnWidth: 120
                }),
                new InstanceListAttribute({
                    name: EndpointPolicy._description,
                    columnWidth: 120
                }),
            ]}
        />
    );
};

