import {ItemRenderer, Select2} from "@blueprintjs/select";
import {FC} from "react";
import {Alignment, Button, MenuItem} from "@blueprintjs/core";
import * as React from "react";


export interface EnumSelectProps {
    instance: any;
    attributeName: string;
    onChange(name: string, value: string): void;
    disabled?: boolean;
    attribute: {[index: string]: any};
    placeholder?: string;
}

const _EnumSelect = Select2.ofType<string>();

export const EnumSelect: FC<EnumSelectProps> = (props) => {
    const {instance, attribute, attributeName, onChange, disabled, placeholder} = props;

    const renderOption: ItemRenderer<any> = (option, {handleClick, modifiers}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }

        return (
            <MenuItem
                disabled={disabled}
                className={'bp4-text-large'}
                active={modifiers.active}
                key={option}
                text={option === null ? '--- Not Selected ---' : option}
                onClick={handleClick}
            />
        );
    };

    const onItemSelect = (item: string) => {
        onChange(attribute.name, item)
    }

    // bp4-text-large
    const className = disabled ? 'bp4-disabled' : '';

    return (
        <_EnumSelect
            popoverProps={{matchTargetWidth: true, usePortal: false}}
            popoverContentProps={{className: 'object-list-suggest-popover-content'}}
            fill
            inputProps={{id: attributeName, className: 'related-select'}}
            disabled={disabled}
            noResults={<MenuItem disabled={true} text="No results."/>}
            query={instance[attributeName] || ""}
            filterable={false}
            activeItem={instance[attributeName] || attribute.defaultValue || placeholder}
            itemRenderer={renderOption}
            items={placeholder ? [null].concat(attribute.enum) : attribute.enum}
            onItemSelect={onItemSelect}
        >
            <Button
                alignText={Alignment.LEFT}
                className={className + ' select-button'}
                text={instance[attributeName] || attribute.defaultValue || placeholder}
                rightIcon="double-caret-vertical"
            />
        </_EnumSelect>
    )
}
