import * as React from "react";

import {FC, ReactNode} from "react";
import {Services} from "../../../../services/Services";

export interface InstanceViewPanelProps {
    services: Services;
    children?: ReactNode;
}

export const InstanceViewPanel: FC<InstanceViewPanelProps> = (props) => {

    return (
        <>
            {props.children}
        </>
    );
}
