import * as React from "react";

import {FC} from "react";
import {InstanceHomeProps} from "../../../InstanceHome";


const EndpointHome: FC<InstanceHomeProps> = (props) => {
    return (
        <div className={'instance-home-outer'}>
            <div className={'instance-home-title'}>Endpoints</div>
            <div className={'instance-home-main-line'}>
                Endpoints are things to securely manage like REST Applications, Network Devices and Linux Hosts.
            </div>
            <div className={'instance-home-sub-line'}>
                Select one on the left or add a new one by clicking the (+)
            </div>
        </div>
    );
}

export default EndpointHome;

