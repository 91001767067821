import * as React from "react";
import {FC} from "react";
import {InstanceListProps} from "../../../InstanceList";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {logo} from "./InstanceListUtil";
import {Icon} from "@blueprintjs/core";
import {Tooltip2} from "@blueprintjs/popover2";
import {IconName} from "@blueprintjs/icons";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";

export const EdgeList: FC<InstanceListProps> = (props) => {

    const getStatus = (edge: Edge) => {
        if(edge.activated) {
            if(edge.connected === 'Yes') {
                return {
                    icon: edge.inProduction ? 'full-circle' : 'error',
                    color: 'green',
                    msg: 'Activated and Connected',
                }
            }
            else {
                return {
                    icon: 'error',
                    color: 'red',
                    msg: 'Activated and Disconnected',
                }
            }
        }
        else {
            return {
                icon: 'full-circle',
                color: 'grey',
                msg: 'Not Activated',
            }
        }
    }

    return (
        <BaseInstanceList
            {...props}

            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const edge = instance as Edge;
                    const status = getStatus(edge);
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(edge)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 5,
                                }}
                            >
                                <Tooltip2
                                    content={<div className={'tooltip-content'}>{status.msg}</div>}
                                    openOnTargetFocus={false}
                                    placement="bottom-end"
                                    usePortal={true}
                                >
                                    <Icon icon={status.icon as IconName} size={12} color={status.color} />
                                </Tooltip2>
                            </div>
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 5,
                                }}
                            >
                                {edge.inProduction ? 'In production' : 'Not in production'}
                            </div>

                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{edge.name}</div>
                                <div className={"instance-list-card-attribute"}>{edge.location}</div>
                                <div className={"instance-list-card-attribute"}>{(edge.edgeInfo as any)?.publicIPAddress?.IPAddress}</div>
                            </div>
                        </>
                    )
            }}

            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Edge._name,
                }),
                new InstanceListAttribute({
                    name: Edge._location,
                }),
            ]}
        />
    );
};
