import React, {FC, useEffect, useState} from "react";
import {Services} from "../../../services/Services";
import {InstanceDetail} from "./detail/InstanceDetail";
import {InstanceMode} from "../../../enums/InstanceMode";
import {getById} from "../../../utils/instanceUtil";
import {LogWindow} from "./logwindow/LogWindow";
import {Selected} from "../../../model/Selected";
import {InstanceLog} from "../../../model/InstanceLog";
import {InstanceEditAll} from "./edit/InstanceEditAll";
import {usePrevious} from "../../../utils/stateUtil";
import {Intent, Spinner} from "@blueprintjs/core";
import {useAppSelector} from "../../../redux/hooks";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {InstanceHome} from "../../../../custom/benches/InstanceHome";
import {InstanceEdit} from "../../../../custom/benches/InstanceEdit";
import {RelatedContainer} from "../../../../custom/benches/RelatedContainer";
import {InstanceJob} from "../../../model/InstanceJob";

type JSONObject = {[index: string]: any};

export interface InstanceContainerProps {
    selected: Selected;
    rootSelected?: Selected;
    services: Services;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => void;
    onInstanceDelete: (specification: {[index: string]: {}}, instanceId: string, callback: (res: any, err: any) => void) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    logs?: Array<InstanceLog>;
    selectedPage: string;
    relatedView?: boolean;
    selectedDeployment: Deployment;
    onJob?: (job: InstanceJob, parameters: any) => void;
}

export const InstanceContainer: FC<InstanceContainerProps> = (props) => {
    const {
        getInstances,
        getInstance,
        selected,
        rootSelected,
        services,
        onInstanceSave,
        relatedSelectCount,
        setRelatedSelectCount,
        selectedPage,
        onInstanceDelete,
        relatedView,
        selectedDeployment,
        onJob,
    } = props;
    const instances = useAppSelector((state) => state.instances)
    const [editedInstance, setEditedInstance] = useState<{ [index: string]: any; }>({});
    const [editMode, setEditMode] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleInstanceEditAttributeChange = (name: string, value: string) => {
        setEditedInstance((prevState: JSONObject) => {
            //console.log("prevState", prevState, name, value);
            return {...prevState, [name]: value};
        });
    }

    const handleOnCancel = () => {
        setEditMode(false);
    }

    const updateEditedInstance = () => {
        if(instances && selected && selected.specification && instances[selected.getSpecificationKey()]?.instances) {
            const instance = getById(instances[selected.getSpecificationKey()].instances, selected.instanceId);
            setEditedInstance(Object.assign({}, instance));
        }
    }

    const handleOnInstanceSave = (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => {
        onInstanceSave(specification, instance, response);
        setEditMode(false);
    }

    const previousSelected : Selected|undefined = usePrevious(selected);
    useEffect(() => {
        if(previousSelected?.instanceId !== selected.instanceId) {
            updateEditedInstance();
        }
    }, [selected]);

    useEffect(() => {
        if(editMode) {
            updateEditedInstance();
        }
    }, [editMode]);

    useEffect(() => {
    }, [])

    useEffect(() => {
    }, [loading])

    // Instance Mode
    let instanceComponent;

    const outerClassName = relatedView ? 'instance-view-outer-related' : 'instance-view-outer';

    if(!selected.instanceId) {
        instanceComponent = <InstanceHome selected={selected} />
    }
    else if(selected && selected.specification && selected.instanceId !== null && instances[selected.getSpecificationKey()] && instances[selected.getSpecificationKey()]?.instances.length > 0) {
        const instance = getById(instances[selected.getSpecificationKey()]?.instances, selected.instanceId);

        if(InstanceMode.Data === selected.instanceMode) {
            instanceComponent = (
                <div className={outerClassName}>
                    <InstanceEdit
                        key={'InstanceContainer_' + selected.specification.name}
                        onAttributeChange={handleInstanceEditAttributeChange}
                        onInstanceSave={handleOnInstanceSave}
                        specification={selected.specification}
                        services={services}
                        instance={editMode ? editedInstance : instance as any}
                        setInstance={setEditedInstance}
                        relatedSelectCount={relatedSelectCount}
                        setRelatedSelectCount={setRelatedSelectCount}
                        getInstances={getInstances}
                        getInstance={getInstance}
                        selectedPage={selectedPage}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        onCancel={handleOnCancel}
                        selected={selected}
                        setLoading={setLoading}
                        rootSelected={rootSelected}
                    />
                </div>
            )
        }
        else if(InstanceMode.Detail === selected.instanceMode) {
            instanceComponent = (
                <div className={outerClassName}>
                    <InstanceDetail
                        getInstance={getInstance}
                        selected={selected}
                        specification={selected.specification}
                        services={services}
                        instance={instance}
                        getInstances={getInstances}
                        selectedPage={selectedPage}
                        editMode={editMode}
                        setEditMode={setEditMode}
                    />
                </div>
            )
        }
        else if(InstanceMode.Related === selected.instanceMode) {
            instanceComponent = (
                <RelatedContainer
                    specification={selected.specification}
                    services={services}
                    selectedPage={selectedPage}
                    selected={selected}
                    relatedSelectCount={relatedSelectCount}
                    setRelatedSelectCount={setRelatedSelectCount}
                    getInstances={getInstances}
                    getInstance={getInstance}
                    onInstanceSave={handleOnInstanceSave}
                    onInstanceDelete={onInstanceDelete}
                    selectedDeployment={selectedDeployment}
                    onInstanceMenuOnJob={onJob}
                    rootSelected={rootSelected}
                />
            )
        }
        else if(InstanceMode.Logs === selected.instanceMode) {
            instanceComponent = (
                <div className={'instance-view-logs'}>
                    <LogWindow
                        selected={selected}
                        services={services}
                        className={'log-window-table'}
                    />
                </div>
            )
        }
    }

    const mainStyle = loading ? { display: 'none' } : { display: 'inline'};
    const spinnerStyle = !loading ? { display: 'none' } : { display: 'inline'};

    return (
        <div className={"instance-container"}>
            <div style={mainStyle}>{instanceComponent}</div>
            <div style={spinnerStyle}><Spinner className={"instance-spinner"} intent={Intent.PRIMARY} size={60} /></div>
        </div>
    );
}
