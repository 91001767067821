
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";

import {SideBarEntry} from "../../../../common/components/workbench/sidebar/SideBar";
import React from "react";
import {RemoteCLI} from "./components/RemoteCLI";
import {AutomationBuilder} from "./components/AutomationBuilder";
import {Destination} from "raasify-models-specification-ts/core/Destination";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";

export const SideBarLayout: {[category: string]: Array<SideBarEntry>} = {
    'Inventory': [
        {class: Edge, icon: 'cargo-ship'},
        {class: Endpoint, icon: 'application'},
        {class: EndpointPolicy, icon: 'layers'},
        {class: Destination, icon: 'flow-end'},
    ],
    'Automation': [
        {class: AutomationPolicy, label: "Policy Definitions", icon: 'cube'}, // or cog?
        {class: Automation, label: "Runtime Instances", icon: 'video'},
    ],
    'Tools': [
        {label: 'Remote CLI', component: RemoteCLI, icon: 'antenna'},
        {label: 'Automation Builder', component: AutomationBuilder, icon: 'wrench'},
    ],
}
