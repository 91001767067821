
export class ListAttribute {
    private readonly _name: string;
    private readonly _label: string|undefined;
    private readonly _columnWidth: number = 100;

    constructor(name: string, label: string, columnWidth?: number) {
        this._name = name;
        this._label = label;
        if(columnWidth) {
            this._columnWidth = columnWidth;
        }
    }

    get name(): string {
        return this._name;
    }

    get label(): string|undefined {
        return this._label;
    }

    get columnWidth(): number {
        return this._columnWidth;
    }
}
