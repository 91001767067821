import {FC, ReactElement} from "react";

export class InstanceJob {
    private readonly _command: string;
    private readonly _label: string;
    private readonly _icon: string;
    private readonly _parameters: ReactElement|undefined;
    private readonly _overlay: {label:string, button:string}|undefined;
    private readonly _isDisabled: ((instance: any) => boolean)|undefined;

    constructor({command, icon, label, parameters, overlay, isDisabled}: {
        command: string,
        icon: string,
        label?: string,
        parameters?: ReactElement,
        overlay?: { label: string, button: string },
        isDisabled?: (instance: any) => boolean,
    }) {
        this._command = command;
        this._label = label ? label : command;
        this._icon = icon;
        this._parameters = parameters;
        this._overlay = overlay;
        this._isDisabled = isDisabled;
    }

    get command(): string {
        return this._command;
    }

    get icon(): string {
        return this._icon;
    }

    get label(): string {
        return this._label;
    }

    get parameters(): ReactElement | undefined {
        return this._parameters;
    }

    get overlay(): { label: string; button: string }|undefined {
        return this._overlay;
    }

    get isDisabled(): ((instance: any) => boolean) | undefined {
        return this._isDisabled;
    }
}
