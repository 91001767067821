import * as React from "react";

import {Destination} from "raasify-models-specification-ts/core/Destination";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {BaseInstanceEdit} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";
import {getFromInputs} from "../../../../../common/utils/instanceUtil";
import {getRandomInt} from "../../../../../common/utils/numberUtil";
import {InputSchema} from "../../../../../common/components/workbench/common/schema/InputSchema";
import {InstanceEditProps} from "../../../InstanceEdit";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {Credential} from "raasify-models-specification-ts/core/Credential";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";


const DestinationEdit: FC<InstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(Destination.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{width: "33%"}}
                            name={Destination._executionType}
                            default={() => Destination.executionType.Automation}
                        />
                        <TabAttribute
                            style={{width: "33%"}}
                            name={Destination._associatedEdgeId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(Edge._name, 9),
                                new TabAttributeRelatedAttribute(Edge._inProduction, 3),
                            ]}
                        />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute hidden={(input:any) => !input.instance.associatedDestinationTemplateId} style={{width: "50%"}} name={Destination._name} />
                        <TabAttribute hidden={(input:any) => !input.instance.associatedDestinationTemplateId} style={{width: "50%"}} name={Destination._description} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute
                            hidden={(input:any) => !input.instance.associatedDestinationTemplateId}
                            style={{width: "100%"}}
                            name={Endpoint._associatedCredentialId}
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(Credential._name, 4),
                                new TabAttributeRelatedAttribute(Credential._description, 6),
                                new TabAttributeRelatedAttribute(Credential._method, 2),
                            ]}
                        />
                    </TabAttributeRow>
                    <InputSchema
                        {...props}
                        sourceUrl={Destination.getUrl()}
                        sourceIdAttribute={Destination._associatedDestinationTemplateId}
                        schemaAttribute={Destination._inputSchema}
                    />
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default DestinationEdit;

