import * as React from "react";

import {FC} from "react";
import {InstanceHomeProps} from "../../../InstanceHome";


const EndpointPolicyHome: FC<InstanceHomeProps> = (props) => {
    return (
        <div className={'instance-home-outer'}>
            <div className={'instance-home-title'}>Endpoints</div>
            <div className={'instance-home-main-line'}>
                Endpoint Policies can be created to define common configuration for a group Endpoints.
            </div>
            <div className={'instance-home-sub-line'}>
                Select one on the left or add a new one by clicking the (+)
            </div>
        </div>
    );
}

export default EndpointPolicyHome;

