import React, {FC, useEffect, useState} from "react";
import {
    Alignment, AnchorButton,
    Button,
    Classes, Icon, Menu, MenuItem,
    Navbar,
} from "@blueprintjs/core";
import UserService from "../../services/UserService";
import {user, core} from "raasify-models-specification-json/index.json";
import {Popover2} from "@blueprintjs/popover2";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {User} from "raasify-models-specification-ts/user/User";
import {useAppSelector} from "../../redux/hooks";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {InstanceListFilter} from "../../model/InstanceListFilter";
// @ts-ignore
const DEPLOYMENT_KEY = user.enums.Specification.Deployment;
// @ts-ignore
const ACTIVATION_REQUEST_KEY = core.enums.Specification.ActivationRequest;
const ACTIVATION_REQUEST_SPEC = core.specifications["core.ActivationRequest"];

export interface TopMenuProps
{
    params?: URLSearchParams;
    selectedPage?: string;
    selectedDeployment: Deployment|undefined;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean) => void;
    me: User;
}

export const TopMenu: FC<TopMenuProps> = (props) => {
    const {
        selectedPage,
        selectedDeployment,
        getInstances,
        params,
        me,
    } = props;

    const instances = useAppSelector((state) => state.instances)
    const deployments = instances[DEPLOYMENT_KEY]?.instances;
    const activationRequests = instances[ACTIVATION_REQUEST_KEY]?.instances;
    const navigate = useNavigate();
    const t:any = UserService.getTokenParsed();
    const displayFullName = t.given_name ? t.given_name + " " + t.family_name : t.preferred_username
    const displayShortName = t.given_name ? t.given_name : t.preferred_username
    const emailUser = t.email.split("@")[0];
    const emailDomain = t.email.split("@")[1];
    const userGroupName = `${emailUser}-${emailDomain}`;
    const orgName = me.associatedOrganizationName;

    const location = useLocation();

    useEffect(() => {
        if(selectedDeployment) {
            getInstances(ACTIVATION_REQUEST_SPEC, undefined, {transient: "status=Pending"});
        }
    }, [selectedDeployment])

    const hasActivationRequests = () => {
        return activationRequests && activationRequests.length > 0;
    }

    const handleDeploymentSelect = (deployment: any) => {
        console.log("Navigating to", `/deployments/${deployment?.id}/dashboard`);
        navigate(`/deployments/${deployment.id}/dashboard`);
    }

    const deploymentMenuItems = (
        <>
            <Menu className="bp4-menu bp4-elevation-1">
                {deployments && deployments.map((deployment: {[key:string]:string}) => {
                    return (
                        <MenuItem
                            key={deployment.id}
                            className="bp4-text-small"
                            text={deployment.name}
                            icon="database"
                            active={deployment.id === selectedDeployment?.id}
                            onClick={() => handleDeploymentSelect(deployment)}
                        >
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )

    /*
    const handleActivationRequestsClick = () => {

    }

    const handleLogsClick = () => {

    }

    const notificationMenuItems = (
        <>
            <Menu className="bp4-menu bp4-elevation-1">
                <MenuItem
                    key={'activationRequests'}
                    className="bp4-text-small"
                    text={'Activation Requests'}
                    icon="inbox"
                    onClick={() => handleActivationRequestsClick()}
                />
                <MenuItem
                    key={'logs'}
                    className="bp4-text-small"
                    text={'Logs'}
                    icon="info-sign"
                    onClick={() => handleLogsClick()}
                />
            </Menu>
        </>
    )
    */

    const isActive = (page: string):boolean|undefined => {
        if(selectedDeployment) {
            return true;
        }
        else {
            return selectedPage === 'deployments' && page === 'deployments'
        }
    }

    const updateInfos = (event: any) => {
        //console.log("updateInfos", event);
    }

    const userMenuItems = (
        <>
            <Menu className="bp4-menu bp4-elevation-1">
                <Button
                    className={Classes.POPOVER_DISMISS + " " + Classes.MINIMAL + " bp4-text-small user-menu-button"}
                    text={displayFullName}
                    icon="user"
                    disabled
                />
                <div className="break"/>
                <Button
                    className={Classes.POPOVER_DISMISS + " " + Classes.MINIMAL + " bp4-text-small user-menu-button"}
                    text={orgName as string}
                    icon="globe"
                    disabled
                />
                <div className="break"/>
                <Link to={`/deployments`} className={'text-decoration-none'}>
                    <Button
                        className={Classes.POPOVER_DISMISS + " " + Classes.MINIMAL + " bp4-text-small user-menu-button"}
                        icon="database"
                        text="Deployments"
                    />
                </Link>
                <div className="break"/>
                <Button
                    className={Classes.POPOVER_DISMISS + " " + Classes.MINIMAL + " bp4-text-small user-menu-button"}
                    text="Logout"
                    onClick={() => {
                        UserService.doLogout();
                    }}
                    icon="log-out"
                />
            </Menu>
        </>
    )

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const showPowerbench = () => {
        return params?.get('powerbench');
    }

    const notificationCircleDisplayClass = hasActivationRequests() ? "notification-circle-display-visible" : "notification-circle-display-hidden";

    return (
        <Navbar>
            <Navbar.Group align={Alignment.LEFT}>
                <Navbar.Heading className={"navbar-heading"}><img className={"navbar-logo"} src={"/logo.png"} /></Navbar.Heading>
                <Navbar.Divider />
                <Link to={`/deployments/${selectedDeployment?.id}/dashboard`}>
                    <Button className={Classes.MINIMAL} active={selectedPage === 'dashboard'} icon="dashboard" text="Dashboard" disabled={!isActive('dashboard')}/>
                </Link>
                <Link to={`/deployments/${selectedDeployment?.id}/marketplace`}>
                    <Button className={Classes.MINIMAL} active={selectedPage === 'marketplace'} icon="shop" text="Marketplace" disabled={!isActive('marketplace')}/>
                </Link>
                <Link to={`/deployments/${selectedDeployment?.id}/workbench`}>
                    <Button className={Classes.MINIMAL} active={selectedPage === 'workbench'} icon="cube" text="Workbench" disabled={!isActive('workbench')}/>
                </Link>
                {showPowerbench() && <Link to={`/deployments/${selectedDeployment?.id}/powerbench`}>
                    <Button className={Classes.MINIMAL} active={selectedPage === 'powerbench'} icon="th" text="Powerbench" disabled={!isActive('powerbench')}/>
                </Link>}
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
                {selectedDeployment && <Popover2
                    placement="bottom"
                    content={deploymentMenuItems}
                >
                    <div>
                        <Button className={Classes.MINIMAL} icon="database" text={selectedDeployment?.name}/>
                    </div>
                </Popover2>}
                {selectedDeployment &&
                    <div className={'notification-icon-container'}>
                        <div className={'notification-circle ' + notificationCircleDisplayClass} />
                        <div>
                            <Link to={`/deployments/${selectedDeployment?.id}/notifications`}>
                                <Button className={Classes.MINIMAL} icon="notifications" />
                            </Link>
                        </div>
                    </div>
                }
                <Popover2
                    placement="bottom"
                    content={userMenuItems}
                    canEscapeKeyClose
                    interactionKind={'click'}
                    popoverClassName={Classes.POPOVER_CONTENT}
                >
                    <div>
                        <Button className={Classes.MINIMAL} icon="user" text={displayShortName} />
                    </div>
                </Popover2>
            </Navbar.Group>
        </Navbar>
    );
}


// onClick={() => openInNewTab(`/deployments/${selectedDeployment?.id}/notifications`)}
