
export class SpecificationService {
    private readonly specifications: {[index: string]: any};
    constructor(specifications: {[index: string]: any}) {
        this.specifications = specifications;
    }

    get(key: string) {
        return this.specifications[key];
    }

    getByURL(url: string) {
        for(const spec of Object.values(this.specifications)) {
            if(spec.url === url) {
                return spec;
            }
        }
        return null;
    }

    getAll() {
        return this.specifications;
    }

    getKey(specification: {[index: string]: any} | null) {
        if(specification) {
            return specification.package + '.' + specification.name;
        }
    }

    getAttributeNames(specificationKey: string) {
        return Object.keys(this.get(specificationKey).attributes);
    }

    getAttribute(specificationKey: string, attributeName: string) {
        return this.get(specificationKey).attributes[attributeName];
    }

    getAttributeNamesBySpecification(specification: {[index: string]: any} | null) {
        return specification && Object.keys(specification.attributes);
    }

    getAttributeBySpecification(specification: {[index: string]: any} | null, attributeName: string) {
        return specification && specification.attributes[attributeName];
    }
}
