import { Automation } from "raasify-models-specification-ts/core/Automation";
import { AutomationExecution } from "raasify-models-specification-ts/core/AutomationExecution";
import { AutomationPolicy } from "raasify-models-specification-ts/core/AutomationPolicy";
import { Endpoint } from "raasify-models-specification-ts/core/Endpoint";
import { InstanceListFilter } from "../../../../common/model/InstanceListFilter";

export const instanceListFilter: {[specKey: string]: InstanceListFilter} = {
    [AutomationPolicy.getKey()]: {db: "type=User"},
    [Automation.getKey()]: {db: "type=User"},
    [AutomationExecution.getKey()]: {db: "type=Automation"},
    [Endpoint.getKey()]: {transient: "edgeService=false"},
}
