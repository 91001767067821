
export class InstanceListAttribute {
    private readonly _name: string;
    private readonly _filter: string|undefined;
    private readonly _label: string|undefined;
    private readonly _columnWidth: number = 100;
    private readonly _rowFilter: ((value: string) => boolean) | undefined ;

    constructor({name, filter, label, columnWidth, rowFilter}: {name:string, filter?:string, label?: string, columnWidth?: number, rowFilter?:(value: string) => boolean}) {
        this._name = name;
        this._filter = filter;
        this._label = label;
        if(columnWidth) {
            this._columnWidth = columnWidth;
        }
        this._rowFilter = rowFilter;
    }

    get name(): string {
        return this._name;
    }

    get filter(): string|undefined {
        return this._filter;
    }

    get label(): string|undefined {
        return this._label;
    }

    get columnWidth(): number {
        return this._columnWidth;
    }


    get rowFilter(): ((value: string) => boolean) | undefined {
        return this._rowFilter;
    }
}
