import * as React from "react";

import {Metric} from "raasify-models-specification-ts/core/Metric";
import {MetricTrigger} from "raasify-models-specification-ts/core/MetricTrigger";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC, useEffect, useState} from "react";
import {BaseInstanceEdit, BaseInstanceEditProps} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {MetricTriggerEditor} from "./MetricTriggerEditor";
import {useAppSelector} from "../../../../../common/redux/hooks";
import {AppToaster} from "../../../../../AppToaster";
import {Intent} from "@blueprintjs/core";
import {getRandomInt} from "../../../../../common/utils/numberUtil";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";
import {getFromInputs} from "../../../../../common/utils/instanceUtil";


type JSONObject = {[index: string]: any};

const SPECIFICATION = MetricTrigger.specification;


const MetricEdit: FC<BaseInstanceEditProps> = (props) => {

    const [editInstances, setEditInstances] = useState<Array<JSONObject>>([]);
    const instances = useAppSelector((state) => state.instances)
    const _instances = instances[SPECIFICATION.key as string]?.instances;

    const doGetInstances = () => {
        if(!props.isAddNew) {
            props.getInstances(SPECIFICATION, (data) => {
                setEditInstances(data);
            }, {db: `associatedMetricId=${props.selected.instanceId}`})
        }
        else {
            setEditInstances([]);
        }
    }


    const SaveInstance = (specification: {[index: string]: any}, instance: {[index: string]: any}, callback: (res: any, err: any) => void) => {
        if(instance.id) {
            // UPDATE
            props.services.data.put(specification.url, instance)
                .then(data => {
                    callback(data, null);
                })
                .catch(err => {
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: err.message,
                    });
                    console.error(err);
                    console.error(JSON.stringify(err, null, 4));
                    callback(null, err);
                })
        }
        else {
            // CREATE
            //console.log("Creating new instance", instance);
            props.services.data.post(specification.url, instance)
                .then(data => {
                    callback(data, null);
                })
                .catch(err => {
                    AppToaster.show({
                        icon: "error",
                        intent: Intent.DANGER,
                        message: err.message,
                    });
                    console.error(err);
                    console.error(JSON.stringify(err, null, 4));
                    callback(null, err);
                })
        }
    }

    useEffect(() => {
        doGetInstances();
    }, []);

    useEffect(() => {
        doGetInstances();
    }, [props.selected]);

    const handleOnInstanceSave = async (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => {
        const promises = [];
        for(const instance of editInstances) {
            const _savingInstance = Object.assign({}, instance);
            if(!props.isAddNew) {
                _savingInstance[`associated${props.selected.specification?.name}Id`] = props.selected?.instanceId;
            }
            promises.push(SaveInstance(SPECIFICATION, _savingInstance, (res, err) => {}));
        }
        await Promise.all(promises);
        props.onInstanceSave(specification, instance, response);
    }

    return (
        <BaseInstanceEdit
            {...props}
            onInstanceSave={handleOnInstanceSave}
            specification={getSpecification(Metric.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Metric._name} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Metric._description} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={Metric._sourceAttribute} />
                        <TabAttribute disabled style={{width: "50%"}} name={Metric._evaluationMode} default={() => Metric.evaluationMode.Criteria}/>
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={Metric._evaluationOperation} />
                        <TabAttribute style={{width: "50%"}} name={Metric._evaluationValue} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={Metric._retentionMode} />
                        <TabAttribute style={{width: "50%"}} name={Metric._retentionValue} default={(inputs: any) => {
                            if(!inputs.instance.name) {
                                // Only set on first load
                                return "1";
                            }
                        }}/>
                    </TabAttributeRow>
                    <MetricTriggerEditor
                        {...props}
                        editInstances={editInstances}
                        setEditInstances={setEditInstances}
                        instances={_instances}
                    />
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default MetricEdit;

