import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface TemplatePayload {
    data?: string;
    endpoint?: any;
}

export enum BuilderAction {
    Set = 'set',
    Clear = 'clear',
}

interface BuilderState {
    data?: string;
    endpoint?: any;
}

const initialState: BuilderState = {};

export const builderSlice = createSlice({
    name: 'builder',
    initialState,
    reducers: {
        set(state, action: PayloadAction<TemplatePayload>) {
            if(action.payload.data !== undefined) {
                state.data = action.payload.data;
            }
            if(action.payload.endpoint !== undefined) {
                state.endpoint = action.payload.endpoint;
            }
        },
        clear(state) {
            delete state.data;
            delete state.endpoint;
        },
    },
})

export const {set, clear} = builderSlice.actions;

export default builderSlice.reducer;
