import React, {ChangeEvent, FC, ReactElement, SyntheticEvent, useEffect, useRef, useState} from "react";
import {
    Button,
    Intent,
    Spinner,
    Elevation,
    Card,
    InputGroup,
    Icon,
    Overlay,
    H4,
    Classes,
    HTMLTable,
    Alert,
    EditableText, TextArea
} from "@blueprintjs/core";

import {marketplace, core} from "raasify-models-specification-json/index.json";
import {Services} from "../../services/Services";
import {AppToaster} from "../../../AppToaster";
import {AutomationTemplate} from "raasify-models-specification-ts/core/AutomationTemplate";
import {InstalledPackage} from "raasify-models-specification-ts/core/InstalledPackage";
import {Package} from "raasify-models-specification-ts/marketplace/Package";
import classNames from "classnames";
import {useAppSelector} from "../../redux/hooks";
import UserService from "../../services/UserService";
import {CustomSelect} from "../workbench/common/forms/CustomSelect";
import {CustomMultiSelect} from "../workbench/common/forms/CustomMultiSelect";
import {getById} from "../../utils/instanceUtil";
import {User} from "raasify-models-specification-ts/user/User";
import {InstanceListFilter} from "../../model/InstanceListFilter";

const INSTALLED_PACKAGE_KEY = core.enums.Specification.InstalledPackage;
const INSTALLED_PACKAGE_SPEC = core.specifications["core.InstalledPackage"];

const LOAD_REQUEST_KEY = marketplace.enums.Specification.LoadRequest;
const LOAD_REQUEST_SPEC = marketplace.specifications["marketplace.LoadRequest"];

const PACKAGE_KEY = marketplace.enums.Specification.Package;
const PACKAGE_SPEC = marketplace.specifications["marketplace.Package"];

const INSTALL_NEXT_VERSION = 'installnextversion';

const overlayClasses = classNames(
    Classes.CARD,
    Classes.PORTAL,
    "marketplace-detail-overlay",
);


export interface MarketplaceProps {
    loading: boolean;
    setLoading: (loading: boolean) => void;
    services: Services;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean)  => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: any, err: any) => void) => void;
    filteredInstances: Array<{[index: string]: any}>;
    filter: String;
    onFilter: (filter: string) => void;
    selectedPage?: string;
    params?: URLSearchParams;
    me: User;
}


export const Marketplace: FC<MarketplaceProps> = (props) => {
    const {
        getInstances,
        loading,
        setLoading,
        onInstanceSave,
        filteredInstances,
        filter,
        onFilter,
        selectedPage,
        services,
        params,
        me,
    } = props;
    const instances = useAppSelector((state) => state.instances)

    const packages = filter ? filteredInstances : instances[PACKAGE_SPEC.key]?.instances;

    const [showPackageDetail, setShowPackageDetail] = useState<boolean>(false);
    const [selectedPackage, setSelectedPackage] = useState<Package>();
    const [page, setPage] = useState<ReactElement<any, any>|null>(null);
    const [deleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [deleteAutomationAlertOpen, setDeleteAutomationAlertOpen] = useState<boolean>(false);
    const [selectedAutomation, setSelectedAutomation] = useState<AutomationTemplate>();

    const selectedPackageRef:any = useRef();
    selectedPackageRef.current = selectedPackage;

    const t:any = UserService.getTokenParsed();
    const emailUser = t.email.split("@")[0];
    const emailDomain = t.email.split("@")[1];
    const userDirectory = `${emailUser}-${emailDomain}`;
    const orgName = me.associatedOrganizationName;

    const isAuthorizedToManage = (_package: Package) => {
        return _package.directory === orgName || _package.directory === userDirectory;
    }

    const isForkable = (_package: Package) => {
        return _package.directory !== userDirectory && !_package.forkedFrom;
    }

    const handleOnInstallPackage = () => {
        const newLoadRequest = {
            loadPackage: selectedPackage?.npmName as string,
        };
        setPage(getPage(true));
        setLoading(true)
        onInstanceSave(LOAD_REQUEST_SPEC, newLoadRequest, (res, err) => {
            setLoading(false)
            setShowPackageDetail(false);
            //console.log("res", res);
            if(!err) {
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.SUCCESS,
                    message: `${newLoadRequest.loadPackage} installed.`,
                });
            }
            else {
                console.log("err", err);
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.DANGER,
                    message: err.message,
                });
                // handle error
            }
        });
    }

    const searchButton = (
        <Button
            className={"marketplace-button marketplace-update-button"}
            intent={Intent.PRIMARY}
            icon="search"
            text={"Search"}
            disabled={loading}
        />
    )


    const logo = (_package: Package|undefined) => {
        const logo = _package?.logo;
        return (
            <img alt='' {...logo} />
        )
    }

    const handleOnClosePackageDetail = () => {
        if(editMode) {
            setSelectedPackage(getById(packages as any, selectedPackage?.id as string) as Package);
            setEditMode(false);
        }
        else {
            setShowPackageDetail(false);
        }
    }

    const handleOnDeletePackage = () => {
        setDeleteAlertOpen(true);
    }

    const handleOnEditPackage = () => {
        setEditMode(true);
    }

    const handleOnForkPackage = () => {
        setLoading(true);
        const endpoint = `packages/${selectedPackage?.id}/fork`;
        services.marketplaceData.request('POST', endpoint, undefined, {})
            .then((data) => {
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.SUCCESS,
                    message: `${selectedPackage?.name} forked.`,
                });
                setEditMode(false);
                setLoading(false);
                setShowPackageDetail(false);
                installNextPackageVersion(true);
            })
            .catch((err) => {
                setLoading(false);
                console.log("err", err);
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.DANGER,
                    message: `Error occurred: ${err}`,
                });
            })
    }

    const handleOnSavePackage = () => {
        setLoading(true);
        onInstanceSave(PACKAGE_SPEC, selectedPackage as any, (res: any, err: any) => {
            //console.log("onInstanceSave", res, err);
            if(res) {
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.SUCCESS,
                    message: `${selectedPackage?.name} saved.`,
                });
                installNextPackageVersion();
            }
            if(err) {
                console.log("err", err);
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.DANGER,
                    message: `Error occurred: ${err}`,
                });
            }
            setEditMode(false);
            setLoading(false);
            setShowPackageDetail(false);
        })
    }

    const getInstalled = (_package: Package|undefined) => {
        if(instances[INSTALLED_PACKAGE_KEY]) {
            for (const installedPackage of instances[INSTALLED_PACKAGE_KEY]?.instances) {
                if (installedPackage.name === _package?.npmName) {
                    return installedPackage;
                }
            }
        }
        return null;
    }

    const getStatus = (_package: Package|undefined) => {
        const installed = getInstalled(_package);
        if(installed && installed?.version === _package?.version) {
            return 'installed';
        }
        else if (installed) {
            return 'upgrade';
        }
        let status = _package?.status;
        return status;
    }

    const handleDeleteAlertCancel = () => {
        setDeleteAlertOpen(false);
    }

    const handleDeleteAlertConfirm = () => {
        services.marketplaceData.delete('packages', selectedPackage?.id as string)
            .then((data) => {
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.SUCCESS,
                    message: `${selectedPackage?.name} deleted.`,
                });
                setDeleteAlertOpen(false);
                setShowPackageDetail(false);
            })
            .catch((err) => {
                console.log("err", err);
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.DANGER,
                    message: `Error occurred: ${err}`,
                });
            })
    }

    const handleDeleteAutomationAlertCancel = () => {
        setDeleteAutomationAlertOpen(false);
    }

    const handleDeleteAutomationAlertConfirm = () => {
        setLoading(true);
        const endpoint = `packages/${selectedPackage?.id}/automationtemplates`;
        services.marketplaceData.request('DELETE', endpoint, undefined, {name: selectedAutomation?.name})
            .then((data) => {
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.SUCCESS,
                    message: `${selectedAutomation?.name} deleted.`,
                });
                setDeleteAutomationAlertOpen(false);
                setEditMode(false);
                setLoading(false);
                setShowPackageDetail(false);
                installNextPackageVersion();
            })
            .catch((err) => {
                setLoading(false);
                console.log("err", err);
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.DANGER,
                    message: `Error occurred: ${err}`,
                });
            })
    }

    const handleOnPackageChange = (attribute: string, res: any) => {
        let value = typeof res === 'string' ? res : res.target.value;
        if(attribute === 'searchTerms') {
            value = value.split('\n');
        }
        if(attribute === 'logo') {
            value = {src: value, height: selectedPackageRef.current.logo.height || 30};
        }
        if(attribute === 'logoHeight') {
            attribute = 'logo';
            value = {src: selectedPackageRef.current.logo.src, height: value};
        }
        setSelectedPackage({
            ...selectedPackageRef.current,
            [attribute]: value,
        })
    }

    const handleOnDeleteAutomation = (automation: any) => {
        setSelectedAutomation(automation);
        setDeleteAutomationAlertOpen(true);
    }

    const installNextPackageVersion = (installFork?: boolean) => {
        const forkedPackage = {
            name: selectedPackage?.name,
            directory: userDirectory,
            forkedFrom: selectedPackage?.directory,
        }
        const requestData = {
            package: installFork ? forkedPackage : selectedPackage,
        }
        setTimeout(() => {
            AppToaster.show({
                icon: "refresh",
                intent: Intent.PRIMARY,
                message: `Building and Installing ${selectedPackage?.name} ...`,
            });
        }, 1000);
        services.marketplaceData.post(INSTALL_NEXT_VERSION, requestData)
            .then((data) => {
                AppToaster.show({
                    icon: "tick",
                    intent: Intent.SUCCESS,
                    message: `${selectedPackage?.name} installed.`,
                });
            })
            .catch((err) => {
                console.error(err);
                AppToaster.show({
                    icon: "cross",
                    intent: Intent.DANGER,
                    message: err.message,
                });
            })
    }

    const handleSearchWordRemove = (value: string, index: number) => {
        const searchTerms = [...selectedPackage?.searchTerms as string[]];
        searchTerms.splice(index, 1);
        setSelectedPackage({...selectedPackage, searchTerms} as Package)
    }

    const handleSearchWordCreate = (value: string) => {
        //console.log("handleSearchWordCreate", value);
        return value as string;
    }

    const handleSearchWordSelectItem = (
        item: string,
    ) => {
        //console.log("handleItemSelect", item);
        if (!selectedPackage?.searchTerms?.includes(item)) {
            setSelectedPackage({
                ...selectedPackage,
                searchTerms: [
                    ...selectedPackage?.searchTerms as string[],
                    item,
                ]
            } as Package);
        }
    };

    const getOverlay = (setLoading?: boolean) => {
        const status = getStatus(selectedPackage);
        const installed = getInstalled(selectedPackage);
        if(!selectedPackage) {
            return null;
        }
        return (
            <Overlay
                isOpen={showPackageDetail}
                onClose={handleOnClosePackageDetail}
                autoFocus
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                enforceFocus
                hasBackdrop
                usePortal

            >
                <div className={overlayClasses}>
                    <div className={"marketplace-detail-overlay-container"}>
                        <div className={"marketplace-detail-overlay-header"}>
                            {selectedPackage.directory + '/' + selectedPackage.name}
                        </div>
                        <div className={"marketplace-detail-overlay-text-container"}>
                            <div className={"marketplace-detail-overlay-text-container-left"}>
                                <div className={'flex-row-div'}>
                                    <div className={'marketplace-detail-edit-text-label'}>Package Description: </div>
                                    {editMode &&
                                        <InputGroup
                                            onChange={(e) => handleOnPackageChange('description', e)}
                                            value={selectedPackage?.description as string}
                                            className={'marketplace-detail-edit-input'}
                                        />
                                    }
                                    {!editMode &&
                                        <>
                                            {selectedPackage?.description}
                                        </>
                                    }
                                </div>
                                <div className={'flex-row-div'}>
                                    <div className={'marketplace-detail-edit-text-label'}>Package Label: </div>
                                    {editMode &&
                                        <InputGroup
                                            onChange={(e) => handleOnPackageChange('label', e)}
                                            value={selectedPackage?.label as string}
                                            className={'marketplace-detail-edit-input'}
                                        />
                                    }
                                    {!editMode &&
                                        <>
                                            {selectedPackage?.label}
                                        </>
                                    }
                                </div>
                                <div className={'flex-row-div'}>
                                    <div className={'marketplace-detail-edit-text-label'}>Package Visibility: </div>
                                    {editMode &&
                                        <div className={"marketplace-detail-edit-input"}>
                                            <CustomSelect
                                                className={"marketplace-detail-edit-input"}
                                                inputClassName={"marketplace-detail-edit-input"}
                                                buttonClassName={"marketplace-detail-edit-input"}
                                                value={selectedPackage?.visibility as string}
                                                onChange={(e) => handleOnPackageChange('visibility', e)}
                                                items={[
                                                    'private',
                                                    'org',
                                                    'public'
                                                ]}
                                            />
                                        </div>
                                    }
                                    {!editMode &&
                                        <>
                                            {selectedPackage?.visibility}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className={"marketplace-detail-overlay-text-container-right"}>
                                {selectedPackage?.forkedFrom &&
                                    <div className={'flex-row-div'}>
                                        <div className={"marketplace-detail-edit-text-label"}>
                                            Forked From:
                                        </div>
                                        <div>
                                            {selectedPackage?.forkedFrom + '/' + selectedPackage?.name}
                                        </div>

                                    </div>
                                }
                                <div className={'flex-row-div'}>
                                    <div className={"marketplace-detail-edit-text-label"}>
                                        Marketplace Version:
                                    </div>
                                    <div>
                                        {selectedPackage?.version}
                                    </div>
                                </div>
                                {installed &&
                                    <div className={'flex-row-div'}>
                                        <div className={"marketplace-detail-edit-text-label"}>
                                            Installed Version:
                                        </div>
                                        <div>
                                            {installed?.version}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={"marketplace-detail-overlay-logo"}>
                            {editMode &&
                                <div className={'marketplace-detail-edit-package-logo-container'}>
                                    <div className={'flex-row-div'}>
                                        <div className={"marketplace-detail-edit-text-label"}>Image Base64:</div>
                                        <InputGroup
                                            onChange={(e) => handleOnPackageChange('logo', e)}
                                            value={(selectedPackage?.logo as any)?.src}
                                            className={'marketplace-detail-edit-input-long'}
                                        />
                                    </div>
                                    <div className={'flex-row-div'}>
                                        <div className={"marketplace-detail-edit-text-label"}>Image Height:</div>
                                        <InputGroup
                                            onChange={(e) => handleOnPackageChange('logoHeight', e)}
                                            value={(selectedPackage?.logo as any)?.height}
                                            className={'marketplace-detail-edit-input-long'}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={"marketplace-detail-overlay-status-div " + "marketplace-detail-overlay-status-div-" + status}>{status}</div>
                        {!editMode && <div className={"marketplace-detail-overlay-logo"}>{logo(selectedPackage)}</div>}
                    </div>
                    <div className={"marketplace-detail-overlay-content-container"}>
                        <div className={"marketplace-detail-overlay-content-left-container"}>
                            {selectedPackage?.automations && (selectedPackage?.automations as Array<string>).length > 0 &&
                                <>
                                    <div className={"marketplace-detail-overlay-card-automations-header"}>Automations:</div>
                                    <div className={"marketplace-detail-overlay-card-automations"}>
                                        <HTMLTable
                                            bordered
                                        >
                                            <tbody>
                                            {selectedPackage?.automations && Object.values(selectedPackage?.automations).map((automation: any, ix: number) => {
                                                return (
                                                    <tr key={automation.name + ix}>
                                                        <td className={'marketplace-detail-overlay-card-automation-text'}>{automation.name}</td>
                                                        <td className={'marketplace-detail-overlay-card-automation-text'}>{automation.description}</td>
                                                        <td className={'marketplace-detail-overlay-card-automation-text'}>
                                                            {editMode &&
                                                                <Icon size={12} className={'marketplace-detail-overlay-edit-automation-delete-button'} onClick={() => handleOnDeleteAutomation(automation)} icon="delete"/>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </HTMLTable>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={"marketplace-detail-overlay-content-right-container"}>
                            {selectedPackage &&
                                <>
                                    <div className={"marketplace-detail-overlay-card-about-header"}>About:</div>
                                    <div className={"marketplace-detail-overlay-card-detail-description"}>
                                        {editMode &&
                                            <TextArea
                                                onChange={(e) => handleOnPackageChange('about', e)}
                                                value={selectedPackage?.about as string}
                                                className={'marketplace-detail-edit-about'}
                                            />
                                        }
                                        {!editMode &&
                                            <div>
                                                {selectedPackage?.about as string}
                                            </div>
                                        }
                                    </div>
                                </>

                            }
                            <div className={"marketplace-detail-overlay-card-detail-search-words"}>
                                {editMode &&
                                    <>
                                        <div className={"marketplace-detail-overlay-card-about-header"}>Search Terms:</div>
                                        <CustomMultiSelect
                                            onRemove={handleSearchWordRemove}
                                            onItemSelect={handleSearchWordSelectItem}
                                            createNewItemFromQuery={handleSearchWordCreate}
                                            items={selectedPackage?.searchTerms as string[]}
                                            selectedItems={selectedPackage?.searchTerms as string[]}
                                            className={'marketplace-detail-edit-search-words'}
                                            noMenu
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>


                    {setLoading && <Spinner className={"marketplace-install-spinner"} intent={Intent.PRIMARY} size={60} />}
                    {
                        <div className={Classes.DIALOG_FOOTER_ACTIONS + " marketplace-detail-overlay-buttons"}>
                            {<Button disabled={setLoading || editMode || (status !== 'available' && status !== 'installed' && status !== 'upgrade') || installed?.version === selectedPackage?.version}
                                     onClick={handleOnInstallPackage}
                                     intent={Intent.SUCCESS}
                                     icon="add"
                                     text={status === 'upgrade' ? "Upgrade" : "Install"}
                            />}
                            {!editMode && isForkable(selectedPackage as Package) && <Button disabled={setLoading} onClick={handleOnForkPackage} intent={Intent.PRIMARY} icon="fork" text={"Fork"}/>}
                            {editMode && isAuthorizedToManage(selectedPackage as Package) && <Button disabled={setLoading} onClick={handleOnSavePackage} intent={Intent.SUCCESS} icon="saved" text={"Save"}/>}
                            {!editMode && isAuthorizedToManage(selectedPackage as Package) && <Button disabled={setLoading} onClick={handleOnEditPackage} intent={Intent.PRIMARY} icon="edit" text={"Edit"}/>}
                            {isAuthorizedToManage(selectedPackage as Package) && <Button disabled={setLoading || editMode} onClick={handleOnDeletePackage} intent={Intent.DANGER} icon="delete" text={"Delete"}/>}
                            {<Button disabled={setLoading} onClick={handleOnClosePackageDetail} intent={Intent.DANGER} icon="undo" text={"Cancel"}/>}
                        </div>
                    }
                    <Alert
                        className={'new-instance-abort'}
                        cancelButtonText={"Cancel"}
                        confirmButtonText={"Delete"}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={deleteAlertOpen}
                        onCancel={handleDeleteAlertCancel}
                        onConfirm={handleDeleteAlertConfirm}
                    >
                        <p>
                            Are you sure you want to delete this package from the repository?
                        </p>
                    </Alert>
                    <Alert
                        className={'new-instance-abort'}
                        cancelButtonText={"Cancel"}
                        confirmButtonText={"Delete"}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={deleteAutomationAlertOpen}
                        onCancel={handleDeleteAutomationAlertCancel}
                        onConfirm={handleDeleteAutomationAlertConfirm}
                    >
                        <p>
                            Are you sure you want to delete {selectedAutomation?.name} from this package?
                        </p>
                    </Alert>
                </div>
            </Overlay>
        )
    }

    const handlePackageClick = (marketplacePackage: Package) => {
        setSelectedPackage(marketplacePackage);
        setShowPackageDetail(true);
    }

    const packageCard = (marketplacePackage: Package) => {
        const status = getStatus(marketplacePackage);

        return (
            <Card onClick={(e) => handlePackageClick(marketplacePackage)} className={"marketplace-card"} interactive={true} elevation={Elevation.TWO}>
                <div className={"marketplace-logo"}>{logo(marketplacePackage)}</div>
                <div className={"marketplace-status-div " + "marketplace-status-div-" + status}>{getStatus(marketplacePackage)}</div>
                <div className={"marketplace-visibility-div " + "marketplace-visibility-div-" + marketplacePackage.visibility}>{marketplacePackage.visibility}</div>
                <div className={"marketplace-version-div"}>v{marketplacePackage.version}</div>
                <div className={"marketplace-card-name"}>
                    {marketplacePackage.directory + '/' + marketplacePackage.name}
                    {marketplacePackage.forkedFrom && <> (fork)</>}
                </div>

                <div className={"marketplace-card-description"}>{marketplacePackage.description}</div>
                {marketplacePackage.automations
                    && (marketplacePackage.automations as Array<any>).map((automation: string, ix: number) => {
                        if(ix < 6) {
                            return (
                                <div key={"package-card-detail-" + ix}
                                     className={"marketplace-card-detail"}>{(automation as any).name}</div>
                            )
                        }
                })}
            </Card>
        )
    }

    useEffect(() => {
        getInstances(INSTALLED_PACKAGE_SPEC);
        let filter = 'type=Marketplace';
        filter += params?.get('demo') ? '&type=Demo' : '';
        filter += params?.get('core') ? '&type=Core' : '';
        getInstances(PACKAGE_SPEC, undefined, {db: filter});
    }, [])

    useEffect(() => {
        //console.log("selectedPackage", selectedPackage);
    }, [selectedPackage])

    if(loading) {
        return page;
    }

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const filter = event.target.value;
        onFilter(filter);
    }

    const handleFilterClear = () => {
        onFilter("");
    }

    const getPage = (setLoading?: boolean) => {
        return (
            <div className={"marketplace-container"}>
                <div className={"marketplace-content"}>
                    <InputGroup
                        className={"marketplace-search"}
                        large
                        leftElement={<Icon icon="search"/>}
                        placeholder="Filter Marketplace ..."
                        value={filter.toString()}
                        onChange={handleFilterChange}
                        rightElement={<Button onClick={handleFilterClear} icon={"delete"}/>}
                    />
                    {packages && packages.length > 0 &&
                        <div className={"marketplace-grid"}>
                            {packages.map && packages.map((_package: Package) => {
                                return (
                                    <div key={"package-card-" + _package.id} className={"marketplace-item"}>{packageCard(_package)}</div>
                                )
                            })}
                        </div>
                    }
                </div>
                {getOverlay(setLoading)}
            </div>
        );
    }

    return getPage();

};
