import React, {FC, useEffect, useState} from "react";

import {Specification as SpecificationType} from "common-models-ts";
import {Selected} from "../../../../model/Selected";
import {Services} from "../../../../services/Services";
import {InstanceListAttribute} from "../../../../model/InstanceListAttribute";
import {InstanceContainer} from "../InstanceContainer";
import {InstanceMode} from "../../../../enums/InstanceMode";
import {useAppSelector} from "../../../../redux/hooks";
import {usePrevious} from "../../../../utils/stateUtil";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {useNavigate} from "react-router-dom";
import {InstanceList} from "../../../../../custom/benches/InstanceList";
import {InstanceMenu} from "../../../../../custom/benches/InstanceMenu";
import {InstanceJob} from "../../../../model/InstanceJob";
import {InstanceListMenu} from "../../../../../custom/benches/InstanceListMenu";
import {InstanceListFilter} from "../../../../model/InstanceListFilter";


export interface BaseRelatedContainerProps {
    specification: {[index: string]: any};
    selected: Selected;
    rootSelected?: Selected;
    services: Services;
    selectedPage: string;
    selectedDeployment: Deployment;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, callback: (res: any, err: any) => void) => void;
    onInstanceDelete: (specification: {[index: string]: {}}, instanceId: string, callback: (res: any, err: any) => void) => void;
    onInstanceListOnMount?: (instanceAttributeList: Array<InstanceListAttribute>) => void;
    onInstanceListOnSelect?: (instanceId: string) => void;
    onInstanceMenuOnJob?: (job: InstanceJob, parameters: any) => void;
}


export const BaseRelatedContainer: FC<BaseRelatedContainerProps> = (props) => {
    const {
        getInstances,
        getInstance,
        selected,
        rootSelected,
        services,
        selectedPage,
        selectedDeployment,
        onInstanceSave,
        onInstanceDelete,
        onInstanceListOnSelect,
        onInstanceListOnMount,
        relatedSelectCount,
        setRelatedSelectCount,
        onInstanceMenuOnJob,
    } = props;

    const instances = useAppSelector((state) => state.instances)
    const relatedSpec = selected.relatedSpecification;
    const spec = selected.specification;

    useEffect(() => {
        //console.log("mount");
        getRelatedInstances();

    }, [])

    const previousSelected = usePrevious(selected);
    useEffect(() => {
        if(previousSelected?.relatedSpecification !== selected.relatedSpecification) {
            if(selected.relatedSpecification) {
                setRelatedSelected(getSelectedFromSpec(selected.relatedSpecification));
            }
        }
        if(previousSelected?.relatedInstanceId !== selected.relatedInstanceId) {
            setRelatedSelected(prevState => prevState.clone().setInstanceId(selected.relatedInstanceId));
        }
        if(previousSelected?.instanceId !== selected.instanceId) {
            getRelatedInstances();
        }
    }, [selected])

    const getSelectedFromSpec = (spec: SpecificationType) => {
        return new Selected()
            .setSpecification(spec)
            .setJobSpecification(services.specification.get(spec.key + 'Job'))
            .setInstanceMode(InstanceMode.Data);
    }

    if(!relatedSpec) {
        return <></>
    }

    const [relatedSelected, setRelatedSelected] = useState<Selected>(getSelectedFromSpec(relatedSpec));

    const handleInstanceListOnMount = () => {

    }

    const handleOnClick = (mode: InstanceMode) => {
        setRelatedSelected(relatedSelected.clone().setInstanceMode(mode))
    }

    const navigate = useNavigate();

    const handleInstanceSave = (specification: SpecificationType, savingInstance: any, callback: (data: any, err: any) => void) => {
        savingInstance[`associated${spec?.name}Id`] = selected?.instanceId;
        onInstanceSave(specification as any, savingInstance, (res, err) => {
            if(res) {
                let url = `/deployments/${selectedDeployment.id}/${selectedPage}/${selected.specification?.url}/${selected.instanceId}/${selected.relatedSpecification?.url}/${res.id}`;
                console.log("Navigating to", `${url}`);
                navigate(url);
            }
            callback(res, err);
        });
    }

    const getRelatedInstances = () => {
        if(selected.relatedSpecification) {
            let filter = {db: `associated${spec?.name}Id=${selected?.instanceId}`};
            getInstances(selected.relatedSpecification, undefined, filter);
        }
    }

    return (
        <div className={'base-related-container'}>
            <div>
                <InstanceListMenu
                    className={'related-list-outer-width'}
                    onInstanceSave={handleInstanceSave}
                    onInstanceDelete={onInstanceDelete}
                    services={services}
                    selected={relatedSelected}
                    relatedSelectCount={relatedSelectCount}
                    setRelatedSelectCount={setRelatedSelectCount}
                    rootSelected={selected}
                    getInstances={getInstances}
                    getInstance={getInstance}
                    selectedPage={selectedPage}
                    selectedDeployment={selectedDeployment}
                    specification={relatedSelected.specification as SpecificationType}
                />
                {relatedSelected.specification && instances[relatedSelected.getSpecificationKey()]?.instances &&
                    <InstanceList
                        relatedView
                        className={'related-list-table'}
                        onMount={handleInstanceListOnMount}
                        services={services}
                        selected={relatedSelected}
                        rootSelected={selected}
                        specification={relatedSelected.specification as SpecificationType}
                        selectedPage={selectedPage}
                        selectedDeployment={selectedDeployment}
                        onInstanceDelete={onInstanceDelete}
                        getInstances={getInstances}
                        getInstance={getInstance}
                    />
                }
            </div>

            <div className={'related-view-pane'}>
                <InstanceMenu
                    services={services}
                    getInstances={getInstances}
                    getInstance={getInstance}
                    selected={relatedSelected}
                    relatedSelectCount={relatedSelectCount}
                    setRelatedSelectCount={setRelatedSelectCount}
                    onInstanceDelete={onInstanceDelete}
                    selectedPage={selectedPage}
                    rootSelected={selected}
                    onClick={handleOnClick}
                    specification={relatedSelected.specification as SpecificationType}
                    onJob={onInstanceMenuOnJob}
                    selectedDeployment={selectedDeployment}
                />
                <InstanceContainer
                    relatedView
                    onInstanceSave={handleInstanceSave}
                    services={services}
                    selected={relatedSelected}
                    relatedSelectCount={relatedSelectCount}
                    setRelatedSelectCount={setRelatedSelectCount}
                    getInstances={getInstances}
                    getInstance={getInstance}
                    selectedPage={selectedPage}
                    onInstanceDelete={onInstanceDelete}
                    selectedDeployment={selectedDeployment}
                    rootSelected={rootSelected || selected}
                />
            </div>
        </div>
    );
}
