
export const getById = (list: Array<{[index: string]: any}>, id: string|null) => {
    if(list) {
        for(const item of list) {
            if(item.id === id) {
                return item;
            }
        }
    }
    return undefined;
}

export const getByAttributeValue = (list: Array<{[index: string]: any}>, attributeName: string, attributeValue: any) => {
    if(list) {
        for(const item of list) {
            if(item[attributeName] === attributeValue) {
                return item;
            }
        }
    }
    return undefined;
}

export const getIndex = (list: Array<{[index: string]: any}>, id: string|null) => {
    for(let i=0; i<list.length; i++) {
        if(list[i].id === id) {
            return i;
        }
    }
}


export const getFromInputs = (attribute: string, inputs: any) => {
    if(inputs && inputs.instance) {
        const instance = inputs.instance;
        const id = instance[attribute];
        if(inputs[id] && inputs[id].id) {
            return inputs[id];
        }
    }
    return undefined;
}
