import * as React from "react";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {InstanceListProps} from "../../../InstanceList";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {logo} from "./InstanceListUtil";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";

export const EndpointPolicyList: FC<InstanceListProps> = (props) => {


    return (
        <BaseInstanceList
            {...props}

            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const endpointPolicy = instance as EndpointPolicy;
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(endpointPolicy)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{endpointPolicy.name}</div>
                                <div className={"instance-list-card-attribute"}>{endpointPolicy.associatedEndpointTemplateName}</div>
                                <div className={"instance-list-card-attribute"}>{endpointPolicy.isDefault}</div>
                            </div>
                        </>
                    )
                }
            }

            instanceListAttributes={[
                new InstanceListAttribute({
                    name: EndpointPolicy._name,
                }),
                new InstanceListAttribute({
                    name: EndpointPolicy._associatedEndpointTemplateName,
                }),
                new InstanceListAttribute({
                    name: EndpointPolicy._isDefault,
                }),
            ]}
        />
    );
};
