import * as React from "react";
import {FC, ReactElement, ReactNode, useEffect} from "react";
import {Services} from "../../../../services/Services";
import {DefaultTabs} from "../../common/tabs/DefaultTabs";
import {Selected} from "../../../../model/Selected";
import {InstanceListFilter} from "../../../../model/InstanceListFilter";

export interface BaseInstanceEditProps {
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    specification: {[index: string]: any};
    instance:  { [index: string]: any; };
    setInstance: (editedInstance: { [index: string]: any; }) => void;
    services: Services;
    onCancel: () => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => void;
    onAttributeChange: (name: string, value: any) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedPage: string;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    selected: Selected;
    rootSelected?: Selected;
    isReadOnly?: boolean;
    children?: ReactNode;
    setLoading?: (state: boolean) => void;
    isAddNew?: boolean;
}

export const BaseInstanceEdit: FC<BaseInstanceEditProps> = (props) => {
    const {
        instance,
        services,
        onCancel,
        onInstanceSave,
        onAttributeChange,
        relatedSelectCount,
        setRelatedSelectCount,
        getInstances,
        specification,
        selectedPage,
        editMode,
        setEditMode,
        selected,
        isReadOnly,
        getInstance,
    } = props;

    useEffect(() => {
        //console.log("instance", instance);
    }, [instance])

    useEffect(() => {
        //console.log("remount");
    }, [])

    return (
        <DefaultTabs
            onAttributeChange={onAttributeChange}
            specification={specification}
            onInstanceSave={onInstanceSave}
            onCancel={onCancel}
            id={specification.key}
            instance={instance}
            services={services}
            instanceEditTabs={props.children as ReactElement}
            relatedSelectCount={relatedSelectCount}
            setRelatedSelectCount={setRelatedSelectCount}
            getInstances={getInstances}
            getInstance={getInstance}
            selectedPage={selectedPage}
            editMode={editMode}
            setEditMode={setEditMode}
            selected={selected}
            isReadOnly={isReadOnly}
        />
    );
}
