import React from "react";
import {BrowserRouter as Router,} from "react-router-dom";
import {AppContainer} from "./AppContainer";
import UserService from "./common/services/UserService";
import 'animate.css';
import './stylesheets/app.css';

import { createRoot } from 'react-dom/client';
import store from "./common/redux/store";
import {Provider} from "react-redux";

const appContainer = document.getElementById('app');
const root = createRoot(appContainer!); // createRoot(container!) if you use TypeScript

const renderApp = () => root.render(
    <Router>
        <Provider store={store}>
            <AppContainer />
        </Provider>
    </Router>
);

UserService.initKeycloak(renderApp);
