import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {ActivationResponse} from "raasify-models-specification-ts/core/ActivationResponse";
import {Application} from "raasify-models-specification-ts/core/Application";
import {ApplicationPolicy} from "raasify-models-specification-ts/core/ApplicationPolicy";
import {ApplicationState} from "raasify-models-specification-ts/core/ApplicationState";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationExecution} from "raasify-models-specification-ts/core/AutomationExecution";
import {AutomationJob} from "raasify-models-specification-ts/core/AutomationJob";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {AutomationState} from "raasify-models-specification-ts/core/AutomationState";
import {AutomationTemplate} from "raasify-models-specification-ts/core/AutomationTemplate";
import {Certificate} from "raasify-models-specification-ts/core/Certificate";
import {Connector} from "raasify-models-specification-ts/core/Connector";
import {ConnectorPolicy} from "raasify-models-specification-ts/core/ConnectorPolicy";
import {ConnectorTemplate} from "raasify-models-specification-ts/core/ConnectorTemplate";
import {ConnectorRequest} from "raasify-models-specification-ts/core/ConnectorRequest";
import {Credential} from "raasify-models-specification-ts/core/Credential";
import {CronSchedule} from "raasify-models-specification-ts/core/CronSchedule";
import {DeploymentPolicy} from "raasify-models-specification-ts/core/DeploymentPolicy";
import {Destination} from "raasify-models-specification-ts/core/Destination";
import {DestinationPolicy} from "raasify-models-specification-ts/core/DestinationPolicy";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import {DiscoveredEdgeJob} from "raasify-models-specification-ts/core/DiscoveredEdgeJob";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {EdgeJob} from "raasify-models-specification-ts/core/EdgeJob";
import {EdgePolicy} from "raasify-models-specification-ts/core/EdgePolicy";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {EndpointExecution} from "raasify-models-specification-ts/core/EndpointExecution";
import {EndpointHost} from "raasify-models-specification-ts/core/EndpointHost";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";
import {EndpointTemplate} from "raasify-models-specification-ts/core/EndpointTemplate";
import {Metric} from "raasify-models-specification-ts/core/Metric";
import {MetricExecution} from "raasify-models-specification-ts/core/MetricExecution";
import {MetricTrigger} from "raasify-models-specification-ts/core/MetricTrigger";
import {MetricTriggerExecution} from "raasify-models-specification-ts/core/MetricTriggerExecution";
import {Node} from "raasify-models-specification-ts/core/Node";
import {NodeState} from "raasify-models-specification-ts/core/NodeState";
import {NodePolicy} from "raasify-models-specification-ts/core/NodePolicy";
import {Settings} from "raasify-models-specification-ts/core/Settings";
import {State} from "raasify-models-specification-ts/core/State";
import {StateGroup} from "raasify-models-specification-ts/core/StateGroup";
import {Transformer} from "raasify-models-specification-ts/core/Transformer";
import {VMPolicy} from "raasify-models-specification-ts/core/VMPolicy";
    
import { SideBarEntry } from "../../../../common/components/workbench/sidebar/SideBar";


export const SideBarLayout: {[category: string]: Array<SideBarEntry>} = {
    '': [
        {class: ActivationRequest},
        {class: ActivationResponse},
        {class: Application},
        {class: ApplicationState},
        {class: ApplicationPolicy},
        {class: Automation},
        {class: AutomationJob},
        {class: AutomationExecution},
        {class: AutomationPolicy},
        {class: AutomationState},
        {class: AutomationTemplate},
        {class: Certificate},
        {class: Connector},
        {class: ConnectorPolicy},
        {class: ConnectorTemplate},
        {class: ConnectorRequest},
        {class: Credential},
        {class: CronSchedule},
        {class: DeploymentPolicy},
        {class: Destination},
        {class: DestinationPolicy},
        {class: DiscoveredEdge},
        {class: DiscoveredEdgeJob},
        {class: Edge},
        {class: EdgeJob},
        {class: EdgePolicy},
        {class: Endpoint},
        {class: EndpointExecution},
        {class: EndpointHost},
        {class: EndpointPolicy},
        {class: EndpointTemplate},
        {class: Metric},
        {class: MetricExecution},
        {class: MetricTrigger},
        {class: MetricTriggerExecution},
        {class: Node},
        {class: NodeState},
        {class: NodePolicy},
        {class: Settings},
        {class: State},
        {class: StateGroup},
        {class: Transformer},
        {class: VMPolicy},
    ],
}
