import {InstanceMode} from "../enums/InstanceMode";
import * as React from "react";
import {CustomComponentProps} from "../components/main/CustomComponentProps";
import {SideBarEntry} from "../components/workbench/sidebar/SideBar";

export class Selected {
    private _specification: { [index: string]: any; } | null = null;
    private _jobSpecification: { [index: string]: any; } | null = null;
    private _instanceMode: string | null = InstanceMode.Data;
    private _instanceId: string | null = null;
    private _relatedSpecification: { [index: string]: any; } | null = null;
    private _relatedJobSpecification: { [index: string]: any; } | null = null;
    private _relatedInstanceId: string | null = null;
    private _component: React.FC<CustomComponentProps> | null = null;
    private _sideBarEntry: SideBarEntry | null = null;

    constructor() {
    }

    get specification(): {[index: string]: any} | null {
        return this._specification;
    }

    set specification(value: {[index: string]: any} | null) {
        this._specification = value;
    }

    get jobSpecification(): {[index: string]: any} | null {
        return this._jobSpecification;
    }

    set jobSpecification(value: {[index: string]: any} | null) {
        this._jobSpecification = value;
    }

    get instanceMode(): string | null {
        return this._instanceMode;
    }

    set instanceMode(value: string | null) {
        this._instanceMode = value;
    }

    get instanceId(): string | null {
        return this._instanceId;
    }

    set instanceId(value: string | null) {
        this._instanceId = value;
    }

    get relatedSpecification(): { [p: string]: any } | null {
        return this._relatedSpecification;
    }

    set relatedSpecification(value: { [p: string]: any } | null) {
        this._relatedSpecification = value;
    }

    get relatedJobSpecification(): { [p: string]: any } | null {
        return this._relatedJobSpecification;
    }

    set relatedJobSpecification(value: { [p: string]: any } | null) {
        this._relatedJobSpecification = value;
    }

    get relatedInstanceId(): string | null {
        return this._relatedInstanceId;
    }

    set relatedInstanceId(value: string | null) {
        this._relatedInstanceId = value;
    }

    get component(): React.FC<CustomComponentProps> | null {
        return this._component;
    }

    set component(value: React.FC<CustomComponentProps> | null) {
        this._component = value;
    }

    get sideBarEntry(): SideBarEntry | null {
        return this._sideBarEntry;
    }

    set sideBarEntry(value: SideBarEntry | null) {
        this._sideBarEntry = value;
    }

    clone(): Selected {
        const copy = new Selected();
        Object.assign(copy, this);
        return copy;
    }

    setSpecification(value: any) {
        this._specification = value;
        return this;
    }

    setJobSpecification(value: any) {
        this._jobSpecification = value;
        return this;
    }

    setInstanceMode(value: string | null) {
        this._instanceMode = value;
        return this;
    }

    setInstanceId(value: string | null) {
        this._instanceId = value;
        return this;
    }

    getSpecificationKey() {
        return this._specification?.key;
    }

    getSpecificationUrl() {
        return this._specification?.url;
    }

    getSpecificationLabel() {
        return this._specification?.label;
    }

    getSpecificationName() {
        return this._specification?.name;
    }

    setRelatedSpecification(value: any) {
        this._relatedSpecification = value;
        return this;
    }

    setRelatedJobSpecification(value: any) {
        this._relatedJobSpecification = value;
        return this;
    }

    setRelatedInstanceId(value: any) {
        this._relatedInstanceId = value;
        return this;
    }

    setComponent(value: React.FC<CustomComponentProps>|null) {
        this._component = value;
        return this;
    }

    setSideBarEntry(value: SideBarEntry) {
        this._sideBarEntry = value;
        return this;
    }

}
