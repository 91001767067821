import * as React from "react";
import {FC, ReactElement, useRef, useState} from "react";

import {
    Alignment,
    Button,
    Classes, EditableText,
    FormGroup,
    Icon,
    InputGroup,
    Radio,
    RadioGroup,
    Switch,
    TextArea
} from "@blueprintjs/core";
import { DatePicker, TimePrecision } from "@blueprintjs/datetime";
import {EnumSelect} from "./EnumSelect";
import {JSONEditor} from "./JSONEditor";
import {ListSuggest} from "./ListSuggest";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import moment from 'moment';
import {CodeEditor} from "./CodeEditor";
import {ObjectListSuggest} from "./ObjectListSuggest";

export interface AttributeRawProps {
    instance: any;
    attributeName: string;
    label?: string;
    units?: string;
    description?: string;
    onChange(name: string, value: any): void;
    disabled?: boolean;
    placeholder?: string;
    focus?: boolean;
    type: string;
    tabAttribute?: ReactElement;
    enumList?: Array<string>;
    defaultValue?: any;
    nullLabel?: boolean;
    inheritanceEnabled?: boolean;
    useAttributeNamesAsLabels?: boolean;
    style?: any;
    radioItems?: Array<RadioItem>;
    suggestList?: Array<any>;
    suggestListLabelAttribute?: string;
    suggestListAddNew?: (name: string) => any;
    suggestListHideLabels?: boolean;
    editableText?: boolean,
    className?: string,
}

export interface RadioItem {
    label: string;
    value: string;
}

export const AttributeRaw: FC<AttributeRawProps> = (props) => {
    const {
        instance,
        attributeName,
        onChange,
        disabled,
        placeholder,
        focus,
        tabAttribute,
        enumList,
        defaultValue,
        nullLabel,
        type,
        inheritanceEnabled,
        useAttributeNamesAsLabels,
        label,
        units,
        description,
        style,
        radioItems,
        suggestList,
        suggestListLabelAttribute,
        suggestListAddNew,
        editableText,
        className,
        suggestListHideLabels,
    } = props;

    const attribute = {
        enum: enumList,
        name: attributeName,
        defaultValue, label, units, description, type,
    };

    const [showPopover, setShowPopover] = useState<boolean>(false);

    const wrapperRef = useRef(null);

    const handleOnChange = (event: any) => {
        const target = event && event.target;
        const value = target && (target.type === "checkbox" ? target.checked : target.value);
        const id = target && target.id;
        //console.log("handleOnChange", id, value);
        onChange(id, value);
    }

    const handleArrayOnChange = (event: any) => {
        const target = event && event.target;
        const value = target.value;
        onChange(attributeName, value ? value.split("\n") : []);
    }

    const handleJSONEditorOnChange = (value: object | undefined) => {
        onChange(attributeName, value);
    }

    const handleTextAreaOnChange = (value: object | undefined) => {
        //console.log("handleTextAreaOnChange", value);
        onChange(attributeName, value);
    }

    let attributeComponent;

    if(!instance) {
        attributeComponent = (<></>)
    }
    else if(enumList && !disabled) {
        attributeComponent = (
            <EnumSelect
                 attribute={attribute}
                 attributeName={attributeName}
                 instance={instance}
                 disabled={disabled}
                 onChange={onChange}
            />
        )
    }
    else if(editableText) {
        attributeComponent = (
            <EditableText
                disabled={disabled}
                type={'text'}
                onChange={(value) => onChange(attributeName, value)}
                value={defaultValue ? defaultValue : instance[attributeName] ? instance[attributeName] : ""}
                placeholder={placeholder}
            />
        )
    }
    else if(['string'].includes(type) && (tabAttribute?.props.suggestList || suggestList)) {
        attributeComponent = (
            <ObjectListSuggest
                attributeName={attributeName}
                instance={instance}
                disabled={disabled}
                onChange={(value) => onChange(attributeName, value)}
                items={suggestList || tabAttribute?.props.suggestList}
                labelAttribute={suggestListLabelAttribute}
                suggestListAddNew={suggestListAddNew}
                hideLabels={suggestListHideLabels}
            />
        )
    }
    else if(['string', 'integer', 'number', 'email'].includes(type)) {
        attributeComponent = (
            <InputGroup
                autoFocus={focus}
                id={attributeName}
                disabled={disabled}
                type={['email'].includes(type) ? type : 'text'}
                onChange={handleOnChange}
                value={defaultValue ? defaultValue : instance[attributeName] ? instance[attributeName] : ""}
                placeholder={placeholder}
            />
        )
    }
    else if(['datetime'].includes(type)) {
        const pad = (value: number) => {
            return String(value).padStart(2, '0')
        }

        const handleDateChange = (selectedDate: Date, isUserChange: boolean) => {
            if(selectedDate) {
                const dateString = `${selectedDate.getFullYear()}-${pad(selectedDate.getMonth()+1)}-${pad(selectedDate.getDate())}`
                const timeString = `${pad(selectedDate.getHours())}:${pad(selectedDate.getMinutes())}`
                const convertToUtc = moment(dateString + ' ' + timeString, 'YYYY-MM-DD HH:mm').toISOString();
                onChange(attributeName, convertToUtc)
            }
            else {
                onChange(attributeName, null)
            }
        }

        const convertToLocal = instance[attributeName]
            ? moment.utc(instance[attributeName], 'YYYY-MM-DDTHH:mm:ss.SSSZ').local()
            : null;
        const convertToLocalString = convertToLocal ? convertToLocal?.format('YYYY-MM-DD HH:mm:ss.SSS') : null;

        const now = new Date();
        const tomorrow = new Date(new Date().getTime()+(1 * 24*60*60*1000));
        const nextWeek = new Date(new Date().getTime()+(7 * 24*60*60*1000));
        const nextMonth = new Date(new Date().getTime()+(31 * 24*60*60*1000));

        // if the date is in the past, reset to tomorrow
        const defaultValue
            = (convertToLocal && now.getTime() < convertToLocal?.toDate().getTime() ? convertToLocal?.toDate() : tomorrow) as Date;

        const handleOnClickInputField = () => {
            setShowPopover(!showPopover);
        }

        attributeComponent = (
            <div>
                <Popover2
                    usePortal
                    isOpen={showPopover}
                    content={
                    <div ref={wrapperRef}>
                        <DatePicker
                            className={Classes.ELEVATION_1}
                            onChange={handleDateChange}
                            timePrecision={TimePrecision.MINUTE}
                            timePickerProps={{useAmPm: true, showArrowButtons: true}}
                            highlightCurrentDay
                            reverseMonthAndYearMenus
                            minDate={now}
                            defaultValue={defaultValue}
                            shortcuts={[
                                {date: new Date(), includeTime: true, label: "Now"},
                                {date: tomorrow, label: "Tomorrow"},
                                {date: nextWeek, includeTime: true, label: "Next Week"},
                                {date: nextMonth, includeTime: true, label: "Next Month"},
                            ]}
                            showActionsBar
                        />
                        <button
                            className="bp4-button bp4-minimal datepicker-button"
                            onClick={() => setShowPopover(false)}
                        ><span className="bp4-button-text">OK</span></button>
                    </div>

                    }
                    enforceFocus
                    canEscapeKeyClose
                    placement="bottom"
                    disabled={disabled}
                >
                    <Button
                        onClick={handleOnClickInputField}
                        disabled={disabled}
                        alignText={Alignment.LEFT}
                        className={'attribute-date-picker-button'}
                        text={<div id={'dateTimeButton'}>{convertToLocalString || nullLabel}</div>}
                        rightIcon={<Icon className={"attribute-date-picker-button-right-icon"} icon={"caret-down"}/>}
                    />
                </Popover2>
            </div>
        )
    }
    else if(['text'].includes(type)) {
        let value = instance[attributeName];

        attributeComponent = (
            <TextArea
                autoFocus={focus}
                className={'attribute-textarea'}
                id={attributeName}
                disabled={disabled}
                growVertically={true}
                fill
                onChange={handleOnChange}
                value={value !== null ? value : ""}
                placeholder={placeholder}
            />
        )
    }
    else if(['yaml'].includes(type)) {
        let value = instance[attributeName];
        attributeComponent = (
            <CodeEditor
                language={"yaml"}
                width={"calc( (100vw - 200px) * 0.5  - 10px)"}
                height={"80vh"}
                className={"playbook-tester-editor playbook-tester-yaml"}
                disabled={false}
                onChange={(data) => onChange(attributeName, data)}
                value={instance && instance.request ? instance.request.playbook : ""}
            />
        )
    }
    else if(['array'].includes(type)) {
        let value = Array.isArray(instance[attributeName]) ? instance[attributeName].join("\n") : "";
        let placeholderText = Array.isArray(placeholder) ? placeholder.join("\n") : "";
        let rows = value ? value.split("\n").length
            : placeholderText.split("\n").length;
        attributeComponent = (
            <TextArea
                autoFocus={focus}
                className={'attribute-textarea'}
                id={attributeName}
                disabled={disabled}
                fill
                onChange={handleArrayOnChange}
                value={value !== null ? value : ""}
                placeholder={placeholderText}
                rows={rows}
            />
        )
    }
    else if(['json'].includes(type)) {
        attributeComponent = (
            <JSONEditor
                id={attributeName}
                disabled={disabled}
                value={instance[attributeName] ? instance[attributeName] : undefined}
                onChange={handleJSONEditorOnChange}
                placeholder={placeholder}
            />
        )
    }
    else if(['boolean'].includes(type) && inheritanceEnabled) {
        // Use inherit, enabled, disabled radio button group (with placeholder)

        const handleOnChangeRadioButton = (event: any) => {
            let result = undefined;
            if(event.target.value === "enabled") result = true;
            else if(event.target.value === "disabled") result = false;
            onChange(attributeName, result);
        }

        const selectedValue = instance[attributeName] === undefined ? "inherit" : instance[attributeName] ? "enabled" : "disabled";
        const placeholderValue = placeholder === undefined ? "inherit" : placeholder ? "enabled" : "disabled";

        attributeComponent = (
            <div className={"attribute-inheritance-boolean-inherit-container"}>
                <RadioGroup
                    disabled={disabled}
                    onChange={handleOnChangeRadioButton}
                    selectedValue={selectedValue}
                >
                    <Radio label="Inherit" value={"inherit"} />
                    <Radio label="Enabled" value={"enabled"} />
                    <Radio label="Disabled" value={"disabled"} />
                </RadioGroup>
                <div className={"attribute-inheritance-boolean-inherit-placeholder"}>({placeholderValue})</div>
            </div>
        )
    }
    else if(['boolean'].includes(type)) {
        attributeComponent = (
            <Switch
                id={attributeName}
                disabled={disabled}
                onChange={handleOnChange}
                checked={instance[attributeName]}
            />
        )
    }
    else if(['radio'].includes(type)) {
        const handleOnChangeRadioButton = (event: any) => {
            onChange(attributeName, event.target.value);
        }
        attributeComponent = (
            <div>
                <RadioGroup
                    inline
                    disabled={disabled}
                    onChange={handleOnChangeRadioButton}
                    selectedValue={instance[attributeName]}
                >
                    {radioItems && radioItems.map((item: RadioItem) => {
                        return (
                            <Radio label={item.label} value={item.value}/>
                        )
                    })}
                </RadioGroup>
            </div>
        )
    }

    if(!instance) {
        return <></>
    }

    return (
        <FormGroup
            style={style}
            key={attribute.name}
            className={"bp4-text-small attribute-container " + className}
            label={<div className={'attribute-label-container'}>
                <div className={'attribute-label'}>
                    {useAttributeNamesAsLabels ? attribute.name : attribute?.label}
                    {attribute?.units && <span className={'attribute-label-units'}>({attribute?.units})</span>}
                </div>
                {description && <Tooltip2
                    className={'tooltip-container'}
                    content={<div className={'tooltip-content'}>{attribute?.description}</div>}
                    placement="right-start"
                >
                    <Icon
                        color={"rgba(15,67,146,0.77)"}
                        className={"tooltip-icon"}
                        icon={"help"}
                        size={11}
                    />
                </Tooltip2>}
            </div>}
            labelFor={attributeName}
        >
            <div
                className={'attribute'}
            >
                {attributeComponent}
            </div>
        </FormGroup>
    );
};
