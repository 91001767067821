import React, {FC} from "react";
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {Metric} from "raasify-models-specification-ts/core/Metric";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {Services} from "../../common/services/Services";
import {Selected} from "../../common/model/Selected";
import {InstanceJob} from "../../common/model/InstanceJob";
import {AutomationMenu} from "./workbench/components/menu/AutomationMenu";
import {AutomationPolicyMenu} from "./workbench/components/menu/AutomationPolicyMenu";
import {MetricMenu} from "./workbench/components/menu/MetricMenu";
import {EdgeMenu} from "./workbench/components/menu/EdgeMenu";
import {EndpointMenu} from "./workbench/components/menu/EndpointMenu";
import {DestinationMenu} from "./workbench/components/menu/DestinationMenu";
import {EndpointPolicyMenu} from "./workbench/components/menu/EndpointPolicyMenu";
import {ActivationRequestMenu} from "./notifications/components/menu/ActivationRequestMenu";
import {BaseInstanceMenu} from "../../common/components/workbench/instance/menu/BaseInstanceMenu";
import {InstanceMenuItem} from "../../common/model/InstanceMenuItem";
import {InstanceMode} from "../../common/enums/InstanceMode";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import {DiscoveredEdgeMenu} from "./notifications/components/menu/DiscoveredEdgeMenu";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {Destination} from "raasify-models-specification-ts/core/Destination";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";


export interface InstanceMenuProps
{
    services: Services;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    selected: Selected;
    rootSelected?: Selected;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    onClick(instanceMode: string, relatedSpecKey?: string): void;
    onJob?: (job: InstanceJob, parameters: any) => void;
    onInstanceDelete(specification: {[index: string]: {}} | null, instanceId: string, callback: (res: any, err: any) => void): void;
    selectedPage: string;
    children?: React.ReactNode;
    specification: {[index: string]: any};
    editMode?: boolean;
    setEditMode?: (editMode: boolean) => void;
    selectedDeployment: Deployment;
}

export const DefaultMenu: FC<InstanceMenuProps> = (props) => {
    return (
        <BaseInstanceMenu
            {...props}
            instanceMenuItems={[
                new InstanceMenuItem({
                    mode: InstanceMode.Data,
                }),
                new InstanceMenuItem({
                    mode: InstanceMode.Detail,
                }),
                new InstanceMenuItem({
                    mode: InstanceMode.Logs,
                }),
                new InstanceMenuItem({
                    mode: InstanceMode.Jobs,
                }),
                new InstanceMenuItem({
                    mode: InstanceMode.Delete,
                }),
            ]}
        />
    );
};

export const InstanceMenu: FC<InstanceMenuProps> = (props) => {
    const name = props.specification.name;
    if(name === ActivationRequest.specification.name) {
        return <ActivationRequestMenu {...props} />
    }
    else if(name === DiscoveredEdge.specification.name) {
        return <DiscoveredEdgeMenu {...props} />
    }
    else if (name === Automation.specification.name) {
        return <AutomationMenu {...props} />
    }
    else if (name === AutomationPolicy.specification.name) {
        return <AutomationPolicyMenu {...props} />
    }
    else if (name === Metric.specification.name) {
        return <MetricMenu {...props} />
    }
    else if (name === Edge.specification.name) {
        return <EdgeMenu {...props} />
    }
    else if (name === Endpoint.specification.name) {
        return <EndpointMenu {...props} />
    }
    else if (name === EndpointPolicy.specification.name) {
        return <EndpointPolicyMenu {...props} />
    }
    else if (name === Destination.specification.name) {
        return <DestinationMenu {...props} />
    }
    else {
        return <DefaultMenu {...props} />
    }
};
