import React, {FC, useEffect} from "react";
import {getAttributeSchema, SingleResultView, SingleResultViewSection} from "./OutputDataTable";
import {JSONEditor} from "../forms/JSONEditor";
import {Selected} from "../../../../model/Selected";

type JSONObject = {[index: string]: any};


export interface VerticalDataTableProps {
    result: JSONObject;
    schema: JSONObject;
    className?: string;
    selected: Selected;
    schemaViewIndex?: number;
}

export const VerticalDataTable: FC<VerticalDataTableProps> = (props) => {
    const {result, schema, className, selected, schemaViewIndex} = props;
    const singleResultView = schemaViewIndex !== undefined ? schema?.views?.singleResult[schemaViewIndex] : schema?.views?.singleResult;
    const singleResultViewSections = singleResultView?.sections;
    const noLabels = schema?.views?.singleResult?.noLabels;
    const usePreTags = schema?.views?.singleResult?.usePreTags;

    return (
        <div className={className}>
            {singleResultViewSections && singleResultViewSections.map((section: SingleResultViewSection, x: number) => {
                let attributeSchema: any;

                // Nested Data
                let sectionJSONPath = section.data ? `/$defs/${section.data}` : ``;
                let units: string | undefined;

                // No rows, just a single attribute in data
                if (!section.rows) {
                    const attributeJSONPath = `/properties/${section.data}`;
                    attributeSchema = getAttributeSchema(schema, attributeJSONPath);
                    if(attributeSchema) {
                        units = attributeSchema.unitsShort ? `(${attributeSchema.unitsShort})` : undefined;
                    }
                }

                const width100Class = noLabels ? 'width-100' : '';

                // TODO: Add JSON Viewer

                const getAttributeComponent = (x:number, y:number=0, row?:string) => {
                    let value;

                    if(section.data && result[section.data] && row) {
                        value = result[section.data][row];
                    }
                    else if(!section.data && row) {
                        value = result[row];
                    }
                    else {
                        value = result[section.data];
                    }

                    const parseValue = (value: any, attributeSchema: any) => {
                        if(attributeSchema.type === "boolean") {
                            return value ? "Yes" : "No";
                        }
                        return value;
                    }

                    return (
                        <div key={`output-data-table-section-${x}-row-${y}`} className={'output-data-table-attribute'}>
                            {!noLabels && <div className={'output-data-table-attribute-label'}>
                                {attributeSchema?.shortTitle || attributeSchema?.title || row} {units}
                            </div>}
                            <div className={'output-data-table-attribute-value ' + width100Class}>
                                {attributeSchema && attributeSchema.type !== "json" && usePreTags && <pre>{parseValue(value, attributeSchema)}</pre>}
                                {attributeSchema && attributeSchema.type !== "json" && !usePreTags && parseValue(value, attributeSchema)}
                                {attributeSchema && attributeSchema.type === "json" &&
                                    <JSONEditor
                                        selected={selected}
                                        className={"output-data-json"}
                                        id={"json"}
                                        disabled={true}
                                        onChange={() => {}}
                                        value={value}
                                    />}
                            </div>
                        </div>
                    )
                }

                //console.log("section", section);

                return (
                    <div key={`output-data-table-section-${x}`} className={`output-data-table-section`}>
                        {section.label && <div className={"output-data-table-section-label"}>{section.label}</div>}
                        {section.rows && section.rows.map((row: string, y: number) => {
                            const attributeJSONPath = `${sectionJSONPath}/properties/${row}`;
                            attributeSchema = getAttributeSchema(schema, attributeJSONPath);
                            if(attributeSchema) {
                                units = attributeSchema.unitsShort ? `(${attributeSchema.unitsShort})` : undefined;
                                return getAttributeComponent(x, y, row);
                            }
                            return <></>;
                        })}
                        {!section.rows && getAttributeComponent(x)}
                    </div>
                )
            })}
        </div>
    )
}
