import * as React from "react";

import {FC} from "react";
import {InstanceHomeProps} from "../../../InstanceHome";


const AutomationPolicyHome: FC<InstanceHomeProps> = (props) => {
    return (
        <div className={'instance-home-outer'}>
            <div className={'instance-home-title'}>Automation Policies</div>
            <div className={'instance-home-main-line'}>
                Automation Policies are created to define an Automation to run against an Endpoint.
            </div>
            <div className={'instance-home-sub-line'}>
                Select one on the left or add a new one by clicking the (+)
            </div>
        </div>
    );
}

export default AutomationPolicyHome;

