import * as React from "react";

import {FC, useEffect, useState} from "react";
import {Services} from "../../../../services/Services";
import {Attribute} from "../forms/Attribute";
import {Selected} from "../../../../model/Selected";

type JSONObject = {[index: string]: any};

export interface InputSchemaProps {
    services: Services;
    instance:  { [index: string]: any; };
    setInstance: (editedInstance: { [index: string]: any; }) => void;
    sourceUrl: string; //
    sourceIdAttribute: string; // ID Attribute of the relatedInstance. ie: instance[sourceAttribute]
    schemaAttribute: string; // Schema Attribute on the relatedInstance
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    selectedPage: string;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    policyUrl?: string;
    policyIdAttribute?: string;
    policyInputAttribute?: string;
    selected: Selected;
    schema?:  { [index: string]: any; };
    inputSourceInstance?:  { [index: string]: any; };
}

export const InputSchema: FC<InputSchemaProps> = (props) => {
    const {
        services,
        instance,
        setInstance,
        sourceUrl,
        sourceIdAttribute,
        schemaAttribute,
        relatedSelectCount,
        setRelatedSelectCount,
        getInstances,
        getInstance,
        selectedPage,
        editMode,
        setEditMode,
        policyUrl,
        policyIdAttribute,
        policyInputAttribute,
        selected,
        schema,
        inputSourceInstance,
    } = props;

    const [sourceInstance, setSourceInstance] = useState<JSONObject>();
    const [specification, setSpecification] = useState<JSONObject>();
    const [defaultInputInstance, setDefaultInputInstance] = useState<JSONObject>();
    const [policyInstance, setPolicyInstance] = useState<JSONObject>();
    const [policyInputInstance, setPolicyInputInstance] = useState<JSONObject>();

    const sourceId = instance && instance[sourceIdAttribute] ? instance[sourceIdAttribute] : policyInstance && policyInstance[sourceIdAttribute];


    const getSourceInstance = () => {
        return inputSourceInstance ? inputSourceInstance : sourceInstance;
    }

    const updateSourceInstance = () => {
        if(sourceId) {
            services.data.get(sourceUrl, sourceId)
                .then((res) => {
                    const data = res.data;
                    setSourceInstance(res.data);
                });
        }
    }

    const updatePolicyInstance = () => {
        if (policyUrl && policyIdAttribute && instance[policyIdAttribute]) {
            services.data.get(policyUrl, instance[policyIdAttribute])
                .then((res) => {
                    const data = res.data;
                    setPolicyInstance(data);
                });
        }
    }

    const updateSpecification = () => {
        const sourceInstance = getSourceInstance();
        if(schema || (sourceInstance && sourceInstance[schemaAttribute])) {
            const _schema = schema ? schema : sourceInstance ? sourceInstance[schemaAttribute] : null;
            const specification = getSpecification(_schema);
            setSpecification(specification);
        }
        else {
            setSpecification(undefined);
        }
    }

    const getSpecification = (schema: JSONObject) => {
        const specification: JSONObject = {title: schema.title, attributes: {}};
        if(schema.properties) {
            for (const [attributeName, attribute] of Object.entries(schema.properties)) {
                const _attribute = Object.assign({}, attribute) as JSONObject;
                specification.attributes[attributeName] = _attribute;
                specification.attributes[attributeName].name = attributeName;
                specification.attributes[attributeName].label = _attribute.title;
                specification.attributes[attributeName].style = _attribute.style;
            }
        }
        return specification;
    }

    const updatePolicyInputInstance = () => {
        const sourceInstance = getSourceInstance();
        if(schema || (sourceInstance && sourceInstance[schemaAttribute])) {
            const _schema = schema ? schema : sourceInstance ? sourceInstance[schemaAttribute] : null;
            const _instance: JSONObject = {};
            if(_schema && _schema.properties) {
                for (const [attributeName, attribute] of Object.entries(_schema?.properties)) {
                    if (policyInstance?.inputData) {
                        _instance[attributeName] = policyInstance?.inputData[attributeName];
                    }
                }
            }
            setPolicyInputInstance(_instance);
        }
    }

    useEffect(() => {
        //console.log("useEffect schema", schema);
        updateSpecification();
    }, [schema])

    useEffect(() => {
        //console.log("useEffect instance", instance);
        updatePolicyInstance();
        updateSourceInstance();
        initializeInputData();
    }, [instance]);

    useEffect(() => {
        //console.log("useEffect sourceInstance", sourceInstance);
        updateSpecification();
        setDefaultInputInstance(sourceInstance?.inputData);
        updatePolicyInputInstance();
    }, [sourceInstance]);

    useEffect(() => {
        //console.log("useEffect inputSourceInstance", inputSourceInstance);
        updateSpecification();
        setDefaultInputInstance(inputSourceInstance?.inputData);
    }, [inputSourceInstance]);


    useEffect(() => {
        //console.log("useEffect policyInstance", policyInstance);
        updatePolicyInputInstance();
        updateSourceInstance();
    }, [policyInstance]);

    useEffect(() => {
        //console.log("useEffect specification", specification);
    }, [specification]);

    const handleInstanceSave = () => {

    }

    const initializeInputData = () => {
        if(instance && !instance.inputData) {
            setInstance((prevState: JSONObject) => ({
                ...prevState,
                inputData: {},
            }));
        }
    }

    const handleOnChange = (name: string, value: string, attribute: any) => {
        setInstance((prevState: JSONObject) => {
            const inputData = Object.assign({}, prevState.inputData);
            for(const [name, value] of Object.entries(inputData)) {
                if(value === undefined || value === "") {
                    delete inputData[name];
                }
            }
            if(value === undefined || value === "") {
                delete inputData[name];
            }
            else {
                inputData[name] = value;
            }
            return {
                ...prevState,
                inputData,
            }
        });
    }

    //console.log("instance", instance, "\n", "inputSourceInstance", inputSourceInstance, "\n", "sourceId", sourceId, "\n", "specification", specification, "\n")

    if(!instance || (!sourceId && !inputSourceInstance) || !specification || !policyInputInstance) {
        return <></>
    }

    const columnClassName = 'input-schema-inputs-attribute-50';

    return (
        <div className={"input-schema-container"}>
            <div className={"input-schema-inputs-container"}>
                <div className={"input-schema-inputs-fields"}>
                    <div className={"input-schema-title " + columnClassName}>
                        {specification.title}
                    </div>
                    <div className={"input-schema-inputs-attribute-container"}>

                    {specification && Object.values(specification.attributes).map((attribute: any) => {
                        let placeholder = defaultInputInstance && defaultInputInstance[attribute.name];
                        if(policyUrl && policyInputInstance && policyInputInstance[attribute.name]) {
                            placeholder = policyInputInstance[attribute.name];
                        }
                        if((placeholder === undefined || placeholder === "") && defaultInputInstance) {
                            placeholder = defaultInputInstance[attribute.name];
                        }
                        return (
                            <div key={attribute.name} className={columnClassName} style={attribute.style}>
                                <Attribute
                                    getInstance={getInstance}
                                    key={attribute.name}
                                    instance={instance.inputData || {}}
                                    services={services}
                                    onChange={(name, value) => {
                                        handleOnChange(name, value, attribute)
                                    }}
                                    selected={selected}
                                    specification={specification}
                                    attributeName={attribute.name}
                                    disabled={!editMode}
                                    onInstanceSave={handleInstanceSave}
                                    relatedSelectCount={relatedSelectCount}
                                    setRelatedSelectCount={setRelatedSelectCount}
                                    getInstances={getInstances}
                                    isDetail={false}
                                    selectedPage={selectedPage}
                                    editMode={editMode}
                                    setEditMode={setEditMode}
                                    placeholder={placeholder}
                                    inheritanceEnabled
                                />
                            </div>

                        )
                    })}
                    </div>

                </div>
            </div>
        </div>
    );
}
