import {ItemListRenderer, ItemRenderer, Suggest2} from "@blueprintjs/select";
import {FC, ReactNode, useEffect, useState} from "react";
import {Alignment, Button, Menu, MenuItem} from "@blueprintjs/core";
import * as React from "react";


export interface MenuItemLayout {
    attributeName: string;
    label: string;
    style?: any;
    className?: string;
    modifier?: (value:string) => string;
}


export interface ListSuggestProps {
    instance: any;
    attributeName: string;
    menuItemLayout?: Array<MenuItemLayout>;
    onChange(value: any|null): void;
    disabled?: boolean;
    items: Array<any>;
    labelAttribute?: string;
    children?: React.ReactNode;
    className?: string;
    suggestListAddNew?: (name: string) => any;
    hideLabels?: boolean;
    filterAttributes?: Array<string>;
}

const ObjectSuggest = Suggest2.ofType<any>();

export const ObjectListSuggest: FC<ListSuggestProps> = (props) => {
    const {instance, attributeName, onChange, disabled, items, className, labelAttribute, menuItemLayout, suggestListAddNew, hideLabels, filterAttributes} = props;

    const [query, setQuery] = useState<string>();

    const renderOption: ItemRenderer<any> = (option, {handleClick, modifiers}) => {
        if (!option || !modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                disabled={disabled}
                className={'bp4-text-large object-list-suggest-menu-container'}
                active={modifiers.active}
                key={option[labelAttribute || attributeName]}
                text={
                    <div className={'object-list-suggest-menu-item-container'}>
                        {!menuItemLayout && option[labelAttribute || attributeName]}
                        {menuItemLayout && menuItemLayout.map((layout) => {
                            return (
                                <div style={layout.style as any} className={layout.className as any}>
                                    {layout.modifier ? layout.modifier(option[layout.attributeName]) : option[layout.attributeName]}
                                </div>
                            )
                        })}
                    </div>
                }
                onClick={handleClick}
            />
        );
    };

    const handleQueryChange = (queryChange: string) => {
        setQuery(queryChange);
    }

    const renderOptionValue = (item: any) => {
        let value = "";
        if(menuItemLayout) {
            for(const layout of menuItemLayout) {
                if(item[layout.attributeName]) {
                    if(value != "") {
                        value += "   |   ";
                    }
                    value += layout.modifier ? layout.modifier(item[layout.attributeName]) : item[layout.attributeName];
                }
            }
            return value;
        }
        else {
            if (item) {
                return item[labelAttribute || attributeName];
            }
            else {
                return "";
            }
        }
    }

    const renderMenu: ItemListRenderer<any> = ({ items, itemsParentRef, query, renderItem }) => {
        const renderedItems = items.map(renderItem).filter(item => item != null);
        return (
            <Menu
                className={'custom-select'}
                ulRef={itemsParentRef}>
                <MenuItem
                    className={'related-select-menu-item'}
                    disabled={true}
                    text={(
                        <div className={'flex-row-div'}>
                            {menuItemLayout?.map((menuItemLayout: MenuItemLayout) => {
                                return <div key={menuItemLayout.attributeName} style={menuItemLayout.style} className={menuItemLayout.className}>{menuItemLayout.label}</div>
                            })}
                        </div>
                    )}
                />
                {renderedItems}
            </Menu>
        );
    };

    const onItemSelect = (item: any) => {
        onChange(item)
        setQuery("");
    }

    const itemPredicate = (query: string, item: any, index?: undefined | number) => {
        if(filterAttributes) {
            for(const filterAttribute of filterAttributes) {
                if(item[filterAttribute] && item[filterAttribute].toLowerCase().includes(query.toLowerCase())) {
                    return true;
                }
            }
        }
        return String(item[labelAttribute || attributeName]).toLowerCase().includes(query.toLowerCase());
    }

    const renderCreateOption = (
        query: string,
        active: boolean,
        handleClick: React.MouseEventHandler<HTMLElement>,
    ) => (
        <MenuItem
            icon="add"
            text={`Add new "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    );

    useEffect(() => {
        if(instance) {
            console.log("instance", instance.name);
        }
    }, [instance])

    return (
        <ObjectSuggest
            popoverProps={{matchTargetWidth: true, usePortal: false}}
            popoverContentProps={{className: 'list-popover-content-normal'}}
            className={'object-list-suggest ' + className}
            {...props as any}
            inputValueRenderer={renderOptionValue}
            inputProps={{id: 'ObjectSuggest' + className + attributeName}}
            disabled={disabled}
            closeOnSelect
            createNewItemFromQuery={suggestListAddNew}
            createNewItemRenderer={suggestListAddNew ? renderCreateOption : undefined}
            noResults={<MenuItem className={'bp4-text-small'} disabled={true} text={"No results."}/>}
            query={query}
            onQueryChange={handleQueryChange}
            fill
            activeItem={instance || ""}
            selectedItem={instance || ""}
            key={'ObjectSuggest' + className + attributeName}
            itemRenderer={renderOption}
            itemListRenderer={!hideLabels ? renderMenu : undefined}
            items={items}
            itemPredicate={itemPredicate}
            onItemSelect={onItemSelect}
        />
    )
}
