import * as React from "react";

import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {BaseInstanceEdit, BaseInstanceEditProps} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";


const DiscoveredEdgeEdit: FC<BaseInstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(DiscoveredEdge.getKey())}
            isReadOnly={true}
        >
            <InstanceEditTabs>
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "25%"}} name={DiscoveredEdge._created} />
                        <TabAttribute style={{width: "25%"}} name={DiscoveredEdge._status} />
                        <TabAttribute style={{width: "25%"}} name={DiscoveredEdge._connected} />
                        <TabAttribute style={{width: "25%"}} name={DiscoveredEdge._edgeActivated} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={DiscoveredEdge._lastActivationRequestStatus} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={DiscoveredEdge._SSHPublicKey} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={DiscoveredEdge._edgeInfo} />
                    </TabAttributeRow>
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default DiscoveredEdgeEdit;

