import * as React from "react";
import {FC, ReactNode} from "react";

export interface TabAttributeRowProps {
    children?: ReactNode;
}

export const TabAttributeRow: FC<TabAttributeRowProps> = (props) => {
    return (
        <>
            {props.children}
        </>
    );
}
