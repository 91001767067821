import * as React from "react";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const EndpointList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Endpoint._name,
                    columnWidth: 120
                }),
                new InstanceListAttribute({
                    name: Endpoint._associatedEndpointPolicyName,
                    columnWidth: 80
                }),
            ]}
        />
    );
};

