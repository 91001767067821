import * as React from "react";

import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {EndpointPolicy} from "raasify-models-specification-ts/core/EndpointPolicy";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {InstanceEditProps} from "../../../InstanceEdit";
import {BaseInstanceEdit} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {EndpointTemplate} from "raasify-models-specification-ts/core/EndpointTemplate";
import {InputSchema} from "../../../../../common/components/workbench/common/schema/InputSchema";
import {getFromInputs} from "../../../../../common/utils/instanceUtil";


const EndpointPolicyEdit: FC<InstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(EndpointPolicy.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "33%"}} name={Endpoint._name} />
                        <TabAttribute
                            style={{width: "33%"}}
                            name={Endpoint._associatedEndpointTemplateId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(EndpointTemplate._name, 5),
                                new TabAttributeRelatedAttribute(EndpointTemplate._description, 7),
                            ]}
                        />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Endpoint._description} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "25%"}} name={EndpointPolicy._retentionMode} />
                        <TabAttribute style={{width: "25%"}} name={EndpointPolicy._retentionValue} default={(inputs: any) => {
                            const endpointTemplate = getFromInputs(EndpointPolicy._associatedEndpointTemplateId, inputs);
                            if(endpointTemplate && inputs.instance && inputs.prevInstance && inputs.instance.associatedEndpointTemplateId != inputs.prevInstance.associatedEndpointTemplateId) {
                                // Only set on Automation Template change
                                return "1";
                            }
                        }}/>
                        <TabAttribute style={{width: "25%"}} name={EndpointPolicy._overrideDiscoveryInterval} />
                        <TabAttribute style={{width: "25%"}} name={EndpointPolicy._applicationDiscoveryInterval} hidden={inputs => !inputs.instance.overrideDiscoveryInterval} />
                    </TabAttributeRow>

                    <InputSchema
                        {...props}
                        sourceUrl={EndpointTemplate.getUrl()}
                        sourceIdAttribute={EndpointPolicy._associatedEndpointTemplateId}
                        schemaAttribute={EndpointTemplate._inputSchema}
                    />
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default EndpointPolicyEdit;

