import React, {FC, useEffect, useReducer, useRef, useState} from "react";
import {DashboardList} from "../../common/components/dashboard/widgets/DashboardList";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {useAppSelector} from "../../common/redux/hooks";
import {Services} from "../../common/services/Services";
import {Selected} from "../../common/model/Selected";
import {InstanceListFilter} from "../../common/model/InstanceListFilter";
import {EndpointTemplate} from "raasify-models-specification-ts/core/EndpointTemplate";
import {Button, Classes, Intent, Menu, MenuItem, Spinner} from "@blueprintjs/core";
import {Popover2} from "@blueprintjs/popover2";

export interface DashboardProps {
    selectedDeployment: Deployment|undefined;
    setLoading(status: boolean): void;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void, instanceListFilter?: InstanceListFilter, pagination?: string, useFilterAnd?: boolean) => void;
    services: Services;
    selected: Selected;
}

export const Dashboard: FC<DashboardProps> = (props) => {
    const instances = useAppSelector((state) => state.instances);

    const {
        getInstances,
        selectedDeployment,
        setLoading,
        services,
        selected,
    } = props;

    const [dashboardLoading, setDashboardLoading] = useState<boolean>(true);
    const [dashboards, setDashboards] = useState<Array<any>>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<any>();
    const [queryData, setQueryData] = useState<any>({});

    const getDashboards = (endpointTemplates: any) => {
        let _selectedDashboard;
        const dashboards: any = {};
        if(endpointTemplates) {
            for (const endpointTemplate of endpointTemplates) {
                const viewMap = endpointTemplate.outputSchema?.dashboard?.view;
                if (viewMap) {
                    for (const [dashboardName, dashboard] of Object.entries(viewMap)) {
                        const _dashboard = {
                            ...dashboard as any,
                            associatedEndpointTemplateId: endpointTemplate.id,
                        }
                        dashboards[`${endpointTemplate.id}${dashboardName}`] = _dashboard;
                        if (!selectedDashboard && !_selectedDashboard) {
                            _selectedDashboard = _dashboard;
                            setSelectedDashboard(_dashboard)
                        }
                    }
                }
            }
            setDashboards(dashboards);
        }
    }

    const loadDashboard = () => {
        if(selectedDashboard) {
            for(const component of selectedDashboard.components) {
                services.data.get(
                    `endpointtemplates/${selectedDashboard.associatedEndpointTemplateId}/queries/${component.query}`,
                )
                .then((data) => {
                    setQueryData((prevState: any) => ({...prevState, [component.query]: [...data.data]}));
                })
            }
        }
        setDashboardLoading(false);
    }

    const loadInstances = () => {
        if(selectedDeployment) {
            getInstances(EndpointTemplate.specification, (data) => {
                getDashboards(data);
                if(selectedDashboard) {
                    loadDashboard();
                }
            });
        }
    }

    const dashboardMenuItems = (
        <>
            <Menu className="bp4-menu bp4-elevation-1">
                {dashboards && Object.entries(dashboards).map(([key, dashboard]) => {
                    return (
                        <MenuItem
                            key={key}
                            className="bp4-text-small"
                            text={dashboard.label}
                            icon="panel-table"
                            active={dashboard === selectedDashboard}
                            onClick={() => setSelectedDashboard(dashboard)}
                        >
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )

    useEffect(() => {
        if(selectedDashboard) {
            loadDashboard();
            const interval = setInterval(()=> {
                loadDashboard();
            },30_000)
            return() => clearInterval(interval);
        }
    }, [selectedDashboard])

    useEffect(() => {
    }, [queryData])

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        loadInstances();
        setLoading(false)
    }, [selectedDeployment])

    if(dashboardLoading) {
        return <Spinner className={"dashboard-spinner"} intent={Intent.PRIMARY} size={60}/>
    }

    if(!selectedDeployment) {
        return <></>
    }

    return (
        <div>
            <div className={"dashboard-selector"}>
                {selectedDeployment && <Popover2
                    placement="bottom"
                    content={dashboardMenuItems}
                >
                    <div>
                        <Button
                            className={"dashboard-selector-button"}
                            rightIcon="caret-down"
                            text={selectedDashboard?.label}
                        />
                    </div>
                </Popover2>}
            </div>
            <div className={"dashboard-container"}>
                {selectedDashboard && selectedDashboard.components && selectedDashboard.components.map((component: any) => {
                    const data = queryData[component.query];
                    if(data) {
                        return (
                            <DashboardList
                                className={"dashboard-widget"}
                                titleClassName={"dashboard-widget-title"}
                                tableClassName={'dashboard-data-table'}
                                title={component.title}
                                instances={data}
                                specColumns={component.columns}
                            />
                        )
                    }
                })}
            </div>

        </div>
    );
}
