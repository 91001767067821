import * as React from "react";
import {CronSchedule} from "raasify-models-specification-ts/core/CronSchedule";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const CronScheduleList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: CronSchedule._name,
                    columnWidth: 280
                }),
                new InstanceListAttribute({
                    name: CronSchedule._cronEntry,
                    columnWidth: 280,
                }),
            ]}
        />
    );
};
