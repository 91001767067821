import React, {FC, useEffect, useRef, useState} from "react";
import {Column, Cell, Table, SelectionModes, ColumnHeaderCell, IRegion as Region} from "@blueprintjs/table";
import moment from "moment";
import {ListAttribute} from "../../../../model/ListAttribute";
import {getIndex} from "../../../../utils/instanceUtil";

type JSONObject = {[index: string]: any};

interface OutputListTableProps {
    instances: Array<JSONObject>;
    onSelect: (instanceId: string) => void;
    listAttributes: Array<ListAttribute>;
    selectedResult: JSONObject|undefined,
    className: string;
    title?: string;
}

export const OutputListTable: FC<OutputListTableProps> = (props) => {
    const {className, instances, onSelect, listAttributes, selectedResult, title} = props;

    const [columnWidths, setColumnWidths] = useState<Array<number>>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 38 && selectedIndex > 0) {
            // Up
            setSelectedIndex(prevState => (prevState - 1))
        }
        else if (e.keyCode === 40 && selectedIndex < instances.length - 1) {
            // Down
            setSelectedIndex(prevState => (prevState + 1))
        }
    }

    const initialColumnState = [];
    for(const a of listAttributes) {
        initialColumnState.push(100);
    }

    const table = useRef(null);

    const getInitialColumnWidths = () => {
        const _columnWidths = [];
        for(const col of getColumns()) {
            _columnWidths.push(col.columnWidth);
        }
        return _columnWidths;
    }

    useEffect(() => {
        setColumnWidths(getInitialColumnWidths());
    }, [])

    const cellRenderer = (rowIndex: number, attributeName: string) => {
        let value = instances && instances[rowIndex][attributeName];

        // Handle Boolean
        if (typeof value == "boolean") {
            value = value ? 'Yes' : 'No';
        }

        // Convert Datetime
        if (String(value).match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+Z/)) {
            value = moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss.SSS');
        }

        const cellClass = instances && instances[rowIndex].new ? 'log-cell-new' : 'log-cell';

        return (
            <Cell wrapText>
                <div
                    className={cellClass}
                    onClick={(e) => setSelectedIndex(rowIndex)}
                >
                    {value}
                </div>
            </Cell>
        );
    };

    if(!listAttributes || !instances) {
        return <></>;
    }

    let region:Region[] = [];

    if(typeof selectedIndex === "number") {
        region = [
            {
                "rows": [
                    selectedIndex,
                    selectedIndex
                ]
            }
        ];
    }

    const getColumns = () => {
        const columns:Array<{label:string|undefined, attribute:string, columnWidth: number}> = [];
        listAttributes.map((attribute) => {
            let _attribute: string;
            let _label: string | undefined;
            let _columnWidth: number = 100;

            _attribute = attribute.name;
            _label = attribute.label;
            _columnWidth = attribute.columnWidth;

            columns.push({
                attribute: _attribute,
                label: _label,
                columnWidth: _columnWidth,
            });
        });
        return columns;
    }

    const getRowHeights = (columns: Array<{ label: string | undefined; attribute: string; filter: string | undefined, columnWidth: number }>) => {
        const rowHeights: Array<number> = [];
        const ROW_HEIGHT = 20;
        const COL_LENGTH = 100;
        const CHAR_WIDTH = 6.7;

        for(const instance of instances) {
            let height = ROW_HEIGHT;
            let i=0;
            for(const col of columns) {
                const colLength = columnWidths ? columnWidths[i] : COL_LENGTH;
                let valueLength = instance[col.attribute]?.length;
                if(valueLength) {
                    valueLength += 3 // for padding
                    const _height = ROW_HEIGHT * Math.max(1, Math.ceil(valueLength/(colLength/CHAR_WIDTH)));
                    height = Math.max(height, _height);
                }
                i++;
            }
            rowHeights.push(height);
        }
        return rowHeights;
    }

    const handleColumnWidthChanged = (index: number, size: number) => {
        if(columnWidths) {
            const _columnWidths = [];
            for(const columnWidth of columnWidths) {
                _columnWidths.push(columnWidth);
            }
            _columnWidths[index] = size;
            setColumnWidths(_columnWidths);
        }
    }

    const columns = getColumns();
    const rowHeights = getRowHeights(columns as any);

    useEffect(() => {
        if(instances && instances[selectedIndex]) {
            onSelect(instances[selectedIndex].id);
        }
    }, [selectedIndex])

    useEffect(() => {
        const newIndex = getIndex(instances, selectedResult?.id);
        if(newIndex !== undefined) {
            setSelectedIndex(newIndex);
        }
    }, [selectedResult, instances])

    useEffect(() => {
        setColumnWidths(getInitialColumnWidths());
    }, [])

    if(!columnWidths || columnWidths.length !== columns.length) {
        return <></>
    }

    return (
        <div className={className} onKeyDown={handleKeyDown}>
            {title && <div className={"output-table-title"}>{title}</div>}
            <Table
                className={"output-list-table"}
                onColumnWidthChanged={handleColumnWidthChanged}
                enableRowHeader={true}
                enableRowResizing={false}
                enableColumnResizing={true}
                numRows={instances.length}
                selectionModes={SelectionModes.ROWS_AND_CELLS}
                enableMultipleSelection={false}
                selectedRegions={region}
                rowHeights={rowHeights}
                columnWidths={columnWidths}
                //bodyContextMenuRenderer={contextRenderer}
            >
                { columns.map((col) => {
                    const columnHeaderCellRenderer = () => {
                        return (
                            <ColumnHeaderCell
                                className={'instance-list-header'}
                                name={col.label}
                            />
                        )
                    }

                    return (
                        <Column
                            key={col.attribute}
                            className={'output-list-table'}
                            name={col.label}
                            cellRenderer={(rowIndex) => cellRenderer(rowIndex, col.attribute)}
                            columnHeaderCellRenderer={columnHeaderCellRenderer}
                        />
                    )
                })}
            </Table>
        </div>
    );
};
