import React, {FC, useEffect, useRef, useState} from "react";

// @ts-ignore
import io, {Socket} from "socket.io-client";
import {InstanceLog} from "../model/InstanceLog";
import {AppToaster} from "../../AppToaster";
import {Intent} from "@blueprintjs/core";
import {DataService} from "./DataService";
import UserService from "./UserService";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {instancesSlice, services} from "../redux/slices/instances";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {logsSlice} from "../redux/slices/logs";
import {InstanceListFilter} from "../model/InstanceListFilter";
import {SERVICE_MAP} from "./Services";

type JSONObject = {[index: string]: any};

export interface EventServiceProps {
    setLogs?: (logs: Array<InstanceLog>) => void | undefined;
    service: string;
    dataService: DataService;
    deployment?: Deployment|undefined;
    deploymentMode?: boolean;
    instanceFilters: {[index: string]: string};
    defaultInstancesKey?: string;
    defaultInstancesIdAttribute?: string
}

const queryStringToJSON = (params: string) => {
    const pairs: string[] = params.split('&');
    const result: JSONObject = {};
    pairs.forEach((pair) => {
        const pairArray = pair.split('=');
        result[pairArray[0]] = pairArray[1];
    });
    return result;
}


const modifierFilter = (item: {[index: string]: {}}, instanceListFilter?: InstanceListFilter) => {
    if(instanceListFilter) {
        for(const _instanceListFilter of [instanceListFilter.transient, instanceListFilter.db]) {
            if (_instanceListFilter) {
                const _filter = queryStringToJSON(_instanceListFilter);
                for (const [key, value] of Object.entries(_filter)) {
                    if (item[key].toString() !== value) {
                        return false;
                    }
                }
            }
        }
    }
    return true;
}

export const EventService: FC<EventServiceProps> = (props) => {
    const {
        service,
        deployment,
        deploymentMode,
        instanceFilters,
        defaultInstancesKey,
        defaultInstancesIdAttribute,
        dataService,
    } = props;

    const instances = useAppSelector((state) => state.instances)
    const logs = useAppSelector((state) => state.logs)
    const dispatch = useAppDispatch();

    const [socket, setSocket] = useState<Socket|undefined>();

    const instanceFiltersRef:{ [index: string]: any; } = useRef();
    instanceFiltersRef.current = instanceFilters;

    const instancesRef:{ [index: string]: any; } = useRef();
    instancesRef.current = instances;

    const connect = () => {
        if(socket) {
            socket?.close();
        }
        const socket_io_path = `/socket.io`;

        const headers: {[key:string]: string} = {
            Authorization: "Bearer " + UserService.getToken(),
        }

        // connect to both user-logic and core-logic events
        headers['x-service'] = service;
        if(deployment) {
            headers['x-deployment-id'] = deployment.id as string;
        }

        const _socket = io({
            path: socket_io_path,
            extraHeaders: headers,
        });
        // @ts-ignore
        _socket.on('event', events => {
            //console.log('on event', events);
            for (const event of events) {
                onEvent(event);
            }
        });
        if(logs) {
            // @ts-ignore
            _socket.on('log', logs => {
                //console.log('on log', logs);
                for (const log of logs) {
                    onLog(log);
                }
            });
        }
        _socket.on('connecting', function () {
            console.info(`${socket_io_path} connecting ${service}`);
        });
        _socket.on('disconnect', function () {
            console.info(`${socket_io_path} disconnect ${service}`);
        });
        _socket.on('connect_failed', function() {
            console.info(`${socket_io_path} connect_failed ${service}`);
        })
        _socket.on('error', function() {
            console.info(`${socket_io_path} error ${service}`);
        })
        _socket.on('reconnect', function() {
            console.info(`${socket_io_path} reconnect ${service}`);
        })
        _socket.on('reconnecting', function() {
            console.info(`${socket_io_path} reconnecting ${service}`);
        })
        _socket.on('reconnect_failed', function() {
            console.info(`${socket_io_path} reconnect_failed ${service}`);
        })
        _socket.on('connect', function () {
            console.info(`${socket_io_path} connected ${service}`);
        });

        setSocket(_socket);
    }

    useEffect(() => {
        // on mount
        if(!deploymentMode) {
            connect();
        }
    }, []);

    useEffect(() => {
        // on deployment change
        if(deployment && deploymentMode) {
            connect();
        }
    }, [deployment]);

    const onLog = (log: any): void => {
        //console.log('New Log:', log);
        console.debug('New Log:\n%s', JSON.stringify(log, null, 4));

        let from = log.from;
        if (log.from && defaultInstancesKey && defaultInstancesIdAttribute && instancesRef.current[defaultInstancesKey]) {
            // try to look it up in instances and swap with name if exists;
            for (const instance of instancesRef.current[defaultInstancesKey]?.instances) {
                if (instance[defaultInstancesIdAttribute] === log.from && instance.name) {
                    from = instance.name;
                }
            }
        }
        dispatch(logsSlice.actions.add({data: {...log, from}}));
    }

    const checkFilter = (specification: string, data: any) => {
        const instanceFilter = instanceFiltersRef.current[specification];
        const res = instanceFilter ? modifierFilter(data, instanceFilter) : true;
        //console.log("checkFilter", specification, res, data, instanceFilter);
        return res;
    }

    const onEvent = (event: any) => {
        console.debug('onEvent New Event:\n%s', JSON.stringify(event, null, 4));
        if(event && event.data && !event.error) {
            if(!SERVICE_MAP[service]) {
                console.error('Error processing event. ' +
                    'Service Map entry not found: %s\n' +
                    'event.data.service: %s\n' +
                    'SERVICE_MAP[service]: %s\n' +
                    'SERVICE_MAP: %s\n' +
                    'event:\n%s',
                    service,
                    event.data.service,
                    SERVICE_MAP[service],
                    JSON.stringify(SERVICE_MAP),
                    JSON.stringify(event, null, 4)
                );
            }
            else if(event.data.service === SERVICE_MAP[service]) {
                console.debug('New Event:\n%s', JSON.stringify(event, null, 4));
                const specification = event.data.service + '.' + event.data.specification;
                const url = event.data.url;
                const data = event.data;

                if (event.operation === 'CREATE') {
                    // Check the Instance Filters in case we are filtering out this create event
                    if (checkFilter(specification, data)) {
                        dispatch(instancesSlice.actions.create({key: specification, data}));
                    }
                } else if (event.operation === 'UPDATE') {
                    dispatch(instancesSlice.actions.update({key: specification, data}));
                } else if (event.operation === 'DELETE') {
                    dispatch(instancesSlice.actions.remove({key: specification, data}));
                } else if (event.operation === 'REFRESH') {
                    //console.log("EVENT REFRESH", event);
                    dataService.get(url).then((res) => {
                        const data = res.data;
                        dispatch(instancesSlice.actions.replace({key: specification, data}));
                    })
                }
                if (event && event.error) {
                    console.error('New Event:\n%s', JSON.stringify(event, null, 4));
                }
            }
        }
    }

    return null;
}
