import * as React from "react";
import {FC} from "react";
import {InstanceListProps} from "../../../InstanceList";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {logo} from "./InstanceListUtil";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";


export const AutomationPolicyList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}

            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const automationPolicy = instance as AutomationPolicy;
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(automationPolicy)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 5,
                                }}
                            >
                                {automationPolicy.schedule}
                            </div>

                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{automationPolicy.name}</div>
                                <div className={"instance-list-card-attribute"}>{automationPolicy.automationTemplatePackageLabel}</div>
                            </div>
                        </>
                    )
                }
            }
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: AutomationPolicy._name,
                }),
                new InstanceListAttribute({
                    name: AutomationPolicy._automationTemplatePackageLabel,
                }),
                new InstanceListAttribute({
                    name: AutomationPolicy._schedule,
                }),
            ]}
        />
    );
};
