import * as React from "react";
import {Edge} from "raasify-models-specification-ts/core/Edge";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const EdgeList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Edge._name,
                    columnWidth: 110
                }),
                new InstanceListAttribute({
                    name: Edge._activated,
                    columnWidth: 70,
                }),
                new InstanceListAttribute({
                    name: Edge._connected,
                    columnWidth: 80,
                }),
                new InstanceListAttribute({
                    name: Edge._inProduction,
                    columnWidth: 90,
                }),
            ]}
        />
    );
};

