import React, {FC} from 'react';
import {Services} from "../../common/services/Services";
import {InstanceJob} from "../../common/model/InstanceJob";
import {AutomationJobs} from "./workbench/components/jobs/AutomationJobs";
import {EdgeJobs} from "./workbench/components/jobs/EdgeJobs";
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {ActivationRequestJobs} from "./notifications/components/jobs/ActivationRequestJobs";
import {DiscoveredEdgeJobs} from "./notifications/components/jobs/DiscoveredEdgeJobs";

export interface InstanceJobsProps {
    specification: {[index: string]: any};
    instance: {[index: string]: any}|undefined;
    services: Services;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    onJob: (job: InstanceJob, parameters?: any) => void;
    selectedPage?: string;
    children?: React.ReactNode;
}


export const InstanceJobs: FC<InstanceJobsProps> = (props) => {
    const name = props.specification.name;

    return (
        <>
            {name === ActivationRequest.specification.name && <ActivationRequestJobs {...props} />}
            {name === Automation.specification.name && <AutomationJobs {...props} />}
            {name === DiscoveredEdge.specification.name && <DiscoveredEdgeJobs {...props} />}
            {name === Edge.specification.name && <EdgeJobs {...props} />}
        </>
    );
};
