import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LogMessageData} from "common-models-ts";

type JSONObject = {[index: string]: any};

interface LogActionPayload {
    data: JSONObject;
}

interface ReplaceLogsPayload {
    data: Array<JSONObject>;
    filterId?: string;
}

type Logs = Array<JSONObject>;

interface LogState {
    logs: Logs,
    filterId?: string,
}

export enum LogsAction {
    Add = 'add',
}

const initialState: LogState = {
    logs: []
}

export const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        add(state, action: PayloadAction<LogActionPayload>) {
            const logMessage: LogMessageData = action.payload.data as LogMessageData;
            let filter = false;
            if(state.filterId) {
                if (logMessage.related) {
                    for (const related of logMessage.related) {
                        if(related.id === state.filterId) {
                            filter = true;
                        }
                    }
                }
            }
            else {
                filter = true;
            }
            if(filter) {
                //console.log("newLog", logMessage);
                state.logs.unshift(logMessage);
            }
            else {
                //console.log("newLog filtered out", logMessage);
            }
        },
        clearAll(state) {
            //console.log("Clearing logs");
            state.logs.splice(0, state.logs.length)
            state.filterId = undefined;
        },
        replaceAll(state, action: PayloadAction<ReplaceLogsPayload>) {
            state.logs.splice(0, state.logs.length);
            state.filterId = action.payload.filterId;
            for(const log of action.payload.data) {
                state.logs.push(log);
            }
        }
    },
})

export const {add, clearAll} = logsSlice.actions;

export default logsSlice.reducer;
