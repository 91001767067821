import * as React from "react";

import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
// @ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Icon, Intent} from "@blueprintjs/core";
import {Services} from "../../../../services/Services";
import {Attribute} from "../forms/Attribute";
import {AppToaster} from "../../../../../AppToaster";
import {TabAttributeRow, TabAttributeRowProps} from "../../instance/edit/TabAttributeRow";
import {Selected} from "../../../../model/Selected";
import {TabAttribute} from "../../instance/edit/TabAttribute";
import {Tooltip2} from "@blueprintjs/popover2";

type JSONObject = {[index: string]: any};

export interface DefaultPanelProps {
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    instance: { [index: string]: any; };
    services: Services;
    specification: {[index: string]: any}|null;
    disabled?: boolean;
    useAttributeNamesAsLabels?: boolean;
    onAttributeChange?: (name: string, value: any) => void;
    onInstanceSave: (specification: {[index: string]: {}}, instance: {[index: string]: {}}, response: (res: string, err: string) => void) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    isDetail? : boolean;
    loading? : boolean;
    selectedPage: string;
    instanceEditTab: ReactElement|undefined;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    selected: Selected;
    className?: string;
}

export const DefaultPanel: FC<DefaultPanelProps> = (props) => {
    const {
        getInstances,
        instance,
        services,
        specification,
        disabled,
        useAttributeNamesAsLabels,
        onAttributeChange,
        onInstanceSave,
        relatedSelectCount,
        setRelatedSelectCount,
        isDetail,
        loading,
        selectedPage,
        instanceEditTab,
        editMode,
        setEditMode,
        selected,
        className,
        getInstance,
    } = props;

    const [inputs, setInputs] = useState<JSONObject>({});

    const handleCopyAttribute = (attribute: string, value: string) => {
        AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: `Copied '${attribute}' value to the clipboard`,
        });
    }

    const handleCopyJSON = () => {
        AppToaster.show({
            icon: "tick",
            intent: Intent.SUCCESS,
            message: `Copied instance JSON to the clipboard`,
        });
    }

    const convertTextToCopy = (attribute: string, value: string) => {
        const attributeType = specification?.attributes[attribute]?.type;
        if(attributeType === 'json') {
            return JSON.stringify(value, null, 4);
        }
        return value;
    }

    const handleAttributeChange = (name: string, value: string) => {
        setInputs((prevState: JSONObject) => ({
            ...prevState,
            instance: {
                ...prevState.instance,
                [name]: value,
            },
            prevInstance: {
                ...prevState.instance
            }
        }))
        onAttributeChange && onAttributeChange(name, value);
    }

    const updateDefaults = () => {
        // Call default function to update defaults (ie. name/description auto gen)
        if(inputs?.instance && editMode) {
            for (const tabAttributeRow of tabAttributeRowList) {
                const tabAttributeList = Array.isArray(tabAttributeRow.props.children) ? tabAttributeRow.props.children : [tabAttributeRow.props.children];
                for (const tabAttribute of tabAttributeList) {
                    if (onAttributeChange && tabAttribute?.props?.default) {
                        const defaultValue = tabAttribute.props.default(inputs);
                        if (defaultValue !== undefined) {
                            onAttributeChange(tabAttribute.props.name, defaultValue);
                        }
                    }
                }
            }
        }
    }

    const setRelatedInstance = (relatedInstance: JSONObject) => {
        setInputs((prevState: JSONObject) => ({
            ...prevState,
            [relatedInstance?.id]: relatedInstance,
        }))
    }

    useEffect(() => {
        setInputs((prevState: JSONObject) => ({
            ...prevState,
            instance,
        }))
    }, [selected])

    const isDetailDivClassName = isDetail ? 'details-copied-attribute-div' : '';

    const tabAttributeRowList = instanceEditTab && Array.isArray(instanceEditTab.props.children)
        ? instanceEditTab.props.children : instanceEditTab ? [instanceEditTab?.props.children] : [];

    useEffect(() => {
        updateDefaults();
    }, [inputs]);

    let firstAttribute = true;

    if(!instance) {
        return <></>
    }

    return (
        <div className={className}>
            {isDetail &&
                <div className={"details-copied details-copy-json"}>
                    <Tooltip2
                        content={<div className={'tooltip-content'}>Copy JSON to clipboard</div>}
                        openOnTargetFocus={false}
                        placement="right-start"
                        usePortal={false}
                    >
                        <CopyToClipboard
                            text={JSON.stringify(instance, null, 4)}
                            onCopy={
                                // @ts-ignore
                                value => handleCopyJSON()
                            }>
                            <Icon
                                className={"details-clipboard-button"}
                                icon="duplicate"
                            />
                        </CopyToClipboard>
                    </Tooltip2>
                </div>
            }
            <div>
                { tabAttributeRowList && tabAttributeRowList.map((tabAttributeRow: ReactElement, x: number) => {
                    if((tabAttributeRow.type as FC)?.name === TabAttributeRow.name) {
                        const tabAttributeList = Array.isArray(tabAttributeRow.props.children) ? tabAttributeRow.props.children : [tabAttributeRow.props.children];
                        return (
                            <div key={`tab-attribute-row-${x}`} className={'tab-attribute-row'}>
                                { tabAttributeList && tabAttributeList.map((tabAttribute: ReactElement, y: number) => {
                                    if(tabAttribute && (tabAttribute.type as FC)?.name === TabAttribute.name) {
                                        if (tabAttribute.props.disabled && editMode) {
                                            return <div key={String(100 * x + y)}></div>
                                        }
                                        if (inputs.instance && tabAttribute.props.hidden && tabAttribute.props.hidden(inputs)) {
                                            return <div key={String(100 * x + y)}></div>
                                        }

                                        const _attribute = tabAttribute.props.name;

                                        const attributeComponent = (
                                            <div key={`tab-attribute-row-${x}-${y}`} className={'tab-attribute'} style={tabAttribute.props.style}>
                                                <Attribute
                                                    selected={selected}
                                                    focus={firstAttribute}
                                                    key={_attribute}
                                                    useAttributeNamesAsLabels={useAttributeNamesAsLabels}
                                                    instance={instance}
                                                    services={services}
                                                    onChange={handleAttributeChange}
                                                    specification={specification}
                                                    attributeName={_attribute}
                                                    disabled={disabled || loading || tabAttribute.props.disabled || !editMode}
                                                    tabAttribute={tabAttribute}
                                                    onInstanceSave={onInstanceSave}
                                                    relatedSelectCount={relatedSelectCount}
                                                    setRelatedSelectCount={setRelatedSelectCount}
                                                    getInstances={getInstances}
                                                    getInstance={getInstance}
                                                    isDetail={isDetail}
                                                    selectedPage={selectedPage}
                                                    editMode={editMode}
                                                    setEditMode={setEditMode}
                                                    onSelectedRelatedInstance={setRelatedInstance}
                                                    nullLabel={tabAttribute.props.nullLabel}
                                                    sort={tabAttribute.props.sort}
                                                    inputs={inputs}
                                                />
                                            </div>
                                        )

                                        firstAttribute = false;

                                        // For Details and Edit in readonly mode: Only include it if it exists in REST
                                        if ((isDetail || !editMode) && instance && Object.keys(instance).includes(_attribute)) {
                                            return attributeComponent;
                                        } else if (!isDetail) {
                                            return attributeComponent;
                                        }
                                    }
                                    else {
                                        // Custom component here
                                        return <div key={`tab-attribute-row-${x}-${y}`}>{tabAttribute}</div>;
                                    }
                                })}
                            </div>
                        )
                    }
                    else {
                        // Custom component here
                        return <div key={`tab-attribute-row-${x}`}>{tabAttributeRow}</div>;
                    }

                })}
            </div>
        </div>
    );
};
