import React, {FC} from "react";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {ActivationRequest} from "raasify-models-specification-ts/core/ActivationRequest";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {Metric} from "raasify-models-specification-ts/core/Metric";
import {Edge} from "raasify-models-specification-ts/core/Edge";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {ActivationRequestListMenu} from "./notifications/components/listmenu/ActivationRequestListMenu";
import {Selected} from "../../common/model/Selected";
import {Services} from "../../common/services/Services";
import {AutomationListMenu} from "./workbench/components/listmenu/AutomationListMenu";
import {AutomationPolicyListMenu} from "./workbench/components/listmenu/AutomationPolicyListMenu";
import {DestinationPolicyListMenu} from "./workbench/components/listmenu/DestinationPolicyListMenu";
import {MetricListMenu} from "./workbench/components/listmenu/MetricListMenu";
import {EdgeListMenu} from "./workbench/components/listmenu/EdgeListMenu";
import {EndpointListMenu} from "./workbench/components/listmenu/EndpointListMenu";
import {BaseInstanceListMenu} from "../../common/components/workbench/instance/listmenu/BaseInstanceListMenu";
import {InstanceListMenuItem} from "../../common/enums/InstanceListMenuItem";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import {DiscoveredEdgeListMenu} from "./notifications/components/listmenu/DiscoveredEdgeListMenu";
import {DestinationPolicy} from "raasify-models-specification-ts/core/DestinationPolicy";


export interface InstanceListMenuProps {
    className: string;
    getInstances: (specification: { [index: string]: any }, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    onFilter?: (filter: string) => void;
    filter?: String;
    selected: Selected;
    rootSelected?: Selected;
    services: Services;
    onInstanceSave: (specification: { [index: string]: {} }, instance: { [index: string]: {} }, response: (res: string, err: string) => void) => void;
    onInstanceDelete: (specification: {[index: string]: {}}, instanceId: string, callback: (res: any, err: any) => void) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    selectedPage: string;
    selectedDeployment: Deployment;
    specification: { [index: string]: any };
}

const PAGE_POWERBENCH = "powerbench";

export const DefaultListMenu: FC<InstanceListMenuProps> = (props) => {
    return (
        <BaseInstanceListMenu
            {...props}
            instanceListMenuItems={[
                InstanceListMenuItem.Add,
                InstanceListMenuItem.Clone,
                InstanceListMenuItem.Filter,
            ]}
        />

    );
};

export const PowerListMenu: FC<InstanceListMenuProps> = (props) => {
    return (
        <BaseInstanceListMenu
            {...props}
            instanceListMenuItems={[
                InstanceListMenuItem.Add,
                InstanceListMenuItem.DeleteAll,
                InstanceListMenuItem.Clone,
                InstanceListMenuItem.Filter,
            ]}
        />

    );
};

export const InstanceListMenu: FC<InstanceListMenuProps> = (props) => {
    const {specification, selectedPage} = props;
    const {name} = specification;

    if(selectedPage === PAGE_POWERBENCH) {
        return <PowerListMenu {...props} />
    }
    if (name === ActivationRequest.specification.name) {
        return <ActivationRequestListMenu {...props} />
    }
    else if (name === DiscoveredEdge.specification.name) {
        return <DiscoveredEdgeListMenu {...props} />
    }
    else if (name === Automation.specification.name) {
        return <AutomationListMenu {...props} />
    }
    else if (name === AutomationPolicy.specification.name) {
        return <AutomationPolicyListMenu {...props} />
    }
    else if (name === DestinationPolicy.specification.name) {
        return <DestinationPolicyListMenu {...props} />
    }
    else if (name === Metric.specification.name) {
        return <MetricListMenu {...props} />
    }
    else if (name === Edge.specification.name) {
        return <EdgeListMenu {...props} />
    }
    else if (name === Endpoint.specification.name) {
        return <EndpointListMenu {...props} />
    }
    else {
        return <DefaultListMenu {...props} />
    }
};
