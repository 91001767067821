import * as React from "react";

import {FC, useEffect, useRef} from "react";

// @ts-ignore
import { JSONEditor as Editor } from 'svelte-jsoneditor/dist/jsoneditor.js'
import {Selected} from "../../../../model/Selected";
import {usePrevious} from "../../../../utils/stateUtil";

type JSON = Object | Array<JSON> | string | number | boolean | null
type Content = { json: JSON; text: undefined } | { json: undefined; text: string }
type Path = Array<string | number>
type JSONPatchDocument = JSONPatchOperation[]
type JSONPatchOperation = {
    op: 'add' | 'remove' | 'replace' | 'copy' | 'move' | 'test'
    path: string
    from?: string
    value?: JSON
}
type JSONPatchResult = {
    json: JSON
    previousJson: JSON
    undo: JSONPatchDocument
    redo: JSONPatchDocument
}
type ValidationError = {
    path: Path
    message: string
    isChildError?: boolean
}
type QueryLanguage = {
    id: string
    name: string
    description: string
    createQuery: (json: JSON, queryOptions: QueryLanguageOptions) => string
    executeQuery: (json: JSON, query: string) => JSON
}
type QueryLanguageOptions = {
    filter?: {
        path?: string[]
        relation?: '==' | '!=' | '<' | '<=' | '>' | '>='
        value?: string
    }
    sort?: {
        path?: string[]
        direction?: 'asc' | 'desc'
    }
    projection?: {
        paths?: string[][]
    }
}
type RenderValueProps = {
    path: Path
    value: JSON
    readOnly: boolean
    selection?: Selection
    searchResult?: SearchResultItem
    isSelected: boolean
    isEditing: boolean
    normalization: ValueNormalization
    onPatch: (patch: JSONPatchDocument, newSelection: Selection | null) => void
    onPasteJson: (pastedJson: { path: Path; contents: JSON }) => void
    onSelect: (selection: Selection) => void
}
type ValueNormalization = {
    escapeValue: (escapeValue: any) => string
    unescapeValue: (unescapeValue: string) => string
}
type SearchResultItem = {
    path: Path
    field: Symbol
    fieldIndex: number
    start: number
    end: number
}


export interface JSONEditorProps {
    id: string;
    value: any;
    disabled?: boolean;
    onChange(value: object): void;
    JSONEditorProps?: any;
    className?: string;
    style?: any;
    selected?: Selected;
    placeholder?: any;
}

const MODE_TREE = 'tree';
const MODE_CODE = 'code';

export const JSONEditor: FC<JSONEditorProps> = (props) => {
    const {id, value, disabled, onChange, JSONEditorProps, className, placeholder, selected, style} = props;

    const editor = useRef(null);

    const expand = () => {
        try {
            // @ts-ignore
            setTimeout(() => editor.current?.expand(), 0);
        } catch (err) {
            console.error(err);
        }
    };

    const handleOnChange = (content: Content, previousContent: Content, patchResult: JSONPatchResult | null) => {
        let result = content.json;
        if(content.text) {
            try {
                result = JSON.parse(content.text)
            }
            catch(err) {
                return;
            }
        }
        onChange(result as object);
    }

    const handleOnChangeMode = (mode: string) => {
        if(mode === MODE_TREE) {
            expand();
        }
    }

    const editorProps = {
        content: {
            json: value,
        },
        onChange: handleOnChange,
        onChangeMode: handleOnChangeMode,
        mainMenuBar: true,
        navigationBar: true,
        readOnly: disabled,
        indentation: 4,
        ...JSONEditorProps,
    };

    // create editor
    const onRef = (ref: any) => {
        if (ref && !editor.current) {
            // @ts-ignore
            editor.current = new Editor({
                target: ref,
                props: editorProps,
            });
        }
    };



    const previousProps = usePrevious(props);
    useEffect(() => {
        if (value && editor.current) {
            // @ts-ignore
            editor.current.set({
                json: value,
            });
            expand();
        }
        else if(value === undefined && placeholder && disabled) {
            // display placeholder in disabled mode
            // @ts-ignore
            editor.current.set({
                json: placeholder,
            });
        }
    }, [selected]);

    useEffect(() => {
        if (value && editor.current) {
            // @ts-ignore
            editor.current.set({
                json: value,
            });
            expand();
        }
        else if(value === undefined && placeholder && disabled) {
            // display placeholder in disabled mode
            // @ts-ignore
            editor.current.set({
                json: placeholder,
            });
        }
    }, [value]);

    useEffect(() => {
        if (editor.current) {
            if(value === undefined && placeholder && !disabled) {
                // remove placeholder in edit mode
                // @ts-ignore
                editor.current.set({
                    text: "",
                });
            }
            // @ts-ignore
            editor.current.updateProps({readOnly: disabled});
        }
    }, [disabled]);

    useEffect(() => {
        if (editor.current) {
            expand();
        }
        return () => {
            // destroy editor
            if (editor.current) {
                // @ts-ignore
                editor.current.destroy();
                editor.current = null;
            }
        };
    }, []);

    const placeholderClass = value === undefined && placeholder && disabled ? 'json-editor-placeholder-mode' : '';

    return <div
        id={id}
        className={"json-editor " + className + " " + placeholderClass}
        style={style}
        ref={onRef}
    />
}
