import * as React from "react";
import {Credential} from "raasify-models-specification-ts/core/Credential";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const CredentialList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Credential._name,
                    columnWidth: 230
                }),
                new InstanceListAttribute({
                    name: Credential._description,
                    columnWidth: 230,
                }),
                new InstanceListAttribute({
                    name: Credential._username,
                    columnWidth: 120,
                }),
            ]}
        />
    );
};
