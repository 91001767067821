import Keycloak from "keycloak-js";
import axios from "axios";

let _kc: Keycloak.KeycloakInstance;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
// @ts-ignore
const initKeycloak = async (onAuthenticatedCallback) => {
    axios.get('/info.json')
        .then((res) => {
            console.log(JSON.stringify(res.data, null, 4));
            _kc = Keycloak(res.data.keycloak);
            _kc.init({
                onLoad: 'login-required',
                checkLoginIframe: false,
                pkceMethod: 'S256',
                enableLogging: true,
            })
                .then((authenticated) => {
                    if (!authenticated) {
                        console.log("user is not authenticated..!");
                    }
                    else {
                        onAuthenticatedCallback();
                    }
                })
                .catch(console.error);
        })
        .catch(console.error);
}

const getRealmUrl = () => {
    if (typeof _kc.authServerUrl !== 'undefined') {
        if (_kc.authServerUrl.charAt(_kc.authServerUrl.length - 1) == '/') {
            return _kc.authServerUrl + 'realms/' + encodeURIComponent(_kc.realm as string);
        } else {
            return _kc.authServerUrl + '/realms/' + encodeURIComponent(_kc.realm as string);
        }
    } else {
        return undefined;
    }
}

const getLogoutUrl = function(redirectURI: string) {
    let url = getRealmUrl() + '/protocol/openid-connect/logout'
        + '?client_id=' + encodeURIComponent(_kc.clientId as string)
        + '&post_logout_redirect_uri=' + encodeURIComponent(redirectURI);

    if (_kc.idToken) {
        url += '&id_token_hint=' + encodeURIComponent(_kc.idToken);
    }

    return url;
};

const doLogout = () => {
    if(_kc) {
        const redirectURI = window.location.toString();
        const url = getLogoutUrl(redirectURI);
        window.location.replace(url);
    }
}

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

// Note: minValidity should be the same as the Access Token Validity set in the user service
// This will ensure it gets refreshed when called which is important when creating a deployment
const updateToken = (successCallback: () => void) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(_kc.login);

const forceUpdateToken = (successCallback: () => void) =>
    _kc.updateToken(86400)
        .then(successCallback)
        .catch(_kc.login);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getTokenParsed = () => _kc.tokenParsed;

// @ts-ignore
const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
    initKeycloak,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    forceUpdateToken,
    getUsername,
    getTokenParsed,
    hasRole,
};

export default UserService;
