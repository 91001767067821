import * as React from "react";
import {FC} from "react";
import {Services} from "../../../../services/Services";
import {InstanceJob} from "../../../../model/InstanceJob";
import {Menu, MenuItem} from "@blueprintjs/core";

export interface BaseInstanceJobsProps {
    specification: {[index: string]: any};
    instance: {[index: string]: any}|undefined;
    services: Services;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    jobs: Array<InstanceJob>;
    onJob: (job: InstanceJob, parameters?: any) => void;
    children?: React.ReactNode;
}

export const BaseInstanceJobs: FC<BaseInstanceJobsProps> = (props) => {
    const {
        jobs,
        onJob,
        instance,
    } = props;

    return (
        <>
            <Menu
                className="bp4-menu .modifier bp4-elevation-1"
            >
                { jobs.map(job => {
                    return (
                        <MenuItem
                            className="bp4-text-small"
                            key={job.command}
                            text={job.label}
                            onClick={() => onJob(job)}
                            icon={job.icon as any}
                            disabled={job.isDisabled && job.isDisabled(instance)}
                        />
                    )
                })}
            </Menu>
        </>
    );
}
