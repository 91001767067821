import * as React from "react";

import {Credential} from "raasify-models-specification-ts/core/Credential";

import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {BaseInstanceEdit, BaseInstanceEditProps} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";


const CredentialEdit: FC<BaseInstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(Credential.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Credential._name} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Credential._description} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Credential._method} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Credential._username} />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "100%"}} name={Credential._password} password />
                    </TabAttributeRow>
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default CredentialEdit;

