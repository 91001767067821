import {DataService} from "./DataService";
import {SpecificationService} from "./SpecificationService";

export const CORE = 'core';
export const USER = 'user';
export const EDGE = 'edge';
export const MARKETPLACE = 'marketplace';
export const EDGE_LOGIC = 'edge-logic';
export const CORE_LOGIC = 'cloud-core-logic';
export const USER_LOGIC = 'cloud-user-logic';
export const MARKETPLACE_LOGIC = 'cloud-marketplace-logic';


export const SERVICE_MAP: any = {
    [`${CORE_LOGIC},${EDGE_LOGIC}`]: CORE,
    [USER_LOGIC]: USER,
    [MARKETPLACE_LOGIC]: MARKETPLACE,
}

export class Services {
    private readonly _data: DataService;
    private readonly _userData: DataService;
    private readonly _marketplaceData: DataService;
    private readonly _specification: SpecificationService;

    constructor(specifications: {[index: string]: any}) {
       this._data = new DataService(CORE);
       this._userData = new DataService(USER);
       this._marketplaceData = new DataService(MARKETPLACE);
       this._specification = new SpecificationService(specifications);
    }

    get data(): DataService {
        return this._data;
    }

    get userData(): DataService {
        return this._userData;
    }

    get marketplaceData(): DataService {
        return this._marketplaceData;
    }

    get specification(): SpecificationService {
        return this._specification;
    }
}
