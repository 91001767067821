import React, {FC, useEffect, useRef, useState} from "react";
import {Alert, Card, Elevation, Icon, Intent, Menu, MenuItem, Spinner} from "@blueprintjs/core";
import {getIndex} from "../../../../utils/instanceUtil";
import {BaseInstanceListProps} from "./BaseInstanceList";
import {Instance} from "common-models-ts";
import {useAppSelector} from "../../../../redux/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {Pagination} from "../../common/pagination/Pagination";

export const InstanceListTable: FC<BaseInstanceListProps> = (props) => {
    const {
        relatedView,
        className,
        selected,
        services,
        filteredInstances,
        onSortClick,
        instanceListAttributes,
        onMount,
        onInstanceDelete,
        selectedPage,
        selectedDeployment,
        rootSelected,
        instanceListCard,
        getInstances,
        getInstance,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();

    const instances = useAppSelector((state) => state.instances)

    const _instances = filteredInstances ? filteredInstances : instances[selected.getSpecificationKey()]?.instances;
    const pagination = instances[selected.getSpecificationKey()]?.pagination;

    const JSONToQueryString = (params: any) => {
        let result = "";
        for(const [key, value] of Object.entries(params)) {
            result += `${key}=${value}&`
        }
        return result.substring(0, result.length-1); // get rid of trailing &
    }

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 38 && selectedIndex > 0) {
            // Up
            setSelectedIndex(prevState => (prevState - 1))
        }
        else if (e.keyCode === 40 && selectedIndex < _instances.length - 1) {
            // Down
            setSelectedIndex(prevState => (prevState + 1))
        }
        else if (e.keyCode == 8 || e.keyCode == 46) {
            // Delete or backspace
            handleDeleteClick();
        }
        else if (e.keyCode == 13) {
            // Enter
            if(isDeleteAlertOpen) {
                // If alert button is open, then confirm delete
                handleDeleteAlertConfirm();
            }
        }
    }

    const handleInstanceDelete = () => {
        if(selected.instanceId) {
            onInstanceDelete(selected.specification, selected.instanceId, (res: any, err: any) => {
                if(err) {
                    console.error(err);
                }
            })
        }
    }

    const handleDeleteClick = () => {
        setDeleteAlertOpen(true);
    }

    const handleDeleteAlertCancel = () => {
        setDeleteAlertOpen(false);
    }

    const handleDeleteAlertConfirm = () => {
        setDeleteAlertOpen(false);
        handleInstanceDelete();
    }

    const instanceList = _instances;

    const getLink = (index: number) => {
        return rootSelected
            ? `/deployments/${selectedDeployment.id}/${selectedPage}/${rootSelected.specification?.url}/${rootSelected.instanceId}/${selected.specification?.url}/${instanceList[index].id}`
            : `/deployments/${selectedDeployment.id}/${selectedPage}/${selected.specification?.url}/${instanceList[index].id}`
    }

    const isRelatedInstance = () => {
        return rootSelected !== undefined;
    }

    useEffect(() => {
        onMount(instanceListAttributes);
        const _pagination = {...pagination, page: 1};
        //getInstances(selected.specification as any, () => setLoading(false),
        //    undefined, JSONToQueryString(_pagination), undefined);
    }, [])

    useEffect(() => {
        if(instanceList) {
            const newIndex = getIndex(_instances, selected.instanceId);
            if(newIndex) {
                // Update the index
                setSelectedIndex(newIndex || -1);
            }
        }
    }, [instanceList])

    useEffect(() => {
        let selectedIndex = -1; // set default
        if (_instances) {
            // try to get from selected.instanceId
            const _selectedIndex = getIndex(_instances, selected.instanceId);
            if(_selectedIndex !== undefined && _instances[_selectedIndex].specification === selected.specification?.name) {
                selectedIndex = _selectedIndex;
            }
        }
        setSelectedIndex(selectedIndex);
    }, [selected])

    const outerClassName = relatedView ? 'instance-list-outer-related' : 'instance-list-outer';

    const handleInstanceClick = (instance: any, ix: number) => {
        setSelectedIndex(ix);
        getInstance(selected.specification as any, instance.id);
        navigate(getLink(ix));
    }

    const instanceCard = (instance: any, ix: number) => {
        const className = ix === selectedIndex ? 'selected-instance-list-card' : ''
        if(instanceListCard) {
            return (
                <Card onClick={(e) => handleInstanceClick(instance, ix)} className={"instance-list-card " + className} interactive={true} elevation={Elevation.TWO}>
                    {instanceListCard(instance, isRelatedInstance())}
                </Card>
            )
        }
        else {
            // Default content (name/description)
            return (
                <Card onClick={(e) => handleInstanceClick(instance, ix)} className={"instance-list-card " + className} interactive={true}
                      elevation={Elevation.TWO}>
                    <div className={"instance-list-logo"}><Icon icon={'media'} size={25} color={'black'}/></div>
                    <div className={'normal-row-div'}>
                        {instance.name &&
                            <>
                                <div className={"instance-list-card-name"}>{instance.name}</div>
                                <div className={"instance-list-card-description"}>{instance.description}</div>
                            </>
                        }
                        {!instance.name &&
                            <>
                                <div className={"instance-list-card-name"}>{instance.created}</div>
                                <div className={"instance-list-card-description"}>{instance.specification}</div>
                            </>
                        }
                    </div>
                </Card>
            )
        }
    }

    const widthClassName = isRelatedInstance() ? "related-instance-list-width" : "instance-list-width";
    const heightClassName = pagination && pagination.total > pagination.size && _instances.length > 0
        ? "instance-list-height-with-pagination" : "instance-list-height";

    return (
        <div className={outerClassName} onKeyDown={handleKeyDown} >
            <div className={"instance-list " + widthClassName + " " + heightClassName}>
                {loading && <Spinner className={"instance-list-spinner"} intent={Intent.PRIMARY} size={20}/>}
                {_instances && _instances.length > 0 && _instances.map((instance: Instance, ix: number) => {
                    return (
                        <div key={"package-card-" + instance.id} className={"instance-list-item"}>{instanceCard(instance, ix)}</div>
                    )
                })}
            </div>
            {pagination && pagination.total > pagination.size && _instances.length > 0 && <Pagination
                className={"instance-list-pagination"}
                buttonClassName={"instance-list-pagination-button"}
                pageNumberDivClassName={"instance-list-pagination-page-number-div"}
                total={pagination?.total}
                size={pagination?.size}
                initialPage={pagination?.page}
                onPageChange={(page: number) => {
                    const _pagination = {...pagination, page};
                    getInstances(selected.specification as any, () => setLoading(false),
                        undefined, JSONToQueryString(_pagination), undefined);
                }}
            />}
            <Alert
                className={'new-instance-abort'}
                cancelButtonText={"Cancel"}
                confirmButtonText={"Delete"}
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={handleDeleteAlertCancel}
                onConfirm={handleDeleteAlertConfirm}
                canEscapeKeyCancel
            >
                <p>
                    Are you sure you want to delete this instance?
                </p>
            </Alert>
        </div>
    );
};
