import * as React from "react";
import {FC} from "react";
import {BaseInstanceListMenu} from "../../../../../common/components/workbench/instance/listmenu/BaseInstanceListMenu";
import {InstanceListMenuItem} from "../../../../../common/enums/InstanceListMenuItem";
import {InstanceListMenuProps} from "../../../InstanceListMenu";

export const MetricListMenu: FC<InstanceListMenuProps> = (props) => {
    return (
        <BaseInstanceListMenu
            {...props}
            instanceListMenuItems={[
                InstanceListMenuItem.Add,
            ]}
        />

    );
};
