import * as React from "react";

import {FC} from "react";
import {InstanceHomeProps} from "../../InstanceHome";


const ActivationRequestHome: FC<InstanceHomeProps> = (props) => {
    return (
        <div className={'instance-home-outer'}>
            <div className={'instance-home-title'}>Activation Requests</div>
            <div className={'instance-home-main-line'}>
                Activation Requests are automatically generated requests from Discovered Edges to securely join the deployment.
            </div>
            <div className={'instance-home-main-line'}>
                Select one on the left
            </div>
        </div>
    );
}

export default ActivationRequestHome;

