import * as React from "react";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {InstanceListProps} from "../../../InstanceList";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {Metric} from "raasify-models-specification-ts/core/Metric";
import {logo} from "./InstanceListUtil";

export const MetricList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListCard={
                (instance: any, isRelatedInstance: boolean|undefined) => {
                    const metric = instance as Metric;
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(metric)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{metric.name}</div>
                                <div className={"instance-list-card-attribute"}>{metric.description}</div>
                            </div>
                        </>
                    )
                }
            }
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: Metric._name,
                }),
                new InstanceListAttribute({
                    name: Metric._sourceAttribute,
                }),
            ]}
        />
    );
};
