import moment from "moment";


export class InstanceLog {
    from: string;
    category: string;
    id: string;
    level: string;
    message: string;
    related: Array<any>;
    specification: string;
    timestamp: number;
    //new: boolean;

    constructor({from, category, id, level, message, related, specification, timestamp}
                    : {from:string, category:string, id:string, level:string, message:string, related:Array<any>, specification:string, timestamp: number}) {
        this.from = from;
        this.category = category;
        this.id = id;
        this.level = level;
        this.message = message;
        this.related = related;
        this.specification = specification;
        this.timestamp = timestamp;
        //this.new = true;

        const log = this;
        //setTimeout(() => {
        //    log.new = false;
        //}, 3000);
    }

    static getFrom(log: InstanceLog): string {
        if((log.from as any) instanceof Object) {
            return "";
        }
        return log.from;
    }

    static getCategory(log: InstanceLog): string {
        return log.category;
    }

    static getId(log: InstanceLog): string {
        return log.id;
    }

    static getLevel(log: InstanceLog): string {
        return log.level;
    }

    static getMessage(log: InstanceLog): string {
        return log.message;
    }

    static getRelated(log: InstanceLog): Array<any> {
        return log.related;
    }

    static getSpecification(log: InstanceLog): string {
        return log.specification;
    }

    static getTimestamp(log: InstanceLog): string {
        return moment(log.timestamp).format('YYYY-MM-DD HH:mm:ss.SSS');
    }

    static _from = 'from';
    static _category = 'category';
    static _id = 'id';
    static _level = 'level';
    static _message = 'message';
    static _related = 'related';
    static _specification = 'specification';
    static _timestamp = 'timestamp';

}
