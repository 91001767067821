import * as React from "react";
import {Component, FC, ReactElement} from "react";
import ScrollToBottom from 'react-scroll-to-bottom';

export interface TerminalProps {
    bindPluginAPI: (API: PluginAPI) => void;
    handleCommand: (command: string) => void;
    welcomeMessage?: ReactElement|string;
    onSingleLineMode: () => void;
    onMultiLineMode: () => void;
    onReset: () => void;
}

export interface PluginAPI {
    printLine: (output: ReactElement|string) => Promise<void>;
    updateLine: (line: number, output: ReactElement|string) => Promise<void>;
    getLine: (line: number) => ReactElement|string|undefined;
    getLines: () => Array<ReactElement|string>;
    setLines: (lines: Array<ReactElement|string>) => Promise<void>;
    setPrompt: (prompt: string) => Promise<void>;
    setCommand: (command: string) => void
}

export interface PromptProps {
    prompt: string;
    className?: string;
}

const Prompt: FC<PromptProps> = (props) => {
    return (
        <div className={props.className}>
            {props.prompt}
        </div>
    )
}

interface TerminalState {
    multiLineMode: boolean,
    lines: Array<ReactElement|string>,
    prompt: string,
    command: string,
    history: Array<string>,
    historyCursor: number,
}

export class Terminal extends Component {
    state: TerminalState;
    props: TerminalProps;
    private readonly initialLines: (React.ReactElement<any, string | React.JSXElementConstructor<any>> | string)[];
    private readonly inputText: any;
    constructor(props: TerminalProps) {
        super(props);
        this.props = props;
        this.initialLines = props.welcomeMessage ? [props.welcomeMessage] : [];
        this.state = {
            multiLineMode: false,
            lines: this.initialLines,
            prompt: '#',
            command: '',
            history: [],
            historyCursor: 0,
        }
        this.setStateSynchronous = this.setStateSynchronous.bind(this);
        this.printLine = this.printLine.bind(this);
        this.updateLine = this.updateLine.bind(this);
        this.getLine = this.getLine.bind(this);
        this.getLines = this.getLines.bind(this);
        this.setLines = this.setLines.bind(this);
        this.setPrompt = this.setPrompt.bind(this);
        this.setMultiLineMode = this.setMultiLineMode.bind(this);
        this.setSingleLineMode = this.setSingleLineMode.bind(this);
        this.reset = this.reset.bind(this);
        this.onChangeCommand = this.onChangeCommand.bind(this);
        this.onChangeMultiCommand = this.onChangeMultiCommand.bind(this);
        this.handleKeyDownInput = this.handleKeyDownInput.bind(this);
        this.handleKeyDownTextArea = this.handleKeyDownTextArea.bind(this);
        this.onCommand = this.onCommand.bind(this);

        this.props.bindPluginAPI({
            printLine: this.printLine,
            updateLine: this.updateLine,
            getLine: this.getLine,
            getLines: this.getLines,
            setLines: this.setLines,
            setPrompt: this.setPrompt,
            setCommand: this.setCommand,
        });
    }

    setStateSynchronous(stateUpdate: any) {
        return new Promise<void>(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    async printLine(output: ReactElement|string) {
        return this.setStateSynchronous({
            lines: [...this.state.lines, output]
        });
    }

    async updateLine(line: number, output: ReactElement|string) {
        //console.log("updateLine", line, output);
        const newLines: Array<ReactElement|string> = [...this.state.lines]
        if(newLines.length > line) {
            newLines[line] = output;
        }
        return this.setStateSynchronous({
            lines: newLines
        });
    }

    getLine(line: number): ReactElement|string|undefined {
        return this.state.lines.length > line ? this.state.lines[line] : undefined;
    }

    getLines(): Array<ReactElement|string> {
        return this.state.lines;
    }

    async setLines(lines: Array<ReactElement|string>) {
        return this.setStateSynchronous({lines});
    }

    async setPrompt(prompt: string) {
        return this.setStateSynchronous({prompt});
    }

    setMultiLineMode() {
        this.props.onMultiLineMode();
        return this.setStateSynchronous({multiLineMode: true, command: ""})
    }

    setSingleLineMode() {
        this.props.onSingleLineMode();
        return this.setStateSynchronous({multiLineMode: false, command: ""});
    }

    reset() {
        this.props.onReset();
        return this.setStateSynchronous({lines: this.initialLines, command: ""});
    }

    setCommand() {
        return this.setStateSynchronous({command: ""});
    }

    onChangeCommand(event: any) {
        this.setState({command: event.target.value});
    }

    onChangeMultiCommand(event: any) {
        this.setStateSynchronous({command: event.target.value.replace(/^[\r\n]/, '')})
            .then(() => {
                this.handleKeyDownTextArea(event);
            });
    }

    onCommand(noHistory?: boolean) {
        if(this.state.command !== "") {
            // regex to remove blank lines
            const command = this.state.command
                .replace(/^\s*[\r\n]/gm, '') // remove empty lines
                .replace(/^\/x$/m, '') // remove /x
            this.props.handleCommand(command);
            if(noHistory) {
                this.setState({command: ""});
            }
            else {
                this.setState({
                    command: "",
                    history: [
                        ...this.state.history,
                        this.state.command,
                    ],
                    historyCursor: this.state.history.length + 1,
                });
            }
        }
    }

    componentDidUpdate() {
        //console.log("state", JSON.stringify(this.state, null, 4));
    }

    handleKeyDownInput(event: any) {
        if (event.key === 'Enter') {
            if(this.state.command === '/m') {
                this.setMultiLineMode();
            }
            else if(this.state.command === '/r') {
                this.reset();
            }
            else {
                this.printLine(this.state.prompt + " " + this.state.command)
                    .then(() => {
                        this.onCommand();
                    });
            }
        }
        else if (event.key === 'ArrowUp') {
            if(this.state.history.length > 0 && this.state.historyCursor > 0) {
                const newHistoryCursor = Math.max(this.state.historyCursor - 1, 0);
                const newCommand = this.state.history[newHistoryCursor];
                this.setState({
                    historyCursor: newHistoryCursor,
                    command: newCommand,
                }, () => {
                    //console.log("new state", JSON.stringify(this.state, null, 4));
                    // @ts-ignore
                    setTimeout(() => {
                        event.target.selectionStart = event.target.selectionEnd = newCommand.length;
                    }, 5);
                });
            }
        }
        else if (event.key === 'ArrowDown') {
            if(this.state.historyCursor < this.state.history.length) {
                const newHistoryCursor = Math.min(this.state.historyCursor + 1, this.state.history.length);
                this.setState({
                    historyCursor: newHistoryCursor,
                    command: newHistoryCursor >= this.state.history.length ? "" : this.state.history[newHistoryCursor],
                });
            }
        }
    }

    handleKeyDownTextArea(event: any) {
        //console.log("handleKeyDownTextArea event.target.value");
        event.target.style.height = 'inherit';
        event.target.style.height = `${event.target.scrollHeight}px`;
        if(event.key === 'Enter') {
            // regex to remove blank lines
            const lines = this.state.command.replace(/^\s*[\r\n]/gm, '').split('\n');
            // Execute
            if(lines.length > 0 && lines[lines.length - 1] === '/x') {
                let output = this.state.prompt + " " + lines[0] + '\n';
                lines.shift();
                lines.pop();
                for (const line of lines) {
                    output += Array(this.state.prompt.length + 2).join(" ") + line + '\n';
                }
                this.printLine(output)
                    .then(() => {
                        this.onCommand(true);
                    });
            }
            else if(lines.length > 0 && lines[lines.length - 1] === '/s') {
                this.setSingleLineMode();
            }
        }
    }

    render() {
        return (
            <div className={'terminal-global'}>
                <div>
                    <div className={'terminal-help-container'}>
                        <div className={'terminal-help-entry'}>
                            <span className={'terminal-help-entry-header'}>/m</span> : multi line mode
                        </div>
                        <div className={'terminal-help-entry'}>
                            <span className={'terminal-help-entry-header'}>/s</span> : back to single line mode
                        </div>
                        <div className={'terminal-help-entry'}>
                            <span className={'terminal-help-entry-header'}>/x</span> : execute multi line cmd
                        </div>
                        <div className={'terminal-help-entry-last'}>
                            <span className={'terminal-help-entry-header'}>/r</span> : reset the terminal
                        </div>
                    </div>
                </div>
                <ScrollToBottom className={'terminal-outer'}>
                    <div className={'terminal-container'}>
                        <div className={'terminal-history'}>
                            {this.state.lines.map((line) => {
                                return <pre className={'terminal-history-entry'}>{line}</pre>;
                            })}
                        </div>
                        <div className={'terminal-command'}>
                            <Prompt className={"terminal-command-prompt"} prompt={this.state.prompt}/>
                            {!this.state.multiLineMode &&
                                <input
                                    autoFocus
                                    className={"terminal-command-input"}
                                    value={this.state.command}
                                    onChange={this.onChangeCommand}
                                    onKeyDown={this.handleKeyDownInput}
                                />
                            }
                            {this.state.multiLineMode &&
                                <textarea
                                    autoFocus
                                    className={"terminal-command-textarea"}
                                    value={this.state.command}
                                    onChange={this.onChangeMultiCommand}
                                    onKeyDown={this.handleKeyDownTextArea}
                                />
                            }
                        </div>
                    </div>
                </ScrollToBottom>
            </div>
        );
    }
}
