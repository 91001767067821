// file: store.ts
import { configureStore } from '@reduxjs/toolkit'

import logger from 'redux-logger';

import instancesReducer from "./slices/instances";
import logsReducer from "./slices/logs";
import builderReducer from "./slices/builder";

const reducer = {
    instances: instancesReducer,
    logs: logsReducer,
    builder: builderReducer,
}

const preloadedState = {
    instances: {},
    logs: {
        logs: [],
    },
    builder: {},
}

const store = configureStore({
    reducer,
    //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
        }), //.concat(logger),
    //devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
    enhancers: [],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;

export default store;

