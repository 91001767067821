import React, { FC } from "react";
import {Services} from "../../../../services/Services";
import {InstanceViewPanel} from "../view/InstanceViewPanel";
import {DefaultPanel} from "../../common/panel/DefaultPanel";
import {InstanceEditTab} from "../edit/InstanceEditTab";
import {TabAttributeRow} from "../edit/TabAttributeRow";
import {TabAttribute} from "../edit/TabAttribute";
import {Selected} from "../../../../model/Selected";


export interface InstanceDetailProps {
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    specification: {[index: string]: any};
    instance: any;
    services: Services;
    selectedPage: string;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    children?: React.ReactNode;
    selected: Selected;
}

export const InstanceDetail: FC<InstanceDetailProps> = (props) => {
    const {
        instance,
        services,
        specification,
        getInstances,
        getInstance,
        selectedPage,
        editMode,
        setEditMode,
        selected,
    } = props;
    const attributeNames = services.specification.getAttributeNamesBySpecification(specification);

    const instanceEditTab = (
        <InstanceEditTab noHeader>
            {attributeNames && attributeNames.map((attributeName) => {
                return (
                    <TabAttributeRow key={attributeName}>
                        <TabAttribute name={attributeName} />
                    </TabAttributeRow>
                )
            })}
        </InstanceEditTab>
    )

    //console.log("Instance Detail", specification, attributeNames);
    return (
        <div className={"instance-details-panel"}>
            <InstanceViewPanel services={services} children={
                <DefaultPanel
                    selected={selected}
                    useAttributeNamesAsLabels
                    disabled={true}
                    services={services}
                    instance={instance}
                    specification={specification}
                    instanceEditTab={instanceEditTab}
                    onInstanceSave={() => {}} // not actually needed here
                    setRelatedSelectCount={() => {}} // not actually needed here
                    relatedSelectCount={0}
                    getInstances={getInstances}
                    getInstance={getInstance}
                    isDetail={true}
                    selectedPage={selectedPage}
                    editMode={editMode}
                    setEditMode={setEditMode}
                />}
            />
        </div>
    );

};
