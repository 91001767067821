import * as React from "react";

import {InstanceEditProps} from "../../../InstanceEdit";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {BaseInstanceEdit} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {ActivationResponse} from "raasify-models-specification-ts/core/ActivationResponse";

const ActivationResponseEdit: FC<InstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(ActivationResponse.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={ActivationResponse._associatedDiscoveredEdgeId} disabled />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={ActivationResponse._status} disabled />
                    </TabAttributeRow>
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default ActivationResponseEdit;

