import React, {ChangeEvent, FC, ReactElement, useEffect, useState} from "react";
import classNames from "classnames";
import {
    Alert,
    Alignment,
    Button,
    Classes,
    H4, Icon,
    InputGroup, Intent,
    Navbar, Overlay,
} from "@blueprintjs/core";
import {InstanceListMenuItem} from "../../../../enums/InstanceListMenuItem";
import {Services} from "../../../../services/Services";
import {Selected} from "../../../../model/Selected";
import {useAppSelector} from "../../../../redux/hooks";
import {useNavigate} from "react-router-dom";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {InstanceEdit} from "../../../../../custom/benches/InstanceEdit";
import {Tooltip2} from "@blueprintjs/popover2";
import {AppToaster} from "../../../../../AppToaster";
import {DEPLOYMENT_SPEC} from "../../../deployments/Deployments";
type JSONObject = {[index: string]: any};


export interface BaseInstanceListMenuProps
{
    className: string;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    getInstance: (specification: {[index: string]: any}, id: string, callback?: (res: any, err: any) => void) => void;
    onFilter?: (filter: string) => void;
    filter?: String;
    selected: Selected;
    rootSelected?: Selected;
    services: Services;
    onInstanceSave: (specification: {[index: string]: any}, instance: {[index: string]: any}, callback: (res: any, err: any) => void) => void;
    onInstanceDelete: (specification: {[index: string]: {}}, instanceId: string, callback: (res: any, err: any) => void) => void;
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    instanceListMenuItems: Array<string>;
    selectedPage: string;
    selectedDeployment: Deployment;
    specification: {[index: string]: any};
}

export const BaseInstanceListMenu: FC<BaseInstanceListMenuProps> = (props) => {
    const {
        className,
        getInstances,
        getInstance,
        onFilter,
        filter,
        selected,
        services,
        onInstanceSave,
        onInstanceDelete,
        relatedSelectCount,
        setRelatedSelectCount,
        instanceListMenuItems,
        selectedPage,
        rootSelected,
        selectedDeployment,
    } = props;
    const instances = useAppSelector((state) => state.instances)
    const [page, setPage] = useState<ReactElement<any, any>|null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [isDeleteAllAlertOpen, setIsDeleteAllAlertOpen] = useState<boolean>(false);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [newInstance, setNewInstance] = useState<{}>({});
    const [newInstanceIsOpen, setNewInstanceIsOpen] = useState<boolean>(false);

    if(!selected.specification) {
        return <></>
    }

    const handleInstanceEditAttributeChange = (name: string, value: string) => {
        setNewInstance((prevState: JSONObject) => ({...prevState, [name]: value}));
    }

    const isRelatedInstance = () => {
        return rootSelected !== undefined;
    }

    const getLink = (id: string) => {
        return rootSelected
            ? `/deployments/${selectedDeployment.id}/${selectedPage}/${rootSelected.specification?.url}/${rootSelected.instanceId}/${selected.specification?.url}/${id}`
            : `/deployments/${selectedDeployment.id}/${selectedPage}/${selected.specification?.url}/${id}`
    }

    const navigate = useNavigate();
    const handleInstanceEditOnSave = (specification: {[index: string]: {}}, instance: {[index: string]: {}}, callback: (data: any, err: any) => void) => {
        setPage(getPage());
        setLoading(true);
        onInstanceSave(
            specification,
            Object.assign({}, instance),
            (res: any, err: any) => {
                setLoading(false);
                callback(res, err);
                if(!err && specification.key === selected.getSpecificationKey()) {
                    setNewInstance({});
                    setNewInstanceIsOpen(false);
                }
                // navigate to the new instance
                console.log("Navigating to", `${getLink(res.id)}`);
                navigate(getLink(res.id))
            });

    }

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const filter = event.target.value;
        onFilter && onFilter(filter);
    }

    const handleFilterClear = () => {
        onFilter && onFilter("");
        onFilter && console.log("cleared filter");
    }

    const handleAddNewOnClick = () => {
        setNewInstanceIsOpen(true);
    }

    const handleDeleteAllOnClick = () => {
        setIsDeleteAllAlertOpen(true);
    }

    const handleDeleteAllAlertCancel = () => {
        setIsDeleteAllAlertOpen(false);
    }

    const handleDeleteAllAlertConfirm = () => {
        handleDeleteAll();
    }

    const handleDeleteAll = () => {
        setLoading(true);
        if(selected.specification) {
            for (const instance of instances[selected.specification.key]?.instances) {
                if(!filter || (instance.name && instance.name.toLowerCase().includes(filter.toLowerCase()))) {
                    onInstanceDelete(selected.specification as any, instance?.id, (res, err) => {
                        if (err) {
                            AppToaster.show({
                                icon: "tick",
                                intent: Intent.DANGER,
                                message: `Error occurred: ${err}`,
                            });
                        }
                        setIsDeleteAllAlertOpen(false);
                        setLoading(false);
                    })
                }
            }
        }
    }

    const handleCloneOnClick = () => {
        if(selected.specification) {
            for(const instance of instances[selected.specification.key].instances) {
                if(instance.id === selected.instanceId) {
                    // clone this one
                    const cloneInstance = Object.assign({}, instance)
                    // delete the id
                    delete cloneInstance.id;
                    delete cloneInstance.systemId;
                    cloneInstance.name += " (copy)"
                    // If edge change status and remove certs, etc
                    if(selected.specification.key === 'core.Edge') {
                        cloneInstance.status = 'Inactive';
                        cloneInstance.certificate = null;
                        cloneInstance.CACertificate = null;
                        cloneInstance.associatedDiscoveredEdgeId = null;
                    }
                    // If node or application clear states
                    if(selected.specification.key === 'core.Node' || selected.specification.key === 'core.Application') {
                        cloneInstance.discoveredState = 'Unknown';
                        cloneInstance.configuredState = 'None';
                    }
                    setNewInstance(cloneInstance);
                    setNewInstanceIsOpen(true);
                }
            }
        }
    }

    const handleAddNewOnCancel = () => {
        setNewInstance({});
        setNewInstanceIsOpen(false);
        setEditMode(false);
    }

    const classes = classNames(
        Classes.CARD,
        Classes.PORTAL,
        'instance-overlay',
    );

    useEffect(() => {
    }, [loading])

    useEffect(() => {
        setEditMode(true);
    }, [newInstanceIsOpen])

    if(loading) {
        return page;
    }

    const widthClassName = isRelatedInstance() ? "related-instance-list-menu-width" : "instance-list-menu-width";

    const getPage = () => {
        return (
            <div className={className}>
                <Navbar className={'instance-list-menu ' + widthClassName}>
                    <Navbar.Group align={Alignment.LEFT} className={'instance-list-menu-group'}>
                        {instanceListMenuItems.includes(InstanceListMenuItem.Add) &&
                            <Tooltip2
                                content={<div className={'tooltip-content'}>Add new</div>}
                                openOnTargetFocus={false}
                                placement="right-start"
                                usePortal={false}
                            >
                                <Button onClick={handleAddNewOnClick} className={Classes.MINIMAL + ' instance-list-menu-button'} icon="add"
                                        text=""/>
                            </Tooltip2>
                        }
                        {instanceListMenuItems.includes(InstanceListMenuItem.DeleteAll) &&
                            <Tooltip2
                                content={<div className={'tooltip-content'}>Delete All</div>}
                                openOnTargetFocus={false}
                                placement="right-start"
                                usePortal={false}
                            >
                                <Button onClick={handleDeleteAllOnClick} className={Classes.MINIMAL + ' instance-list-menu-button'} icon="trash"
                                        text=""/>
                            </Tooltip2>
                        }
                        {instanceListMenuItems.includes(InstanceListMenuItem.Clone) && selected.instanceId !== null &&
                            <Tooltip2
                                content={<div className={'tooltip-content'}>Clone selected</div>}
                                openOnTargetFocus={false}
                                placement="right-start"
                                usePortal={false}
                            >
                                <Button disabled={selected.instanceId === null} onClick={handleCloneOnClick}
                                        className={Classes.MINIMAL + ' instance-list-menu-button'} icon="duplicate" text=""/>
                            </Tooltip2>
                        }
                    </Navbar.Group>
                    <Navbar.Group align={Alignment.RIGHT} className={'instance-list-menu-group'}>
                        {instanceListMenuItems.includes(InstanceListMenuItem.Filter) &&
                            <InputGroup
                                className={'instance-list-menu-filter-input'}
                                asyncControl={true}
                                disabled={false}
                                leftElement={<Icon className={"instance-list-menu-filter-icon"} icon={"filter"} size={18}/>}
                                onChange={handleFilterChange}
                                placeholder={"Filter..."}
                                small={true}
                                rightElement={<div onClick={handleFilterClear}><Icon className={"instance-list-menu-delete-icon"}  icon={"delete"} /></div>}
                                value={filter && filter.toString()}
                            />
                        }
                    </Navbar.Group>
                </Navbar>
                <Overlay
                    isOpen={newInstanceIsOpen}
                    onClose={handleAddNewOnCancel}
                    autoFocus
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    enforceFocus
                    hasBackdrop
                    usePortal
                >
                    <div className={classes}>
                        <H4 className={'new-instance-overlay-header'}>Add a new {selected.getSpecificationLabel()}</H4>
                        <InstanceEdit
                            selected={selected}
                            key={'InstanceListMenu_' + selected?.specification?.name}
                            onAttributeChange={handleInstanceEditAttributeChange}
                            onInstanceSave={handleInstanceEditOnSave}
                            onCancel={handleAddNewOnCancel}
                            instance={newInstance}
                            setInstance={setNewInstance}
                            services={services}
                            specification={selected.specification as { [index: string]: any; } }
                            relatedSelectCount={relatedSelectCount}
                            setRelatedSelectCount={setRelatedSelectCount}
                            getInstances={getInstances}
                            getInstance={getInstance}
                            selectedPage={selectedPage}
                            editMode={editMode}
                            setEditMode={setEditMode}
                            isAddNew
                            rootSelected={rootSelected}
                        />
                    </div>
                </Overlay>
                <Alert
                    className={"deployments-delete-alert"}
                    cancelButtonText={"Cancel"}
                    confirmButtonText={"Delete"}
                    icon="trash"
                    intent={Intent.DANGER}
                    isOpen={isDeleteAllAlertOpen}
                    onCancel={handleDeleteAllAlertCancel}
                    onConfirm={handleDeleteAllAlertConfirm}
                >
                    <p className={"bp4-text-large"}>
                        Are you sure you want to delete all the endpoints in this list?
                    </p>
                </Alert>
            </div>
        );
    }

    return getPage();
}
