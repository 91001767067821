import * as React from "react";
import {VMPolicy} from "raasify-models-specification-ts/core/VMPolicy";

import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const VMPolicyList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: VMPolicy._name,
                    columnWidth: 100
                }),
                new InstanceListAttribute({
                    name: VMPolicy._description,
                    columnWidth: 280,
                }),
                new InstanceListAttribute({
                    name: VMPolicy._vCPUs,
                    columnWidth: 60,
                }),
                new InstanceListAttribute({
                    name: VMPolicy._memoryGB,
                    columnWidth: 100,
                }),
            ]}
        />
    );
};

