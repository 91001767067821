import * as React from "react";

import {Component, FC, ReactElement, useEffect, useRef, useState} from "react";
import {Services} from "../../../../services/Services";
import {Selected} from "../../../../model/Selected";
import {OutputListTable} from "./OutputListTable";
import {ListAttribute} from "../../../../model/ListAttribute";
import {getById} from "../../../../utils/instanceUtil";
import {OutputDataTable, SingleResultViewType} from "./OutputDataTable";
import {Intent, Spinner, Tab, Tabs} from "@blueprintjs/core";
import {JSONEditor} from "../forms/JSONEditor";
import {HorizontalDataTable} from "./HorizontalDataTable";
import {flatten} from "./HorizontalDataUtil";
import {connect} from "react-redux";

type JSONObject = {[index: string]: any};


/*
                        resultUrl
                        resultMetricExecutionUrl
                        resultMetricExecutionSchema
                        resultKey
                        resultIdAttribute

 */

export interface OutputSchemaProps {
    services: Services;
    instance:  { [index: string]: any; };
    setInstance: (editedInstance: { [index: string]: any; }) => void;
    sourceUrl: string; //
    sourceIdAttribute: string; // ID Attribute of the relatedInstance. ie: instance[sourceAttribute]
    resultUrl?: string; //
    resultMetricExecutionUrl?: string;
    resultMetricExecutionSchema?: any;
    resultKey?: string; //
    resultIdAttribute?: string; // ID Attribute on the resultIdAttribute which points to the selected instance
    resultAttribute: string; // JSON attribute where result is stored
    schemaAttribute: string; // Schema Attribute on the relatedInstance
    relatedSelectCount: number;
    setRelatedSelectCount: (count: number) => void;
    getInstances: (specification: {[index: string]: any}, callback?: (res: any, err: any) => void) => void;
    selectedPage: string;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    selected: Selected;
    listAttributes: Array<ListAttribute>;
    setLoading?: (state: boolean) => void;
    useSelf?: boolean; // if true, there is no separate result entity to look up. Data is in the current instance
    instances:  { [index: string]: any; };
}

interface OutputSchemaState {
    allResults: Array<JSONObject>|undefined;
    resultInstances: Array<JSONObject>|undefined;
    resultMetricExecutionInstances: Array<JSONObject>|undefined;
    sourceInstance: JSONObject|undefined;
    selectedResult: JSONObject|undefined;
    selectedTab: string;
}

class OutputSchema extends Component<OutputSchemaProps, OutputSchemaState> {
    state: OutputSchemaState;
    props: OutputSchemaProps;

    constructor(props: OutputSchemaProps) {
        super(props);
        this.props = props;
        this.state = {
            allResults: undefined,
            resultInstances: undefined,
            resultMetricExecutionInstances: undefined,
            sourceInstance: undefined,
            selectedResult: undefined,
            selectedTab: "result",
        }

        this.handleOnTabSelect = this.handleOnTabSelect.bind(this);
        this.handleOnTableSelect = this.handleOnTableSelect.bind(this);
        this.addNewResultInstances = this.addNewResultInstances.bind(this);
        this.updateSourceInstance = this.updateSourceInstance.bind(this);
        this.retrieveResultMetricExecutionInstances = this.retrieveResultMetricExecutionInstances.bind(this);
        this.retrieveResultInstances = this.retrieveResultInstances.bind(this);
        this.setSelectedTab = this.setSelectedTab.bind(this);
        this.setSelectedResult = this.setSelectedResult.bind(this);
        this.setSourceInstance = this.setSourceInstance.bind(this);
        this.setResultMetricExecutionInstances = this.setResultMetricExecutionInstances.bind(this);
        this.setResultInstances = this.setResultInstances.bind(this);
        this.setAllResults = this.setAllResults.bind(this);
    }

    componentDidMount() {
        console.log("remount");
        //setLoading && setLoading(true);
        this.updateSourceInstance();
        this.retrieveResultInstances();
    }

    componentDidUpdate(prevProps: Readonly<OutputSchemaProps>, prevState: Readonly<OutputSchemaState>, snapshot?: any) {
        const {instances, instance, schemaAttribute, resultAttribute} = this.props;
        const {resultInstances, sourceInstance} = this.state;

        if(prevProps.instance?.id !== instance?.id) {
            //console.log("instance", instance);
            //setLoading && setLoading(true);
            this.updateSourceInstance();
            this.retrieveResultInstances(true);
            //setSelectedTab('result');
        }

        if(!prevProps.instances || JSON.stringify(prevProps.instances) !== JSON.stringify(instances)) {
            //this.addNewResultInstances();
            this.retrieveResultInstances();
        }

        if(!prevState.resultInstances || JSON.stringify(prevState.resultInstances) !== JSON.stringify(resultInstances)) {
            const schema = sourceInstance && sourceInstance[schemaAttribute];
            if (resultInstances) {
                // Get All results
                const _allResults = [];
                if (schema?.views?.allResults) {
                    for (const resultInstance of resultInstances) {
                        const flattenedResult = flatten(resultInstance[resultAttribute], true);
                        let res: JSONObject = {
                            ...flattenedResult,
                        };
                        for (const listAttribute of this.props.listAttributes) {
                            // Push the list attributes as the list will be hidden
                            res[listAttribute.name] = resultInstance[listAttribute.name];
                        }
                        //if(resultInstance.new) {
                        //    res.new = true;
                        //    setTimeout(() => {
                        //        res.new = false;
                        //    }, 3000);
                        //}
                        _allResults.push(res);
                    }
                }
                this.setAllResults(_allResults);
            }
        }

        if(prevState.selectedResult !== this.state.selectedResult) {
            //console.log("selectedResult", selectedResult);
            this.retrieveResultMetricExecutionInstances();
        }
    }

    setAllResults(allResults: Array<JSONObject>|undefined) {
        this.setState({allResults});
    }
    setResultInstances(resultInstances: Array<JSONObject>|undefined) {
        this.setState({resultInstances});
    }
    setResultMetricExecutionInstances(resultMetricExecutionInstances: Array<JSONObject>|undefined) {
        this.setState({resultMetricExecutionInstances});
    }
    setSourceInstance(sourceInstance: JSONObject|undefined) {
        this.setState({sourceInstance});
    }
    setSelectedResult(selectedResult: JSONObject|undefined) {
        this.setState({selectedResult});
    }
    setSelectedTab(selectedTab: string) {
        this.setState({selectedTab});
    }

    retrieveResultInstances(resetSelected?: boolean) {
        const {services, resultIdAttribute, resultUrl, instance, setLoading} = this.props;

        if (resultIdAttribute && resultUrl) {
            services.data.get(resultUrl, undefined, {db: `${resultIdAttribute}=${instance.id}`}, "page=1&size=20")
                .then((res) => {
                    const data = res.data;
                    this.setResultInstances(data);
                    if (data && data.length > 0 && resetSelected) {
                        this.setSelectedResult(data[0]);
                    }
                    setLoading && setLoading(false);
                });
        }
    }

    retrieveResultMetricExecutionInstances() {
        const {services, resultUrl, resultMetricExecutionUrl} = this.props;
        const {selectedResult} = this.state;

        if (selectedResult?.id && resultUrl) {
            services.data.get(resultUrl, selectedResult?.id, undefined, undefined, undefined, resultMetricExecutionUrl)
                .then((res) => {
                    const data = res.data;
                    this.setResultMetricExecutionInstances(data);
                });
        }
    }

    updateSourceInstance() {
        const {services, instance, sourceIdAttribute, sourceUrl} = this.props;

        if (instance[sourceIdAttribute]) {
            services.data.get(sourceUrl, instance[sourceIdAttribute])
                .then((res) => {
                    const data = res.data;
                    this.setSourceInstance(data);
                });
        }
    }

    // Add new result instances on event (ie. instances change)
    addNewResultInstances() {
        const {instances, resultKey} = this.props;
        const {resultInstances} = this.state;

        if (instances && resultKey && resultInstances && instances[resultKey]?.instances && resultInstances.length > 0 && instances[resultKey]?.instances.length > 0
            && instances[resultKey]?.instances[0].associatedAutomationId === resultInstances[0].associatedAutomationId) {
            let newInstances = false;
            const prevResultIds = [];
            const newResultInstances = [...resultInstances as JSONObject[]];
            for (const resultInstance of resultInstances) {
                prevResultIds.push(resultInstance.id);
            }
            for (const _instance of instances[resultKey]?.instances) {
                // if new flag it and add it
                if (prevResultIds && prevResultIds.length > 0 && !prevResultIds.includes(_instance.id)) {
                    newInstances = true;
                    //_instance.new = true;
                    newResultInstances.unshift(_instance);
                }
            }
            if (newInstances) {
                this.setResultInstances(newResultInstances);
            }
        }
    }

    handleOnTableSelect(selectedId: string) {
        const {resultInstances, selectedResult} = this.state;

        if(resultInstances && selectedId !== selectedResult?.id) {
            this.setSelectedResult(getById(resultInstances, selectedId));
        }
    }

    handleOnTabSelect(tabId: string) {
        this.setSelectedTab(tabId);
    }

    render() {
        const {props} = this;

        const {
            instance,
            schemaAttribute,
            listAttributes,
            resultAttribute,
            selected,
            editMode,
            resultMetricExecutionSchema,
            useSelf,
        } = props;

        const {
            allResults,
            resultInstances,
            resultMetricExecutionInstances,
            sourceInstance,
            selectedResult,
            selectedTab,
        } = this.state;

        const schema = sourceInstance && sourceInstance[schemaAttribute];

        if(!useSelf && !resultInstances) {
            return <></>
        }

        //console.log("sourceInstance", sourceInstance);
        //console.log("schema", schema);
        //console.log("instance", instance);
        //console.log("resultAttribute", resultAttribute);

        return (
            <div className={"output-schema-container"}>
                <hr />
                {!useSelf && resultInstances && resultInstances.length > 0 && !editMode &&
                    <div className={"output-list-container"}>
                        {selectedTab !== 'all' && <OutputListTable
                            title={"Results"}
                            className={"output-list-table"}
                            instances={resultInstances}
                            onSelect={this.handleOnTableSelect}
                            listAttributes={listAttributes}
                            selectedResult={selectedResult}
                        />}
                        <Tabs
                            onChange={this.handleOnTabSelect}
                            className={"output-schema-tabs"}
                            id={"tabs"}
                            defaultSelectedTabId={"result"}
                            selectedTabId={selectedTab}
                            renderActiveTabPanelOnly
                        >
                            <Tab id="result" title="Result" panel={
                                <div className={"output-schema-results"}>
                                    <OutputDataTable
                                        {...props}
                                        className={"output-data-table"}
                                        instance={selectedResult}
                                        schema={schema}
                                        resultAttribute={resultAttribute}
                                    />
                                </div>
                            }/>
                            <Tab id="json" title="JSON" panel={
                                <div className={"output-schema-results"}>
                                    <JSONEditor
                                        selected={selected}
                                        className={"output-data-json"}
                                        id={"json"}
                                        disabled={true}
                                        onChange={() => {}}
                                        value={selectedResult && selectedResult[resultAttribute] ? selectedResult[resultAttribute] : {}}
                                    />
                                </div>
                            }/>
                            <Tab id="raw" title="Raw" panel={
                                <div className={"output-schema-results"}>
                                    <JSONEditor
                                        selected={selected}
                                        className={"output-data-json"}
                                        id={"raw"}
                                        disabled={true}
                                        onChange={() => {}}
                                        value={selectedResult && selectedResult['rawResponse'] ? selectedResult['rawResponse'] : {}}
                                    />
                                </div>
                            }/>
                            <Tab id="request" title="Request" panel={
                                <div className={"output-schema-results"}>
                                    <JSONEditor
                                        selected={selected}
                                        className={"output-data-json"}
                                        id={"request"}
                                        disabled={true}
                                        onChange={() => {}}
                                        value={selectedResult && selectedResult['request'] ? selectedResult['request'] : {}}
                                    />
                                </div>
                            }/>
                            {resultMetricExecutionInstances && resultMetricExecutionInstances.length > 0 && <Tab id="metrics" title="Metrics" panel={
                                <div className={"output-schema-results"}>
                                    <HorizontalDataTable
                                        {...props}
                                        className={"all-list-table"}
                                        results={resultMetricExecutionInstances}
                                        schema={resultMetricExecutionSchema}
                                        allResultsView
                                    />
                                </div>
                            }/>}
                            {schema?.views?.allResults &&
                                <Tab id="all" title="All" panel={
                                    <div className={"output-schema-results"}>
                                        <HorizontalDataTable
                                            {...props}
                                            className={"all-list-table"}
                                            results={allResults}
                                            schema={schema}
                                            allResultsView
                                        />
                                    </div>
                                }/>
                            }
                        </Tabs>
                    </div>
                }
                {useSelf &&
                    <div className={"output-schema-results"}>
                        <OutputDataTable
                            {...props}
                            className={"output-data-table"}
                            instance={instance}
                            schema={schema}
                            resultAttribute={resultAttribute}
                        />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    instances: state.instances
});

export default connect(mapStateToProps)(OutputSchema);
