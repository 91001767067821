import * as React from "react";

import {Button, Classes, Intent} from "@blueprintjs/core";
import {FC, ReactNode} from "react";
import {Services} from "../../../../services/Services";

export interface InstanceEditorPanelProps {
    services: Services;
    showBackButton?: boolean;
    showNextButton?: boolean;
    showSaveButton?: boolean;
    showCancelButton?: boolean;
    onBackClick?: () => void;
    onNextClick?: () => void;
    onSaveClick?: () => void;
    onCancelClick?: () => void;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    children?: ReactNode;
}

export const InstanceEditorPanel: FC<InstanceEditorPanelProps> = (props) => {

    const {editMode, showNextButton, showBackButton, showSaveButton, showCancelButton, onBackClick, onNextClick, onSaveClick, onCancelClick} = props;

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                onSaveClick && onSaveClick();
            }}
        >
            {props.children}
            {editMode &&
                <div className={'instance-editor-panel-buttons'}>
                    {showBackButton && <Button className={'instance-editor-panel-button'} onClick={onBackClick} intent={Intent.PRIMARY} icon="step-backward" text={"Back"}/>}
                    {showNextButton && <Button className={'instance-editor-panel-button'} onClick={onNextClick} intent={Intent.PRIMARY} icon="step-forward" text={"Next"}/>}
                    {showSaveButton && <Button type={'submit'} className={'instance-editor-panel-button'} intent={Intent.SUCCESS} icon="floppy-disk" text={"Save"}/>}
                    {showCancelButton && <Button className={'instance-editor-panel-button'} onClick={onCancelClick} intent={Intent.DANGER} icon="undo" text={"Cancel"}/>}
                </div>
            }
        </form>
    );
}
