import * as React from "react";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";

export const AutomationPolicyList: FC<InstanceListProps> = (props) => {
    return (
        <BaseInstanceList
            {...props}
            instanceListAttributes={[
                new InstanceListAttribute({
                    name: AutomationPolicy._name,
                    columnWidth: 200
                }),
                new InstanceListAttribute({
                    name: AutomationPolicy._type,
                    columnWidth: 60,
                }),
                new InstanceListAttribute({
                    name: AutomationPolicy._discoveryRetentionMode,
                    columnWidth: 120,
                }),
                new InstanceListAttribute({
                    name: AutomationPolicy._retentionMode,
                    columnWidth: 120,
                }),
                new InstanceListAttribute({
                    name: AutomationPolicy._scope,
                    columnWidth: 100,
                }),
            ]}
        />
    );
};
