
import * as React from "react";
import {Services} from "../../../services/Services";
import {Selected} from "../../../model/Selected";
import Pluralize from 'pluralize';
import {Instance} from "common-models-ts";
import {CustomComponentProps} from "../../main/CustomComponentProps";
import {Link} from "react-router-dom";
import {Deployment} from "raasify-models-specification-ts/user/Deployment";
import {Icon} from "@blueprintjs/core";
import {IconName} from "@blueprintjs/icons";


export interface SideBarEntry {
    class?: typeof Instance,
    label?: string,
    component?: React.FC<CustomComponentProps>,
    icon?: string,
}

export interface SideBarProps
{
    layout: {[category: string]: Array<SideBarEntry>};
    selected: Selected;
    selectedDeployment: Deployment;
    services: Services;
    selectedPage: string;
}

export const SideBar: React.FC<SideBarProps> = props => {
    const {layout, selected, selectedPage, selectedDeployment} = props;

    return (
        <div className={'sidebar-container'}>
            {Object.keys(layout).map((category) => {
                const categoryLayout = layout[category];
                return (
                    <div key={category} className={'sidebar-category-container'}>
                        <div className={'sidebar-category'}>{category}</div>
                        {categoryLayout.map((item) => {
                            //console.log("selected", selected, "item", item);
                            const itemSelectedClass = item.class
                                ? selected.specification?.name === item.class?.specification.name ? 'sidebar-category-item-selected' : ''
                                : selected.component?.name === item.component?.name ? 'sidebar-category-item-selected' : ''

                            const link = `/deployments/${selectedDeployment?.id}/${selectedPage}/${item.component ? item.component.name : item.class?.specification.url}`;

                            const isSelected = item.class
                                ? selected.specification?.name === item.class?.specification.name ? true : false
                                : selected.component?.name === item.component?.name ? true : false
                            const iconColor = isSelected ? 'white' : 'black';
                            return (
                                <Link className={"text-decoration-none"} to={link} key={item.class ? item.class?.specification.name: item.label}>
                                    <div
                                        className={'sidebar-category-item ' + itemSelectedClass}
                                    >
                                        {item.icon &&
                                            <Icon color={iconColor} icon={item.icon as IconName} size={15} style={{marginRight: '8px'}}/>
                                        }
                                        {item.label ? item.label : Pluralize(item.class?.specification.label as string)}
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    );
};
