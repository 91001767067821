import * as React from "react";

import {AutomationExecution} from "raasify-models-specification-ts/core/AutomationExecution";
import {Automation} from "raasify-models-specification-ts/core/Automation";
import {AutomationPolicy} from "raasify-models-specification-ts/core/AutomationPolicy";
import {AutomationTemplate} from "raasify-models-specification-ts/core/AutomationTemplate";
import {MetricExecution} from "raasify-models-specification-ts/core/MetricExecution";
import {Endpoint} from "raasify-models-specification-ts/core/Endpoint";
import {Transformer} from "raasify-models-specification-ts/core/Transformer";
import {getSpecification} from "../../../../util/specificationUtil";
import {FC} from "react";
import {BaseInstanceEdit} from "../../../../../common/components/workbench/instance/edit/BaseInstanceEdit";
import {InstanceEditTab} from "../../../../../common/components/workbench/instance/edit/InstanceEditTab";
import {TabAttributeRow} from "../../../../../common/components/workbench/instance/edit/TabAttributeRow";
import {TabAttribute} from "../../../../../common/components/workbench/instance/edit/TabAttribute";
import {InstanceEditTabs} from "../../../../../common/components/workbench/instance/edit/InstanceEditTabs";
import {TabAttributeRelatedAttribute} from "../../../../../common/model/TabAttributeRelatedAttribute";
import {getFromInputs} from "../../../../../common/utils/instanceUtil";
import {getRandomInt} from "../../../../../common/utils/numberUtil";
import {InputSchema} from "../../../../../common/components/workbench/common/schema/InputSchema";
import OutputSchema from "../../../../../common/components/workbench/common/schema/OutputSchema";
import {ListAttribute} from "../../../../../common/model/ListAttribute";
import {InstanceEditProps} from "../../../InstanceEdit";


const resultMetricExecutionSchema = {
    views: {
        allResults: {
            columns: {
                "associatedMetricName": {width: 120, label: "Metric"},
                "result": {width: 120, label: "Result"},
                "value": {width: 90, label: "Value"},
                "occurrences": {width: 100, label: "Occurrences"},
                "evaluation": {width: 220, label: "Evaluation"},
            },
        }
    }
}


const AutomationEdit: FC<InstanceEditProps> = (props) => {
    return (
        <BaseInstanceEdit
            {...props}
            specification={getSpecification(Automation.getKey())}
        >
            <InstanceEditTabs
                showBackButton={false}
                showNextButton={false}
                showSaveButton={true}
                showCancelButton={true}
                isReadOnly={false}
            >
                <InstanceEditTab noHeader>
                    <TabAttributeRow>
                        <TabAttribute
                            style={{width: "25%"}}
                            name={Automation._associatedAutomationPolicyId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(AutomationPolicy._name, 9),
                                new TabAttributeRelatedAttribute(AutomationPolicy._packageLabel, 3),
                            ]}
                            filter={(item: any) => item.type === AutomationPolicy.type.User}
                        />
                        <TabAttribute
                            style={{width: "25%"}}
                            name={Automation._associatedEndpointId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(Endpoint._name, 5),
                                new TabAttributeRelatedAttribute(Endpoint._description, 7),
                            ]}
                            hidden={(inputs: any) => {
                                const automationPolicy: AutomationPolicy = getFromInputs(Automation._associatedAutomationPolicyId, inputs);
                                return !automationPolicy || !(!automationPolicy.associatedEndpointTemplateEdgeService);
                            }}
                            filter={(item: any, inputs: any) => {
                                const automationPolicy: AutomationPolicy = getFromInputs(Automation._associatedAutomationPolicyId, inputs);
                                return !automationPolicy || item.associatedEndpointTemplateId === automationPolicy.associatedEndpointTemplateId;
                            }}
                        />
                        <TabAttribute
                            style={{width: "25%"}}
                            name={Automation._associatedEdgeId}
                            disableRelatedAddNew
                            relatedAttributes={[
                                new TabAttributeRelatedAttribute(Endpoint._name, 5),
                                new TabAttributeRelatedAttribute(Endpoint._description, 7),
                            ]}
                            hidden={(inputs: any) => {
                                const automationPolicy: AutomationPolicy = getFromInputs(Automation._associatedAutomationPolicyId, inputs);
                                return !automationPolicy || !automationPolicy.associatedEndpointTemplateEdgeService;
                            }}
                        />
                        <TabAttribute style={{width: "25%"}} name={Automation._status} disabled />
                    </TabAttributeRow>
                    <TabAttributeRow>
                        <TabAttribute style={{width: "50%"}} name={AutomationPolicy._name} default={(inputs: any) => {
                            const automationPolicy = getFromInputs(Automation._associatedAutomationPolicyId, inputs);
                            const endpoint = getFromInputs(Automation._associatedEndpointId, inputs);
                            const edge = getFromInputs(Automation._associatedEdgeId, inputs);

                            if(endpoint && automationPolicy && inputs.instance && inputs.prevInstance &&
                                    (inputs.instance.associatedAutomationPolicyId != inputs.prevInstance.associatedAutomationPolicyId
                                        || inputs.instance.associatedEndpointId != inputs.prevInstance.associatedEndpointId)
                                ) {
                                return `${endpoint.name} - ${automationPolicy.name} - ${getRandomInt(9999)}`;
                            }
                            else if(edge && automationPolicy && inputs.instance && inputs.prevInstance &&
                                (inputs.instance.associatedAutomationPolicyId != inputs.prevInstance.associatedAutomationPolicyId
                                || inputs.instance.associatedEdgeId != inputs.prevInstance.associatedEdgeId)
                                ) {
                                return `${edge.name} - ${automationPolicy.name} - ${getRandomInt(9999)}`;
                            }
                            return inputs.instance && inputs.instance.associatedAutomationPolicyId
                                && (inputs.instance.associatedEndpointId || inputs.instance.associatedEdgeId) ? undefined : null;
                        }}/>
                        <TabAttribute style={{width: "50%"}} name={AutomationPolicy._description} default={(inputs: any) => {
                            const automationPolicy = getFromInputs(Automation._associatedAutomationPolicyId, inputs);
                            const endpoint = getFromInputs(Automation._associatedEndpointId, inputs);
                            if(automationPolicy && endpoint && inputs.instance && inputs.prevInstance &&
                                (inputs.instance.associatedAutomationPolicyId != inputs.prevInstance.associatedAutomationPolicyId
                                    || inputs.instance.associatedEndpointId != inputs.prevInstance.associatedEndpointId)
                            ) {
                                return `${automationPolicy.name} running on ${endpoint.name}`;
                            }
                            return inputs.instance && inputs.instance.associatedAutomationPolicyId
                                && (inputs.instance.associatedEndpointId || inputs.instance.associatedEdgeId) ? undefined : null;
                        }}/>
                    </TabAttributeRow>
                    <InputSchema
                        {...props}
                        sourceUrl={AutomationTemplate.getUrl()}
                        sourceIdAttribute={AutomationPolicy._associatedAutomationTemplateId}
                        schemaAttribute={AutomationTemplate._inputSchema}
                        policyUrl={AutomationPolicy.getUrl()}
                        policyIdAttribute={Automation._associatedAutomationPolicyId}
                        policyInputAttribute={AutomationPolicy._inputData}
                    />
                    <OutputSchema
                        {...props}
                        sourceUrl={Transformer.getUrl()}
                        sourceIdAttribute={Automation._associatedTransformerId}
                        schemaAttribute={Transformer._outputSchema}
                        resultUrl={AutomationExecution.getUrl()}
                        resultKey={AutomationExecution.getKey()}
                        resultIdAttribute={AutomationExecution._associatedAutomationId}
                        resultAttribute={AutomationExecution._transformedResponse}
                        resultMetricExecutionUrl={MetricExecution.getUrl()}
                        resultMetricExecutionSchema={resultMetricExecutionSchema}
                        listAttributes={[
                            new ListAttribute(AutomationExecution._startDateTime, "Started", 100),
                            new ListAttribute(AutomationExecution._result, "Result", 60),
                        ]}
                    />
                </InstanceEditTab>
            </InstanceEditTabs>
        </BaseInstanceEdit>
    );
}

export default AutomationEdit;

