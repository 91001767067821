import * as React from "react";
import {DiscoveredEdge} from "raasify-models-specification-ts/core/DiscoveredEdge";
import {InstanceListAttribute} from "../../../../../common/model/InstanceListAttribute";
import {FC} from "react";
import {BaseInstanceList} from "../../../../../common/components/workbench/instance/list/BaseInstanceList";
import {InstanceListProps} from "../../../InstanceList";
import {convertDateTime, logo} from "../../../workbench/components/list/InstanceListUtil";
import {Tooltip2} from "@blueprintjs/popover2";
import {Icon} from "@blueprintjs/core";
import {IconName} from "@blueprintjs/icons";

export const DiscoveredEdgeList: FC<InstanceListProps> = (props) => {
    const getStatus = (discoveredEdge: DiscoveredEdge) => {
        if(discoveredEdge.connected) {
            return {
                icon: 'full-circle',
                color: 'green',
                msg: 'Connected',
            }
        }
        else {
            return {
                icon: 'error',
                color: 'red',
                msg: 'Disconnected',
            }
        }
    }

    return (
        <BaseInstanceList
            {...props}

            instanceListCard={
                (instance: any, isRelatedInstance) => {
                    const discoveredEdge = instance as DiscoveredEdge;
                    const status = getStatus(discoveredEdge);
                    return (
                        <>
                            {!isRelatedInstance && <div className={"instance-list-logo"}>{logo(discoveredEdge)}</div>}
                            {isRelatedInstance && <div className={"instance-list-margin"}></div>}
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 5,
                                }}
                            >
                                <Tooltip2
                                    content={<div className={'tooltip-content'}>{status.msg}</div>}
                                    openOnTargetFocus={false}
                                    placement="bottom-end"
                                    usePortal={true}
                                >
                                    <Icon icon={status.icon as IconName} size={12} color={status.color} />
                                </Tooltip2>
                            </div>
                            <div
                                className={"instance-list-card-attribute"}
                                style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 5,
                                }}
                            >
                                {discoveredEdge.lastActivationRequestStatus}
                            </div>

                            <div className={'normal-row-div'}>
                                <div className={"instance-list-card-name"}>{convertDateTime(discoveredEdge.created as string)}</div>
                                <div className={"instance-list-card-attribute"}>{(discoveredEdge.edgeInfo as any)?.publicIPAddress?.IPAddress}</div>
                                <div className={"instance-list-card-attribute"}>{(discoveredEdge.edgeInfo as any)?.publicIPAddress?.city}</div>
                            </div>
                        </>
                    )
                }
            }

            instanceListAttributes={[
                new InstanceListAttribute({
                    name: DiscoveredEdge._created,
                }),
                new InstanceListAttribute({
                    name: DiscoveredEdge._edgeInfo,
                    filter: 'publicIPAddress.IPAddress',
                }),
                new InstanceListAttribute({
                    name: DiscoveredEdge._edgeInfo,
                    filter: 'publicIPAddress.city',
                }),
            ]}
        />
    );
};

