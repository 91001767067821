import {InstanceMode} from "../enums/InstanceMode";

export class InstanceMenuItem {
    private readonly _mode: InstanceMode;
    private readonly _relatedSpecKey: string|undefined;
    private readonly _label: string|undefined;
    private readonly _icon: string|undefined;

    constructor({mode, relatedSpecKey, label, icon}: {mode: InstanceMode, relatedSpecKey?:string, label?:string, icon?:string}) {
        this._mode = mode;
        this._relatedSpecKey = relatedSpecKey;
        this._label = label;
        this._icon = icon;
    }

    get mode(): InstanceMode {
        return this._mode;
    }

    get relatedSpecKey(): string | undefined {
        return this._relatedSpecKey;
    }

    get label(): string | undefined {
        return this._label;
    }

    get icon(): string | undefined {
        return this._icon;
    }
}
